import {put, select, take, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import modalIds from '../../modalIds';

const deleteCPContactDataFlow = function* deleteCPContactDataFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.INITIATE_DELETE_CP_CONTACT_DATA_FLOW);
        const {contactDataId, contractPartnerId} = payload;

        yield* openModal(modalIds.DELETE_CP_CONTACT_DATA_CONFIRMATION);

        const responseAction = yield take([
            contractPartnerActionTypes.CONFIRM_DELETE_CP_CONTACT_DATA,
            contractPartnerActionTypes.DECLINE_DELETE_CP_CONTACT_DATA,
        ]);

        if (responseAction.type === contractPartnerActionTypes.CONFIRM_DELETE_CP_CONTACT_DATA) {
            yield fork(
                fetchRequest,
                contractPartnerActionTypes.DELETE_CP_CONTACT_DATA_REQUEST,
                partnerManagementService.deleteCPContactData,
                {
                    contactDataId,
                },
            );

            const deleteCPOperatingUnitResponseAction = yield take([
                contractPartnerActionTypes.DELETE_CP_CONTACT_DATA_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.DELETE_CP_CONTACT_DATA_REQUEST_FAILED,
            ]);

            if (!deleteCPOperatingUnitResponseAction.error) {
                yield put({
                    type: contractPartnerActionTypes.REMOVE_CP_CONTACT_DATA,
                    payload: {
                        contractPartnerId,
                        contactDataId,
                    },
                });
            }
        }

        yield* closeModal(modalIds.DELETE_CP_CONTACT_DATA_CONFIRMATION);
    }
};

export default deleteCPContactDataFlow;
