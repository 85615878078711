/**
 * Route paths
 */
export default {
    ROOT: '/',
    DASHBOARD_SECTION: '/dashboard',
    DASHBOARD: '/dashboard/',
    CONTRACT_PARTNER_SEARCH: '/dashboard/contract-partner-search',
    CONTRACT_PARTNER: '/contract-partners/:contractPartnerId',
    QUALITY_MANAGEMENT_FEEDBACKS_SEARCH: '/dashboard/quality-management-feedbacks-search',
    QUALITY_MANAGEMENT_FEEDBACK: '/quality-management-feedback/:qualityManagementFeedbackId',
    PRICE_MANAGEMENT: '/dashboard/price-management',
    KEYCLOAK_AUTHORIZE: '/oidc/authorize',
    KEYCLOAK_AUTHENTICATE: '/oidc/authenticate',
    ARCGIS_AUTHORIZE: '/oauth2/authorize',
    ARCGIS_AUTHENTICATE: '/oauth2/authenticate',
    SYSTEM_ACCESS_DENIED: '/access/403',
    ROUTE_ACCESS_DENIED: '/403',
};
