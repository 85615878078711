import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {persistenceStates} from '@computerrock/formation-core';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, ButtonPrimary, Divider, LoaderSpinner, HighlightCircle} from '@ace-de/ui-components';
import {Icon, checkmarkIcon, closeIcon, editIcon, infoAlertIcon} from '@ace-de/ui-components/icons';
import * as contractPartnerSelectors from './contractPartnerSelectors';
import * as contractPartnerActionTypes from './contractPartnerActionTypes';

const ContractPartnerEllaActivePanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_basic_data_tab');
    const {contractPartner, initiateCPCreateELLAAccount, initiateCPEditELLAAccount} = props;
    const {ellaAccountPersistenceState} = props;

    // if no contract partner, don't render
    if (!contractPartner) return null;

    return (
        <Panel
            title={translateTab('panel_title.invoice')}
            actions={!contractPartner.affiliatedPartner && contractPartner.hasELLAAccount
                ? (<Icon icon={editIcon} onClick={initiateCPEditELLAAccount} />)
                : (<Fragment />)}
        >
            {ellaAccountPersistenceState === persistenceStates.PENDING && (
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-full-width',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--justify-content-center',
                        'global!ace-u-margin--24-0',
                    ])}
                >
                    <LoaderSpinner />
                </div>
            )}
            {ellaAccountPersistenceState === persistenceStates.FAILED && (
                <div
                    className={cx([
                        'global!ace-u-width--full',
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--justify-center',
                        'global!ace-u-margin--top-24',
                        'global!ace-u-typography--variant-caption',
                        'global!ace-u-typography--color-warning',
                    ])}
                >
                    <HighlightCircle
                        className={cx([
                            `ace-c-highlight-circle--medium`,
                            `ace-c-highlight-circle--negative`,
                            'global!ace-u-margin--bottom-32',
                        ])}
                    >
                        <Icon
                            className={cx([
                                'ace-c-icon--xl',
                                'global!ace-c-icon--color-warning',
                            ])}
                            icon={infoAlertIcon}
                        />
                    </HighlightCircle>
                    <p
                        className={cx([
                            'global!ace-u-typography--align-center',
                            'global!ace-u-typography--variant-body-bold',
                            'global!ace-u-typography--color-medium-emphasis',
                        ])}
                    >
                        {translateTab('error_message.could_not_load_ella_account')}
                    </p>
                </div>
            )}
            {ellaAccountPersistenceState === persistenceStates.READY && (
                <Fragment>
                    <div
                        className={cx([
                            'global!ace-u-full-width',
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-space-between',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-padding--16',
                        ])}
                    >
                        <span className={cx('global!ace-u-typography--variant-body')}>
                            {translate(`contract_partner_basic_data_tab.label.is_ella_account_active`)}
                        </span>
                        <Icon
                            icon={(contractPartner.ellaActive && contractPartner.hasELLAAccount)
                            || (contractPartner.affiliatedPartner?.ellaActive
                                && contractPartner.affiliatedPartner?.hasELLAAccount)
                                ? checkmarkIcon : closeIcon}
                            className={cx((contractPartner.ellaActive && contractPartner.hasELLAAccount)
                            || (contractPartner.affiliatedPartner?.ellaActive
                                && contractPartner.affiliatedPartner?.hasELLAAccount)
                                ? 'ace-c-icon--color-success'
                                : 'ace-c-icon--color-warning')}
                        />
                    </div>
                    {!contractPartner.affiliatedPartner && !contractPartner.hasELLAAccount && (
                        <Fragment>
                            <Divider />
                            <ButtonPrimary
                                name="createELLAAccountButton"
                                onClick={() => initiateCPCreateELLAAccount({
                                    contractPartnerId: contractPartner.id,
                                    contractPartnerName: contractPartner.name,
                                    affiliatePartnersIds: contractPartner.affiliatePartners?.length
                                        ? contractPartner.affiliatePartners.map(cp => cp.id).filter(id => !!id)
                                        : [],
                                })}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--top-32',
                                ])}
                            >
                                {translateTab('button_label.create_ella_account')}
                            </ButtonPrimary>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Panel>
    );
};

ContractPartnerEllaActivePanel.propTypes = {
    contractPartner: PropTypes.object,
    initiateCPCreateELLAAccount: PropTypes.func.isRequired,
    initiateCPEditELLAAccount: PropTypes.func.isRequired,
    ellaAccountPersistenceState: PropTypes.string.isRequired,
};

ContractPartnerEllaActivePanel.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
        ellaAccountPersistenceState: state.contractPartners.ellaAccountPersistenceState,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateCPCreateELLAAccount: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CP_CREATE_ELLA_ACCOUNT_FLOW,
        payload,
    }),
    initiateCPEditELLAAccount: () => dispatch({
        type: contractPartnerActionTypes.INITIATE_CP_EDIT_ELLA_ACCOUNT_FLOW,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerEllaActivePanel));
