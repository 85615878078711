import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter, resolveRoute} from '@computerrock/formation-router';
import {useArcGISMap} from '@ace-de/eua-arcgis-map';
import {useStyles} from '@ace-de/ui-components';
import routePaths from '../../routePaths';
import styles from './ContractPartnerReloadButton.module.scss';

const ContractPartnerReloadButton = props => {
    const {isDisabled, history, filtersData} = props;
    const {cx} = useStyles(props, styles);
    const {translate} = useTranslate();
    const arcGISMap = useArcGISMap('vpm-contract-partners');

    const handleClick = () => {
        const newSearchQueryParams = new URLSearchParams();
        if (filtersData?.regions?.length) {
            filtersData.regions
                .forEach(reg => newSearchQueryParams.append('region', reg));
        }
        if (filtersData?.contractStatuses?.length) {
            filtersData.contractStatuses
                .forEach(status => newSearchQueryParams.append('contractStatus', status));
        }
        if (arcGISMap) {
            const [longitude, latitude] = arcGISMap.getMapCenter(true);
            newSearchQueryParams.append('lng', longitude);
            newSearchQueryParams.append('lat', latitude);
        }
        history.replace(resolveRoute(
            routePaths.DASHBOARD, {}, {search: newSearchQueryParams.toString()},
        ));
    };

    return (
        <button
            type="button"
            className={cx([
                'ace-c-contract-partner-reload-button',
                'global!ace-u-padding--24',
                'global!ace-u-typography--variant-body',
                'global!ace-u-cursor--pointer',
            ], {
                'ace-c-contract-partner-reload-button--is-disabled': !!isDisabled,
            })}
            disabled={!!isDisabled}
            onClick={handleClick}
        >
            {!isDisabled
                ? translate('dashboard_screen.reload_button_label.show_contractors')
                : translate('dashboard_screen.reload_button_disabled_label.zoom_in')}
        </button>
    );
};

ContractPartnerReloadButton.propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    history: PropTypes.object,
    filtersData: PropTypes.object,
};

ContractPartnerReloadButton.defaultProps = {
    history: {},
    filtersData: null,
};

const mapStateToProps = state => {
    return {
        isDisabled: state.contractPartners.isCPReloadButtonDisabled,
    };
};

export default withRouter(connect(mapStateToProps, null)(ContractPartnerReloadButton));
