import React from 'react';
import PropTypes from 'prop-types';
import {useStyles, Icon, attachmentIcon} from '@ace-de/ui-components';

const QMFeedbackCaseLogAttachment = props => {
    const {cx} = useStyles(props);
    const {attachment, handleOnDownload} = props;

    if (!attachment) return null;

    return (
        <span
            className={cx([
                'global!ace-u-flex',
                'global!ace-u-flex--align-center',
                'global!ace-u-typography--variant-body',
                'global!ace-u-typography--color-highlighted',
                'global!ace-u-cursor--pointer',
                'global!ace-u-margin--bottom-4',
            ])}
            onClick={() => handleOnDownload(attachment)}
        >
            <Icon
                icon={attachmentIcon}
                className={cx([
                    'global!ace-u-margin--right-8',
                    'ace-c-icon--color-highlight',
                ])}
            />
            {attachment.fileName}
        </span>
    );
};

QMFeedbackCaseLogAttachment.propTypes = {
    attachment: PropTypes.object,
    handleOnDownload: PropTypes.func.isRequired,
};

QMFeedbackCaseLogAttachment.defaultProps = {
    attachment: null,
};

export default QMFeedbackCaseLogAttachment;
