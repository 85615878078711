import {fork, put, select, take} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import * as qualityManagementActionTypes from '../../quality-management-feedbacks/qualityManagementFeedbackActionTypes';

/**
 * Search Quality Management Feedbacks
 */
const searchCPQualityManagementFeedbacks = function* searchCPQualityManagementFeedbacks({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const {searchQueryParams, contractPartnerId} = payload;

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST,
        partnerManagementService.getQualityManagementFeedbacks,
        {
            searchQueryParams,
            contractPartnerId,
        },
    );

    const responseAction = yield take([
        contractPartnerActionTypes.SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_FAILED,
    ]);

    if (responseAction.error) return;

    const {response} = responseAction.payload;

    const {
        acePartnerQualityManagementFeedbackDTOs: qualityManagementFeedbacksSearchResults,
        totalCount: qualityManagementFeedbacksSearchCount,
        countPositive: positiveQMFeedbacksCount,
        countNegative: negativeQMFeedbacksCount,
    } = response;

    yield put({
        type: contractPartnerActionTypes.STORE_CP_QUALITY_MANAGEMENT_FEEDBACKS_SEARCH_RESULTS,
        payload: {
            qualityManagementFeedbacksSearchResults,
            qualityManagementFeedbacksSearchCount,
            positiveQMFeedbacksCount,
            negativeQMFeedbacksCount,
        },
    });

    yield put({
        type: qualityManagementActionTypes.STORE_QUALITY_MANAGEMENT_FEEDBACKS,
        payload: {qualityManagementFeedbackDTOs: qualityManagementFeedbacksSearchResults},
    });
};

export default searchCPQualityManagementFeedbacks;
