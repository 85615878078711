import {fork, select, take} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as qualityManagementActionTypes from '../qualityManagementFeedbackActionTypes';

const downloadQualityManagementFeedbacks = function* downloadQualityManagementFeedbacks() {
    const {serviceManager} = yield select(state => state.application);
    const apmFileAssetsService = serviceManager.loadService('apmFileAssetsService');

    while (true) {
        const {payload} = yield take(qualityManagementActionTypes.INITIATE_QUALITY_MANAGEMENT_FEEDBACKS_DOWNLOAD_FLOW);
        const {fileName, url} = payload;

        yield fork(
            fetchRequest,
            qualityManagementActionTypes.DOWNLOAD_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST,
            apmFileAssetsService.downloadFile,
            {
                fileName,
                url,
            },
        );
        yield take([
            qualityManagementActionTypes.DOWNLOAD_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_SUCCEEDED,
            qualityManagementActionTypes.DOWNLOAD_QUALITY_MANAGEMENT_FEEDBACKS_REQUEST_FAILED,
        ]);
    }
};

export default downloadQualityManagementFeedbacks;
