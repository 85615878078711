import {take, put, fork, select} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {persistenceStates} from '@computerrock/formation-core';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import modalIds from '../../modalIds';
import errorTypes from '../../application/errorTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import determineActionTypes from '../../application/determineActionTypes';

const cpFixedPriceModalFlow = function* cpFixedPriceModalFlow() {
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    let shouldWaitForAction = true;
    let selectedModalOption = null;
    let errorType = null;
    let isErrorModalOpen = false;
    while (true) {
        if (shouldWaitForAction) {
            yield take(priceManagementActionTypes.INITIATE_CONTRACT_PARTNER_FIXED_PRICE_EDIT_FLOW);
            yield* openModal(modalIds.CP_FIXED_PRICE_MODAL);
        }
        if (!isErrorModalOpen) {
            selectedModalOption = yield take([
                priceManagementActionTypes.DECLINE_CONTRACT_PARTNER_FIXED_PRICE_EDIT_FLOW,
                priceManagementActionTypes.CONFIRM_CONTRACT_PARTNER_FIXED_PRICE_EDIT_FLOW,
            ]);
        }

        if (selectedModalOption?.type === priceManagementActionTypes.DECLINE_CONTRACT_PARTNER_FIXED_PRICE_EDIT_FLOW) {
            shouldWaitForAction = true;
            yield* closeModal(modalIds.CP_FIXED_PRICE_MODAL);
        }

        if (selectedModalOption
            && selectedModalOption.type === priceManagementActionTypes.CONFIRM_CONTRACT_PARTNER_FIXED_PRICE_EDIT_FLOW) {
            const {fixedPriceData, contractPartnerId, isFixedPrice, activePeriod} = selectedModalOption.payload;
            shouldWaitForAction = false;

            if (activePeriod === 'NEW') {
                const searchQueryParams = new URLSearchParams();
                searchQueryParams.append('contractPartner', contractPartnerId);
                Object.keys(fixedPriceData).forEach(key => {
                    searchQueryParams.append(`${key}`, fixedPriceData[key]);
                });

                yield fork(
                    fetchRequest,
                    priceManagementActionTypes.CREATE_CONTRACT_PARTNER_FIXED_PRICE_PERIOD_REQUEST,
                    pricingManagementService.createPricePeriod,
                    {
                        searchQueryParams,
                    },
                );

                const responseAction = yield take([
                    priceManagementActionTypes.CREATE_CONTRACT_PARTNER_FIXED_PRICE_PERIOD_REQUEST_SUCCEEDED,
                    priceManagementActionTypes.CREATE_CONTRACT_PARTNER_FIXED_PRICE_PERIOD_REQUEST_FAILED,
                ]);

                if (!responseAction.error) {
                    if (isErrorModalOpen) {
                        yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                        yield put({
                            type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                            payload: {persistenceState: persistenceStates.READY},
                        });
                        yield* openModal(modalIds.CP_FIXED_PRICE_MODAL);
                        isErrorModalOpen = false;
                        errorType = null;
                    }
                    const {response} = responseAction.payload;
                    const {pricePeriodDTOs} = response;
                    yield put({
                        type: priceManagementActionTypes.STORE_FIXED_PRICES,
                        payload: {contractPartnerId, pricePeriodDTOs},
                    });
                }
                if (responseAction.error) {
                    errorType = errorTypes.CP_FIXED_PRICE_CREATION_FAILED;
                }
            }

            if (activePeriod !== 'NEW') {
                if (isFixedPrice) {
                    yield fork(
                        fetchRequest,
                        priceManagementActionTypes.UPDATE_PRICES_BY_TIME_PERIOD_REQUEST,
                        pricingManagementService.updatePricesByTimePeriod,
                        {
                            pricePeriodId: activePeriod,
                            pricePeriodData: fixedPriceData,
                        },
                    );

                    const updatePricesResponseAction = yield take([
                        priceManagementActionTypes.UPDATE_PRICES_BY_TIME_PERIOD_REQUEST_SUCCEEDED,
                        priceManagementActionTypes.UPDATE_PRICES_BY_TIME_PERIOD_REQUEST_FAILED,
                    ]);

                    if (!updatePricesResponseAction.error) {
                        if (isErrorModalOpen) {
                            yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                            yield put({
                                type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                                payload: {persistenceState: persistenceStates.READY},
                            });
                            yield* openModal(modalIds.CP_FIXED_PRICE_MODAL);
                            isErrorModalOpen = false;
                            errorType = null;
                        }
                        const {response} = updatePricesResponseAction.payload;
                        const {pricePeriodDTO} = response;

                        yield put({
                            type: priceManagementActionTypes.SET_UPDATED_FIXED_PRICES,
                            payload: {
                                contractPartnerId,
                                pricePeriodId: activePeriod,
                                pricePeriodDTO,
                            },
                        });
                    }

                    if (updatePricesResponseAction.error) {
                        errorType = errorTypes.CP_FIXED_PRICE_UPDATE_FAILED;
                    }
                }
                if (!isFixedPrice) {
                    yield fork(
                        fetchRequest,
                        priceManagementActionTypes.DELETE_FIXED_PRICE_PERIOD_REQUEST,
                        pricingManagementService.deletePricePeriod,
                        {pricePeriodId: activePeriod},
                    );

                    const deletePriceResponseAction = yield take([
                        priceManagementActionTypes.DELETE_FIXED_PRICE_PERIOD_REQUEST_SUCCEEDED,
                        priceManagementActionTypes.DELETE_FIXED_PRICE_PERIOD_REQUEST_FAILED,
                    ]);

                    if (!deletePriceResponseAction.error) {
                        if (isErrorModalOpen) {
                            yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                            yield put({
                                type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                                payload: {persistenceState: persistenceStates.READY},
                            });
                            yield* openModal(modalIds.CP_FIXED_PRICE_MODAL);
                            isErrorModalOpen = false;
                            errorType = null;
                        }
                        yield put({
                            type: priceManagementActionTypes.REMOVE_DELETED_FIXED_PRICE,
                            payload: {contractPartnerId, pricePeriodId: activePeriod},
                        });
                    }
                    if (deletePriceResponseAction.error) {
                        errorType = errorTypes.CP_FIXED_PRICE_DELETION_FAILED;
                    }
                }
            }
        }
        if (errorType) {
            if (!isErrorModalOpen) {
                yield* closeModal(modalIds.CP_FIXED_PRICE_MODAL);
                yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {
                    errorType,
                });
                isErrorModalOpen = true;
            }

            yield put({
                type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                payload: {persistenceState: persistenceStates.READY},
            });
            const confirmActionType = determineActionTypes(errorType)?.confirm;
            const declineActionType = determineActionTypes(errorType)?.decline;

            const nextAction = yield take([
                confirmActionType,
                declineActionType,
            ]);

            if (nextAction.type === confirmActionType) {
                yield put({
                    type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.PENDING},
                });
            }

            if (nextAction.type === declineActionType) {
                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                yield* openModal(modalIds.CP_FIXED_PRICE_MODAL);
                isErrorModalOpen = false;
                errorType = null;
            }
        }
    }
};

export default cpFixedPriceModalFlow;
