import {take, fork, select, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {persistenceStates} from '@computerrock/formation-core';
import {EmergencyContact} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import modalIds from '../../modalIds';
import errorTypes from '../../application/errorTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';

const createCPContactDataFlow = function* createCPContactDataFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    let shouldWaitForAction = true;
    let payloadData = null;
    let chosenModalOption = null;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contractPartnerActionTypes.INITIATE_CREATE_CP_CONTACT_DATA_FLOW);
            payloadData = payload;
        }
        const {contractPartnerId, isAdditional = false} = payloadData;

        if (shouldWaitForAction) {
            yield* openModal(
                modalIds.CONTRACT_PARTNER_CONTACT_DATA,
                isAdditional ? {isAdditional: `${!!isAdditional}`} : undefined,
            );
            chosenModalOption = yield take([
                contractPartnerActionTypes.CONFIRM_CREATE_CP_CONTACT_DATA,
                contractPartnerActionTypes.DECLINE_CREATE_CP_CONTACT_DATA,
            ]);
        }

        if (chosenModalOption
            && chosenModalOption.type === contractPartnerActionTypes.CONFIRM_CREATE_CP_CONTACT_DATA
        ) {
            const {contactData} = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                contractPartnerActionTypes.CREATE_CP_CONTACT_DATA_REQUEST,
                partnerManagementService.createCPContactData,
                {
                    contactDataDTO: EmergencyContact.objectToDTO(contactData),
                },
            );

            const responseAction = yield take([
                contractPartnerActionTypes.CREATE_CP_CONTACT_DATA_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.CREATE_CP_CONTACT_DATA_REQUEST_FAILED,
            ]);

            if (responseAction.error) {
                if (shouldWaitForAction) {
                    yield* closeModal(
                        modalIds.CONTRACT_PARTNER_CONTACT_DATA,
                        isAdditional ? {isAdditional: `${!!isAdditional}`} : undefined,
                    );
                    yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {
                        errorType: errorTypes.CP_CONTACT_DATA_CREATION_FAILED,
                    });
                }

                yield put({
                    type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.READY},
                });

                const nextAction = yield take([
                    contractPartnerActionTypes.RETRY_CREATE_CP_CONTACT_DATA,
                    contractPartnerActionTypes.CANCEL_RETRY_CREATE_CP_CONTACT_DATA,
                ]);

                shouldWaitForAction = nextAction.type !== contractPartnerActionTypes.RETRY_CREATE_CP_CONTACT_DATA;

                if (nextAction.type === contractPartnerActionTypes.RETRY_CREATE_CP_CONTACT_DATA) {
                    yield put({
                        type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.PENDING},
                    });
                }

                if (nextAction.type === contractPartnerActionTypes.CANCEL_RETRY_CREATE_CP_CONTACT_DATA) {
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                }

                continue;
            }

            if (!responseAction.error) {
                if (!shouldWaitForAction) {
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});

                    yield put({
                        type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.READY},
                    });
                }
                shouldWaitForAction = true;
                const {response} = responseAction.payload;
                const {contactDataDTO} = response;

                yield put({
                    type: contractPartnerActionTypes.STORE_CP_CONTACT_DATA,
                    payload: {contactDataDTO, contractPartnerId},
                });
            }
        }

        yield* closeModal(
            modalIds.CONTRACT_PARTNER_CONTACT_DATA,
            isAdditional ? {isAdditional: `${!!isAdditional}`} : undefined,
        );
    }
};

export default createCPContactDataFlow;
