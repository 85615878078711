import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../../contract-partners/contractPartnerActionTypes';

/**
 * Search random location geolocation
 */
const searchRandomLocationGeolocation = function* searchRandomLocationGeolocation({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    yield put({
        type: contractPartnerActionTypes.SET_RANDOM_LOCATION_CANDIDATES,
        payload: {randomLocationCandidateDTOs: []},
    });

    const {searchQueryString} = payload;
    yield fork(
        fetchRequest,
        contractPartnerActionTypes.SEARCH_RANDOM_LOCATION_GEOLOCATION_REQUEST,
        arcGISRESTService.searchAddressSuggestions,
        {singleLine: searchQueryString},
    );

    const searchDamageLocationGeolocation = yield take([
        contractPartnerActionTypes.SEARCH_RANDOM_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.SEARCH_RANDOM_LOCATION_GEOLOCATION_REQUEST_FAILED,
    ]);

    if (!searchDamageLocationGeolocation.error) {
        const {response} = searchDamageLocationGeolocation.payload;
        const {arcGISLocationCandidateDTOs: randomLocationCandidateDTOs} = response;

        yield put({
            type: contractPartnerActionTypes.SET_RANDOM_LOCATION_CANDIDATES,
            payload: {
                randomLocationCandidateDTOs,
            },
        });
    }
};

export default searchRandomLocationGeolocation;
