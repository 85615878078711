import {put, take, fork, select} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as userProfileActionTypes from '../userProfileActionTypes';

const loadUserProfiles = function* loadUserProfiles() {
    const {serviceManager} = yield select(state => state.application);
    const userProfileService = serviceManager.loadService('userProfileService');

    yield fork(fetchRequest, userProfileActionTypes.FETCH_USER_PROFILES_REQUEST, userProfileService.getUserProfiles);

    const fetchUserProfilesResponseAction = yield take([
        userProfileActionTypes.FETCH_USER_PROFILES_REQUEST_SUCCEEDED,
        userProfileActionTypes.FETCH_USER_PROFILES_REQUEST_FAILED,
    ]);

    if (!fetchUserProfilesResponseAction.error) {
        const {response} = fetchUserProfilesResponseAction.payload;
        const {userDTOs} = response;

        yield put({
            type: userProfileActionTypes.STORE_USER_PROFILES,
            payload: {userDTOs},
        });
    }
};

export default loadUserProfiles;
