import {put, fork, take, select} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import {alfClientTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import contractPartnerScreenTabs from '../contractPartnerScreenTabs';
import config from '../../config';

const loadContractPartnerELOPath = function* loadContractPartnerELOPath({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    const {activeTab} = yield* selectSearchQueryParams();

    // do not load contract partner's eloPath if agent is not on that tab
    if (config.IS_PRE_BC_VERSION || activeTab !== contractPartnerScreenTabs.ELO) return;

    const {match} = payload;
    const {contractPartnerId} = match.params;
    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('ids', contractPartnerId);
    searchQueryParams.append('client', alfClientTypes.ACE);

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_ELO_PATH_REQUEST,
        leaAssignmentFlowService.getContacts,
        {
            searchQueryParams,
        },
    );

    const responseAction = yield take([
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_ELO_PATH_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_ELO_PATH_REQUEST_FAILED,
    ]);

    if (responseAction.error) return;

    const {response} = responseAction.payload;
    const {contactDTOs = []} = response;

    yield put({
        type: contractPartnerActionTypes.STORE_CONTRACT_PARTNER_ELO_PATH,
        payload: {contractPartnerId, eloPath: contactDTOs[0]?.eloPath || null},
    });
};

export default loadContractPartnerELOPath;
