import {eupUserRoleTypes, clientTypes} from '@ace-de/eua-entity-types';
import {vpmFeatures, vpmFeatureActions} from './vpmFeatures';

/**
 * VPM system available roles
 */
const vpmAuthorizationRoles = {
    [eupUserRoleTypes.CP_MANAGER]: {
        name: eupUserRoleTypes.CP_MANAGER,
        features: {
            [vpmFeatureActions.CREATE]: [
                vpmFeatures.CONTRACT_PARTNER,
                vpmFeatures.QUALITY_REPORT,
                vpmFeatures.TEMPORARY_RESTRICTION,
            ],
            [vpmFeatureActions.DELETE]: [
                vpmFeatures.CONTRACT_PARTNER,
                vpmFeatures.QUALITY_REPORT,
                vpmFeatures.TEMPORARY_RESTRICTION,
            ],
            [vpmFeatureActions.UPDATE]: [
                vpmFeatures.CONTRACT_PARTNER,
                vpmFeatures.QUALITY_REPORT,
                vpmFeatures.TEMPORARY_RESTRICTION,
            ],
            [vpmFeatureActions.READ]: [
                vpmFeatures.CONTRACT_PARTNER,
                vpmFeatures.QUALITY_REPORT,
                vpmFeatures.TEMPORARY_RESTRICTION,
            ],
        },
        systems: [clientTypes.VPM_CLIENT],
        forbiddenRoutes: [],
        allowedRoutes: [],
        layouts: {},
    },
};

export default vpmAuthorizationRoles;
