import {select, call, take, put} from 'redux-saga/effects';
import {eventChannel} from 'redux-saga';
import * as contractPartnerActionTypes from '../../contract-partners/contractPartnerActionTypes';
import config from '../../config';

export const onContractPartnerMapMouseWheel = function* onContractPartnerMapMouseWheel() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'vpm-contract-partners');

    const mapDragEventChannel = eventChannel(emitter => {
        const unsubscribeDragListener = arcGISMap.on('mouse-wheel', () => {
            emitter(true);
        });

        // returns unsubscribe method to eventChannel
        return () => {
            unsubscribeDragListener();
        };
    });

    while (true) {
        const eventOccurred = yield take(mapDragEventChannel);
        if (eventOccurred) {
            yield put({
                type: contractPartnerActionTypes.SET_CONTRACT_PARTNERS_MAP_RELOAD_BUTTON_VISIBILITY,
                payload: {
                    isVisible: !!eventOccurred,
                },
            });

            if (arcGISMap) {
                yield put({
                    type: contractPartnerActionTypes.SET_CONTRACT_PARTNERS_MAP_RELOAD_BUTTON_STATE,
                    payload: {
                        isDisabled: arcGISMap.getMapViewZoomLevel() < config.MINIMUM_MAP_ZOOM_LEVEL_FOR_SEARCHING,
                    },
                });
            }
        }
    }
};

export default onContractPartnerMapMouseWheel;
