import React from 'react';
import PropTypes from 'prop-types';
import {useStyles, Icon, linkIcon} from '@ace-de/ui-components';
import {resolveRoute, withRouter} from '@computerrock/formation-router';
import styles from '../../dashboard/ui-elements/ContractPartnerItem.module.scss';
import routePaths from '../../routePaths';

const ContractPartnerOperationAreaItem = props => {
    const {cx} = useStyles(props, styles);
    const {contractPartnerOperationArea, isSelected, onSelect, hasLink} = props;

    const handleOnClick = () => {
        if (typeof onSelect === 'function') {
            onSelect(contractPartnerOperationArea.id);
        }
    };

    const handleRedirectToContractPartnerScreen = event => {
        event.preventDefault();
        event.stopPropagation();
        const {pathname} = resolveRoute(
            routePaths.CONTRACT_PARTNER,
            {contractPartnerId: contractPartnerOperationArea.id},
        );
        window.open(pathname, '_blank');
    };

    return (
        <div
            className={cx('global!ace-u-cursor--pointer', {
                'ace-c-contract-partner-item--is-selected': isSelected,
            })}
            onClick={handleOnClick}
        >
            <div
                className={cx([
                    'global!ace-u-grid',
                    'global!ace-u-padding--left-32',
                    'global!ace-u-padding--right-32',
                    'global!ace-u-padding--top-16',
                ])}
            >
                <span
                    className={cx([
                        'global!ace-u-typography--variant-body',
                        'global!ace-u-grid-column--span-10',
                        'global!ace-u-margin--bottom-4',
                    ])}
                >
                    {contractPartnerOperationArea.name || ''}
                </span>
                {hasLink && (
                    <span
                        className={cx([
                            'global!ace-u-grid-column--span-2',
                            'global!ace-u-margin--left-8',
                        ])}
                        onClick={handleRedirectToContractPartnerScreen}
                    >
                        <Icon
                            icon={linkIcon}
                        />
                    </span>
                )}
            </div>
            <div
                className={cx([
                    'global!ace-u-typography--variant-body',
                    'global!ace-u-typography--color-disabled',
                    'global!ace-u-padding--bottom-16',
                    'global!ace-u-padding--left-32',
                    'global!ace-u-padding--right-32',
                ])}
            >
                {contractPartnerOperationArea.address
                    ? [
                        contractPartnerOperationArea.address.street,
                        contractPartnerOperationArea.address.postCode,
                        contractPartnerOperationArea.address.city,
                    ].filter(value => !!value).join(' ')
                    : [
                        contractPartnerOperationArea.location.street,
                        contractPartnerOperationArea.location.postCode,
                        contractPartnerOperationArea.location.city,
                    ].filter(value => !!value).join(' ') }
            </div>
        </div>
    );
};

ContractPartnerOperationAreaItem.propTypes = {
    contractPartnerOperationArea: PropTypes.object.isRequired,
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func,
    hasLink: PropTypes.bool,
};

ContractPartnerOperationAreaItem.defaultProps = {
    isSelected: false,
    onSelect: null,
    hasLink: false,
};

export default withRouter(ContractPartnerOperationAreaItem);
