import {put, select, take} from 'redux-saga/effects';
import {closeModal, openModal} from '@computerrock/formation-router/sagas';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import modalIds from '../../modalIds';
import bcModalContentTypes from '../modals/bcModalContentTypes';

const searchBCContactsFlow = function* searchBCContactsFlow({payload}) {
    const {client, initiateContactSearch, contactId} = payload;

    yield* openModal(modalIds.BC_CONTACT_MODAL, {
        ...(client && {client: `${client}`}),
        ...(initiateContactSearch && contactId && {ids: contactId}),
        contentType: bcModalContentTypes.CONTACT_SEARCH,
    });

    if (initiateContactSearch && contactId) {
        const searchQueryParams = new URLSearchParams();
        searchQueryParams.append(`ids`, contactId);
        searchQueryParams.append(`client`, client);

        yield put({
            type: contractPartnerActionTypes.SEARCH_BC_CONTACTS,
            payload: {searchQueryParams},
        });
    }

    yield take(contractPartnerActionTypes.DECLINE_BC_CONTACT_MODAL_FLOW);

    const {location} = yield select(state => state.router);
    yield* closeModal(modalIds.BC_CONTACT_MODAL, {...(location?.query && {...location.query})});
};

export default searchBCContactsFlow;
