import {all, call, put, select, take} from 'redux-saga/effects';
import {eventChannel} from 'redux-saga';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

const onContractPartnerMapSelectOperationArea = function* onContractPartnerMapSelectOperationArea() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    const arcGISMap = yield call(arcGISMapService.getMap, 'vpm-contract-partners-operation-areas');

    if (!arcGISMap) return;

    const vpmContractPartnersLocationsLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-operation-areas-locations');
    const vpmContractPartnersServiceAreasLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-operation-areas-service-areas');
    const vpmContractPartnerAffiliatePartnersLocationsLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-operation-areas-affiliate-partners-locations');

    const locationLayerClickEventChannel = eventChannel(emitter => {
        const unsubscribeContractPartnerClickListener = vpmContractPartnersLocationsLayer.on('feature-select', payload => {
            emitter(payload);
        });
        const unsubscribeAffiliatePartnersClickListener = vpmContractPartnerAffiliatePartnersLocationsLayer.on('feature-select', payload => {
            emitter(payload);
        });
        const unsubscribeOperatingUnitsClickListener = arcGISMap.on('graphic-click', payload => {
            emitter(payload);
        });

        return () => {
            unsubscribeContractPartnerClickListener();
            unsubscribeAffiliatePartnersClickListener();
            unsubscribeOperatingUnitsClickListener();
        };
    });

    while (true) {
        const response = yield take(locationLayerClickEventChannel);
        const {contractPartners, affiliatePartners} = yield select(state => state.contractPartners);

        if (!response.featureDTO && response.graphic) {
            const {location} = yield select(state => state.router);
            const contractPartnerId = location.pathname.split('/').reverse()[0];
            const operatingUnitId = response.graphic.attributes.graphicId.split('-')[1];
            const selectedContractPartnerOperationArea = contractPartners[contractPartnerId].operatingUnits
                .find(operatingUnit => operatingUnit.id === Number(operatingUnitId));

            if (!selectedContractPartnerOperationArea) continue;

            if (vpmContractPartnersLocationsLayer
                && vpmContractPartnerAffiliatePartnersLocationsLayer
                && vpmContractPartnersServiceAreasLayer) {
                yield all([
                    call(vpmContractPartnersLocationsLayer.selectFeatureByAttribute, {
                        where: `contractPa = ''`,
                    }),
                    call(vpmContractPartnerAffiliatePartnersLocationsLayer.selectFeatureByAttribute, {
                        where: `contractPa = ''`,
                    }),
                    call(vpmContractPartnersServiceAreasLayer.selectFeatureByAttribute, {
                        where: `contractPa = ''`,
                    }),
                ]);
            }
            const currentMapCenter = arcGISMap.getMapCenter(true);
            const newMapCenter = [
                selectedContractPartnerOperationArea.location?.longitude,
                selectedContractPartnerOperationArea.location?.latitude,
            ];
            if (currentMapCenter && newMapCenter
                && (currentMapCenter[0] !== newMapCenter[0] || currentMapCenter[1] !== newMapCenter[1])) {
                arcGISMap.setMapCenter(newMapCenter);
            }
            yield put({
                type: contractPartnerActionTypes.SET_SELECTED_CONTRACT_PARTNER_OPERATION_AREA,
                payload: {selectedContractPartnerOperationArea},
            });
            continue;
        }

        if (response.featureDTO && !response.featureDTO.isSelected) {
            const contractPartnerId = response.featureDTO['contractPartnerId'];

            const selectedContractPartnerOperationArea = contractPartners[contractPartnerId]
                ? contractPartners[contractPartnerId]
                : affiliatePartners.find(affiliatePartner => affiliatePartner['id'] === contractPartnerId);

            if (!selectedContractPartnerOperationArea) continue;

            if (vpmContractPartnersLocationsLayer
                && vpmContractPartnerAffiliatePartnersLocationsLayer
                && vpmContractPartnersServiceAreasLayer) {
                yield all([
                    call(vpmContractPartnersLocationsLayer.selectFeatureByAttribute, {
                        where: `contractPa = '${selectedContractPartnerOperationArea.id}'`,
                    }),
                    call(vpmContractPartnerAffiliatePartnersLocationsLayer.selectFeatureByAttribute, {
                        where: `contractPa = '${selectedContractPartnerOperationArea.id}'`,
                    }),
                    call(vpmContractPartnersServiceAreasLayer.selectFeatureByAttribute, {
                        where: `contractPa = '${selectedContractPartnerOperationArea.id}'`,
                    }),
                ]);
            }
            yield put({
                type: contractPartnerActionTypes.SET_SELECTED_CONTRACT_PARTNER_OPERATION_AREA,
                payload: {selectedContractPartnerOperationArea},
            });
        }
    }
};

export default onContractPartnerMapSelectOperationArea;
