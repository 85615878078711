import {put, take, fork, select} from 'redux-saga/effects';
import {replace, END_SIDE_EFFECTS_RUNNING} from '@computerrock/formation-router';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as userProfileActionTypes from '../userProfileActionTypes';
import * as selectors from '../userProfileSelectors';
import vpmAccessControl from '../../vpmAccessControl';
import routePaths from '../../routePaths';

const loadUserProfile = function* loadUserProfile() {
    const userProfile = yield select(selectors.getUser);
    if (userProfile) return;

    const {serviceManager} = yield select(state => state.application);
    const userProfileService = serviceManager.loadService('userProfileService');

    yield fork(fetchRequest, userProfileActionTypes.FETCH_USER_PROFILE_REQUEST, userProfileService.getUserProfile);
    const fetchUserResultAction = yield take([
        userProfileActionTypes.FETCH_USER_PROFILE_REQUEST_SUCCEEDED,
        userProfileActionTypes.FETCH_USER_PROFILE_REQUEST_FAILED,
    ]);

    if (fetchUserResultAction.type === userProfileActionTypes.FETCH_USER_PROFILE_REQUEST_FAILED) {
        yield put(replace(routePaths.SYSTEM_ACCESS_DENIED));
        return END_SIDE_EFFECTS_RUNNING;
    }

    const {response: fetchUserResponse} = fetchUserResultAction.payload;
    const {userDTO} = fetchUserResponse;
    yield put({
        type: userProfileActionTypes.STORE_USER_PROFILE,
        payload: {userDTO},
    });

    vpmAccessControl.setActiveUser(userDTO);
};

export default loadUserProfile;
