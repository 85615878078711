import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Parser} from 'html-to-react';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {efServiceAssignmentTypes, ehmCaseLogActionTypes, ehmCaseLogCategoryTypes} from '@ace-de/eua-entity-types';
import {useStyles, HighlightCircle, Divider, Icon, svgIcons} from '@ace-de/ui-components';
import QMFeedbackCaseLogAttachment from './QMFeedbackCaseLogAttachment';
import {serviceIcons} from './serviceIcons';
import * as qualityManagementFeedbackActionTypes from '../qualityManagementFeedbackActionTypes';

const serviceLogCategories = [
    ehmCaseLogCategoryTypes.NOTE,
    ehmCaseLogCategoryTypes.MESSAGE,
    ehmCaseLogCategoryTypes.ASSIGNMENT,
    ehmCaseLogCategoryTypes.ASSIGNMENT_STATUS_CHANGE,
    ehmCaseLogCategoryTypes.CASE_DATA_CHANGE,
];

// Note: DOCUMENT_UPLOAD has attachments, but different view
const logsWithAttachments = [
    ehmCaseLogActionTypes.ASSIGNMENT_ASSIGNED,
    ehmCaseLogActionTypes.ASSIGNMENT_EMAIL_RESEND,
    ehmCaseLogActionTypes.POWER_OF_ATTORNEY_SENT,
    ehmCaseLogActionTypes.NOTE_ADDED,
];

const getIconFromLogCategory = caseLog => {
    if (!caseLog) return;
    let icon;

    switch (caseLog.logCategory) {
        case ehmCaseLogCategoryTypes.MESSAGE:
        case ehmCaseLogCategoryTypes.NOTE:
        case ehmCaseLogCategoryTypes.CASE_STATUS_CHANGE: {
            icon = {icon: svgIcons.caseIcon, isHighlighted: false};
            break;
        }
        case ehmCaseLogCategoryTypes.TASK: {
            icon = {icon: svgIcons.timeAlertIcon, isHighlighted: false};
            break;
        }
        case ehmCaseLogCategoryTypes.DRIVER_APP: {
            icon = {icon: svgIcons.appIcon, isHighlighted: false};
            break;
        }
        case ehmCaseLogCategoryTypes.QUALITY_MANAGEMENT_FEEDBACK: {
            icon = caseLog?.payload?.positiveFeedback
                ? {icon: svgIcons.ratingPositiveIcon, isHighlighted: true}
                : {icon: svgIcons.ratingNegativeIcon, isHighlighted: true};
            break;
        }
        case ehmCaseLogCategoryTypes.CASE_DATA_CHANGE: {
            if (caseLog.actionType === ehmCaseLogActionTypes.DOCUMENT_UPLOADED) {
                icon = {icon: svgIcons.uploadIcon, isHighlighted: true};
                break;
            }
            if (caseLog.actionType === ehmCaseLogActionTypes.DOCUMENT_DELETED) {
                icon = {icon: svgIcons.deleteIcon, isHighlighted: true};
                break;
            }
            icon = {icon: svgIcons.caseProtocolIcon, isHighlighted: false};
            break;
        }
        case ehmCaseLogCategoryTypes.INVOICE: {
            icon = {icon: svgIcons.euroSymbolIcon, isHighlighted: true};
            break;
        }
        default:
            icon = {icon: svgIcons.caseProtocolIcon, isHighlighted: false};
    }

    return icon;
};

const HTMLParser = new Parser();

const QMFeedbackCaseLogEntry = props => {
    const {cx} = useStyles(props);
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('quality_management_feedback_case_log_tab');
    const {caseLog, submitDocumentDownloadFlow} = props;

    const formatUserDetails = caseLog => {
        if (!caseLog) return '';
        if (caseLog.logCategory === ehmCaseLogCategoryTypes.DRIVER_APP) return translate('global.case_log_user_label.driver_app');
        if (!caseLog.subjectDetails?.id) return translate('global.case_log_user_label.system_user');

        const userShiftRole = caseLog.subjectDetails?.shiftRole
            ? translate(`global.user_shift_role.${caseLog.subjectDetails.shiftRole.toLowerCase()}`)
            : '';

        return `${caseLog?.subjectDetails?.name || ''} (${userShiftRole})`;
    };

    const renderIconDetails = (caseLog, iconProp) => {
        if (serviceLogCategories.includes(caseLog?.logCategory)) {
            if (caseLog?.assignmentType === efServiceAssignmentTypes.VEHICLE && caseLog?.serviceType && caseLog?.serviceType !== 'UNKNOWN') {
                return iconProp === 'isHighlighted' ? true : serviceIcons[caseLog.serviceType][iconProp];
            }

            if (caseLog?.assignmentType && serviceIcons.hasOwnProperty(`${caseLog.assignmentType}`)) {
                return iconProp === 'isHighlighted' ? true : serviceIcons[caseLog.assignmentType][iconProp];
            }
        }
        return getIconFromLogCategory(caseLog)[iconProp];
    };

    const parseCaseLogText = caseLog => {
        const brRegex = /<br>.*<br>/;
        const emailRegex = /(<)(\w+)([.-]?\w+)*(@\w+)([.-]?\w+)*(\.\w{2,3})+(>)/mg;

        const caseLogText = caseLog.actionType === ehmCaseLogActionTypes.DOCUMENT_UPLOADED
            ? caseLog.logTranslation.replace(brRegex, '') : caseLog.logTranslation;
        const trimmedText = caseLogText.replaceAll(emailRegex, (...matchedResult) => {
            return `&lt${matchedResult[2]}${matchedResult[3]}${matchedResult[4]}${matchedResult[6]}&gt`;
        });

        return HTMLParser.parse(trimmedText);
    };

    const formatLogCategory = (category, actionType) => {
        if (!category) return;

        if ([
            ehmCaseLogActionTypes.ASSIGNMENT_CREATED,
            ehmCaseLogActionTypes.ASSIGNMENT_SERVICE_MANUAL_CHANGE,
        ].includes(actionType) && category === ehmCaseLogCategoryTypes.ASSIGNMENT_STATUS_CHANGE) return translate('global.case_log_category.assignment');

        if (actionType && actionType === ehmCaseLogActionTypes.DRIVER_ETA_CREATED) return translate('global.case_log_category.driver_app_eta');

        if ([
            ehmCaseLogCategoryTypes.ASSIGNMENT_STATUS_CHANGE,
            ehmCaseLogCategoryTypes.CASE_STATUS_CHANGE,
            ehmCaseLogCategoryTypes.CASE_DATA_CHANGE,
        ].includes(caseLog.logCategory)) return translate('global.case_log_category.status_change');

        return translate(`global.case_log_category.${caseLog.logCategory.toLowerCase()}`);
    };

    const handleFileDownload = attachment => {
        if (!attachment) return;

        submitDocumentDownloadFlow({
            fileName: attachment.fileName,
            url: attachment.url,
        });
    };

    if (!caseLog) return null;

    return (
        <Fragment>
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-padding--16-0',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--small',
                        'global!ace-u-margin--right-16',
                        'global!ace-u-flex--shrink-0',
                        {
                            'ace-c-highlight-circle--primary-highlight': renderIconDetails(caseLog, 'isHighlighted'),
                        },
                    ])}
                >
                    <Icon
                        icon={renderIconDetails(caseLog, 'icon')}
                        className={cx([
                            `ace-c-icon--${caseLog.logCategory === ehmCaseLogCategoryTypes.INVOICE ? 's' : 'm'}`,
                            'global!ace-u-flex--shrink-0',
                            {
                                'ace-c-icon--color-highlight': renderIconDetails(caseLog, 'isHighlighted'),
                            },
                        ])}
                    />
                </HighlightCircle>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-space-between',
                        'global!ace-u-full-width',
                    ])}
                >
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'ace-u-flex--direction-column',
                            'global!ace-u-margin--left-16',
                        ])}
                    >
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-caption',
                                'global!ace-u-margin--bottom-8',
                            ])}
                        >
                            {`${formatUserDetails(caseLog)} ${moment(caseLog.dateToISOString).locale(activeLocale).format('DD.MM.YYYY, HH:mm')} ${translate('global.time_unit.time')}`}
                        </p>
                        <span
                            className={cx([
                                'global!ace-u-typography--variant-caption',
                                'ace-u-typography--color-highlighted',
                                'global!ace-u-margin--bottom-8',
                            ])}
                        >
                            {formatLogCategory(caseLog.logCategory, caseLog.actionType)}
                        </span>
                        <div className={cx('global!ace-u-typography--variant-body')}>
                            {parseCaseLogText(caseLog)}
                        </div>
                        {caseLog.actionType === ehmCaseLogActionTypes.DOCUMENT_UPLOADED && (
                            <div className={cx('global!ace-u-margin--top-8')}>
                                {caseLog.payload?.attachments?.map(attachment => (
                                    <QMFeedbackCaseLogAttachment
                                        key={attachment.id}
                                        attachment={attachment}
                                        handleOnDownload={handleFileDownload}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                {logsWithAttachments.includes(caseLog.actionType) && (
                    <div className={cx('global!ace-u-margin--top-8')}>
                        {caseLog.actionType === ehmCaseLogActionTypes.NOTE_ADDED
                        && caseLog.payload?.attachments?.length > 0 && (
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-body',
                                    'global!ace-u-margin--top-16',
                                    'global!ace-u-margin--bottom-8',
                                ])}
                            >
                                {`${translateTab('note_text.attachments')}:`}
                            </p>
                        )}
                        {caseLog.payload?.attachments?.map(attachment => (
                            <QMFeedbackCaseLogAttachment
                                key={attachment.id}
                                attachment={attachment}
                                handleOnDownload={handleFileDownload}
                            />
                        ))}
                    </div>
                )}
            </div>
            <Divider />
        </Fragment>
    );
};

QMFeedbackCaseLogEntry.propTypes = {
    caseLog: PropTypes.object,
    submitDocumentDownloadFlow: PropTypes.func.isRequired,
};

QMFeedbackCaseLogEntry.defaultProps = {
    caseLog: null,
};

const mapDispatchToProps = dispatch => ({
    submitDocumentDownloadFlow: payload => dispatch({
        type: qualityManagementFeedbackActionTypes.SUBMIT_CASE_LOG_DOCUMENT_DOWNLOAD_FLOW,
        payload,
    }),
});


export default connect(null, mapDispatchToProps)(QMFeedbackCaseLogEntry);
