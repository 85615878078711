import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

/**
 * Search CP location geolocation
 */
const searchCPLocationGeolocation = function* searchCPLocationGeolocation({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    yield put({
        type: contractPartnerActionTypes.SET_CP_LOCATION_RECOMMENDATIONS,
        payload: {arcGISLocationCandidateDTOs: []},
    });

    const {searchQueryString} = payload;
    yield fork(
        fetchRequest,
        contractPartnerActionTypes.SEARCH_CP_LOCATION_GEOLOCATION_REQUEST,
        arcGISRESTService.searchAddressSuggestions,
        {singleLine: searchQueryString},
    );

    const searchLocations = yield take([
        contractPartnerActionTypes.SEARCH_CP_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.SEARCH_CP_LOCATION_GEOLOCATION_REQUEST_FAILED,
    ]);

    if (!searchLocations.error) {
        const {response} = searchLocations.payload;
        const {arcGISLocationCandidateDTOs} = response;

        yield put({
            type: contractPartnerActionTypes.SET_CP_LOCATION_RECOMMENDATIONS,
            payload: {
                arcGISLocationCandidateDTOs,
                searchQueryString,
            },
        });
    }
};

export default searchCPLocationGeolocation;
