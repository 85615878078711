import React, {Fragment, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, List, Divider, InteractiveIcon, Form, Checkbox, ButtonPrimary, CheckboxGroup} from '@ace-de/ui-components';
import {apmContractPartnerAdditionalFeatureTypes} from '@ace-de/eua-entity-types';
import {Icon, checkmarkIcon, closeIcon, editIcon} from '@ace-de/ui-components/icons';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

const ContractPartnerAdditionalFeaturesPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_basic_data_tab');
    const {contractPartner, submitAdditionalFeaturesForm} = props;
    const [isEditModeActive, setIsEditModeActive] = useState(false);

    const toggleEditMode = () => {
        setIsEditModeActive(prevState => !prevState);
    };

    const handleOnSubmit = formValues => {
        submitAdditionalFeaturesForm({
            contractPartnerData: {
                additionalFeatures: formValues.additionalFeatures,
            },
            contractPartnerId: contractPartner.id,
        });

        setIsEditModeActive(false);
    };

    // if no contract partner, don't render
    if (!contractPartner) return null;

    const {additionalFeatures} = contractPartner;

    return (
        <Panel
            title={translateTab('panel_title.additional_features')}
            actions={(
                <InteractiveIcon
                    icon={!isEditModeActive
                        ? editIcon
                        : closeIcon
                    }
                    onClick={toggleEditMode}
                />
            )}
        >
            {!isEditModeActive
                ? (
                    <List className={cx('global!ace-u-full-width')}>
                        {Object.values(apmContractPartnerAdditionalFeatureTypes).map(additionalFeature => {
                            const hasCPAdditionalFeature = additionalFeatures
                                .includes(additionalFeature);
                            return (
                                <Fragment key={additionalFeature}>
                                    <div
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--justify-space-between',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-padding--16',
                                        ])}
                                    >
                                        <span className={cx('global!ace-u-typography--variant-body')}>
                                            {translate(`global.contract_partner_additional_features.${(additionalFeature).toLowerCase()}`)}
                                        </span>
                                        <Icon
                                            icon={hasCPAdditionalFeature ? checkmarkIcon : closeIcon}
                                            className={cx(hasCPAdditionalFeature
                                                ? 'ace-c-icon--color-success'
                                                : 'ace-c-icon--color-warning')}
                                        />
                                    </div>
                                    <Divider />
                                </Fragment>
                            );
                        })}
                    </List>
                ) : (
                    <Form name="additionalCommissionersForm" onSubmit={handleOnSubmit}>
                        <CheckboxGroup name="additionalFeatures" value={contractPartner.additionalFeatures}>
                            {Object.values(apmContractPartnerAdditionalFeatureTypes).map(additionalFeature => {
                                return (
                                    <Fragment key={additionalFeature}>
                                        <div
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--justify-space-between',
                                                'global!ace-u-flex--align-center',
                                                'global!ace-u-padding--16',
                                            ])}
                                        >
                                            <span className={cx('global!ace-u-typography--variant-body')}>
                                                {translate(`global.contract_partner_additional_features.${(additionalFeature).toLowerCase()}`)}
                                            </span>
                                            <Checkbox
                                                key={additionalFeature}
                                                name={additionalFeature}
                                                value={additionalFeature}
                                            />
                                        </div>
                                        <Divider />
                                    </Fragment>
                                );
                            })}
                        </CheckboxGroup>
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--direction-row-reverse',
                                'global!ace-u-margin--top-32',
                            ])}
                        >
                            <ButtonPrimary
                                name="submitAdditionalFeaturesData"
                                type="submit"
                            >
                                {translateTab('button_label.save')}
                            </ButtonPrimary>
                        </div>
                    </Form>
                )}
        </Panel>
    );
};

ContractPartnerAdditionalFeaturesPanel.propTypes = {
    contractPartner: PropTypes.object,
    submitAdditionalFeaturesForm: PropTypes.func.isRequired,
};

ContractPartnerAdditionalFeaturesPanel.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitAdditionalFeaturesForm: payload => dispatch({
        type: contractPartnerActionTypes.SUBMIT_CONTRACT_PARTNER_ADDITIONAL_FEATURES_FORM,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerAdditionalFeaturesPanel));
