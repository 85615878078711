import {createSelector} from 'reselect';
/**
 * General state selectors
 */

export const getHolidayUpdateError = state => state.priceManagement.hasHolidayUpdateFailed;
export const getHolidayLoadingFlag = state => state.priceManagement.areHolidaysLoading;
export const getHolidayDates = state => state.priceManagement.holidays;
export const getFixedPrices = state => state.priceManagement.fixedPrices;
export const getActiveFixedPricePeriods = state => state.priceManagement.activeFixedPricePeriods;

/**
 * Returns selector for fixed prices for contractPartner matched from route
 */
export const createFixedPricesSelector = () => createSelector([
    getFixedPrices,
    (state, props) => {
        const {match} = props;
        return match?.params['contractPartnerId'];
    },
], (fixedPrices, contractPartnerId) => {
    if (!contractPartnerId || !fixedPrices) return null;

    return fixedPrices[contractPartnerId] || null;
});

/**
 * Returns selector for active fixed price for contractPartner matched from route
 */
export const createActiveFixedPricesSelector = () => createSelector([
    getActiveFixedPricePeriods,
    (state, props) => {
        const {match} = props;
        return match?.params['contractPartnerId'];
    },
], (activeFixedPrices, contractPartnerId) => {
    if (!contractPartnerId || !activeFixedPrices) return null;

    return activeFixedPrices[contractPartnerId] || null;
});

export const createActiveFixedPriceDatasetSelector = () => createSelector([
    createFixedPricesSelector(),
    createActiveFixedPricesSelector(),
], (fixedPrices, activeFixedPrice) => {
    if (!fixedPrices || !activeFixedPrice) return null;

    return Object.values(fixedPrices).find(fixedPrice => fixedPrice.prices[0].id === activeFixedPrice.id);
});
