import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {resolveRoute, useRouter} from '@computerrock/formation-router';
import {closeIcon, InteractiveIcon, Pill, ScreenTitle, useStyles, emailIcon} from '@ace-de/ui-components';
import * as qualityManagementFeedbackActionTypes from '../qualityManagementFeedbackActionTypes';
import routePaths from '../../routePaths';

const QMFeedbackHeader = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateHeader = createTranslateShorthand('qm_feedback_header');
    const router = useRouter();
    const {qualityManagementFeedback, initiateForwardQMFeedback} = props;

    return (
        <Fragment>
            <ScreenTitle>
                <div
                    className={cx([
                        'global!ace-u-typography--variant-body',
                        'global!ace-u-typography--color-contrast',
                    ])}
                >
                    {`${translateHeader('label.quality_notification_for')} `}
                    <a
                        href={resolveRoute(routePaths.CONTRACT_PARTNER, {
                            contractPartnerId: qualityManagementFeedback.contractPartnerId,
                        })?.pathname}
                        target="_blank"
                        rel="noreferrer"
                        className={cx([
                            'global!ace-u-typography--variant-body',
                            'global!ace-u-typography--color-highlighted',
                        ])}
                    >
                        {qualityManagementFeedback?.partnerName || '-'}
                    </a>

                </div>
            </ScreenTitle>
            <div
                className={cx([
                    'global!ace-u-flex--grow-1',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-margin--left-32',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <span className={cx('global!ace-u-typography--variant-body-bold')}>
                            {translateHeader('label.channel')}
                        </span>
                        <span
                            className={cx([
                                'global!ace-u-typography--variant-body',
                                'global!ace-u-margin--left-8',
                            ])}
                        >
                            {qualityManagementFeedback.qualityManagementFeedbackChannel
                                ? translate(`global.quality_management_feedback_channel.${snakeCase(qualityManagementFeedback.qualityManagementFeedbackChannel)}`).replace(':', '')
                                : translate('global.quality_management_feedback_channel.manual')}
                        </span>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <span
                            className={cx([
                                'global!ace-u-margin--left-48',
                                'global!ace-u-typography--variant-body-bold',
                            ])}
                        >
                            {translateHeader('label.status')}
                        </span>
                        <span
                            className={cx([
                                'global!ace-u-margin--left-8',
                                'global!ace-u-margin--right-8',
                            ])}
                        >
                            <Pill
                                type={!qualityManagementFeedback.isQualified
                                    ? 'negative-bold-highlighted'
                                    : 'positive-bold-highlighted'
                                }
                            >
                                {!qualityManagementFeedback.isQualified
                                    ? translate('global.quality_management_feedback_status.open')
                                    : translate('global.quality_management_feedback_status.qualified')}
                            </Pill>
                        </span>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <span
                            className={cx([
                                'global!ace-u-margin--left-48',
                                'global!ace-u-margin--right-8',
                                'global!ace-u-typography--variant-body-bold',
                            ])}
                        >
                            {translateHeader('label.created_at')}
                        </span>
                        <span className={cx('global!ace-u-typography--variant-body')}>
                            {qualityManagementFeedback.createdAt
                                ? `${moment(qualityManagementFeedback.createdAt).format('DD.MM.YYYY, HH:mm')} ${translate('global.time_unit.time')}`
                                : '-'}
                        </span>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <span
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--left-48',
                            ])}
                        >
                            {translateHeader('label.created_by')}
                        </span>
                        <span
                            className={cx([
                                'global!ace-u-margin--left-8',
                                'global!ace-u-typography--variant-body',
                            ])}
                        >
                            {qualityManagementFeedback.createdByUser || '-'}
                        </span>
                    </div>
                </div>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--right-32',
                    ])}
                >
                    <InteractiveIcon
                        icon={emailIcon}
                        className={cx([
                            'ace-c-interactive-icon--primary',
                            'global!ace-u-margin--right-64',
                        ])}
                        onClick={() => initiateForwardQMFeedback()}
                    >
                        {translateHeader('interactive_icon_label.forward')?.toUpperCase()}
                    </InteractiveIcon>
                    <InteractiveIcon
                        icon={closeIcon}
                        onClick={router.goBack}
                    />
                </div>
            </div>
        </Fragment>
    );
};

QMFeedbackHeader.propTypes = {
    qualityManagementFeedback: PropTypes.object,
    initiateForwardQMFeedback: PropTypes.func.isRequired,
};

QMFeedbackHeader.defaultProps = {
    qualityManagementFeedback: null,
};

const mapDispatchToProps = dispatch => ({
    initiateForwardQMFeedback: payload => dispatch({
        type: qualityManagementFeedbackActionTypes.INITIATE_FORWARD_QM_FEEDBACK_FLOW,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(QMFeedbackHeader);
