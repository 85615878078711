import React from 'react';
import PropTypes from 'prop-types';
import {useStyles, Avatar, List} from '@ace-de/ui-components';
import {DropDownTrigger, DropDown, withDropDownProvider} from '@ace-de/ui-components/overlays';

const AvatarMenu = props => {
    const {cx} = useStyles();
    const {userName, userImage, children, onSelect} = props;

    return (
        <DropDownTrigger>
            <Avatar
                alt={userName}
                src={userImage}
                className={cx('ace-c-avatar--small', 'global!ace-u-margin--right-32')}
            />
            <DropDown alignment="end">
                <List
                    name="userOptionList"
                    onChange={typeof onSelect === 'function' ? onSelect : null}
                    isComposedIn={true}
                >
                    {children}
                </List>
            </DropDown>
        </DropDownTrigger>
    );
};

AvatarMenu.propTypes = {
    userName: PropTypes.string.isRequired,
    userImage: PropTypes.string,
    onSelect: PropTypes.func,
};

AvatarMenu.defaultProps = {
    userImage: '',
    onSelect: () => undefined,
};

export default withDropDownProvider(AvatarMenu);
