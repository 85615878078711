import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import {useStyles, HighlightCircle} from '@ace-de/ui-components';
import {Button, ButtonPrimary} from '@ace-de/ui-components/buttons';
import {infoAlertIcon, Icon} from '@ace-de/ui-components/icons';
import bcModalTypes from '../modals/bcModalTypes';
import * as contractPartnersActionTypes from '../contractPartnerActionTypes';

const BCActionFailed = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('bc_action_failed_modal');
    const {retryContractPartnerCreation, retryCreditorCreation} = props;
    const {retryContractPartnerUpdate, retryCreditorUpdate} = props;
    const {goBack, location} = props;
    const [queryParams, setQueryParams] = useState(new URLSearchParams(location.search));
    const modalType = queryParams.get('type');

    useEffect(() => {
        setQueryParams(new URLSearchParams(location.search));
    }, [location.search, setQueryParams]);

    const handleOnRetry = () => {
        if (!modalType) return;

        switch (modalType) {
            case bcModalTypes.CREATE_CREDITOR: {
                retryCreditorCreation();
                break;
            }
            case bcModalTypes.CREATE_CONTRACT_PARTNER: {
                retryContractPartnerCreation();
                break;
            }
            case bcModalTypes.UPDATE_CONTRACT_PARTNER: {
                retryContractPartnerUpdate();
                break;
            }
            case bcModalTypes.UPDATE_CREDITOR: {
                retryCreditorUpdate();
                break;
            }
        }
    };

    return (
        <div
            className={cx([
                'global!ace-u-flex',
                'global!ace-u-full-width',
                'global!ace-u-flex--direction-column',
                'global!ace-u-flex--align-center',
                'global!ace-u-flex--justify-content-center',
            ])}
        >
            <HighlightCircle
                className={cx([
                    'ace-c-highlight-circle--medium',
                    'ace-c-highlight-circle--primary-highlight',
                ])}
            >
                <Icon
                    icon={infoAlertIcon}
                    className={cx('ace-c-icon--xxl', 'global!ace-c-icon--color-highlight')}
                />
            </HighlightCircle>
            {modalType !== bcModalTypes.UPDATE_BUSINESS_RELATIONS
                ? (
                    <h1
                        className={cx([
                            'global!ace-u-margin--24-0',
                            'global!ace-u-typography--variant-h2',
                        ])}
                    >
                        {modalType?.includes('CREATE')
                            ? translateModal('heading.failed_to_create_in_bc')
                            : translateModal('heading.failed_to_edit_in_bc')}
                    </h1>
                )
                : (
                    <span
                        className={cx([
                            'global!ace-u-margin--24-0',
                            'global!ace-u-typography--variant-h3',
                            'global!ace-u-typography--align-center',
                        ])}
                    >
                        {translateModal('heading.failed_to_patch_business_relations')}
                    </span>
                )
            }
            <div>
                <Button className={cx('global!ace-u-margin--right-16')} onClick={goBack}>
                    {translateModal('button_label.back')}
                </Button>
                {modalType !== bcModalTypes.UPDATE_BUSINESS_RELATIONS && (
                    <ButtonPrimary onClick={handleOnRetry}>
                        {translateModal('button_label.try_again')}
                    </ButtonPrimary>
                )}
            </div>
        </div>
    );
};

BCActionFailed.propTypes = {
    retryCreditorCreation: PropTypes.func.isRequired,
    retryContractPartnerCreation: PropTypes.func.isRequired,
    retryContractPartnerUpdate: PropTypes.func.isRequired,
    retryCreditorUpdate: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    location: PropTypes.object,
};

BCActionFailed.defaultProps = {
    location: null,
};

const mapDispatchToProps = dispatch => ({
    goBack: () => dispatch({
        type: contractPartnersActionTypes.CANCEL_ACTION,
    }),
    retryCreditorCreation: () => dispatch({
        type: contractPartnersActionTypes.RETRY_CREDITOR_CREATION,
    }),
    retryContractPartnerCreation: () => dispatch({
        type: contractPartnersActionTypes.RETRY_CONTACT_CREATION,
    }),
    retryContractPartnerUpdate: () => dispatch({
        type: contractPartnersActionTypes.RETRY_UPDATE_CONTACT,
    }),
    retryCreditorUpdate: () => dispatch({
        type: contractPartnersActionTypes.RETRY_UPDATE_CREDITOR,
    }),
});

export default withRouter(connect(null, mapDispatchToProps)(BCActionFailed));
