import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {snakeCase} from 'change-case';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {apmQualityManagementFeedbackClassificationTypes, apmQualityManagementFeedbackChannelTypes} from '@ace-de/eua-entity-types';
import {DataRow, Icon, Panel, ratingNegativeIcon, ratingPositiveIcon, noAccessIcon, useStyles} from '@ace-de/ui-components';
import * as qualityManagementSelectors from '../qualityManagementFeedbackSelectors';

const QMFeedbackBaseDataPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translatePanel = createTranslateShorthand('qm_feedback_base_data_panel');
    const {qualityManagementFeedback} = props;

    /* eslint-disable-next-line max-len */
    const isFromTemporaryRestriction = qualityManagementFeedback.qualityManagementFeedbackChannel === apmQualityManagementFeedbackChannelTypes.TEMPORARY_RESTRICTION;

    return (
        <Panel
            title={translatePanel('panel_title.base_data')}
            className={cx(['global!ace-u-full-height', 'global!ace-u-margin--0'])}
        >
            <DataRow
                label={translatePanel('data_row_label.category')}
                qaIdent="qm-category"
            >
                <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--align-center'])}>
                    <Icon
                        className={cx(['global!ace-u-margin--right-16', 'global!ace-u-min-width--24'])}
                        icon={qualityManagementFeedback.classification === apmQualityManagementFeedbackClassificationTypes.POSITIVE // eslint-disable-line max-len
                            ? ratingPositiveIcon
                            : qualityManagementFeedback.classification === apmQualityManagementFeedbackClassificationTypes.NEGATIVE // eslint-disable-line max-len
                                ? ratingNegativeIcon
                                : noAccessIcon}
                    />
                    {qualityManagementFeedback.categories.map(category => (
                        (qualityManagementFeedback.qualityManagementFeedbackChannel
                            ? translate(`global.quality_management_feedback_channel.${snakeCase(qualityManagementFeedback.qualityManagementFeedbackChannel)}`) + ' '
                            : ''
                        ) + translate(`global.quality_management_feedback_category.${snakeCase(category)}`)
                    )).join(', ')}
                </div>
            </DataRow>
            {isFromTemporaryRestriction ? (
                <Fragment>
                    <DataRow
                        label={translatePanel('data_row_label.restriction_start_date')}
                        qaIdent="qm-restriction-start-date"
                    >
                        {qualityManagementFeedback.restrictionStartDateTime
                            ? moment(qualityManagementFeedback.restrictionStartDateTime).format('DD.MM.YYYY, HH:mm')
                            : '-'}
                    </DataRow>
                    <DataRow
                        label={translatePanel('data_row_label.restriction_end_date')}
                        qaIdent="qm-restriction-end-date"
                    >
                        {qualityManagementFeedback.restrictionEndDateTime
                            ? moment(qualityManagementFeedback.restrictionEndDateTime).format('DD.MM.YYYY, HH:mm')
                            : '-'}
                    </DataRow>
                </Fragment>
            ) : (
                <Fragment>
                    <DataRow
                        label={translatePanel('data_row_label.cp_rank')}
                        qaIdent="qm-cp-rank"
                    >
                        {qualityManagementFeedback.contractPartnerRecommendations.length > 0
                            ? qualityManagementFeedback.contractPartnerRecommendations.find(contractPartner => (
                                contractPartner.id === qualityManagementFeedback.contractPartnerId
                            ))?.rank || '-'
                            : '-'}
                    </DataRow>
                    <DataRow
                        label={translatePanel('data_row_label.decision_by')}
                        qaIdent="qm-decision-by"
                    >
                        {qualityManagementFeedback.decisionBy
                            ? translatePanel(`decision_by.${qualityManagementFeedback.decisionBy.toLowerCase()}`)
                            : '-'}
                    </DataRow>
                    <DataRow
                        label={translatePanel('data_row_label.cp_consultation')}
                        qaIdent="qm-cp-consultation"
                    >
                        {translatePanel(`is_cp_consulted.${qualityManagementFeedback.isContractPartnerConsulted ? 'yes' : 'no'}`)}
                    </DataRow>
                </Fragment>
            )}
            <DataRow
                label={translatePanel('data_row_label.reported_by')}
                qaIdent="qm-reported-by"
            >
                {qualityManagementFeedback.reportedBy || '-'}
            </DataRow>
            <DataRow
                label={translatePanel('data_row_label.description')}
                qaIdent="qm-description"
            >
                {qualityManagementFeedback.description || '-'}
            </DataRow>
        </Panel>
    );
};

QMFeedbackBaseDataPanel.propTypes = {
    qualityManagementFeedback: PropTypes.object,
};

QMFeedbackBaseDataPanel.defaultProps = {
    qualityManagementFeedback: null,
};

const mapStateToProps = (state, props) => {
    const qmFeedbackSelector = qualityManagementSelectors.createQMFeedbackSelector();
    return {
        qualityManagementFeedback: qmFeedbackSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps)(QMFeedbackBaseDataPanel));
