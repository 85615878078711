import {resolveRoute} from '@computerrock/formation-router';
import {FormationAuthPlugin} from '@computerrock/formation-auth';
import routePaths from './routePaths';

const {origin} = window.location;
const authorizeRoute = resolveRoute(routePaths.KEYCLOAK_AUTHORIZE);
const authenticateRoute = resolveRoute(routePaths.KEYCLOAK_AUTHENTICATE);
const {href: authorizeRedirectURI} = new URL(authorizeRoute.pathname, origin);
const {href: authenticateRedirectURI} = new URL(authenticateRoute.pathname, origin);

/**
 * Instantiate FormationAuthPlugin for Keycloak auth layer
 *
 * @type {FormationAuthPlugin}
 */
const keycloakAuth = new FormationAuthPlugin({
    name: 'keycloak',
    authServiceName: 'keycloakAuthService',
    rootApplicationRoute: routePaths.ROOT,
    authorizeRedirectURI,
    authenticateRedirectURI,
});

export default keycloakAuth;
