import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates} from '@computerrock/formation-core';
import {ContentBlock, ContentItem, Divider, HighlightCircle, LoaderSpinner, Panel, ScrollableBlock, useStyles} from '@ace-de/ui-components';
import {ToggleSwitch} from '@ace-de/ui-components/selector-inputs';
import {Icon, infoAlertIcon} from '@ace-de/ui-components/icons';
import {ArcGISMapWidget, createMarkerGraphic, markerOperatingUnitPNG, markerOperatingUnitActivePNG, useArcGISMap} from '@ace-de/eua-arcgis-map'; // eslint-disable-line
import config from '../config';
import * as contractPartnerSelectors from './contractPartnerSelectors';
import * as contractPartnerActionTypes from './contractPartnerActionTypes';
import contractPartnerInfoPopup from '../dashboard/contractPartnerInfoPopup';
import ContractPartnerOperationAreaItem from './ui-elements/ContractPartnerOperationAreaItem';
import SidebarExpansionButton from '../dashboard/ui-elements/SidebarExpansionButton';
import styles from './ContractPartnerOperationAreaTab.module.scss';

const ContractPartnerOperationAreaTab = props => {
    const {cx} = useStyles(props, styles);
    const {contractPartner, selectedContractPartnerOperationArea, affiliatePartners} = props;
    const {initiateCPOperationAreaSelectFlow, initiateCPOperationAreaMap, affiliatePartnersPersistenceState} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_operation_area_tab');
    const arcGISMap = useArcGISMap('vpm-contract-partners-operation-areas');
    const isArcGISMapLoaded = useRef(false);
    const [selectedOperationAreaId, setSelectedOperationAreaId] = useState('');
    const [areOperatingUnitsVisible, setAreOperatingUnitsVisible] = useState(false);
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

    useEffect(() => {
        if (!arcGISMap || !contractPartner?.id || isArcGISMapLoaded.current) return;
        isArcGISMapLoaded.current = true;
        initiateCPOperationAreaMap({
            mapName: 'vpm-contract-partners-operation-areas',
            contractPartnerId: contractPartner?.id,
        });
    }, [arcGISMap, initiateCPOperationAreaMap, contractPartner?.id]);

    useEffect(() => {
        if (!contractPartner.location || !arcGISMap) return;

        if (!areOperatingUnitsVisible) {
            arcGISMap.setGraphics({
                graphics: [],
            });
            return;
        }

        arcGISMap.setGraphics({
            graphics: [
                ...(contractPartner.operatingUnits.length
                    ? contractPartner.operatingUnits.map(operatingUnit => (
                        createMarkerGraphic({
                            id: `contractPartnerOtherLocation-${operatingUnit.id}`,
                            longitude: operatingUnit.location.longitude,
                            latitude: operatingUnit.location.latitude,
                            icon: areOperatingUnitsVisible
                                ? selectedOperationAreaId === operatingUnit.id
                                    ? markerOperatingUnitActivePNG : markerOperatingUnitPNG
                                : null,
                            isSelectable: true,
                            isSmall: true,
                        })
                    )) : []),
            ],
        });
        if (selectedContractPartnerOperationArea) {
            const currentCenter = arcGISMap?.getMapCenter(true);
            const newMapCenter = [
                selectedContractPartnerOperationArea.location?.longitude,
                selectedContractPartnerOperationArea.location?.latitude,
            ];
            if (currentCenter && newMapCenter
                && (currentCenter[0] !== newMapCenter[0] || currentCenter[1] !== newMapCenter[1])) {
                arcGISMap.setMapCenter(newMapCenter);
            }
        }
    }, [
        contractPartner,
        arcGISMap,
        selectedOperationAreaId,
        selectedContractPartnerOperationArea,
        areOperatingUnitsVisible,
    ]);

    useEffect(() => {
        if (!selectedContractPartnerOperationArea
            || selectedContractPartnerOperationArea.id === selectedOperationAreaId) {
            return;
        }

        setSelectedOperationAreaId(selectedContractPartnerOperationArea.id);
    }, [selectedContractPartnerOperationArea, selectedOperationAreaId]);

    const handleSetMapExtent = value => {
        if (!value) return;
        const layer = arcGISMap.getReadyLayer('vpm-contract-partner-operation-areas-locations');
        if (!layer) return;
        if (!contractPartner.location
            || !contractPartner.location.latitude
            || !contractPartner.location.longitude) return;

        const latitudes = [
            contractPartner.location.latitude,
            ...contractPartner.operatingUnits.map(operatingUnit => (
                operatingUnit.location.latitude
            )),
            ...affiliatePartners.map(affiliatePartner => (
                affiliatePartner.location.latitude
            )),
        ];
        const longitudes = [
            contractPartner.location.longitude,
            ...contractPartner.operatingUnits.map(operatingUnit => (
                operatingUnit.location.longitude
            )),
            ...affiliatePartners.map(affiliatePartner => (
                affiliatePartner.location.longitude
            )),
        ];

        // if there are no operating units and affiliate partners, set zoom level to default
        // avoiding error: [esri.views.MapView]: "invalid extent size"
        if (latitudes?.length === 1 && longitudes?.length === 1) {
            arcGISMap.setMapViewZoomLevel(config.DEFAULT_ARCGIS_MAP_WIDGET_ZOOM_LEVEL);
            return;
        }

        const spatialRef = layer.getServiceFeatureLayer().spatialReference;
        const sortedLatitudes = latitudes.sort((a, b) => a - b);
        const sortedLongitudes = longitudes.sort((a, b) => a - b);
        const extent = arcGISMap.createMapExtentFromLocations(
            sortedLongitudes[0],
            sortedLatitudes[0],
            sortedLongitudes[sortedLongitudes.length - 1],
            sortedLatitudes[sortedLatitudes.length - 1],
            spatialRef,
        );
        arcGISMap.setMapExtent(extent);
    };

    const handleContractPartnerSelect = newContractPartnerOperationAreaId => {
        if (!newContractPartnerOperationAreaId || selectedOperationAreaId === newContractPartnerOperationAreaId) return;

        setSelectedOperationAreaId(newContractPartnerOperationAreaId);

        const [newContractPartnerOperationArea] = newContractPartnerOperationAreaId === contractPartner.id
            ? [contractPartner]
            : affiliatePartners
                .find(affiliatePartner => affiliatePartner.id === newContractPartnerOperationAreaId)
                ? affiliatePartners
                    .filter(affiliatePartner => affiliatePartner.id === newContractPartnerOperationAreaId)
                : contractPartner.operatingUnits
                    .filter(operatingUnit => operatingUnit.id === newContractPartnerOperationAreaId);

        if (newContractPartnerOperationArea) {
            initiateCPOperationAreaSelectFlow({
                contractPartnerOperationArea: newContractPartnerOperationArea,
            });
        }
    };

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
                'global!ace-u-flex--align-stretch',
            ])}
        >
            <ArcGISMapWidget
                name="vpm-contract-partners-operation-areas"
                tempArcGISMapId={config.TEMP_ARCGIS_MAP_ID}
                popup={contractPartnerInfoPopup}
                className={cx([
                    'ace-c-arcgis-map-widget--is-visible',
                    'ace-c-arcgis-map-widget--is-in-front',
                ])}
            />
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-3',
                    'global!ace-u-height--full',
                    'global!ace-u-max-height--full',
                    'ace-c-contract-partner-operation-area-tab__left-sidebar',
                ], {
                    'ace-c-contract-partner-operation-area-tab__left-sidebar--shrinked': !isSidebarExpanded,
                })}
            >
                <Panel
                    className={cx([
                        'ace-c-panel--full-bleed-content',
                        'global!ace-u-height--full',
                        'global!ace-u-max-height--full',
                    ])}
                >
                    <ScrollableBlock isLabelDisabled={true} className={cx('ace-c-scrollable-block--full-bleed')}>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--bottom-16',
                                'global!ace-u-padding--left-32',
                                'global!ace-u-padding--right-32',
                            ])}
                        >
                            {translateTab('list_title.main_location')}
                        </p>
                        <ContractPartnerOperationAreaItem
                            contractPartnerOperationArea={contractPartner}
                            isSelected={contractPartner.id === selectedOperationAreaId}
                            onSelect={handleContractPartnerSelect}
                        />
                        <div
                            className={cx([
                                'global!ace-u-padding--left-32',
                                'global!ace-u-padding--right-32',
                            ])}
                        >
                            <Divider />
                        </div>
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--top-32',
                                'global!ace-u-margin--bottom-16',
                                'global!ace-u-padding--left-32',
                                'global!ace-u-padding--right-32',
                            ])}
                        >
                            {translateTab('list_title.affiliated_partners')}
                        </p>
                        {affiliatePartnersPersistenceState === persistenceStates.PENDING && (
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-full-width',
                                    'global!ace-u-flex--direction-column',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-flex--justify-content-center',
                                    'global!ace-u-margin--24-0',
                                ])}
                            >
                                <LoaderSpinner />
                            </div>
                        )}
                        {affiliatePartnersPersistenceState === persistenceStates.FAILED && (
                            <div
                                className={cx([
                                    'global!ace-u-width--full',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--direction-column',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-flex--justify-center',
                                    'global!ace-u-margin--top-24',
                                    'global!ace-u-typography--variant-caption',
                                    'global!ace-u-typography--color-warning',
                                ])}
                            >
                                <HighlightCircle
                                    className={cx([
                                        `ace-c-highlight-circle--medium`,
                                        `ace-c-highlight-circle--negative`,
                                        'global!ace-u-margin--bottom-32',
                                    ])}
                                >
                                    <Icon
                                        className={cx([
                                            'ace-c-icon--xl',
                                            'global!ace-c-icon--color-warning',
                                        ])}
                                        icon={infoAlertIcon}
                                    />
                                </HighlightCircle>
                                <p
                                    className={cx([
                                        'global!ace-u-typography--align-center',
                                        'global!ace-u-typography--variant-body-bold',
                                        'global!ace-u-typography--color-medium-emphasis',
                                    ])}
                                >
                                    {translateTab('error_message.could_not_load_affiliate_partners')}
                                </p>
                            </div>
                        )}
                        {affiliatePartnersPersistenceState === persistenceStates.READY && affiliatePartners.length > 0
                            && affiliatePartners.map(affiliatePartner => {
                                return (
                                    <div key={affiliatePartner.id}>
                                        <ContractPartnerOperationAreaItem
                                            contractPartnerOperationArea={affiliatePartner}
                                            isSelected={affiliatePartner.id === selectedOperationAreaId}
                                            onSelect={handleContractPartnerSelect}
                                            hasLink={true}
                                        />
                                        <div
                                            className={cx([
                                                'global!ace-u-padding--left-32',
                                                'global!ace-u-padding--right-32',
                                            ])}
                                        >
                                            <Divider />
                                        </div>
                                    </div>
                                );
                            })
                        }
                        <div
                            className={cx([
                                'global!ace-u-margin--top-32',
                                'global!ace-u-margin--bottom-16',
                                'global!ace-u-padding--left-32',
                                'global!ace-u-padding--right-32',
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-flex--justify-space-between',
                            ])}
                        >
                            <p className={cx('global!ace-u-typography--variant-body-bold')}>
                                {translateTab('list_title.other_operating_units')}
                            </p>
                            <ToggleSwitch
                                defaultIsSelected={areOperatingUnitsVisible}
                                onChange={value => {
                                    handleSetMapExtent(value);
                                    setAreOperatingUnitsVisible(value);
                                }}
                            />
                        </div>
                        {contractPartner.operatingUnits.length > 0
                            && areOperatingUnitsVisible
                            && contractPartner.operatingUnits.map(operatingUnit => {
                                return (
                                    <div key={operatingUnit.id}>
                                        <ContractPartnerOperationAreaItem
                                            contractPartnerOperationArea={operatingUnit}
                                            isSelected={operatingUnit.id === selectedOperationAreaId}
                                            onSelect={handleContractPartnerSelect}
                                        />
                                        <div
                                            className={cx([
                                                'global!ace-u-padding--left-32',
                                                'global!ace-u-padding--right-32',
                                            ])}
                                        >
                                            <Divider />
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </ScrollableBlock>
                </Panel>
            </ContentItem>
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-9',
                    'global!ace-u-height--full',
                    'global!ace-u-max-height--full',
                ], {
                    'ace-c-content-item--span-12': !isSidebarExpanded,
                })}
            >
                <SidebarExpansionButton
                    isExpanded={isSidebarExpanded}
                    onClick={() => setIsSidebarExpanded(prevState => !prevState)}
                />
            </ContentItem>
        </ContentBlock>
    );
};

ContractPartnerOperationAreaTab.propTypes = {
    contractPartner: PropTypes.object,
    initiateCPOperationAreaSelectFlow: PropTypes.func.isRequired,
    initiateCPOperationAreaMap: PropTypes.func.isRequired,
    selectedContractPartnerOperationArea: PropTypes.object,
    affiliatePartners: PropTypes.array,
    affiliatePartnersPersistenceState: PropTypes.string.isRequired,
};

ContractPartnerOperationAreaTab.defaultProps = {
    contractPartner: null,
    selectedContractPartnerOperationArea: null,
    affiliatePartners: [],
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();
    return {
        contractPartner: contractPartnerSelector(state, props),
        selectedContractPartnerOperationArea: state.contractPartners.selectedContractPartnerOperationArea,
        affiliatePartners: state.contractPartners.affiliatePartners,
        affiliatePartnersPersistenceState: state.contractPartners.affiliatePartnersPersistenceState,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateCPOperationAreaMap: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CONTRACT_PARTNER_OPERATION_AREAS_MAP,
        payload,
    }),
    initiateCPOperationAreaSelectFlow: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CONTRACT_PARTNER_OPERATION_AREA_SELECT_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerOperationAreaTab));
