import {put, select, take, fork, call} from 'redux-saga/effects';
import {persistenceStates} from '@computerrock/formation-core';
import * as contractPartnerActionTypes from '../../contract-partners/contractPartnerActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';

const filterMapContractPartnersBySearchQuery = function* filterMapContractPartnersBySearchQuery(params) {
    const {contractStatuses, regionIds, fieldToFilterBy, searchQueryString} = params;
    const {serviceManager} = yield select(state => state.application);
    const {hasPolygonsError} = yield select(state => state.contractPartners);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'vpm-contract-partners');

    if (!arcGISMap) return;

    const vpmContractPartnersLocationsLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-locations');
    const vpmContractPartnersServiceAreasLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-service-areas');

    if (!vpmContractPartnersServiceAreasLayer || !vpmContractPartnersLocationsLayer) return;

    const spatialReference = vpmContractPartnersLocationsLayer.getServiceFeatureLayer().spatialReference;
    const mapCenter = arcGISMap.getMapCenter();
    const arcGISMapCenterPoint = yield call(arcGISMap.createPoint, mapCenter.x, mapCenter.y, spatialReference);

    yield put({
        type: contractPartnerActionTypes.SET_CONTRACT_PARTNERS_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.PENDING},
    });

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FILTER_CP_DISTANCE_RECOMMENDATIONS_REQUEST,
        vpmContractPartnersLocationsLayer.filterFeaturesByAttribute,
        {
            where: `${fieldToFilterBy} LIKE '%${searchQueryString}%'`
                + (contractStatuses.length > 0
                    ? ` AND (${contractStatuses.map(status => (
                        `contractStatus = '${status}'`
                    )).toString().replaceAll(',', ' OR ')})`
                    : ``)
                + (regionIds.length > 0
                    ? ` AND (${regionIds.map(regionId => `regionId = '${regionId}'`).toString().replaceAll(',', ' OR ')})`
                    : ``),
            featureCount: 20,
            referentialPoint: arcGISMapCenterPoint,
            returnLocationFromAttribute: 'address',
            keepPreviousResults: false,
        },
    );

    const filterPointFeaturesResponseAction = yield take([
        contractPartnerActionTypes.FILTER_CP_DISTANCE_RECOMMENDATIONS_REQUEST_FAILED,
        contractPartnerActionTypes.FILTER_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SUCCEEDED,
    ]);


    if (filterPointFeaturesResponseAction.error) {
        yield put({
            type: contractPartnerActionTypes.SET_CONTRACT_PARTNERS_PERSISTENCE_STATE,
            payload: {persistenceState: persistenceStates.FAILED},
        });

        return;
    }

    // reset 'hasPolygonsError' flag
    if (hasPolygonsError) {
        yield put({
            type: contractPartnerActionTypes.SET_CONTRACT_PARTNERS_MAP_POLYGONS_ERROR,
            payload: {
                hasPolygonsError: false,
            },
        });
    }

    const {response} = filterPointFeaturesResponseAction.payload;
    const {featureDTOs: recommendedContractPartnerDTOs = []} = response;

    if (recommendedContractPartnerDTOs.length) {
        yield fork(
            fetchRequest,
            contractPartnerActionTypes.FILTER_CP_SERVICE_AREAS_REQUEST,
            vpmContractPartnersServiceAreasLayer.filterFeaturesByAttribute,
            {
                where: `contractPa IN (${recommendedContractPartnerDTOs
                    .map(contractPartnerDTO => `'${contractPartnerDTO['contractPartnerId']}'`)
                    .join(', ')})`,
                referentialPoint: arcGISMapCenterPoint,
            },
        );

        const filterPolygonFeaturesResponseAction = yield take([
            contractPartnerActionTypes.FILTER_CP_SERVICE_AREAS_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.FILTER_CP_SERVICE_AREAS_REQUEST_FAILED,
        ]);

        if (filterPolygonFeaturesResponseAction.error) {
            // set 'hasPolygonsError' flag
            yield put({
                type: contractPartnerActionTypes.SET_CONTRACT_PARTNERS_MAP_POLYGONS_ERROR,
                payload: {
                    hasPolygonsError: true,
                },
            });
        }
    }

    yield put({
        type: contractPartnerActionTypes.SET_CONTRACT_PARTNER_RECOMMENDATIONS,
        payload: {recommendedContractPartnerDTOs},
    });

    yield put({
        type: contractPartnerActionTypes.SET_CONTRACT_PARTNERS_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.READY},
    });

    // center map and set zoom level so an agent can see all the features on the map
    const extent = yield call(vpmContractPartnersLocationsLayer.getFeatureLayerExtent, {
        where: `${fieldToFilterBy} LIKE '%${searchQueryString}%'`
            + (contractStatuses.length > 0
                ? ` AND (${contractStatuses.map(status => (
                    `contractStatus = '${status}'`
                )).toString().replaceAll(',', ' OR ')})`
                : ``)
            + (regionIds.length > 0
                ? ` AND (${regionIds.map(regionId => `regionId = '${regionId}'`).toString().replaceAll(',', ' OR ')})`
                : ``),
    });
    yield call(arcGISMap.setMapExtent, extent);

    vpmContractPartnersLocationsLayer.show();
};

export default filterMapContractPartnersBySearchQuery;
