import {take, fork, cancel, put} from 'redux-saga/effects';
import {LOCATION_CHANGE, matchPath, parseSearchQueryParams} from '@computerrock/formation-router';
import routePaths from '../../routePaths';
import qualityManagementFeedbackScreenTabs from '../qualityManagementFeedbackScreenTabs';
import * as qualityManagementFeedbackActionTypes from '../qualityManagementFeedbackActionTypes';
import onQMFeedbackMapClickSelectContractPartner from './onQMFeedbackMapClickSelectContractPartner';

const qmFeedbackMapContractPartnerWatcher = function* qmFeedbackMapContractPartnerWatcher() {
    mainFlow: while (true) {
        yield take(qualityManagementFeedbackActionTypes.START_QM_FEEDBACK_MAP_CONTRACT_PARTNER_WATCHER);

        const onQMFeedbackMapClickSelectContractPartnerTask = yield fork(onQMFeedbackMapClickSelectContractPartner);

        while (true) {
            const watcherTerminationAction = yield take([
                qualityManagementFeedbackActionTypes.STOP_QM_FEEDBACK_MAP_CONTRACT_PARTNER_WATCHER,
                LOCATION_CHANGE,
            ]);

            if (watcherTerminationAction.type === qualityManagementFeedbackActionTypes.STOP_QM_FEEDBACK_MAP_CONTRACT_PARTNER_WATCHER) { // eslint-disable-line max-len
                yield cancel(onQMFeedbackMapClickSelectContractPartnerTask);
                yield put({
                    type: qualityManagementFeedbackActionTypes.SET_ACTIVE_CONTRACT_PARTNER_ID,
                    payload: {
                        activeContractPartnerId: null,
                    },
                });
                continue mainFlow;
            }

            const {payload} = watcherTerminationAction;
            const {location} = payload;

            const qmFeedbackRouteMatch = matchPath(location.pathname, {
                path: routePaths.QUALITY_MANAGEMENT_FEEDBACK,
                exact: true,
            });
            const {activeTab} = qmFeedbackRouteMatch ? parseSearchQueryParams(location.search) : {};
            if (!qmFeedbackRouteMatch || activeTab !== qualityManagementFeedbackScreenTabs.QM_OVERVIEW) {
                yield cancel(onQMFeedbackMapClickSelectContractPartnerTask);
                yield put({
                    type: qualityManagementFeedbackActionTypes.SET_ACTIVE_CONTRACT_PARTNER_ID,
                    payload: {
                        activeContractPartnerId: null,
                    },
                });
                continue mainFlow;
            }
        }
    }
};

export default qmFeedbackMapContractPartnerWatcher;
