import {put} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import * as qualityManagementFeedbackActionTypes from '../qualityManagementFeedbackActionTypes';
import qualityManagementFeedbackScreenTabs from '../qualityManagementFeedbackScreenTabs';
import modalIds from '../../modalIds';

const loadQMFeedbackFullScreenMapCPWatcher = function* loadQMFeedbackFullScreenMapCPWatcher({payload}) {
    const {activeTab} = yield* selectSearchQueryParams();
    if (activeTab !== qualityManagementFeedbackScreenTabs.QM_OVERVIEW) return;

    const {location} = payload;
    if (location?.query?.modal && location.query.modal === modalIds.QM_FEEDBACK_CP_RANKING) {
        yield put({
            type: qualityManagementFeedbackActionTypes.START_QM_FEEDBACK_FULL_SCREEN_MAP_CP_WATCHER,
        });
    }
};

export default loadQMFeedbackFullScreenMapCPWatcher;
