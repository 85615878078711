import {put, fork, take, select} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import contractPartnerScreenTabs from '../contractPartnerScreenTabs';
import modalIds from '../../modalIds';

const loadContractPartnerRelations = function* loadContractPartnerRelations({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const {match, location} = payload;
    const {contractPartnerId} = match.params;
    const {activeTab} = yield* selectSearchQueryParams();

    // do not load contract partner relations if agent is not on that tab
    if (activeTab !== contractPartnerScreenTabs.BASIC_DATA) return;
    // do not reload ace partner relations when agent opens operating unit data modal
    if (location && location.state?.isModalOpen
        && location.state?.modalId === modalIds.CP_OPERATING_UNIT_DATA) return;

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_RELATIONS_REQUEST,
        partnerManagementService.getACEPartnerAvailableAffiliate,
        {
            acePartnerId: contractPartnerId,
        },
    );

    const responseAction = yield take([
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_RELATIONS_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_RELATIONS_REQUEST_FAILED,
    ]);

    if (responseAction.error) return;

    const {response} = responseAction.payload;

    yield put({
        type: contractPartnerActionTypes.STORE_CONTRACT_PARTNER_RELATIONS,
        payload: {contractPartnerId, contractPartnerRelationsDTOs: response.acePartnerAvailableAffiliateDTOs},
    });
};

export default loadContractPartnerRelations;
