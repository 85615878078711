import {germanStateCodes} from '@ace-de/eua-entity-types';

/**
 * German state codes
 */
export default {
    BADEN_WURTTEMBERG: germanStateCodes.BW,
    BAVARIA: germanStateCodes.BY,
    BERLIN: germanStateCodes.BE,
    BRANDENBURG: germanStateCodes.BB,
    BREMEN: germanStateCodes.HB,
    HAMBURG: germanStateCodes.HH,
    HESSE: germanStateCodes.HE,
    LOWER_SAXONY: germanStateCodes.NI,
    MECKLENBURG_VORPOMMERN: germanStateCodes.MV,
    NORTH_RHINE_WESTPHALIA: germanStateCodes.NW,
    RHINELAND_PALATINATE: germanStateCodes.RP,
    SAARLAND: germanStateCodes.SL,
    SAXONY: germanStateCodes.SN,
    SAXONY_ANHALT: germanStateCodes.ST,
    SCHLESWIG_HOLSTEIN: germanStateCodes.SH,
    THURINGIA: germanStateCodes.TH,
};
