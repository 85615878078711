import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {AppLayoutMain} from '@ace-de/ui-components/app-layout';
import {useHistory} from '@computerrock/formation-router';
import {useStyles, ButtonPrimary} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';
import ACELogo from '../dashboard/ui-elements/ACELogo';
import keycloakAuth from '../keycloakAuth';

const AccessDeniedScreen = props => {
    const {cx} = useStyles();
    const history = useHistory();
    const {isSystemAccessGranted, signOutUser} = props;
    const {translate} = useTranslate();

    const handleOnClick = () => {
        if (isSystemAccessGranted) {
            history.goBack();
            return;
        }

        signOutUser();
    };

    return (
        <Fragment>
            <AppLayoutMain>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-padding--48-0',
                        'global!ace-u-flex--justify-content-center',
                        'global!ace-u-height--full',
                    ])}
                >
                    <ACELogo />
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-h1',
                            'global!ace-u-typography--color-medium-emphasis',
                            'global!ace-u-margin--top-32',
                        ])}
                    >
                        403
                    </p>
                    <div
                        className={cx([
                            'global!ace-u-margin--top-16',
                            'global!ace-u-typography--variant-body',
                            'global!ace-u-flex--direction-column',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-typography--color-medium-emphasis',
                            'global!ace-u-typography--align-center',
                        ])}
                    >
                        <p>
                            {translate(`${isSystemAccessGranted
                                ? 'access_denied_screen.message.forbidden'
                                : 'access_denied_screen.message.system_access_forbidden'}`)}
                        </p>
                        <ButtonPrimary
                            className={cx('global!ace-u-margin--top-16')}
                            onClick={handleOnClick}
                        >
                            {translate(`${isSystemAccessGranted
                                ? 'access_denied_screen.button_label.go_back'
                                : 'access_denied_screen.button_label.log_in'}`)}
                        </ButtonPrimary>
                    </div>
                </div>
            </AppLayoutMain>
        </Fragment>
    );
};

AccessDeniedScreen.propTypes = {
    isSystemAccessGranted: PropTypes.bool.isRequired,
    signOutUser: PropTypes.func,
};

AccessDeniedScreen.defaultProps = {
    signOutUser: null,
};

const mapStateToProps = state => {
    return {
        isSystemAccessGranted: state.vpmSystemAccessReducer.isSystemAccessGranted,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        signOutUser: () => dispatch(keycloakAuth.signOutUserAction),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AccessDeniedScreen);
