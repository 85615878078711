import {fork, put, select, take} from 'redux-saga/effects';
import * as qualityManagementFeedbackActionTypes from '../qualityManagementFeedbackActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';

const createQualityManagementFeedbackComment = function* createQualityManagementFeedbackComment() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(qualityManagementFeedbackActionTypes.SUBMIT_QUALITY_MANAGEMENT_FEEDBACK_COMMENT);
        const {comment, qmFeedbackId} = payload;
        yield fork(
            fetchRequest,
            qualityManagementFeedbackActionTypes.CREATE_QUALITY_MANAGEMENT_FEEDBACK_COMMENT_REQUEST,
            partnerManagementService.createACEPartnerQualityManagementComment,
            {
                feedbackId: qmFeedbackId,
                comment,
            },
        );

        const responseAction = yield take([
            qualityManagementFeedbackActionTypes.CREATE_QUALITY_MANAGEMENT_FEEDBACK_COMMENT_REQUEST_SUCCEEDED,
            qualityManagementFeedbackActionTypes.CREATE_QUALITY_MANAGEMENT_FEEDBACK_COMMENT_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            yield fork(
                fetchRequest,
                qualityManagementFeedbackActionTypes.FETCH_QM_FEEDBACKS_REQUEST,
                partnerManagementService.getQualityManagementFeedback,
                {
                    qualityManagementFeedbackId: qmFeedbackId,
                },
            );

            const qmFeedbackResponseAction = yield take([
                qualityManagementFeedbackActionTypes.FETCH_QM_FEEDBACKS_REQUEST_SUCCEEDED,
                qualityManagementFeedbackActionTypes.FETCH_QM_FEEDBACKS_REQUEST_FAILED,
            ]);

            if (!qmFeedbackResponseAction.error) {
                const {response} = qmFeedbackResponseAction.payload;
                const {qualityManagementFeedbackDTO} = response;

                yield put({
                    type: qualityManagementFeedbackActionTypes.STORE_QUALITY_MANAGEMENT_FEEDBACKS,
                    payload: {qualityManagementFeedbackDTOs: [qualityManagementFeedbackDTO]},
                });
            }
        }
    }
};

export default createQualityManagementFeedbackComment;
