import {put} from 'redux-saga/effects';
import config from '../../config';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

const loadContractPartnerSearchResults = function* loadContractPartnerSearchResults({payload}) {
    const {location} = payload;
    const searchQueryParams = new URLSearchParams(location.search);
    if (!searchQueryParams.get('sort')) searchQueryParams.append('sort', 'address.postCode,asc');
    searchQueryParams.append('size', `${config.DEFAULT_PAGE_SIZE}`);
    // include restrictions: get activeRestrictions and lastRestrictionType
    searchQueryParams.append('includeRestrictions', true);

    yield put({
        type: contractPartnerActionTypes.SEARCH_CONTRACT_PARTNERS,
        payload: {searchQueryParams},
    });
};

export default loadContractPartnerSearchResults;
