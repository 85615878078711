/**
 * @typedef {string} tabId
 */

/**
 * SAMC screen tabs
 *
 * @enum {tabId}
 */
export default {
    CONTRACT_PARTNERS: 'contract-partners',
    TEMPORARY_RESTRICTIONS: 'temporary-restrictions',
    QUALITY_MANAGEMENT: 'quality-management',
    PRICE_MANAGEMENT: 'price-management',
};
