import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {useRouter, withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates} from '@ace-de/eua-entity-types';
import {useStyles, ScreenTitle} from '@ace-de/ui-components';
import {InteractiveIcon, closeIcon, minusIcon, timeIcon, Icon, checkmarkIcon} from '@ace-de/ui-components/icons';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import * as priceManagementActionTypes from '../../price-management/priceManagementActionTypes';
import * as priceManagementSelectors from '../../price-management/priceManagementSelectors';
import config from '../../config';

import getPrice from '../../utils/getPrice';

const ContractPartnerHeader = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const router = useRouter();
    const translateHeader = createTranslateShorthand('contract_partner_header');
    const {contractPartner, initiateContractPartnerStatusUpdateFlow, initiateContractPartnerFixedPriceFlow} = props;
    const {activeFixedPrice} = props;

    return (
        <Fragment>
            <ScreenTitle>
                <div
                    className={cx([
                        'global!ace-u-typography--variant-body',
                        'global!ace-u-typography--color-contrast',
                    ])}
                >
                    {contractPartner.name}
                </div>
            </ScreenTitle>
            <div
                className={cx([
                    'global!ace-u-flex--grow-1',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-margin--left-32',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <span>{translateHeader('label.creditor_number')}</span>
                        <span
                            className={cx([
                                'global!ace-u-typography--variant-body',
                                'global!ace-u-margin--left-8',
                            ])}
                        >
                            {contractPartner.id || ''}
                        </span>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-cursor--pointer',
                        ])}
                        onClick={() => initiateContractPartnerStatusUpdateFlow({contractPartnerId: contractPartner.id})}
                    >
                        <span className={cx('global!ace-u-margin--left-48')}>
                            {translateHeader('label.status')}
                        </span>
                        <span
                            className={cx([
                                'global!ace-u-margin--left-8',
                                'global!ace-u-margin--right-8',
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-typography--color-highlighted',
                            ])}
                        >
                            {contractPartner.contractStatus
                                ? translate(`global.contract_status.${snakeCase(contractPartner.contractStatus)}`) : ''}
                        </span>
                        {!!contractPartner.pendingStatusChange && (
                            <Icon icon={timeIcon} />
                        )}
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <span className={cx(['global!ace-u-margin--left-48', 'global!ace-u-margin--right-8'])}>
                            {translateHeader('label.fixed_area_of_application')}
                        </span>
                        {contractPartner.fixedAreaOfApplication ? (
                            <Icon
                                icon={checkmarkIcon}
                                className={cx('ace-c-icon--color-success')}
                            />
                        ) : (
                            <Icon
                                icon={closeIcon}
                                className={cx('ace-c-icon--color-warning')}
                            />
                        )}
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <span className={cx('global!ace-u-margin--left-48')}>
                            {translateHeader('label.fixed_price')}
                        </span>
                        {config.IS_PRE_BC_VERSION && (
                            <span
                                className={cx([
                                    'global!ace-u-typography--variant-body',
                                    'global!ace-u-margin--left-8',
                                ])}
                            >
                                {contractPartner.fixedPrice
                                    ? getPrice(contractPartner.fixedPrice, activeLocale) : '-'}
                            </span>

                        )}
                        {!config.IS_PRE_BC_VERSION && (
                            <span
                                className={cx([
                                    'global!ace-u-typography--variant-body-bold',
                                    'global!ace-u-typography--color-highlighted',
                                    'global!ace-u-cursor--pointer',
                                    'global!ace-u-margin--left-8',
                                    'global!ace-u-flex',
                                ])}
                                onClick={() => {
                                    initiateContractPartnerFixedPriceFlow({contractPartnerId: contractPartner.id});
                                }}
                            >
                                {!!activeFixedPrice?.value && (
                                    getPrice(activeFixedPrice?.value, activeLocale) || ''
                                )}
                                {!activeFixedPrice?.value && (
                                    <InteractiveIcon
                                        qaIndent="fixed-price"
                                        className={cx(['ace-c-icon--s', 'global!ace-u-flex--align-center'])}
                                        icon={minusIcon}
                                    />
                                )}
                            </span>
                        )}
                    </div>
                </div>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-flex-end',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--right-32',
                    ])}
                >
                    <InteractiveIcon
                        icon={closeIcon}
                        onClick={router.goBack}
                        isDisabled={contractPartner.persistenceState === persistenceStates.PENDING}
                    />
                </div>
            </div>
        </Fragment>
    );
};

ContractPartnerHeader.propTypes = {
    contractPartner: PropTypes.object.isRequired,
    initiateContractPartnerStatusUpdateFlow: PropTypes.func.isRequired,
    initiateContractPartnerFixedPriceFlow: PropTypes.func.isRequired,
    activeFixedPrice: PropTypes.object,
};

ContractPartnerHeader.defaultProps = {
    activeFixedPrice: null,
};

const mapDispatchToProps = dispatch => ({
    initiateContractPartnerStatusUpdateFlow: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CONTRACT_PARTNER_STATUS_UPDATE_FLOW,
        payload,
    }),
    initiateContractPartnerFixedPriceFlow: payload => dispatch({
        type: priceManagementActionTypes.INITIATE_CONTRACT_PARTNER_FIXED_PRICE_EDIT_FLOW,
        payload,
    }),
});

const mapStateToProps = (state, props) => {
    const activeFixedPricesSelector = priceManagementSelectors.createActiveFixedPricesSelector();
    return {
        activeFixedPrice: activeFixedPricesSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerHeader));
