import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {apmQualityManagementFeedbackChannelTypes} from '@ace-de/eua-entity-types';
import {ContentBlock, ContentItem, Panel, useStyles} from '@ace-de/ui-components';
import QualityManagementFeedbackBaseDataPanel from './ui-elements/QMFeedbackBaseDataPanel';
import QMFeedbackSnapshotAssignmentDataPanel from './ui-elements/QMFeedbackSnapshotAssignmentDataPanel';
import QMFeedbackVehicleDataPanel from './ui-elements/QMFeedbackVehicleDataPanel';
import QMFeedbackInternalCommentsPanel from './ui-elements/QMFeedbackInternalCommentsPanel';
import QMFeedbackCPRankingPanel from './ui-elements/QMFeedbackCPRankingPanel';
import * as qualityManagementFeedbackSelectors from './qualityManagementFeedbackSelectors';
import styles from './QualityManagementFeedbackOverviewTab.module.scss';

const QualityManagementFeedbackOverviewTab = props => {
    const {cx} = useStyles(props, styles);
    const {translate} = useTranslate();
    const {qmFeedback} = props;

    // if qmFeedback does not exist, don't render
    if (!qmFeedback) return null;
    const shouldDisplayMap = [
        apmQualityManagementFeedbackChannelTypes.CP_SKIPPED,
        apmQualityManagementFeedbackChannelTypes.REJECTED,
    ].includes(qmFeedback.qualityManagementFeedbackChannel);

    /* eslint-disable-next-line max-len */
    const isFromTemporaryRestriction = qmFeedback.qualityManagementFeedbackChannel === apmQualityManagementFeedbackChannelTypes.TEMPORARY_RESTRICTION;

    return (
        <ContentBlock>
            <ContentItem className={cx(['ace-c-content-item--span-9', 'qm-feedback-overview-tab-grid-container'])}>
                <QualityManagementFeedbackBaseDataPanel />
                {!isFromTemporaryRestriction && (
                    <QMFeedbackVehicleDataPanel />
                )}
                {!isFromTemporaryRestriction && (
                    <QMFeedbackSnapshotAssignmentDataPanel />
                )}
                <QMFeedbackInternalCommentsPanel />
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                {shouldDisplayMap ? (
                    <QMFeedbackCPRankingPanel />
                ) : (
                    <Panel
                        title={translate('qm_feedback_cp_ranking_panel.title.list_of_10')}
                        contentClassName={cx('global!ace-u-typography--variant-body')}
                    >
                        {translate('qm_feedback_cp_ranking_panel.text.manual_qm_without_map_data')}
                    </Panel>
                )}
            </ContentItem>
        </ContentBlock>
    );
};

QualityManagementFeedbackOverviewTab.propTypes = {
    qmFeedback: PropTypes.object,
};

QualityManagementFeedbackOverviewTab.defaultProps = {
    qmFeedback: null,
};

const mapStateToProps = (state, props) => {
    const qmFeedbackSelector = qualityManagementFeedbackSelectors.createQMFeedbackSelector();
    return {
        qmFeedback: qmFeedbackSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps)(QualityManagementFeedbackOverviewTab));
