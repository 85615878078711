import {fork, select, take} from 'redux-saga/effects';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';

const downloadCPOverview = function* downloadCPOverview() {
    const {serviceManager} = yield select(state => state.application);
    const apmFileAssetsService = serviceManager.loadService('apmFileAssetsService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.INITIATE_CP_OVERVIEW_DOWNLOAD_FLOW);
        const {url, fileName} = payload;

        yield fork(
            fetchRequest,
            contractPartnerActionTypes.DOWNLOAD_CP_OVERVIEW_REQUEST,
            apmFileAssetsService.downloadFile,
            {
                fileName,
                url,
            },
        );

        yield take([
            contractPartnerActionTypes.DOWNLOAD_CP_OVERVIEW_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.DOWNLOAD_CP_OVERVIEW_REQUEST_FAILED,
        ]);
    }
};

export default downloadCPOverview;
