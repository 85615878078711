import {put, take} from 'redux-saga/effects';
import * as qualityManagementFeedbackActionTypes from '../qualityManagementFeedbackActionTypes';

const loadQMFeedbackMapContractPartnerWatcher = function* loadQMFeedbackMapContractPartnerWatcher() {
    while (true) {
        const {payload} = yield take(qualityManagementFeedbackActionTypes.INITIATE_QM_FEEDBACK_CP_RANKING_MAP);
        const {mapName} = payload;
        if (mapName !== 'qm-feedback-cp-ranking-map') return;

        yield put({
            type: qualityManagementFeedbackActionTypes.START_QM_FEEDBACK_MAP_CONTRACT_PARTNER_WATCHER,
        });
    }
};

export default loadQMFeedbackMapContractPartnerWatcher;
