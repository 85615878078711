import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Modal, HighlightCircle} from '@ace-de/ui-components';
import {ButtonPrimary, ButtonSecondary} from '@ace-de/ui-components/buttons';
import {Icon, closeIcon, warningOutlinedIcon} from '@ace-de/ui-components/icons';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import useCloseOnEscape from '../../utils/useCloseOnEscape';

const DeleteCPOperatingUnitConfirmationModal = props => {
    const {cx} = useStyles();
    const {confirmOperatingUnitDeletion, declineOperatingUnitDeletion, hasBackdrop} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('delete_cp_operating_unit_confirmation_modal');

    useCloseOnEscape(() => {
        declineOperatingUnitDeletion();
    });

    return (
        <Modal
            action={(
                <Icon icon={closeIcon} onClick={declineOperatingUnitDeletion} />
            )}
            hasBackdrop={hasBackdrop}
        >
            <div
                className={cx([
                    'global!ace-u-full-width',
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <HighlightCircle
                    className={cx([
                        'ace-c-highlight-circle--medium',
                        'global!ace-u-margin--bottom-32',
                        'ace-c-highlight-circle--primary',
                    ])}
                >
                    <Icon
                        icon={warningOutlinedIcon}
                        className={cx([
                            'ace-c-icon--xxl',
                            'ace-c-icon--color-highlight-pressed',
                        ])}
                    />
                </HighlightCircle>
                <p
                    className={cx([
                        'global!ace-u-margin--bottom-32',
                        'global!ace-u-typography--variant-h3',
                    ])}
                >
                    {translateModal('title.delete_operating_unit')}
                </p>
                <p
                    className={cx([
                        'global!ace-u-margin--bottom-32',
                        'global!ace-u-typography--align-center',
                    ])}
                >
                    {translateModal('heading.delete_operating_unit')}
                </p>
                <ButtonPrimary
                    className={cx(['global!ace-u-margin--bottom-24', 'global!ace-u-full-width'])}
                    onClick={confirmOperatingUnitDeletion}
                >
                    {translateModal('button_label.yes')}
                </ButtonPrimary>
                <ButtonSecondary
                    className={cx('global!ace-u-full-width')}
                    onClick={declineOperatingUnitDeletion}
                >
                    {translateModal('button_label.abort')}
                </ButtonSecondary>
            </div>
        </Modal>
    );
};

DeleteCPOperatingUnitConfirmationModal.propTypes = {
    declineOperatingUnitDeletion: PropTypes.func.isRequired,
    confirmOperatingUnitDeletion: PropTypes.func.isRequired,
    hasBackdrop: PropTypes.bool,
};

DeleteCPOperatingUnitConfirmationModal.defaultProps = {
    hasBackdrop: true,
};

const mapDispatchToProps = dispatch => ({
    declineOperatingUnitDeletion: () => dispatch({
        type: contractPartnerActionTypes.DECLINE_DELETE_CP_OPERATING_UNIT,
    }),
    confirmOperatingUnitDeletion: () => dispatch({
        type: contractPartnerActionTypes.CONFIRM_DELETE_CP_OPERATING_UNIT,
    }),
});

export default connect(null, mapDispatchToProps)(DeleteCPOperatingUnitConfirmationModal);
