import {delay, fork, put, select, take} from 'redux-saga/effects';
import {replace, resolveRoute} from '@computerrock/formation-router';
import {closeModal} from '@computerrock/formation-router/sagas';
import {LeistContact} from '@ace-de/eua-entity-types';
import bcModalContentTypes from '../modals/bcModalContentTypes';
import * as contractPartnersActionTypes from '../contractPartnerActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import bcModalTypes from '../modals/bcModalTypes';

const updateContractPartnerBCContactFlow = function* updateContractPartnerBCContactFlow() {
    let shouldWaitForAction = true;
    let payloadData = null;
    let shouldRetryContactUpdate = false;
    let retryContactUpdateData = null;

    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contractPartnersActionTypes.INITIATE_BC_CONTACT_UPDATE_MODAL_FLOW);
            payloadData = payload;
        }
        const {location} = yield select(state => state.router);

        let chosenAction;

        const {selectedContact, modalType, client} = payloadData;

        if (!shouldRetryContactUpdate && client && selectedContact && modalType) {
            yield put({
                type: contractPartnersActionTypes.STORE_CONTACTS,
                payload: {contactDTOs: [selectedContact]},
            });

            const searchParams = new URLSearchParams(location.search);
            searchParams.set('contentType', bcModalContentTypes.CONTRACT_PARTNER);
            searchParams.set('client', client);
            searchParams.set('type', modalType);
            searchParams.set('contactId', selectedContact.id);

            yield put(replace(resolveRoute(location.pathname, {}, {search: searchParams.toString()})));

            chosenAction = yield take([
                contractPartnersActionTypes.RETURN_TO_CONTACT_SEARCH,
                contractPartnersActionTypes.SUBMIT_UPDATE_CONTRACT_PARTNER,
            ]);
        }

        if (shouldRetryContactUpdate
            || chosenAction?.type === contractPartnersActionTypes.SUBMIT_UPDATE_CONTRACT_PARTNER) {
            const contactId = !shouldRetryContactUpdate && chosenAction.payload.contactId
                ? chosenAction.payload.contactId : retryContactUpdateData?.contactId;
            const contractPartnerData = !shouldRetryContactUpdate && chosenAction.payload.contractPartnerData
                ? chosenAction.payload.contractPartnerData : retryContactUpdateData?.contractPartnerData;

            const loaderSearchParams = new URLSearchParams(location.search);
            loaderSearchParams.set('contentType', bcModalContentTypes.LOADER);
            loaderSearchParams.set('type', modalType);

            yield put(replace(resolveRoute(location.pathname, {}, {search: loaderSearchParams.toString()})));

            yield fork(fetchRequest,
                contractPartnersActionTypes.UPDATE_CONTACT_REQUEST,
                leaAssignmentFlowService.updateContact,
                {
                    contactId,
                    contactData: {
                        client,
                        ...LeistContact.objectToDTO(contractPartnerData),
                    },
                });

            const responseAction = yield take([
                contractPartnersActionTypes.UPDATE_CONTACT_REQUEST_SUCCEEDED,
                contractPartnersActionTypes.UPDATE_CONTACT_REQUEST_FAILED,
            ]);

            yield delay(100);
            shouldRetryContactUpdate = false;

            if (responseAction.error) {
                const failedQueryParams = new URLSearchParams(location.search);
                failedQueryParams.set('contentType', bcModalContentTypes.BC_ACTION_FAILED);
                failedQueryParams.set('type', bcModalTypes.UPDATE_CONTRACT_PARTNER);
                yield put(replace(resolveRoute(location.pathname, {}, {search: failedQueryParams.toString()})));

                const nextAction = yield take([
                    contractPartnersActionTypes.RETRY_UPDATE_CONTACT,
                    contractPartnersActionTypes.CANCEL_ACTION,
                    contractPartnersActionTypes.DECLINE_BC_CONTACT_MODAL_FLOW,
                ]);

                if (nextAction.type === contractPartnersActionTypes.RETRY_UPDATE_CONTACT) {
                    shouldRetryContactUpdate = true;
                    shouldWaitForAction = false;
                    retryContactUpdateData = {
                        contactId,
                        contractPartnerData,
                        isOnlyContact: true,
                    };
                    continue;
                }

                if (nextAction.type === contractPartnersActionTypes.CANCEL_ACTION) {
                    shouldWaitForAction = false;
                    shouldRetryContactUpdate = false;
                    continue;
                }
            }

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {contactDTO} = response;

                shouldWaitForAction = true;
                yield* closeModal(modalIds.BC_CONTACT_MODAL, {contentType: '', type: '', client: '', contactId: ''});
                yield put({
                    type: contractPartnersActionTypes.INITIATE_BC_CONTACT_MODAL_FLOW,
                    payload: {
                        client,
                        contactId: contactDTO.id,
                        initiateContactSearch: true,
                    },
                });
                continue;
            }
        }

        shouldWaitForAction = true;
        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.set('contentType', bcModalContentTypes.CONTACT_SEARCH);
        newSearchParams.set('client', client);

        yield put(replace(resolveRoute(location.pathname, {}, {search: newSearchParams.toString()})));
    }
};

export default updateContractPartnerBCContactFlow;
