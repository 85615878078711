import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {useSearchQueryParams, withRouter} from '@computerrock/formation-router';
import {ButtonPrimary, Badge, useStyles} from '@ace-de/ui-components';
import {Icon, noAccessIcon, ratingNegativeIcon, ratingPositiveIcon} from '@ace-de/ui-components/icons';
import {AppLayoutMain, HeaderBar, NavigationBar} from '@ace-de/ui-components/app-layout';
import {TabSet, Tab, TabBar, TabPanel} from '@ace-de/ui-components/navigation';
import {apmQualityManagementFeedbackClassificationTypes} from '@ace-de/eua-entity-types';
import * as qualityManagementSelectors from './qualityManagementFeedbackSelectors';
import qualityManagementFeedbackScreenTabs from './qualityManagementFeedbackScreenTabs';
import QMFeedbackHeader from './ui-elements/QMFeedbackHeader';
import QualityManagementFeedbackOverviewTab from './QualityManagementFeedbackOverviewTab';
import * as qualityManagementFeedbackActionTypes from './qualityManagementFeedbackActionTypes';
import QualityManagementFeedbackCaseLogTab from './QualityManagementFeedbackCaseLogTab';

const QualityManagementFeedbackScreen = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('quality_management_feedback_screen');
    const {qualityManagementFeedback, qualifyQualityManagementFeedback, history} = props;
    const {activeTab} = useSearchQueryParams();

    if (!qualityManagementFeedback) return null;

    return (
        <Fragment>
            <HeaderBar>
                <QMFeedbackHeader qualityManagementFeedback={qualityManagementFeedback} />
            </HeaderBar>
            <TabSet
                name="quality-management-feedback-screen-tab-set"
                isRoutingEnabled={true}
                qaIdent="quality-management-feedback-screen"
            >
                <NavigationBar>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--direction-row',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <TabBar
                            name="quality-management-feedback-details-tabs"
                            tabSet="quality-management-feedback-screen-tab-set"
                            defaultValue={activeTab || qualityManagementFeedbackScreenTabs.QM_OVERVIEW}
                            className={cx('global!ace-u-flex--grow-1')}
                        >
                            <Tab
                                name={qualityManagementFeedbackScreenTabs.QM_OVERVIEW}
                                value={qualityManagementFeedbackScreenTabs.QM_OVERVIEW}
                            >
                                {translateScreen('tab_label.qm_overview')}

                            </Tab>
                            <Tab
                                name={qualityManagementFeedbackScreenTabs.CASE_LOG}
                                value={qualityManagementFeedbackScreenTabs.CASE_LOG}
                            >
                                {translateScreen('tab_label.case_log')}
                            </Tab>
                        </TabBar>
                        <div
                            className={cx([
                                'global!ace-u-margin--right-32',
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-flex--justify-flex-end',
                            ])}
                        >
                            {!qualityManagementFeedback.isQualified
                                ? Object.keys(apmQualityManagementFeedbackClassificationTypes).map(key => (
                                    <ButtonPrimary
                                        key={key}
                                        name={`${key.toLowerCase()}Qualification`}
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-margin--left-24',
                                        ], {
                                            'ace-c-button-primary--is-positive': key === apmQualityManagementFeedbackClassificationTypes.POSITIVE,
                                            'ace-c-button-primary--is-negative': key === apmQualityManagementFeedbackClassificationTypes.NEGATIVE,
                                            'ace-c-button-primary--is-neutral': key === apmQualityManagementFeedbackClassificationTypes.NEUTRAL,
                                        })}
                                        onClick={() => qualifyQualityManagementFeedback({
                                            classificationType: key,
                                            qualityManagementFeedbackId: qualityManagementFeedback.id,
                                            history,
                                        })}
                                    >
                                        <Icon
                                            icon={key === apmQualityManagementFeedbackClassificationTypes.POSITIVE
                                                ? ratingPositiveIcon
                                                : key === apmQualityManagementFeedbackClassificationTypes.NEGATIVE
                                                    ? ratingNegativeIcon
                                                    : noAccessIcon}
                                            className={cx([
                                                'global!ace-u-margin--right-8',
                                                'global!ace-c-icon--color-contrast',
                                            ])}
                                        />
                                        {translateScreen(`qualification_label.${key.toLowerCase()}`)}
                                    </ButtonPrimary>
                                ))
                                : (
                                    <Badge
                                        status={qualityManagementFeedback.classification === apmQualityManagementFeedbackClassificationTypes.NEUTRAL // eslint-disable-line max-len
                                            ? 'default'
                                            : qualityManagementFeedback.classification.toLowerCase()}
                                        className={cx([
                                            'global!ace-u-padding--16-32',
                                            'global!ace-u-typography--variant-body-bold',
                                            'global!ace-u-typography--color-contrast',
                                        ])}
                                    >
                                        {translateScreen(`qualification_label.${qualityManagementFeedback.classification.toLowerCase()}`).toUpperCase()}
                                    </Badge>
                                )}
                        </div>
                    </div>
                </NavigationBar>
                <AppLayoutMain>
                    <TabPanel for={qualityManagementFeedbackScreenTabs.QM_OVERVIEW}>
                        <QualityManagementFeedbackOverviewTab />
                    </TabPanel>
                    <TabPanel for={qualityManagementFeedbackScreenTabs.CASE_LOG}>
                        <QualityManagementFeedbackCaseLogTab />
                    </TabPanel>
                </AppLayoutMain>
            </TabSet>
        </Fragment>
    );
};

QualityManagementFeedbackScreen.propTypes = {
    history: PropTypes.object,
    qualityManagementFeedback: PropTypes.object,
    qualifyQualityManagementFeedback: PropTypes.func.isRequired,
};

QualityManagementFeedbackScreen.defaultProps = {
    history: null,
    qualityManagementFeedback: null,
};

const mapStateToProps = (state, props) => {
    const qmFeedbackSelector = qualityManagementSelectors.createQMFeedbackSelector();
    return {
        qualityManagementFeedback: qmFeedbackSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    qualifyQualityManagementFeedback: payload => dispatch({
        type: qualityManagementFeedbackActionTypes.QUALIFY_QUALITY_MANAGEMENT_FEEDBACK,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QualityManagementFeedbackScreen));
