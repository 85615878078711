import {put, select, take, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {persistenceStates} from '@computerrock/formation-core';
import {TemporaryRestriction} from '@ace-de/eua-entity-types';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import errorTypes from '../../application/errorTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';

const editTemporaryRestrictionFlow = function* editTemporaryRestrictionFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    let shouldWaitForAction = true;
    let payloadData = null;
    let chosenModalOption = null;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contractPartnerActionTypes.INITIATE_EDIT_TEMPORARY_RESTRICTION_FLOW);
            payloadData = payload;
        }
        const {contractPartner, temporaryRestrictionId} = payloadData;

        if (shouldWaitForAction) {
            yield* openModal(modalIds.CP_EDIT_TEMPORARY_RESTRICTION, {temporaryRestrictionId: `${temporaryRestrictionId}`});

            chosenModalOption = yield take([
                contractPartnerActionTypes.CONFIRM_EDIT_TEMPORARY_RESTRICTION,
                contractPartnerActionTypes.DECLINE_EDIT_TEMPORARY_RESTRICTION,
            ]);
        }

        if (chosenModalOption
            && chosenModalOption.type === contractPartnerActionTypes.CONFIRM_EDIT_TEMPORARY_RESTRICTION) {
            const {temporaryRestrictionData} = chosenModalOption.payload;
            yield fork(
                fetchRequest,
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST,
                partnerManagementService.updateContractPartnerTemporaryRestriction,
                {
                    temporaryRestrictionId,
                    temporaryRestrictionDTO: TemporaryRestriction.objectToPatchDTO(temporaryRestrictionData),
                },
            );

            const updateTemporaryRestrictionResponseAction = yield take([
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_FAILED,
            ]);

            if (updateTemporaryRestrictionResponseAction.error) {
                if (shouldWaitForAction) {
                    yield* closeModal(modalIds.CP_EDIT_TEMPORARY_RESTRICTION);
                    yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {
                        errorType: errorTypes.CP_TEMPORARY_RESTRICTION_UPDATE_FAILED,
                    });
                }

                yield put({
                    type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.READY},
                });

                const nextAction = yield take([
                    contractPartnerActionTypes.RETRY_UPDATE_CP_TEMPORARY_RESTRICTION,
                    contractPartnerActionTypes.CANCEL_RETRY_UPDATE_CP_TEMPORARY_RESTRICTION,
                ]);

                shouldWaitForAction = nextAction.type !== contractPartnerActionTypes.RETRY_UPDATE_CP_TEMPORARY_RESTRICTION; // eslint-disable-line max-len

                if (nextAction.type === contractPartnerActionTypes.RETRY_UPDATE_CP_TEMPORARY_RESTRICTION) {
                    yield put({
                        type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.PENDING},
                    });
                }

                if (nextAction.type === contractPartnerActionTypes.CANCEL_RETRY_UPDATE_CP_TEMPORARY_RESTRICTION) {
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                }

                continue;
            }

            if (!updateTemporaryRestrictionResponseAction.error) {
                if (!shouldWaitForAction) {
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});

                    yield put({
                        type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.READY},
                    });
                }
                shouldWaitForAction = true;
                const {response} = updateTemporaryRestrictionResponseAction.payload;

                yield put({
                    type: contractPartnerActionTypes.STORE_CONTRACT_PARTNER_RESTRICTIONS,
                    payload: {
                        contractPartnerId: contractPartner.id,
                        contractPartnerRestrictionDTOs: [response.contractPartnerRestrictionDTO],
                    },
                });

                yield* closeModal(modalIds.CP_EDIT_TEMPORARY_RESTRICTION, {temporaryRestrictionId: `${temporaryRestrictionId}`});

                yield* openModal(modalIds.CP_TEMPORARY_RESTRICTION_SUCCESSFUL);
            }
        }
        yield* closeModal(modalIds.CP_EDIT_TEMPORARY_RESTRICTION, {temporaryRestrictionId: `${temporaryRestrictionId}`});
    }
};

export default editTemporaryRestrictionFlow;
