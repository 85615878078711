import {select, call, take, put} from 'redux-saga/effects';
import {eventChannel} from 'redux-saga';
import * as contractPartnerActionTypes from '../../contract-partners/contractPartnerActionTypes';
import config from '../../config';

export const onContractPartnerMapCenterChange = function* onContractPartnerMapCenterChange() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'vpm-contract-partners');

    const mapCenterChangeEventListener = eventChannel(emitter => {
        const unsubscribeCenterChangeEventListener = arcGISMap.on('center-change', payload => {
            emitter(payload);
        });

        // returns unsubscribe method to eventChannel
        return () => {
            unsubscribeCenterChangeEventListener();
        };
    });

    while (true) {
        const {mapCenter} = yield take(mapCenterChangeEventListener);
        if (mapCenter) {
            yield put({
                type: contractPartnerActionTypes.SET_CONTRACT_PARTNERS_MAP_RELOAD_BUTTON_VISIBILITY,
                payload: {
                    isVisible: true,
                },
            });

            if (arcGISMap) {
                yield put({
                    type: contractPartnerActionTypes.SET_CONTRACT_PARTNERS_MAP_RELOAD_BUTTON_STATE,
                    payload: {
                        isDisabled: arcGISMap.getMapViewZoomLevel() < config.MINIMUM_MAP_ZOOM_LEVEL_FOR_SEARCHING,
                    },
                });
            }
        }
    }
};

export default onContractPartnerMapCenterChange;
