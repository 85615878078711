import {resolveRoute} from '@computerrock/formation-router';
import config from '../config';

const formatECSServiceCaseScreenURL = serviceCaseId => {
    if (!serviceCaseId || !config.ACE_ECS_SITE_URL) return '';
    const {pathname} = resolveRoute(config.ECS_SERVICE_CASE_SCREEN_PATH, {serviceCaseId});
    return window.location.hostname === config.BACKDOOR_URL
        ? config.ACE_ECS_SITE_BACKDOOR_URL + pathname
        : config.ACE_ECS_SITE_URL + pathname;
};

export default formatECSServiceCaseScreenURL;
