import {call, fork, select, take} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import config from '../../config';

const filterMapCPOperationAreasById = function* filterMapCPOperationAreasById(contractPartnerId) {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'vpm-contract-partners-operation-areas');
    const contractPartners = yield select(state => state.contractPartners.contractPartners);

    if (!arcGISMap) return;

    const vpmContractPartnersLocationsLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-operation-areas-locations');
    const vpmContractPartnersServiceAreasLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-operation-areas-service-areas');
    const vpmContractPartnerAffiliatePartnersLocationsLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-operation-areas-affiliate-partners-locations');

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST,
        vpmContractPartnersLocationsLayer.filterFeaturesByAttribute,
        {
            where: `contractPa IN ('${contractPartnerId}')`,
        },
    );

    yield take([
        contractPartnerActionTypes.FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.FILTER_CONTRACT_PARTNERS_LOCATIONS_REQUEST_FAILED,
    ]);

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FILTER_CP_SERVICE_AREAS_REQUEST,
        vpmContractPartnersServiceAreasLayer.filterFeaturesByAttribute,
        {
            where: `contractPa IN ('${contractPartnerId}'${contractPartners[contractPartnerId].affiliatePartners.length
                ? `, ${contractPartners[contractPartnerId].affiliatePartners.map(affiliatePartner => (
                    `'${affiliatePartner.id}'`)).join(', ')}`
                : ''})`,
        },
    );

    yield take([
        contractPartnerActionTypes.FILTER_CP_SERVICE_AREAS_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.FILTER_CP_SERVICE_AREAS_REQUEST_FAILED,
    ]);

    if (contractPartners[contractPartnerId].affiliatePartners.length) {
        yield fork(
            fetchRequest,
            contractPartnerActionTypes.FILTER_CP_AFFILIATE_PARTNERS_LOCATIONS_REQUEST,
            vpmContractPartnerAffiliatePartnersLocationsLayer.filterFeaturesByAttribute,
            {
                where: `contractPa IN (${contractPartners[contractPartnerId].affiliatePartners.map(affiliatePartner => (
                    `'${affiliatePartner.id}'`
                )).join(', ')})`,
            },
        );

        yield take([
            contractPartnerActionTypes.FILTER_CP_AFFILIATE_PARTNERS_LOCATIONS_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.FILTER_CP_AFFILIATE_PARTNERS_LOCATIONS_REQUEST_FAILED,
        ]);
    }

    const extent = yield call(vpmContractPartnersLocationsLayer.getFeatureLayerExtent, {
        where: `contractPa IN ('${contractPartnerId}'
        ${contractPartners[contractPartnerId].affiliatePartners.length
        ? `, ${contractPartners[contractPartnerId].affiliatePartners.map(affiliatePartner => (
            `'${affiliatePartner.id}'`
        )).join(', ')}`
        : ''})`,
    });

    yield call(arcGISMap.setMapExtent, extent);
    if (arcGISMap && !contractPartners[contractPartnerId]?.affiliatePartners?.length) {
        arcGISMap.setMapViewZoomLevel(config.DEFAULT_ARCGIS_MAP_WIDGET_ZOOM_LEVEL);
    }

    vpmContractPartnersLocationsLayer.show();
    vpmContractPartnerAffiliatePartnersLocationsLayer.show();
    vpmContractPartnersServiceAreasLayer.show();
};

export default filterMapCPOperationAreasById;
