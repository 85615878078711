import {kml} from '@tmcw/togeojson';

/*
 * Parses the KML polygon data into the ArcGIS-readable format
 */
const parseKMLPolygonData = kmlText => {
    const kmlData = (new DOMParser()).parseFromString(kmlText, 'text/xml');
    if (!kmlData) return null;
    const jsonData = kml(kmlData, {styles: true});
    if (!jsonData) return null;
    const coordinates = jsonData.features[0]?.geometry?.coordinates[0];
    if (!coordinates) return null;
    return {
        type: 'polygon',
        rings: coordinates.reduce((acc, curr) => {
            return [
                ...acc,
                [
                    curr[0],
                    curr[1],
                ],
            ];
        }, []),
    };
};

export default parseKMLPolygonData;
