import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {DataRow, Panel, useStyles} from '@ace-de/ui-components';
import * as qualityManagementSelectors from '../qualityManagementFeedbackSelectors';

const QMFeedbackVehicleDataPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate, activeLocale} = useTranslate();
    const translatePanel = createTranslateShorthand('qm_feedback_vehicle_data_panel');
    const {qualityManagementFeedback} = props;

    // if no qmFeedback, don't render
    if (!qualityManagementFeedback) return null;

    return (
        <Panel
            title={translatePanel('panel_title.vehicle_data')}
            className={cx(['global!ace-u-full-height', 'global!ace-u-margin--0'])}
        >
            <DataRow
                label={translatePanel('data_row_label.vehicle_type')}
                qaIdent="qm-vehicle-type"
            >
                {qualityManagementFeedback.vehicle?.type
                    ? translate(`global.service_assignment_vehicle_type.${qualityManagementFeedback.vehicle.type.toLowerCase()}`)
                    : '-'}
            </DataRow>
            <DataRow
                label={translatePanel('data_row_label.model_and_manufacturer')}
                qaIdent="qm-vehicle-model-and-manufacturer"
            >
                {[
                    qualityManagementFeedback.vehicle?.model,
                    qualityManagementFeedback.vehicle?.manufacturer,
                ].filter(value => !!value).join(' ') || '-'}
            </DataRow>
            <DataRow
                label={translatePanel('data_row_label.tonnage')}
                qaIdent="qm-vehicle-tonnage"
            >
                {qualityManagementFeedback.vehicle?.weight?.toLocaleString(activeLocale) || '-'}
                {!!qualityManagementFeedback.vehicle?.weight && translatePanel('data_row_label.tonnage_unit')}
            </DataRow>
            <DataRow
                label={translatePanel('data_row_label.measurements')}
                qaIdent="qm-vehicle-measurements"
            >
                {[
                    qualityManagementFeedback.vehicle?.length?.toLocaleString(activeLocale),
                    qualityManagementFeedback.vehicle?.width?.toLocaleString(activeLocale),
                    qualityManagementFeedback.vehicle?.height?.toLocaleString(activeLocale),
                ].filter(value => !!value).join(' x ') || '-'}
            </DataRow>
            <DataRow
                label={translatePanel('data_row_label.trailer')}
                qaIdent="qm-vehicle-trailer"
            >
                {translatePanel(`trailer_option.${qualityManagementFeedback.hasTrailer ? 'yes' : 'no'}`)}
            </DataRow>
        </Panel>
    );
};

QMFeedbackVehicleDataPanel.propTypes = {
    qualityManagementFeedback: PropTypes.object,
};

QMFeedbackVehicleDataPanel.defaultProps = {
    qualityManagementFeedback: null,
};

const mapStateToProps = (state, props) => {
    const qmFeedbackSelector = qualityManagementSelectors.createQMFeedbackSelector();
    return {
        qualityManagementFeedback: qmFeedbackSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps)(QMFeedbackVehicleDataPanel));
