import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useSearchQueryParams} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates} from '@ace-de/eua-entity-types';
import {useStyles} from '@ace-de/ui-components';
import {AppLayoutMain, HeaderBar, NavigationBar} from '@ace-de/ui-components/app-layout';
import {TabSet, Tab, TabBar, TabPanel} from '@ace-de/ui-components/navigation';
import * as contractPartnerSelectors from './contractPartnerSelectors';
import contractPartnerScreenTabs from './contractPartnerScreenTabs';
import ContractPartnerHeader from './ui-elements/ContractPartnerHeader';
import ContractPartnerBasicDataTab from './ContractPartnerBasicDataTab';
import ContractPartnerServicesTab from './ContractPartnerServicesTab';
import ContractPartnerCommissioningTab from './ContractPartnerCommissioningTab';
import ContractPartnerContactTab from './ContractPartnerContactTab';
import ContractPartnerTemporaryRestrictionsTab from './ContractPartnerTemporaryRestrictionsTab';
import ContractPartnerCaseHistoryTab from './ContractPartnerCaseHistoryTab';
import ContractPartnerQualityManagementTab from './ContractPartnerQualityManagementTab';
import ContractPartnerOperationAreaTab from './ContractPartnerOperationAreaTab';
import ContractPartnerELOTab from './ContractPartnerELOTab';
import config from '../config';

const ContractPartnerScreen = props => {
    const {cx} = useStyles();
    const {contractPartner} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('contract_partner_screen');
    const {activeTab} = useSearchQueryParams();

    // if no contract partner don't render
    if (!contractPartner) return null;

    return (
        <Fragment>
            <HeaderBar>
                <ContractPartnerHeader
                    contractPartner={contractPartner}
                />
            </HeaderBar>
            <TabSet
                name="contract-partner-screen-tab-set"
                isRoutingEnabled={true}
                qaIdent="contract-partner-screen"
            >
                <NavigationBar>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--direction-row',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <TabBar
                            name="contract-partner-details-tabs"
                            tabSet="contract-partner-screen-tab-set"
                            defaultValue={activeTab || contractPartnerScreenTabs.BASIC_DATA}
                            isDisabled={contractPartner.persistenceState === persistenceStates.PENDING}
                            className={cx('global!ace-u-flex--grow-1')}
                        >
                            <Tab
                                name={contractPartnerScreenTabs.BASIC_DATA}
                                value={contractPartnerScreenTabs.BASIC_DATA}
                            >
                                {translateScreen('tab_label.basic_data')}

                            </Tab>
                            <Tab
                                name={contractPartnerScreenTabs.SERVICES}
                                value={contractPartnerScreenTabs.SERVICES}
                            >
                                {translateScreen('tab_label.services')}

                            </Tab>
                            <Tab
                                name={contractPartnerScreenTabs.CONTACT}
                                value={contractPartnerScreenTabs.CONTACT}
                            >
                                {translateScreen('tab_label.contact')}

                            </Tab>
                            <Tab
                                name={contractPartnerScreenTabs.COMMISSIONING}
                                value={contractPartnerScreenTabs.COMMISSIONING}
                            >
                                {translateScreen('tab_label.commissioning')}

                            </Tab>
                            <Tab
                                name={contractPartnerScreenTabs.TEMPORARY_RESTRICTIONS}
                                value={contractPartnerScreenTabs.TEMPORARY_RESTRICTIONS}
                            >
                                {translateScreen('tab_label.temporary_restrictions')}

                            </Tab>
                            <Tab
                                name={contractPartnerScreenTabs.CASE_HISTORY}
                                value={contractPartnerScreenTabs.CASE_HISTORY}
                            >
                                {translateScreen('tab_label.case_history')}

                            </Tab>
                            <Tab
                                name={contractPartnerScreenTabs.OPERATION_AREA}
                                value={contractPartnerScreenTabs.OPERATION_AREA}
                            >
                                {translateScreen('tab_label.operation_area')}

                            </Tab>
                            <Tab
                                name={contractPartnerScreenTabs.QUALITY_MANAGEMENT}
                                value={contractPartnerScreenTabs.QUALITY_MANAGEMENT}
                            >
                                {translateScreen('tab_label.quality_management')}

                            </Tab>
                            {!config.IS_PRE_BC_VERSION && (
                                <Tab
                                    name={contractPartnerScreenTabs.ELO}
                                    value={contractPartnerScreenTabs.ELO}
                                >
                                    {translateScreen('tab_label.elo')}

                                </Tab>
                            )}
                        </TabBar>
                    </div>
                </NavigationBar>
                <AppLayoutMain>
                    <TabPanel for={contractPartnerScreenTabs.BASIC_DATA}>
                        <ContractPartnerBasicDataTab />
                    </TabPanel>
                    <TabPanel for={contractPartnerScreenTabs.SERVICES}>
                        <ContractPartnerServicesTab />
                    </TabPanel>
                    <TabPanel for={contractPartnerScreenTabs.COMMISSIONING}>
                        <ContractPartnerCommissioningTab />
                    </TabPanel>
                    <TabPanel for={contractPartnerScreenTabs.CONTACT}>
                        <ContractPartnerContactTab />
                    </TabPanel>
                    <TabPanel for={contractPartnerScreenTabs.TEMPORARY_RESTRICTIONS}>
                        <ContractPartnerTemporaryRestrictionsTab />
                    </TabPanel>
                    <TabPanel for={contractPartnerScreenTabs.CASE_HISTORY}>
                        <ContractPartnerCaseHistoryTab />
                    </TabPanel>
                    <TabPanel for={contractPartnerScreenTabs.OPERATION_AREA}>
                        <ContractPartnerOperationAreaTab />
                    </TabPanel>
                    <TabPanel for={contractPartnerScreenTabs.QUALITY_MANAGEMENT}>
                        <ContractPartnerQualityManagementTab />
                    </TabPanel>
                    {!config.IS_PRE_BC_VERSION && (
                        <TabPanel for={contractPartnerScreenTabs.ELO}>
                            <ContractPartnerELOTab />
                        </TabPanel>
                    )}
                </AppLayoutMain>
            </TabSet>
        </Fragment>
    );
};

ContractPartnerScreen.propTypes = {
    contractPartner: PropTypes.object,
};

ContractPartnerScreen.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();
    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

export default connect(mapStateToProps, null)(ContractPartnerScreen);
