import {select} from 'redux-saga/effects';
import {apmContractPartnerRegionTypes, apmContractPartnerContractStatusTypes} from '@ace-de/eua-entity-types';
import filterMapContractPartnersByDistance from './filterMapContractPartnersByDistance';
import filterMapContractPartnersByLocation from './filterMapContractPartnersByLocation';
import filterMapContractPartnersBySearchQuery from './filterMapContractPartnersBySearchQuery';
import searchContractPartnerByAttribute from './searchContractPartnerByAttribute';
import searchRandomLocationGeolocation from './searchRandomLocationGeolocation';

const mapRegionToRegionId = (region, accountManagers = []) => {
    if (region && Object.values(apmContractPartnerRegionTypes).includes(region)) {
        const accountManager = accountManagers
            .find(manager => manager.region === region);
        if (accountManager?.regionId) {
            return accountManager.regionId;
        }
    }
    return null;
};

const loadContractPartnerRecommendations = function* loadContractPartnersRecommendations({payload}) {
    const {location} = payload;
    const {accountManagers} = yield select(state => state.contractPartners);
    const searchQueryParams = new URLSearchParams(location.search);
    const searchType = searchQueryParams.get('searchType');
    const searchTerm = searchQueryParams.get('searchTerm');
    const locationId = searchQueryParams.get('locationId');
    const contractStatuses = searchQueryParams.getAll('contractStatus')
        .filter(status => Object.values(apmContractPartnerContractStatusTypes).includes(status));
    const regions = searchQueryParams.getAll('region')
        .filter(region => Object.values(apmContractPartnerRegionTypes).includes(region));
    const regionIds = regions
        .map(region => mapRegionToRegionId(region, Object.values(accountManagers)))
        .filter(region => !!region);
    const centerCoordinates = searchQueryParams.has('lng') && searchQueryParams.has('lat')
        ? [searchQueryParams.get('lng'), searchQueryParams.get('lat')] : null;
    let isDefaultSearch = false;

    if (searchTerm && searchType) {
        if (['address', 'name'].includes(searchType)) {
            yield* searchContractPartnerByAttribute({
                payload: {
                    fieldToFilterBy: searchType,
                    searchQueryString: searchTerm,
                },
            });

            yield* filterMapContractPartnersBySearchQuery({
                fieldToFilterBy: searchType,
                searchQueryString: searchTerm,
                regionIds,
                contractStatuses,
            });
            return;
        }
        if (locationId) {
            yield* searchRandomLocationGeolocation({
                payload: {
                    searchQueryString: searchTerm,
                },
            });

            yield* filterMapContractPartnersByDistance({
                regionIds,
                contractStatuses,
                locationId,
            });
        }
        return;
    }
    // when an agent clicks on the logo, apply default search
    if (!contractStatuses?.length && !regionIds?.length && !centerCoordinates) {
        contractStatuses.push(apmContractPartnerContractStatusTypes.ACTIVE);
        isDefaultSearch = true;
    }
    yield* filterMapContractPartnersByLocation({
        regionIds,
        contractStatuses,
        centerCoordinates,
        isDefaultSearch,
    });
};

export default loadContractPartnerRecommendations;
