import {take, call, select} from 'redux-saga/effects';
import * as contractPartnerActionTypes from '../../contract-partners/contractPartnerActionTypes';

/**
 * Change contract partner service areas layer visibility state flow
 */
const changeCPServiceAreasLayerVisibilityState = function* changeCPServiceAreasLayerVisibilityState() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    while (true) {
        const {payload} = yield take([
            contractPartnerActionTypes.INITIATE_CHANGE_CP_SERVICE_AREAS_LAYER_VISIBILITY_STATE,
        ]);
        const {isVisible} = payload;

        const arcGISMap = yield call(arcGISMapService.getMap, 'vpm-contract-partners');

        if (!arcGISMap) return;

        const vpmContractPartnerServiceAreasLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-service-areas');

        if (!vpmContractPartnerServiceAreasLayer) return;

        if (isVisible) {
            vpmContractPartnerServiceAreasLayer.show();
            continue;
        }

        vpmContractPartnerServiceAreasLayer.hide();
    }
};

export default changeCPServiceAreasLayerVisibilityState;
