import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {DataRow, Panel, useStyles, LoaderSpinner, HighlightCircle} from '@ace-de/ui-components';
import {Icon, editIcon, InteractiveIcon, infoAlertIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import {europeanCountries, alfClientTypes} from '@ace-de/eua-entity-types';
import bcModalContentTypes from '../modals/bcModalContentTypes';
import bcModalTypes from '../modals/bcModalTypes';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

const ContractPartnerBCContactData = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_basic_data_tab');
    const {contractPartnerBCDetails, areContractPartnerBCDetailsLoading, isContractPartnerBCDetailsError} = props;
    const {initiateUpdateContractPartnerBCContactDataFlow} = props;

    const updateBCContactData = () => {
        // do not update if there are no BC contact details
        if (!contractPartnerBCDetails?.id) return;
        initiateUpdateContractPartnerBCContactDataFlow({
            client: alfClientTypes.ACE,
            contactId: contractPartnerBCDetails.id,
            contentType: bcModalContentTypes.CONTRACT_PARTNER,
            // if there is a creditor, update creditor data. otherwise, update the contact data
            type: contractPartnerBCDetails?.creditorId
                ? bcModalTypes.UPDATE_CREDITOR : bcModalTypes.UPDATE_CONTRACT_PARTNER,
        });
    };

    return (
        <Panel
            title={translateTab('panel_title.bc_contact_data')}
            actions={!isContractPartnerBCDetailsError && (
                <InteractiveIcon
                    icon={editIcon}
                    onClick={updateBCContactData}
                />
            )}
            qaIdent="cp-bc-data"
        >
            {areContractPartnerBCDetailsLoading && (
                <div
                    className={cx([
                        'global!ace-u-padding--128-0',
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--justify-center',
                    ])}
                >
                    <HighlightCircle
                        className={cx([
                            'ace-c-highlight-circle--medium',
                            'global!ace-u-margin--bottom-32',
                            'ace-c-highlight-circle--primary',
                        ])}
                    >
                        <LoaderSpinner />
                    </HighlightCircle>
                </div>
            )}
            {!areContractPartnerBCDetailsLoading && isContractPartnerBCDetailsError && (
                <div
                    className={cx([
                        'global!ace-u-width--full',
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-center',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <HighlightCircle
                        className={cx([
                            'ace-c-highlight-circle--medium',
                            'ace-c-highlight-circle--primary-highlight',
                            'global!ace-u-margin--right-24',
                        ])}
                    >
                        <Icon
                            icon={infoAlertIcon}
                            className={cx([
                                'ace-c-icon--xl',
                                'global!ace-c-icon--color-highlight',
                            ])}
                        />
                    </HighlightCircle>
                    <h2 className={cx('global!ace-u-typography--variant-h3')}>
                        {translateTab('contract_partner_bc_data_error.bc_error')}
                    </h2>
                </div>
            )}
            {!areContractPartnerBCDetailsLoading && !isContractPartnerBCDetailsError && (
                <div className={cx('global!ace-u-two-column-layout')}>
                    <div>
                        <DataRow
                            label={translateTab('data_row_label.name')}
                            className={cx('global!ace-u-flex--basis-30')}
                            contentClassName={cx('global!ace-u-flex--align-self-flex-start')}
                            qaIdent="cp-name"
                        >
                            {contractPartnerBCDetails?.name || '-'}
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.street')}
                            qaIdent="cp-address-street"
                        >
                            {contractPartnerBCDetails?.address?.street || '-'}
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.post_code')}
                            qaIdent="cp-address-post-code"
                        >
                            {contractPartnerBCDetails?.address?.postCode || '-'}
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.city')}
                            qaIdent="cp-address-city"
                        >
                            {contractPartnerBCDetails?.address?.city || '-'}
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.country')}
                            qaIdent="cp-address-country"
                        >
                            {contractPartnerBCDetails?.address?.country
                            /* eslint-disable-next-line max-len */
                            && Object.values(europeanCountries).find(country => country.name === contractPartnerBCDetails?.address.country)
                                ? translate(`global.country.${snakeCase(contractPartnerBCDetails.address.country)}`)
                                : '-'}
                        </DataRow>
                    </div>
                    <div>
                        <DataRow
                            label={translateTab('data_row_label.iban')}
                            className={cx('global!ace-u-flex--basis-30')}
                            contentClassName={cx('global!ace-u-flex--align-self-flex-start')}
                            qaIdent="cp-iban"
                        >
                            {contractPartnerBCDetails?.bankAccount?.iban || '-'}
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.swift')}
                            qaIdent="cp-swift"
                        >
                            {contractPartnerBCDetails?.bankAccount?.swiftCode || '-'}
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.bank_name')}
                            qaIdent="cp-bank"
                        >
                            {contractPartnerBCDetails?.bankAccount?.name || '-'}
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.payment_terms_code')}
                            qaIdent="cp-payment-terms-code"
                        >
                            {contractPartnerBCDetails?.bankAccount?.paymentTermsCode
                                ? translate(`global.payment_terms_code.${snakeCase(contractPartnerBCDetails.bankAccount.paymentTermsCode)}`)
                                : '-'}
                        </DataRow>
                    </div>
                </div>
            )}
        </Panel>
    );
};

ContractPartnerBCContactData.propTypes = {
    contractPartnerBCDetails: PropTypes.object,
    areContractPartnerBCDetailsLoading: PropTypes.bool,
    isContractPartnerBCDetailsError: PropTypes.bool,
    initiateUpdateContractPartnerBCContactDataFlow: PropTypes.func.isRequired,
};

ContractPartnerBCContactData.defaultProps = {
    contractPartnerBCDetails: null,
    areContractPartnerBCDetailsLoading: false,
    isContractPartnerBCDetailsError: false,
};

const mapStateToProps = state => ({
    contractPartnerBCDetails: state.contractPartners.contractPartnerBCDetails,
    areContractPartnerBCDetailsLoading: state.contractPartners.areContractPartnerBCDetailsLoading,
    isContractPartnerBCDetailsError: state.contractPartners.isContractPartnerBCDetailsError,
});

const mapDispatchToProps = dispatch => ({
    initiateUpdateContractPartnerBCContactDataFlow: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_UPDATE_CONTRACT_PARTNER_BC_CONTACT_DATA,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractPartnerBCContactData);
