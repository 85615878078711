import {take, select, put, fork} from 'redux-saga/effects';
import {ACEPartner, apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import {persistenceStates} from '@computerrock/formation-core';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import modalIds from '../../modalIds';
import errorTypes from '../../application/errorTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';

const contractPartnerAssignmentChannelsUpdateFlow = function* contractPartnerAssignmentChannelsUpdateFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    let shouldWaitForAction = true;
    let chosenModalOption = null;

    while (true) {
        if (shouldWaitForAction) {
            yield take(contractPartnerActionTypes.INITIATE_CP_ASSIGNMENT_CHANNELS_UPDATE_FLOW);

            yield* openModal(modalIds.CP_ASSIGNMENT_CHANNELS_UPDATE);
            chosenModalOption = yield take([
                contractPartnerActionTypes.CONFIRM_CP_ASSIGNMENT_CHANNELS_UPDATE,
                contractPartnerActionTypes.DECLINE_CP_ASSIGNMENT_CHANNELS_UPDATE,
            ]);
        }

        if (chosenModalOption
            && chosenModalOption.type === contractPartnerActionTypes.CONFIRM_CP_ASSIGNMENT_CHANNELS_UPDATE) {
            const {contractPartnerData, contractPartnerId} = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST,
                partnerManagementService.updateACEPartner,
                {
                    acePartnerData: {
                        ...ACEPartner.objectToPatchDTO(contractPartnerData),
                        partnerType: apmACEPartnerTypes.CONTRACT_PARTNER,
                    },
                    acePartnerId: contractPartnerId,
                },
            );

            const responseAction = yield take([
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ASSIGNMENT_CHANNELS_REQUEST_FAILED,
            ]);

            if (responseAction.error) {
                if (shouldWaitForAction) {
                    yield* closeModal(modalIds.CP_ASSIGNMENT_CHANNELS_UPDATE);
                    yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {
                        errorType: errorTypes.CP_ASSIGNMENT_CHANNELS_UPDATE_FAILED,
                    });
                }

                yield put({
                    type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.READY},
                });

                const nextAction = yield take([
                    contractPartnerActionTypes.RETRY_CP_ASSIGNMENT_CHANNELS_UPDATE,
                    contractPartnerActionTypes.CANCEL_RETRY_CP_ASSIGNMENT_CHANNELS_UPDATE,
                ]);

                shouldWaitForAction = nextAction.type !== contractPartnerActionTypes.RETRY_CP_ASSIGNMENT_CHANNELS_UPDATE; // eslint-disable-line max-len

                if (nextAction.type === contractPartnerActionTypes.RETRY_CP_ASSIGNMENT_CHANNELS_UPDATE) {
                    yield put({
                        type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.PENDING},
                    });
                }

                if (nextAction.type === contractPartnerActionTypes.CANCEL_RETRY_CP_ASSIGNMENT_CHANNELS_UPDATE) {
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                }

                continue;
            }

            if (!responseAction.error) {
                if (!shouldWaitForAction) {
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});

                    yield put({
                        type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.READY},
                    });
                }
                shouldWaitForAction = true;
                const {response} = responseAction.payload;
                const {acePartnerDTO} = response;

                yield put({
                    type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
                    payload: {contractPartnerDTOs: [acePartnerDTO]},
                });
            }
        }
        yield* closeModal(modalIds.CP_ASSIGNMENT_CHANNELS_UPDATE);
    }
};

export default contractPartnerAssignmentChannelsUpdateFlow;
