import {eventChannel} from 'redux-saga';
import {select, take, put, call, all} from 'redux-saga/effects';
import * as contractPartnerActionTypes from '../../contract-partners/contractPartnerActionTypes';

const onContractPartnerMapSelectContractPartner = function* onContractPartnerMapSelectContractPartner() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    const arcGISMap = yield call(arcGISMapService.getMap, 'vpm-contract-partners');
    const vpmContractPartnersLocationsLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-locations');
    const vpmContractPartnerServiceAreasLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-service-areas');

    const locationsLayerClickEventChannel = eventChannel(emitter => {
        const unsubscribeClickListener = vpmContractPartnersLocationsLayer.on('feature-select', payload => {
            emitter(payload);
        });

        // returns unsubscribe method to eventChannel
        return () => unsubscribeClickListener();
    });

    while (true) {
        const {featureDTO: contractPartnerDTO} = yield take(locationsLayerClickEventChannel);

        if (contractPartnerDTO && !contractPartnerDTO.isSelected) {
            const contractPartnerId = contractPartnerDTO['contractPartnerId'];

            const {contractPartnerRecommendations} = yield select(state => state.contractPartners);
            const selectedContractPartner = contractPartnerRecommendations
                .find(contractPartner => contractPartner['contractPartnerId'] === contractPartnerId);
            if (!selectedContractPartner) continue;

            if (vpmContractPartnersLocationsLayer && vpmContractPartnerServiceAreasLayer) {
                yield all([
                    call(vpmContractPartnersLocationsLayer.selectFeatureByAttribute, {
                        where: `contractPa = '${selectedContractPartner['contractPartnerId']}'`,
                    }),
                    call(vpmContractPartnerServiceAreasLayer.selectFeatureByAttribute, {
                        where: `contractPa = '${selectedContractPartner['contractPartnerId']}'`,
                    }),
                ]);
            }

            const currentMapCenter = arcGISMap.getMapCenter(true);
            const newMapCenter = [
                selectedContractPartner.location?.longitude,
                selectedContractPartner.location?.latitude,
            ];
            if (currentMapCenter && newMapCenter
                && (currentMapCenter[0] !== newMapCenter[0] || currentMapCenter[1] !== newMapCenter[1])) {
                arcGISMap.setMapCenter(newMapCenter);
            }

            yield put({
                type: contractPartnerActionTypes.SET_SELECTED_CONTRACT_PARTNER,
                payload: {contractPartner: selectedContractPartner},
            });
        }
    }
};

export default onContractPartnerMapSelectContractPartner;
