import modalIds from './modalIds';
import ContractPartnerStatusUpdateModal from './contract-partners/modals/ContractPartnerStatusUpdateModal';
import EditTemporaryRestrictionModal from './contract-partners/modals/EditTemporaryRestrictionModal';
import CreateTemporaryRestrictionSuccessModal from './contract-partners/modals/CreateTemporaryRestrictionSuccessModal';
import EditAssignmentChannelsModal from './contract-partners/modals/EditAssignmentChannelsModal';
import DeleteCPOperatingUnitConfirmationModal from './contract-partners/modals/DeleteCPOperatingUnitConfirmationModal';
import CPOperatingUnitDataModal from './contract-partners/modals/CPOperatingUnitDataModal';
import CreateTemporaryRestrictionModal from './contract-partners/modals/CreateTemporaryRestrictionModal';
import CreateQualityReportModal from './contract-partners/modals/CreateQualityReportModal';
import ContractPartnerContactDataModal from './contract-partners/modals/ContractPartnerContactDataModal';
import DeleteCPContactDataConfirmationModal from './contract-partners/modals/DeleteCPContactDataConfirmationModal';
import BCContactModal from './contract-partners/modals/BCContactModal';
import CreateNewTimePeriodModal from './price-management/modals/CreateNewTimePeriodModal';
import FixedPriceModal from './contract-partners/modals/FixedPriceModal';
import HolidayPeriodsUpdateModal from './price-management/modals/HolidayPeriodsUpdateModal';
import CPCreateELLAAccountModal from './contract-partners/modals/CPCreateELLAAccountModal';
import ForwardQMFeedbackModal from './quality-management-feedbacks/modals/ForwardQMFeedbackModal';
import QualityManagementFeedbackErrorModal from './quality-management-feedbacks/modals/QualityManagementFeedbackErrorModal';
import CPEditELLAAccountModal from './contract-partners/modals/CPEditELLAAccountModal';
import EditPricesModal from './price-management/modals/EditPricesModal';
import QMFeedbackCPRankingModal from './quality-management-feedbacks/modals/QMFeedbackCPRankingModal';
import ErrorMessageModal from './application/modals/ErrorMessageModal';

/**
* Modal configuration
*/
export default [
    {
        id: modalIds.CONTRACT_PARTNER_STATUS_UPDATE,
        component: ContractPartnerStatusUpdateModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_CREATE_TEMPORARY_RESTRICTION,
        component: CreateTemporaryRestrictionModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_EDIT_TEMPORARY_RESTRICTION,
        component: EditTemporaryRestrictionModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_TEMPORARY_RESTRICTION_SUCCESSFUL,
        component: CreateTemporaryRestrictionSuccessModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_ASSIGNMENT_CHANNELS_UPDATE,
        component: EditAssignmentChannelsModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_CREATE_QUALITY_REPORT,
        component: CreateQualityReportModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.DELETE_CP_OPERATING_UNIT_CONFIRMATION,
        component: DeleteCPOperatingUnitConfirmationModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_OPERATING_UNIT_DATA,
        component: CPOperatingUnitDataModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CONTRACT_PARTNER_CONTACT_DATA,
        component: ContractPartnerContactDataModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.DELETE_CP_CONTACT_DATA_CONFIRMATION,
        component: DeleteCPContactDataConfirmationModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.BC_CONTACT_MODAL,
        component: BCContactModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CREATE_TIME_PERIOD_MODAL,
        component: CreateNewTimePeriodModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_FIXED_PRICE_MODAL,
        component: FixedPriceModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.UPDATE_HOLIDAY_PERIODS_MODAL,
        component: HolidayPeriodsUpdateModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_CREATE_ELLA_ACCOUNT,
        component: CPCreateELLAAccountModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.FORWARD_QM_FEEDBACK,
        component: ForwardQMFeedbackModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.QM_FEEDBACK_ERROR,
        component: QualityManagementFeedbackErrorModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CP_EDIT_ELLA_ACCOUNT,
        component: CPEditELLAAccountModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.EDIT_PRICES,
        component: EditPricesModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.QM_FEEDBACK_CP_RANKING,
        component: QMFeedbackCPRankingModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.ERROR_MESSAGE_MODAL,
        component: ErrorMessageModal,
        hasBackdrop: true,
    },
];
