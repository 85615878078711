import {fork, select, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as qualityManagementFeedbackActionTypes from '../qualityManagementFeedbackActionTypes';
import modalIds from '../../modalIds';
import qualityManagementFeedbackErrorModalTypes from '../modals/qualityManagementFeedbackErrorModalTypes';
import fetchRequest from '../../application/sagas/fetchRequest';

const qualifyQualityManagementFeedback = function* qualifyQualityManagementFeedback() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(qualityManagementFeedbackActionTypes.QUALIFY_QUALITY_MANAGEMENT_FEEDBACK);
        const {classificationType, qualityManagementFeedbackId, history} = payload;

        yield fork(
            fetchRequest,
            qualityManagementFeedbackActionTypes.QUALIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST,
            partnerManagementService.createACEPartnerQualityManagementQualification, {
                classification: classificationType,
                feedbackId: qualityManagementFeedbackId,
            },
        );

        const responseAction = yield take([
            qualityManagementFeedbackActionTypes.QUALIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST_SUCCEEDED,
            qualityManagementFeedbackActionTypes.QUALIFY_QUALITY_MANAGEMENT_FEEDBACK_REQUEST_FAILED,
        ]);

        if (responseAction.error) {
            yield* openModal(modalIds.QM_FEEDBACK_ERROR, {
                type: qualityManagementFeedbackErrorModalTypes.QM_QUALIFICATION_FAILED,
            });

            yield take(qualityManagementFeedbackActionTypes.CLOSE_QUALITY_MANAGEMENT_FEEDBACK_ERROR_MODAL);

            yield* closeModal(modalIds.QM_FEEDBACK_ERROR, {
                type: qualityManagementFeedbackErrorModalTypes.QM_QUALIFICATION_FAILED,
            });
            continue;
        }

        yield history.goBack();
    }
};

export default qualifyQualityManagementFeedback;
