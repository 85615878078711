import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {snakeCase} from 'change-case';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Modal, ButtonPrimary, LoaderSpinner, HighlightCircle} from '@ace-de/ui-components';
import {InteractiveIcon, closeIcon, Icon, warningIcon} from '@ace-de/ui-components/icons';
import {Table, TableCell, TableHead, TableBody, TableRow} from '@ace-de/ui-components/data-elements';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import * as priceManagementSelectors from '../priceManagementSelectors';
import germanStateTypes from '../../contract-partners/germanStateTypes';
import germanStateCodes from '../../contract-partners/germanStateCodes';
import useCloseOnEscape from '../../utils/useCloseOnEscape';

const HolidayPeriodsUpdateModal = props => {
    const {cx} = useStyles();
    const {translate} = useTranslate();
    const {hasBackdrop, closeModal, confirmHolidayPeriodsUpdate, isLoading, hasError, holidays} = props;

    useCloseOnEscape(() => {
        if (!isLoading) closeModal();
    });

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            action={(
                <InteractiveIcon icon={closeIcon} onClick={closeModal} isDisabled={isLoading} />
            )}
            title={translate('holiday_periods_update_modal.modal_title.holidays_for_two_years')}
            contentClassName={cx('ace-c-modal__content--full-bleed', 'ace-c-modal__content--scrollable')}
            className={cx('global!ace-u-modal-content-size--l')}
        >
            {!hasError && !isLoading && (
                <Table className={cx('global!ace-u-margin--top-32')}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}>
                                {translate('holiday_periods_update_modal.table_header.regions')}
                            </TableCell>
                            <TableCell colSpan={10}>
                                {translate('holiday_periods_update_modal.table_header.dates')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={2} className={cx('global!ace-u-typography--variant-body-bold')}>
                                {translate(`global.german_state.state_wide`)}
                            </TableCell>
                            <TableCell colSpan={10} className={cx('ace-c-table__cell--white-space-normal')}>
                                {holidays?.['ALL']?.holidayDates && holidays?.['ALL']?.holidayDates.length > 0
                                    ? holidays?.['ALL'].holidayDates.map(holidayDate => moment(holidayDate).format('DD.MM.YYYY')).join(', ')
                                    : '-'}
                            </TableCell>
                        </TableRow>
                        {Object.keys(germanStateTypes).map(germanStateType => {
                            const germanStateCode = germanStateCodes[germanStateType];
                            const stateHolidays = holidays?.[germanStateCode]?.holidayDates || null;
                            return (
                                <TableRow key={germanStateType}>
                                    <TableCell colSpan={2} rowSpan={1}>
                                        {translate(`global.german_state.${snakeCase(germanStateType)}`)}
                                    </TableCell>
                                    <TableCell colSpan={10} rowSpan={1}>
                                        {stateHolidays && stateHolidays.length > 0
                                            ? stateHolidays.map(stateHoliday => moment(stateHoliday).format('DD.MM.YYYY')).join(', ')
                                            : '-'}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            )}
            {(isLoading || hasError) && (
                <div className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-margin--32',
                    'global!ace-u-full-width',
                ])}
                >
                    {hasError && (
                        <HighlightCircle
                            className={cx([
                                'ace-c-highlight-circle--warning',
                                'ace-c-highlight-circle--medium',
                            ])}
                        >
                            <Icon
                                icon={warningIcon}
                                className={cx([
                                    'ace-c-icon--color-warning',
                                    'ace-c-icon--xl',
                                ])}
                            />
                        </HighlightCircle>
                    )}
                    {isLoading && (
                        <LoaderSpinner />
                    )}
                    <p
                        className={cx([
                            'global!ace-u-typography--variant-h3',
                            'global!ace-u-margin--top-32',
                        ])}
                    >
                        {translate(`holiday_periods_update_modal.content_text.${isLoading ? 'loading' : 'error_occurred'}`)}
                    </p>
                </div>
            )}
            <div className={cx([
                'global!ace-u-flex',
                'global!ace-u-flex--justify-flex-end',
                'global!ace-u-flex--align-center',
                'global!ace-u-margin--32',
                'global!ace-u-full-width',
            ])}
            >
                <ButtonPrimary isDisabled={isLoading} onClick={confirmHolidayPeriodsUpdate}>
                    {translate('holiday_periods_update_modal.button_label.update_holidays')}
                </ButtonPrimary>
            </div>
        </Modal>
    );
};

HolidayPeriodsUpdateModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    confirmHolidayPeriodsUpdate: PropTypes.func.isRequired,
    hasBackdrop: PropTypes.bool,
    isLoading: PropTypes.bool,
    hasError: PropTypes.bool,
    holidays: PropTypes.object,
};

HolidayPeriodsUpdateModal.defaultProps = {
    hasBackdrop: true,
    isLoading: true,
    hasError: false,
    holidays: null,
};

const mapStateToProps = state => {
    // ToDo fetch loader and error states + fetch holiday data
    return {
        holidays: priceManagementSelectors.getHolidayDates(state),
        isLoading: priceManagementSelectors.getHolidayLoadingFlag(state),
        hasError: priceManagementSelectors.getHolidayUpdateError(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        closeModal: () => dispatch({
            type: priceManagementActionTypes.DECLINE_UPDATE_HOLIDAY_PERIODS_FLOW,
            payload: null,
        }),
        confirmHolidayPeriodsUpdate: () => dispatch({
            type: priceManagementActionTypes.CONFIRM_UPDATE_HOLIDAY_PERIODS_FLOW,
            payload: null,
        }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HolidayPeriodsUpdateModal);
