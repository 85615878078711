import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {camelCase} from 'change-case';
import {ButtonPrimary, useStyles, InputCurrency, warningIcon, Icon} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {apsSurchargeRateTypes, apsPriceTypes} from '@ace-de/eua-entity-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {useSearchQueryParams} from '@computerrock/formation-router';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import config from '../../config';

const EditSurchargesData = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateModal = createTranslateShorthand('edit_surcharges_modal');
    const {confirmUpdateSurcharges, surchargeRatePrices} = props;
    const {pricePeriodId} = useSearchQueryParams();

    const [surcharges, setSurcharges] = useState({
        [apsSurchargeRateTypes.ADDITIONAL_STAFF]:
            surchargeRatePrices?.find(surcharge => (
                surcharge.surchargeType === apsSurchargeRateTypes.ADDITIONAL_STAFF
            ))?.value || '',
        [apsSurchargeRateTypes.VEHICLE_WEIGHT_VALUE_3]:
            surchargeRatePrices?.find(surcharge => (
                surcharge.surchargeType === apsSurchargeRateTypes.VEHICLE_WEIGHT_VALUE_3
            ))?.value || '',
        [apsSurchargeRateTypes.VEHICLE_WEIGHT_VALUE_5]:
            surchargeRatePrices?.find(surcharge => (
                surcharge.surchargeType === apsSurchargeRateTypes.VEHICLE_WEIGHT_VALUE_5
            ))?.value || '',
    });
    const [errorMessage, setErrorMessage] = useState('');

    const validateFields = useCallback(() => {
        if (!surcharges[apsSurchargeRateTypes.ADDITIONAL_STAFF]
            || !surcharges[apsSurchargeRateTypes.VEHICLE_WEIGHT_VALUE_3]
            || !surcharges[apsSurchargeRateTypes.VEHICLE_WEIGHT_VALUE_5]) {
            setErrorMessage(translateModal('error_message.all_fields_must_be_populated'));
            return;
        }

        if (Object.values(surcharges).some(field => field === `${config.NOT_ALLOWED_PRICE_VALUE}`)) {
            setErrorMessage(translate('global.error_message.zero_values_not_allowed'));
            return;
        }

        setErrorMessage('');
    }, [setErrorMessage, surcharges, translate, translateModal]);

    useEffect(() => {
        validateFields();
    }, [validateFields]);

    const handleOnClick = () => {
        confirmUpdateSurcharges({
            pricePeriodId: parseInt(pricePeriodId, 10),
            prices: Object.entries(surcharges).map(([key, value]) => {
                return {
                    value: parseInt(value, 10),
                    type: apsPriceTypes.SURCHARGE_RATE,
                    surchargeType: key,
                };
            }),
        });
    };

    const handleOnChange = (surchargeType, value) => {
        setSurcharges({
            ...surcharges,
            [surchargeType]: value,
        });
    };

    return (
        <div className={cx('global!ace-u-margin--32-0')}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="edit-surcharges-service">
                            {translateModal('table_header.service')}
                        </TableCell>
                        <TableCell qaIdentPart="edit-surcharges-surcharge">
                            {translateModal('table_header.surcharge')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {surchargeRatePrices?.length > 0 && surchargeRatePrices.map(surcharge => {
                        const {surchargeType} = surcharge;

                        return (
                            <TableRow key={surchargeType}>
                                <TableCell>
                                    {translateModal(`table_cell.${surchargeType.toLowerCase()}`)}
                                </TableCell>
                                <TableCell>
                                    <InputCurrency
                                        name={camelCase(surchargeType)}
                                        currencySign={config.PERCENTAGE}
                                        className={cx('ace-c-input-currency--small')}
                                        value={surcharges[surchargeType] || '-'}
                                        onChange={value => handleOnChange(surchargeType, value)}
                                        areOnlyIntegersAllowed={true}
                                        min={0}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            {!!errorMessage && (
                <p
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-margin--top-16',
                        'global!ace-u-typography--color-warning',
                        'global!ace-u-typography--variant-caption',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--left-16',
                    ])}
                >
                    <Icon
                        icon={warningIcon}
                        className={cx([
                            'global!ace-u-margin--right-8',
                            'ace-c-icon--s',
                            'ace-c-icon--color-warning',
                        ])}
                    />
                    <span>
                        {errorMessage}
                    </span>
                </p>
            )}
            <div
                className={cx([
                    'global!ace-u-width--full',
                    'global!ace-u-flex',
                    'global!ace-u-margin--top-32',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-flex-end',
                ])}
            >
                <ButtonPrimary
                    onClick={handleOnClick}
                    className={cx('global!ace-u-margin--right-32')}
                    isDisabled={!!errorMessage}
                >
                    {translateModal('button_label.save')}
                </ButtonPrimary>
            </div>
        </div>
    );
};

EditSurchargesData.propTypes = {
    confirmUpdateSurcharges: PropTypes.func.isRequired,
    surchargeRatePrices: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
    return ({
        surchargeRatePrices: state.priceManagement.surchargeRatePrices,
    });
};

const mapDispatchToProps = dispatch => ({
    confirmUpdateSurcharges: payload => dispatch({
        type: priceManagementActionTypes.CONFIRM_UPDATE_PRICES,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditSurchargesData);
