import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {Panel, useStyles} from '@ace-de/ui-components';
import {InteractiveIcon, editIcon} from '@ace-de/ui-components/icons';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {apsPriceTypes} from '@ace-de/eua-entity-types';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import config from '../../config';

const SurchargesPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translatePanel = createTranslateShorthand('surcharges_panel');
    const {initiateUpdateSurcharges, surchargeRatePrices, isEditingDisabled} = props;

    return (
        <Panel
            title={(
                <div>
                    {translatePanel('title.surcharges')}
                    <p className={cx('global!ace-u-typography--variant-body')}>
                        {translatePanel('subtitle.price_calculation_by_time')}
                    </p>
                </div>
            )}
            actions={!isEditingDisabled && (
                <InteractiveIcon
                    icon={editIcon}
                    onClick={() => initiateUpdateSurcharges({contentType: apsPriceTypes.SURCHARGE_RATE})}
                />
            )}
            className={cx('ace-c-panel--full-bleed-content')}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="surcharges-service">
                            {translatePanel('table_header.service')}
                        </TableCell>
                        <TableCell qaIdentPart="surcharges-surcharge">
                            <div className={cx('global!ace-u-typography--align-right')}>
                                {translatePanel('table_header.surcharge')}
                            </div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {surchargeRatePrices.length > 0 && surchargeRatePrices.map((surchargeRate, idx) => {
                        return (
                            <TableRow
                                key={`${surchargeRate.surchargeType}-${idx}`}
                                qaIdentPart={`${surchargeRate.surchargeType}-${idx}`}
                            >
                                <TableCell
                                    qaIdentPart="surcharge-service-type"
                                    qaIdentPartPostfix={surchargeRate.id}
                                >
                                    {surchargeRate.surchargeType
                                        ? translate(`global.surcharge_service_type.${surchargeRate.surchargeType.toLowerCase()}`)
                                        : '-'
                                    }
                                </TableCell>
                                <TableCell
                                    qaIdentPart="surcharge-price"
                                    qaIdentPartPostfix={surchargeRate.id}
                                >
                                    <div className={cx('global!ace-u-typography--align-right')}>
                                        {surchargeRate.value ? `${surchargeRate.value} ${config.PERCENTAGE}` : '-'}
                                    </div>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Panel>
    );
};

SurchargesPanel.propTypes = {
    initiateUpdateSurcharges: PropTypes.func.isRequired,
    surchargeRatePrices: PropTypes.array.isRequired,
    isEditingDisabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
    return {
        surchargeRatePrices: state.priceManagement.surchargeRatePrices,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateUpdateSurcharges: payload => dispatch({
        type: priceManagementActionTypes.INITIATE_UPDATE_PRICES_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurchargesPanel);
