import React, {Fragment, useEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {ArcGISMiniMapWidget, createMarkerGraphic, createPolygonGraphic, markerTowingActivePNG, markerTowingPNG, markerVehiclePNG, markerWorkshopPNG, markerPersonPNG, useArcGISMap} from '@ace-de/eua-arcgis-map';
import {withRouter, useRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, InteractiveIcon, linkIcon, Divider} from '@ace-de/ui-components';
import config from '../../config';
import CPRankingItem from './CPRankingItem';
import * as qualityManagementFeedbackSelectors from '../qualityManagementFeedbackSelectors';
import * as qualityManagementFeedbackActionTypes from '../qualityManagementFeedbackActionTypes';
import modalIds from '../../modalIds';
import parseKMLPolygonData from '../parseKMLPolygonData';

const QMFeedbackCPRankingPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translatePanel = createTranslateShorthand('qm_feedback_cp_ranking_panel');
    const arcGISMap = useArcGISMap('qm-feedback-cp-ranking-map');
    const {openModal} = useRouter();
    const {qmFeedback, activeContractPartnerId, setActiveContractPartnerId, initiateQMFeedbackCPRankingMap} = props;
    const isArcGISMapLoaded = useRef(false);

    const [recommendedContractPartner, otherContractPartners] = useMemo(() => {
        if (!qmFeedback?.contractPartnerRecommendations?.length) return [null, []];
        const recommendedCP = {...(qmFeedback.contractPartnerRecommendations
            .find(contractPartner => contractPartner.rank === 1) || {})};
        if (recommendedCP.fixPricePolygon) {
            recommendedCP['parsedPolygonData'] = parseKMLPolygonData(recommendedCP.fixPricePolygon.trimStart());
        }
        const otherCPs = qmFeedback.contractPartnerRecommendations
            .filter(contractPartner => contractPartner.rank !== 1)
            .map(contractPartner => (contractPartner.fixPricePolygon
                ? {...contractPartner, 'parsedPolygonData': parseKMLPolygonData(contractPartner.fixPricePolygon.trimStart())}
                : contractPartner
            )).sort((cpA, cpB) => {
                return cpA.rank - cpB.rank;
            });
        return [recommendedCP, otherCPs];
    }, [qmFeedback?.contractPartnerRecommendations]);

    useEffect(() => {
        if (!arcGISMap || isArcGISMapLoaded.current) return;
        isArcGISMapLoaded.current = true;
        initiateQMFeedbackCPRankingMap({
            mapName: 'qm-feedback-cp-ranking-map',
        });
    }, [arcGISMap, initiateQMFeedbackCPRankingMap]);

    useEffect(() => {
        if (!arcGISMap) return;

        const markerGraphicData = [
            ...(recommendedContractPartner ? [recommendedContractPartner] : []),
            ...(otherContractPartners || []),
        ]
            .map(contractPartner => (contractPartner.location?.latitude && contractPartner.location?.longitude
                ? createMarkerGraphic({
                    id: `cpRankingItemPin-${contractPartner.id}`,
                    longitude: contractPartner.location.longitude,
                    latitude: contractPartner.location.latitude,
                    icon: contractPartner.id === activeContractPartnerId
                        ? markerTowingActivePNG
                        : markerTowingPNG,
                    isSmall: contractPartner.id !== activeContractPartnerId,
                    isSelectable: true,
                }) : null))
            .filter(graphicData => !!graphicData);

        const polygonGraphicData = [
            ...(recommendedContractPartner ? [recommendedContractPartner] : []),
            ...(otherContractPartners || []),
        ]
            .map(contractPartner => (contractPartner.parsedPolygonData
                ? createPolygonGraphic({
                    id: `cpRankingItemPolygon-${contractPartner.id}`,
                    geometry: contractPartner.parsedPolygonData,
                    isActive: contractPartner.id === activeContractPartnerId,
                }) : null))
            .filter(graphicData => !!graphicData);

        arcGISMap.setGraphics({
            graphics: [
                ...polygonGraphicData,
                ...(qmFeedback?.serviceLocation?.coordinates
                    && qmFeedback.serviceLocation.coordinates?.longitude
                    && qmFeedback.serviceLocation.coordinates?.latitude
                    ? [createMarkerGraphic({
                        id: 'serviceLocation',
                        longitude: qmFeedback.serviceLocation.coordinates.longitude,
                        latitude: qmFeedback.serviceLocation.coordinates.latitude,
                        icon: markerVehiclePNG,
                        isSmall: true,
                    })] : []),
                ...(qmFeedback?.towingDestination?.coordinates
                    && qmFeedback.towingDestination.coordinates?.longitude
                    && qmFeedback.towingDestination.coordinates?.latitude
                    ? [createMarkerGraphic({
                        id: 'towingDestination',
                        longitude: qmFeedback.towingDestination.coordinates.longitude,
                        latitude: qmFeedback.towingDestination.coordinates.latitude,
                        icon: markerWorkshopPNG,
                        isSmall: true,
                    })] : []),
                ...(qmFeedback?.memberLocation?.coordinates
                    && qmFeedback.memberLocation.coordinates?.longitude
                    && qmFeedback.memberLocation.coordinates?.latitude
                    ? [createMarkerGraphic({
                        id: 'memberLocation',
                        longitude: qmFeedback.memberLocation.coordinates.longitude,
                        latitude: qmFeedback.memberLocation.coordinates.latitude,
                        icon: markerPersonPNG,
                        isSmall: true,
                    })] : []),
                ...markerGraphicData,
            ],
        });
    }, [
        arcGISMap,
        activeContractPartnerId,
        recommendedContractPartner,
        otherContractPartners,
        qmFeedback?.serviceLocation?.coordinates,
        qmFeedback?.memberLocation?.coordinates,
        qmFeedback?.towingDestination?.coordinates,
    ]);

    // if not qmFeedback, don't render
    if (!qmFeedback) return null;

    return (
        <Panel
            title={translatePanel('title.list_of_10')}
            className={cx('ace-c-panel--full-bleed-content')}
            actions={(
                <InteractiveIcon
                    icon={linkIcon}
                    onClick={() => {
                        openModal(modalIds.QM_FEEDBACK_CP_RANKING);
                        // set 'activeContractPartnerId' field to default
                        setActiveContractPartnerId({
                            activeContractPartnerId: qmFeedback.contractPartnerId || null,
                        });
                    }}
                />
            )}
        >
            <ArcGISMiniMapWidget
                name="qm-feedback-cp-ranking-map"
                tempArcGISMapId={config.TEMP_ARCGIS_MAP_ID}
            />
            <div className={cx('global!ace-u-padding--bottom-8')}>
                <h4
                    className={cx([
                        'global!ace-u-typography--variant-body-bold',
                        'global!ace-u-typography--align-left',
                        'global!ace-u-margin--top-24',
                        'global!ace-u-margin--left-24',
                    ])}
                >
                    {translatePanel('heading.nearest_contract_partner')}
                </h4>
                {!!recommendedContractPartner && (
                    <Fragment>
                        <CPRankingItem
                            contractPartner={recommendedContractPartner}
                            isSelected={activeContractPartnerId === recommendedContractPartner?.id}
                            onClick={() => setActiveContractPartnerId({
                                activeContractPartnerId: recommendedContractPartner.id,
                            })}
                        />
                        <Divider />
                    </Fragment>
                )}
                <h4
                    className={cx([
                        'global!ace-u-typography--variant-body-bold',
                        'global!ace-u-typography--align-left',
                        'global!ace-u-margin--top-24',
                        'global!ace-u-margin--left-24',
                    ])}
                >
                    {translatePanel('heading.other_contract_partners')}
                </h4>
                {otherContractPartners.map((contractPartner, idx) => (
                    <Fragment
                        key={contractPartner.id}
                    >
                        <CPRankingItem
                            contractPartner={contractPartner}
                            isSelected={activeContractPartnerId === contractPartner.id}
                            onClick={() => setActiveContractPartnerId({
                                activeContractPartnerId: contractPartner.id,
                            })}
                        />
                        {idx !== otherContractPartners.length - 1 && (
                            <Divider />
                        )}
                    </Fragment>
                ))}
            </div>
        </Panel>
    );
};

QMFeedbackCPRankingPanel.propTypes = {
    qmFeedback: PropTypes.object,
    activeContractPartnerId: PropTypes.string,
    setActiveContractPartnerId: PropTypes.func.isRequired,
    initiateQMFeedbackCPRankingMap: PropTypes.func.isRequired,
};

QMFeedbackCPRankingPanel.defaultProps = {
    qmFeedback: null,
    activeContractPartnerId: null,
};

const mapStateToProps = (state, props) => {
    const qmFeedbackSelector = qualityManagementFeedbackSelectors.createQMFeedbackSelector();
    return {
        qmFeedback: qmFeedbackSelector(state, props),
        activeContractPartnerId: state.qualityManagementFeedbacks.activeContractPartnerId,
    };
};

const mapDispatchToProps = dispatch => ({
    setActiveContractPartnerId: payload => dispatch({
        type: qualityManagementFeedbackActionTypes.SET_ACTIVE_CONTRACT_PARTNER_ID,
        payload,
    }),
    initiateQMFeedbackCPRankingMap: payload => dispatch({
        type: qualityManagementFeedbackActionTypes.INITIATE_QM_FEEDBACK_CP_RANKING_MAP,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QMFeedbackCPRankingPanel));
