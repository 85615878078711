import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {persistenceStates} from '@computerrock/formation-core';
import {useTranslate} from '@computerrock/formation-i18n';
import {HighlightCircle, Icon, closeIcon, InteractiveIcon, infoAlertIcon, Modal, useStyles, ButtonPrimary, ButtonSecondary, LoaderSpinner} from '@ace-de/ui-components';
import * as applicationActionTypes from '../applicationActionTypes';
import determineActionTypes from '../determineActionTypes';
import useCloseOnEscape from '../../utils/useCloseOnEscape';

const ErrorMessageModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, closeModal, location, confirmRetry, declineRetry, errorMessagePersistenceState} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('error_message_modal');
    const errorMessageType = location?.query?.errorType;
    const actionTypes = determineActionTypes(errorMessageType);

    useCloseOnEscape(() => {
        if (errorMessagePersistenceState === persistenceStates.READY) {
            actionTypes?.decline ? declineRetry(actionTypes.decline) : closeModal();
        }
    });

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={errorMessagePersistenceState === persistenceStates.READY
                        ? actionTypes.decline
                            ? () => declineRetry(actionTypes.decline)
                            : closeModal
                        : null}
                />
            )}
        >
            {errorMessagePersistenceState === persistenceStates.PENDING && (
                <div
                    className={cx([
                        'global!ace-u-width--full',
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--justify-content-center',
                    ])}
                >
                    <HighlightCircle
                        className={cx([
                            'ace-c-highlight-circle--medium',
                            'ace-c-highlight-circle--primary-highlight',
                            'global!ace-u-margin--top-24',
                        ])}
                    >
                        <LoaderSpinner />
                    </HighlightCircle>
                </div>
            )}
            {errorMessagePersistenceState === persistenceStates.READY && (
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-full-width',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--justify-content-center',
                    ])}
                >
                    <HighlightCircle
                        className={cx([
                            'ace-c-highlight-circle--medium',
                            'ace-c-highlight-circle--primary-highlight',
                        ])}
                    >
                        <Icon
                            icon={infoAlertIcon}
                            className={cx('ace-c-icon--xxl', 'global!ace-c-icon--color-highlight')}
                        />
                    </HighlightCircle>
                    <h1 className={cx('global!ace-u-margin--24-0')}>
                        {translateModal('heading.error')}
                    </h1>
                    {errorMessageType && typeof errorMessageType === 'string' && (
                        <p className={cx(['global!ace-u-margin--bottom-24', 'global!ace-u-typography--align-center'])}>
                            {translateModal(`error_message.${snakeCase(errorMessageType)}`)}
                        </p>
                    )}
                    {!!actionTypes?.confirm && (
                        <div className={cx('global!ace-u-flex', 'global!ace-u-flex--justify-center')}>
                            <ButtonPrimary
                                className={cx('global!ace-u-margin--right-16')}
                                onClick={() => confirmRetry(actionTypes.confirm)}
                            >
                                {translateModal('button.try_again')}
                            </ButtonPrimary>
                            <ButtonSecondary
                                onClick={() => (actionTypes?.decline
                                    ? declineRetry(actionTypes.decline)
                                    : closeModal)
                                }
                            >
                                {translateModal('button.cancel')}
                            </ButtonSecondary>
                        </div>
                    )}
                </div>
            )}
        </Modal>
    );
};

ErrorMessageModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    confirmRetry: PropTypes.func.isRequired,
    declineRetry: PropTypes.func.isRequired,
    location: PropTypes.object,
    errorMessagePersistenceState: PropTypes.string.isRequired,
};

ErrorMessageModal.defaultProps = {
    hasBackdrop: true,
    location: null,
};

const mapStateToProps = state => ({
    errorMessagePersistenceState: state.application.errorMessagePersistenceState,
});

const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch({
        type: applicationActionTypes.DECLINE_ERROR_MESSAGE_FLOW,
        payload: null,
    }),
    confirmRetry: type => dispatch({type, payload: null}),
    declineRetry: type => dispatch({type, payload: null}),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessageModal);
