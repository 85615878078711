import {put, select, take, fork} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import {persistenceStates} from '@computerrock/formation-core';
import {alfClientTypes, apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import contractPartnerScreenTabs from '../contractPartnerScreenTabs';
import config from '../../config';

const loadContractPartner = function* loadContractPartner({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const {activeTab} = yield* selectSearchQueryParams();
    const {match, location} = payload;
    const {contractPartnerId} = match.params;
    const searchQueryParams = new URLSearchParams();

    // reset ella account persistence state to pending so loading spinner displays on page enter
    yield put({
        type: contractPartnerActionTypes.SET_ELLA_ACCOUNT_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.PENDING},
    });
    // on the temporary restriction tab, do not reload contract partner if TR modal is open/closed
    if (activeTab === contractPartnerScreenTabs.TEMPORARY_RESTRICTIONS
        && (location.query?.modal || location.state?.isModalClosed)
    ) return;

    // load contract partner BC details only on the basic data where a modal is not open
    if (!config.IS_PRE_BC_VERSION && activeTab === contractPartnerScreenTabs.BASIC_DATA && !location?.query?.modal) {
        searchQueryParams.append('ids', contractPartnerId);
        searchQueryParams.append('client', alfClientTypes.ACE);

        yield put({
            type: contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_BC_DETAILS,
            payload: {searchQueryParams},
        });
    }

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST,
        partnerManagementService.getACEPartner,
        {
            acePartnerId: contractPartnerId,
        },
    );

    const responseAction = yield take([
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST_FAILED,
    ]);

    if (responseAction.error) return;

    const {response} = responseAction.payload;
    const {acePartnerDTO: contractPartnerDTO} = response;

    yield put({
        type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
        payload: {contractPartnerDTOs: [contractPartnerDTO]},
    });


    if (contractPartnerDTO.affiliatePartners.length && activeTab === contractPartnerScreenTabs.OPERATION_AREA) {
        yield put({
            type: contractPartnerActionTypes.SET_AFFILIATE_PARTNERS_PERSISTENCE_STATE,
            payload: {persistenceState: persistenceStates.PENDING},
        });

        yield fork(
            fetchRequest,
            contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST,
            partnerManagementService.getACEPartnersV2,
            {
                partnerType: apmACEPartnerTypes.CONTRACT_PARTNER,
            },
        );

        const allContractPartnersResponseAction = yield take([
            contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST_FAILED,
        ]);

        if (allContractPartnersResponseAction.error) {
            yield put({
                type: contractPartnerActionTypes.SET_AFFILIATE_PARTNERS_PERSISTENCE_STATE,
                payload: {persistenceState: persistenceStates.FAILED},
            });

            return;
        }

        const {acePartnerDTOs: contractPartnerDTOs} = allContractPartnersResponseAction.payload.response;
        const affiliatePartners = contractPartnerDTOs.filter(contractPartner => {
            return contractPartnerDTO.affiliatePartners
                .find(affiliatePartner => affiliatePartner.id === contractPartner.id);
        });
        yield put({
            type: contractPartnerActionTypes.STORE_AFFILIATE_PARTNERS,
            payload: {affiliatePartners},
        });

        yield put({
            type: contractPartnerActionTypes.SET_AFFILIATE_PARTNERS_PERSISTENCE_STATE,
            payload: {persistenceState: persistenceStates.READY},
        });

        return;
    }

    if (contractPartnerDTO?.address && activeTab === contractPartnerScreenTabs.BASIC_DATA) {
        yield put({
            type: contractPartnerActionTypes.SEARCH_CP_LOCATION_GEOLOCATION,
            payload: {
                searchQueryString: [contractPartnerDTO['address'].street,
                    contractPartnerDTO['address'].postCode,
                    contractPartnerDTO['address'].city,
                    contractPartnerDTO['address'].country].filter(value => !!value).join(', '),
            },
        });
    }

    yield put({
        type: contractPartnerActionTypes.STORE_AFFILIATE_PARTNERS,
        payload: {affiliatePartners: []},
    });
};

export default loadContractPartner;
