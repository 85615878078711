import {fork, select, take} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import * as qualityManagementFeedbackActionTypes from '../qualityManagementFeedbackActionTypes';

const downloadQualityManagementFeedbackCaseLogDocument = function* downloadQualityManagementFeedbackCaseLogDocument() {
    const {serviceManager} = yield select(state => state.application);
    const apmFileAssetsService = serviceManager.loadService('apmFileAssetsService');

    while (true) {
        const {payload} = yield take(qualityManagementFeedbackActionTypes.SUBMIT_CASE_LOG_DOCUMENT_DOWNLOAD_FLOW);
        const {fileName, url} = payload;

        yield fork(fetchRequest, qualityManagementFeedbackActionTypes.DOWNLOAD_CASE_LOG_DOCUMENT_REQUEST,
            apmFileAssetsService.downloadFile, {
                fileName,
                url,
            });

        yield take([
            qualityManagementFeedbackActionTypes.DOWNLOAD_CASE_LOG_DOCUMENT_REQUEST_SUCCEEDED,
            qualityManagementFeedbackActionTypes.DOWNLOAD_CASE_LOG_DOCUMENT_REQUEST_FAILED,
        ]);
    }
};

export default downloadQualityManagementFeedbackCaseLogDocument;

