import {put} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import contractPartnerScreenTabs from '../contractPartnerScreenTabs';

const loadCPOperationAreaMapWatcher = function* loadCPOperationAreaMapWatcher() {
    const {activeTab} = yield* selectSearchQueryParams();

    if (activeTab !== contractPartnerScreenTabs.OPERATION_AREA) return;

    yield put({type: contractPartnerActionTypes.START_MAP_CP_OPERATION_AREA_WATCHER});
};

export default loadCPOperationAreaMapWatcher;
