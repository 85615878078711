import {fork} from 'redux-saga/effects';
import keycloakAuth from './keycloakAuth';
import arcGISAuth from './arcGISAuth';
import applicationWatcher from './application/applicationWatcher';
import dashboardWatcher from './dashboard/dashboardWatcher';
import contractPartnerWatcher from './contract-partners/contractPartnerWatcher';
import qualityManagementFeedbackWatcher from './quality-management-feedbacks/qualityManagementFeedbackWatcher';
import priceManagementWatcher from './price-management/priceManagementWatcher';

// application sagas
export default [
    fork(applicationWatcher),
    fork(keycloakAuth.watcher),
    fork(arcGISAuth.watcher),
    fork(dashboardWatcher),
    fork(contractPartnerWatcher),
    fork(qualityManagementFeedbackWatcher),
    fork(priceManagementWatcher),
];
