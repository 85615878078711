/**
 * Command action types
 */
export const INITIATE_ERROR_MESSAGE_FLOW = '[application] INITIATE_ERROR_MESSAGE_FLOW';
export const DECLINE_ERROR_MESSAGE_FLOW = '[application] DECLINE_ERROR_MESSAGE_FLOW';

/**
 * Store action types
 */
export const SET_ERROR_MESSAGE_PERSISTENCE_STATE = '[application] SET_ERROR_MESSAGE_PERSISTENCE_STATE';
