import {fork, put, select, take} from 'redux-saga/effects';
import * as contractPartnerActionTypes from '../../contract-partners/contractPartnerActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';

const loadContractPartners = function* loadContractPartners() {
    const {areAllContractPartnersFetched} = yield select(state => state.contractPartners);

    if (areAllContractPartnersFetched) return;

    const {serviceManager} = yield select(state => state.application);
    const partnerManagerService = serviceManager.loadService('partnerManagementService');
    const {location} = yield select(state => state.router);

    // do not reload ace partners when the agent opens or closes a modal on the QM search screen
    if (location && (location.state?.isModalOpen || location.state?.isModalClosed)
        && [modalIds.CP_CREATE_QUALITY_REPORT].includes(location.state?.modalId)
    ) return;

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST,
        partnerManagerService.queryContractPartners,
        {
            query: `id name`,
        },
    );

    const responseAction = yield take([
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST_FAILED,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNERS_REQUEST_SUCCEEDED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {contractPartnerDTOs} = response;

        yield put({
            type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
            payload: {contractPartnerDTOs},
        });

        // set areAllContractPartnersFetched flag to true and prevent multiple fetches per session
        yield put({
            type: contractPartnerActionTypes.SET_ARE_ALL_CONTRACT_PARTNERS_FETCHED,
            payload: {areAllContractPartnersFetched: true},
        });
    }
};

export default loadContractPartners;
