import {fork, select, take, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {persistenceStates} from '@computerrock/formation-core';
import {QualityManagementFeedback} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import errorTypes from '../../application/errorTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';

const createQualityReportFlow = function* createQualityReportFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    let shouldWaitForAction = true;
    let payloadData = null;
    let chosenModalOption = null;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contractPartnerActionTypes.INITIATE_CREATE_CONTRACT_PARTNER_QUALITY_REPORT_FLOW); // eslint-disable-line max-len
            payloadData = payload;

            yield* openModal(modalIds.CP_CREATE_QUALITY_REPORT);

            chosenModalOption = yield take([
                contractPartnerActionTypes.DECLINE_CREATE_CONTRACT_PARTNER_QUALITY_REPORT,
                contractPartnerActionTypes.CONFIRM_CREATE_CONTRACT_PARTNER_QUALITY_REPORT,
            ]);
        }

        if (chosenModalOption.type === contractPartnerActionTypes.DECLINE_CREATE_CONTRACT_PARTNER_QUALITY_REPORT) {
            yield* closeModal(modalIds.CP_CREATE_QUALITY_REPORT);
            continue;
        }

        const {qualityReportData} = chosenModalOption.payload;
        const {contractPartnerId} = payloadData || {};
        if (contractPartnerId) {
            qualityReportData['contractPartnerId'] = contractPartnerId;
        }

        yield fork(
            fetchRequest,
            contractPartnerActionTypes.CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST,
            partnerManagementService.createContractPartnerQualityReport,
            {
                qualityReportData: QualityManagementFeedback.objectToDTO(qualityReportData),
            },
        );

        const responseAction = yield take([
            contractPartnerActionTypes.CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.CREATE_CONTRACT_PARTNER_QUALITY_REPORT_REQUEST_FAILED,
        ]);

        if (responseAction.error) {
            if (shouldWaitForAction) {
                yield* closeModal(modalIds.CP_CREATE_QUALITY_REPORT);
                yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {
                    errorType: errorTypes.CP_QUALITY_REPORT_CREATION_FAILED,
                });
            }

            yield put({
                type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                payload: {persistenceState: persistenceStates.READY},
            });

            const nextAction = yield take([
                contractPartnerActionTypes.RETRY_CREATE_CP_QUALITY_REPORT,
                contractPartnerActionTypes.CANCEL_RETRY_CREATE_CP_QUALITY_REPORT,
            ]);

            shouldWaitForAction = nextAction.type !== contractPartnerActionTypes.RETRY_CREATE_CP_QUALITY_REPORT;

            if (nextAction.type === contractPartnerActionTypes.RETRY_CREATE_CP_QUALITY_REPORT) {
                yield put({
                    type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.PENDING},
                });
            }

            if (nextAction.type === contractPartnerActionTypes.CANCEL_RETRY_CREATE_CP_QUALITY_REPORT) {
                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
            }

            continue;
        }

        if (!shouldWaitForAction) {
            yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});

            yield put({
                type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                payload: {persistenceState: persistenceStates.READY},
            });
            shouldWaitForAction = true;
            continue;
        }

        yield* closeModal(modalIds.CP_CREATE_QUALITY_REPORT);
    }
};

export default createQualityReportFlow;
