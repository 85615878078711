import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {renderRoutes, matchPath, resolveRoute, useSearchQueryParams} from '@computerrock/formation-router';
import {AppLayoutMain, NavigationBar} from '@ace-de/ui-components/app-layout';
import {InteractiveIcon, plusIcon, Tab, TabBar, useStyles, ContentItem} from '@ace-de/ui-components';
import NavigationBarContent from './ui-elements/NavigationBarContent';
import * as priceManagementActionTypes from '../price-management/priceManagementActionTypes';
import routePaths from '../routePaths';
import config from '../config';
import styles from './DashboardSectionScreen.module.scss';

const DashboardSectionScreen = props => {
    const {cx} = useStyles(props, styles);
    const {translate} = useTranslate();
    const {history, route, timePeriods, initiateCreateTimePeriodFlow, initiateUpdateHolidayPeriods} = props;
    const {initiateFetchPricesByTime} = props;
    const [timePeriodTabs, setTimePeriodTabs] = useState([...timePeriods]);
    const {pricePeriodId} = useSearchQueryParams();

    const futureTimePeriods = timePeriods.filter(timePeriod => (
        moment(timePeriod.validFrom).isAfter(moment.now())
    ));

    const priceManagementScreenMatch = matchPath(history.location.pathname, {
        path: routePaths.PRICE_MANAGEMENT,
        exact: true,
    });

    useEffect(() => {
        if (pricePeriodId) {
            initiateFetchPricesByTime({pricePeriodId});
        }
    }, [initiateFetchPricesByTime, pricePeriodId]);

    useEffect(() => {
        const sortedTimePeriods = timePeriods.length > 0
            ? [...timePeriods].sort((a, b) => moment(a.validFrom).format('YYYYMMDD') - moment(b.validFrom).format('YYYYMMDD'))
            : [];
        setTimePeriodTabs([...sortedTimePeriods]);
    }, [timePeriods]);

    const navigateToRoute = pricePeriodId => {
        if (!pricePeriodId) return;
        const queryParams = new URLSearchParams();
        const timePeriod = timePeriodTabs.find(timePeriod => timePeriod.id === pricePeriodId);

        queryParams.append('validFrom', timePeriod.validFrom);
        queryParams.append('validUntil', timePeriod.validUntil);
        queryParams.append('pricePeriodId', `${timePeriod.id}`);

        history.push(resolveRoute(routePaths.PRICE_MANAGEMENT, {}, {search: queryParams.toString()}));
    };

    return (
        <Fragment>
            <NavigationBar>
                <NavigationBarContent />
            </NavigationBar>
            {priceManagementScreenMatch && (
                <div
                    className={cx([
                        'ace-c-dashboard-section-screen__time-period-tab-bar',
                        'ace-c-content-block',
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <ContentItem
                        className={cx([
                            'ace-c-content-item--large-span-10',
                            'ace-c-content-item--x-large-span-10',
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <TabBar
                            name="priceManagementTabs"
                            className={cx('ace-c-tab-bar--secondary-background')}
                            onChange={navigateToRoute}
                            value={pricePeriodId ? parseInt(pricePeriodId, 10) : ''}
                        >
                            {timePeriodTabs.length > 0 && (timePeriodTabs.map(timePeriod => (
                                <Tab
                                    key={timePeriod.id}
                                    className={cx('ace-c-tab--secondary-background')}
                                    name={`${timePeriod.validFrom}-${timePeriod.validUntil}`}
                                    value={timePeriod.id}
                                >
                                    {`${moment(timePeriod.validFrom).format('DD.MM.YYYY')}-${moment(timePeriod.validUntil).format('DD.MM.YYYY')}`}
                                </Tab>
                            )))}
                            {futureTimePeriods.length < config.MAXIMUM_FUTURE_TIME_PERIODS && (
                                <InteractiveIcon
                                    className={cx([
                                        'ace-c-interactive-icon--primary',
                                        'ace-c-interactive-icon--highlight',
                                        'global!ace-u-margin--left-8',
                                    ])}
                                    onClick={initiateCreateTimePeriodFlow}
                                    icon={plusIcon}
                                >
                                    {translate('time_periods_tab_bar.button_label.add_new_period')}
                                </InteractiveIcon>
                            )}
                        </TabBar>
                    </ContentItem>
                    <ContentItem
                        className={cx([
                            'ace-c-content-item--span-2',
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-flex-end',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-typography--variant-highlight',
                            'global!ace-u-cursor--pointer',
                        ])}
                    >
                        <p onClick={initiateUpdateHolidayPeriods}>
                            {translate('time_periods_tab_bar.button_label.update_holidays')}
                        </p>
                    </ContentItem>
                </div>
            )}
            <AppLayoutMain>
                {renderRoutes(route.routes)}
            </AppLayoutMain>
        </Fragment>
    );
};

DashboardSectionScreen.propTypes = {
    route: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    initiateCreateTimePeriodFlow: PropTypes.func.isRequired,
    initiateUpdateHolidayPeriods: PropTypes.func.isRequired,
    initiateFetchPricesByTime: PropTypes.func.isRequired,
    timePeriods: PropTypes.array,
};

DashboardSectionScreen.defaultProps = {
    timePeriods: [],
};

const mapStateToProps = state => {
    return {
        timePeriods: state.priceManagement.timePeriods,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateCreateTimePeriodFlow: payload => dispatch({
        type: priceManagementActionTypes.INITIATE_CREATE_TIME_PERIOD_FLOW,
        payload,
    }),
    initiateUpdateHolidayPeriods: payload => dispatch({
        type: priceManagementActionTypes.INITIATE_UPDATE_HOLIDAY_PERIODS_FLOW,
        payload,
    }),
    initiateFetchPricesByTime: payload => dispatch({
        type: priceManagementActionTypes.INITIATE_FETCH_PRICES_BY_TIME_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSectionScreen);
