import {take, select, fork, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {persistenceStates} from '@computerrock/formation-core';
import {ACEPartner, apmACEPartnerTypes, deumUserRoleTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import modalIds from '../../modalIds';
import errorTypes from '../../application/errorTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';

const initiateCPCreateELLAAccountFlow = function* initiateCPCreateELLAAccountFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const driverELLAUserManagementService = serviceManager.loadService('driverELLAUserManagementService');

    let shouldWaitForAction = true;
    let payloadData = null;
    let chosenModalOption = null;
    let hasError = false;
    let errorMessage = null;
    let isErrorModalOpen = false;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contractPartnerActionTypes.INITIATE_CP_CREATE_ELLA_ACCOUNT_FLOW);
            payloadData = payload;

            yield* openModal(modalIds.CP_CREATE_ELLA_ACCOUNT);
        }
        const {contractPartnerId, contractPartnerName, affiliatePartnersIds} = payloadData;

        if (!isErrorModalOpen) {
            chosenModalOption = yield take([
                contractPartnerActionTypes.CONFIRM_CREATE_CP_ELLA_ACCOUNT,
                contractPartnerActionTypes.DECLINE_CREATE_CP_ELLA_ACCOUNT,
            ]);
            yield put({
                type: contractPartnerActionTypes.SET_ELLA_ACCOUNT_FIELD_ERROR,
                payload: {
                    error: null,
                },
            });
        }

        if (chosenModalOption && chosenModalOption.type === contractPartnerActionTypes.CONFIRM_CREATE_CP_ELLA_ACCOUNT) {
            // create new group on the keyCloak for the current CP
            yield fork(
                fetchRequest,
                contractPartnerActionTypes.CREATE_CP_USERS_GROUP_REQUEST,
                driverELLAUserManagementService.createUsersGroup,
                {
                    contractPartnerId,
                    contractPartnerName,
                    affiliatePartnersIds,
                },
            );
            const createGroupResponseAction = yield take([
                contractPartnerActionTypes.CREATE_CP_USERS_GROUP_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.CREATE_CP_USERS_GROUP_REQUEST_FAILED,
            ]);

            if (createGroupResponseAction.error) {
                hasError = true;
            }

            if (!hasError) {
                const {ellaAccountData} = chosenModalOption.payload;
                const {name, email, password} = ellaAccountData;
                const [firstName = null, lastName = null] = name.split(' ');

                yield fork(
                    fetchRequest,
                    contractPartnerActionTypes.CREATE_ELLA_USER_REQUEST,
                    driverELLAUserManagementService.createUser,
                    {
                        firstName,
                        lastName,
                        email,
                        password,
                        isTempPassword: true,
                        userRoles: [
                            deumUserRoleTypes.ADMINISTRATOR,
                            deumUserRoleTypes.ACCOUNTANT,
                        ],
                        contractPartnersIds: [contractPartnerId, ...(affiliatePartnersIds || [])],
                    },
                );
                const createELLAUserResponseAction = yield take([
                    contractPartnerActionTypes.CREATE_ELLA_USER_REQUEST_SUCCEEDED,
                    contractPartnerActionTypes.CREATE_ELLA_USER_REQUEST_FAILED,
                ]);

                if (createELLAUserResponseAction.error) {
                    errorMessage = JSON.parse(createELLAUserResponseAction.payload.message);
                    hasError = true;
                }

                if (!createELLAUserResponseAction.error && !shouldWaitForAction) {
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});

                    yield put({
                        type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.READY},
                    });
                    shouldWaitForAction = true;
                    isErrorModalOpen = false;
                }

                // if the ELLA account is created, update the CP's 'ellaActive' flag
                yield fork(
                    fetchRequest,
                    contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ELLA_ACTIVE_REQUEST,
                    partnerManagementService.updateACEPartner,
                    {
                        acePartnerData: {
                            ...ACEPartner.objectToPatchDTO({ellaActive: true}),
                            partnerType: apmACEPartnerTypes.CONTRACT_PARTNER,
                        },
                        acePartnerId: contractPartnerId,
                    },
                );
                const responseAction = yield take([
                    contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ELLA_ACTIVE_REQUEST_SUCCEEDED,
                    contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ELLA_ACTIVE_REQUEST_FAILED,
                ]);

                if (!responseAction.error) {
                    const {response} = responseAction.payload;
                    const {acePartnerDTO} = response;

                    yield put({
                        type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
                        payload: {contractPartnerDTOs: [acePartnerDTO]},
                    });
                }
            }
            if (hasError) {
                if (errorMessage) {
                    yield put({
                        type: contractPartnerActionTypes.SET_ELLA_ACCOUNT_FIELD_ERROR,
                        payload: {
                            error: {
                                code: errorMessage.constraintViolations[0]?.errorCode,
                                field: errorMessage.constraintViolations[0]?.field,
                            },
                        },
                    });
                    if (isErrorModalOpen) {
                        const {ellaAccountData} = chosenModalOption.payload;
                        yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                        yield* openModal(modalIds.CP_CREATE_ELLA_ACCOUNT, {
                            name: ellaAccountData.name,
                            email: ellaAccountData.email,
                        });
                        isErrorModalOpen = false;
                    }
                    hasError = false;
                    errorMessage = null;
                    shouldWaitForAction = false;
                    continue;
                }
                if (!isErrorModalOpen) {
                    yield* closeModal(modalIds.CP_CREATE_ELLA_ACCOUNT, {name: '', email: ''});
                    yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {
                        errorType: errorTypes.CP_ELLA_ACCOUNT_CREATION_FAILED,
                    });
                    isErrorModalOpen = true;
                }

                yield put({
                    type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.READY},
                });

                const nextAction = yield take([
                    contractPartnerActionTypes.RETRY_CREATE_CP_ELLA_ACCOUNT,
                    contractPartnerActionTypes.CANCEL_RETRY_CREATE_CP_ELLA_ACCOUNT,
                ]);

                shouldWaitForAction = nextAction.type !== contractPartnerActionTypes.RETRY_CREATE_CP_ELLA_ACCOUNT;

                if (nextAction.type === contractPartnerActionTypes.RETRY_CREATE_CP_ELLA_ACCOUNT) {
                    yield put({
                        type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.PENDING},
                    });
                    hasError = false;
                }

                if (nextAction.type === contractPartnerActionTypes.CANCEL_RETRY_CREATE_CP_ELLA_ACCOUNT) {
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                    yield put({
                        type: contractPartnerActionTypes.SET_ELLA_ACCOUNT_FIELD_ERROR,
                        payload: {
                            error: null,
                        },
                    });
                    isErrorModalOpen = false;
                }

                continue;
            }
        }
        shouldWaitForAction = true;
        yield* closeModal(modalIds.CP_CREATE_ELLA_ACCOUNT, {name: '', email: ''});
    }
};

export default initiateCPCreateELLAAccountFlow;
