import {all, fork, takeLatest} from 'redux-saga/effects';
import contractPartnerMapWatcher from './sagas/contractPartnerMapWatcher';
import * as contractPartnerActionTypes from '../contract-partners/contractPartnerActionTypes';
import searchContractPartnerByAttribute from './sagas/searchContractPartnerByAttribute';
import selectContractPartnerFlow from './sagas/selectContractPartnerFlow';
import changeCPServiceAreasLayerVisibilityState from './sagas/changeCPServiceAreasLayerVisibilityState';
import searchRandomLocationGeolocation from './sagas/searchRandomLocationGeolocation';

const dashboardWatcher = function* contractPartnerWatcher() {
    yield all([
        fork(contractPartnerMapWatcher),
        takeLatest(contractPartnerActionTypes.SEARCH_CONTRACT_PARTNER_BY_ATTRIBUTE, searchContractPartnerByAttribute),
        fork(selectContractPartnerFlow),
        fork(changeCPServiceAreasLayerVisibilityState),
        takeLatest(contractPartnerActionTypes.SEARCH_RANDOM_LOCATION_GEOLOCATION, searchRandomLocationGeolocation),
    ]);
};

export default dashboardWatcher;
