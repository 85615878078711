import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {DataRow, Panel, useStyles} from '@ace-de/ui-components';
import * as qualityManagementSelectors from '../qualityManagementFeedbackSelectors';
import formatECSServiceCaseScreenURL from '../../utils/formatECSServiceCaseScreenURL';

const QMFeedbackSnapshotAssignmentDataPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translatePanel = createTranslateShorthand('qm_feedback_snapshot_assignment_data_panel');
    const {qualityManagementFeedback} = props;

    return (
        <Panel
            title={translatePanel('panel_title.assignment_data')}
            className={cx(['global!ace-u-full-height', 'global!ace-u-margin--0'])}
        >
            <DataRow
                label={translatePanel('data_row_label.assignment_id')}
                qaIdent="qm-assignment-id"
            >
                <a
                    href={formatECSServiceCaseScreenURL(qualityManagementFeedback.caseId)}
                    className={cx([
                        'global!ace-u-typography--variant-body-bold',
                        'global!ace-u-typography--color-highlighted',
                    ])}
                    target="_blank"
                    rel="noreferrer"
                >
                    {[
                        qualityManagementFeedback.prefix,
                        qualityManagementFeedback.caseId,
                        qualityManagementFeedback.lineNo,
                    ].filter(value => !!value).join('-')}
                </a>
            </DataRow>
            <DataRow
                label={translatePanel('data_row_label.assigned_at')}
                qaIdent="qm-assignment-assigned-at"
            >
                {qualityManagementFeedback.assignedAt}
            </DataRow>
            <DataRow
                label={translatePanel('data_row_label.service')}
                qaIdent="qm-assignment-service"
            >
                {qualityManagementFeedback.serviceType
                    ? translate(`global.service_type.${snakeCase(qualityManagementFeedback.serviceType)}`)
                    : '-'}
            </DataRow>
            <DataRow
                label={translatePanel('data_row_label.damage_type')}
                qaIdent="qm-assignment-damage-type"
            >
                {qualityManagementFeedback.damageType
                    ? translatePanel(`damage_type.${qualityManagementFeedback.damageType.toLowerCase()}`)
                    : '-'}
            </DataRow>
            <DataRow
                label={translatePanel('data_row_label.self_payer')}
                qaIdent="qm-assignment-self-payer"
            >
                {[
                    translatePanel(`self_payer_option.${qualityManagementFeedback.selfPayerOption ? 'yes' : 'no'}`),
                    qualityManagementFeedback.selfPayerNote,
                ].filter(value => !!value).join(', ')}
            </DataRow>
        </Panel>
    );
};

QMFeedbackSnapshotAssignmentDataPanel.propTypes = {
    qualityManagementFeedback: PropTypes.object,
};

QMFeedbackSnapshotAssignmentDataPanel.defaultProps = {
    qualityManagementFeedback: null,
};

const mapStateToProps = (state, props) => {
    const qmFeedbackSelector = qualityManagementSelectors.createQMFeedbackSelector();
    return {
        qualityManagementFeedback: qmFeedbackSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps)(QMFeedbackSnapshotAssignmentDataPanel));
