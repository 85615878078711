import {fork, put, select, take} from 'redux-saga/effects';
import {persistenceStates} from '@computerrock/formation-core';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import contractPartnerScreenTabs from '../contractPartnerScreenTabs';

const loadContractPartnerELLAAccounts = function* loadContractPartnerELLAAccounts({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const driverELLAUserManagementService = serviceManager.loadService('driverELLAUserManagementService');
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const {activeTab} = yield* selectSearchQueryParams();
    const {match} = payload;
    const {contractPartnerId} = match.params;
    const {contractPartners} = yield select(state => state.contractPartners);
    const contractPartner = contractPartners[contractPartnerId];

    if (activeTab !== contractPartnerScreenTabs.BASIC_DATA) return;

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_DEUM_USERS_REQUEST,
        driverELLAUserManagementService.fetchUsers,
        {
            partnerIds: [!contractPartner.affiliatedPartner ? contractPartnerId : contractPartner.affiliatedPartner.id],
        },
    );

    const responseAction = yield take([
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_DEUM_USERS_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_DEUM_USERS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {data} = response;
        let affiliatedPartnerELLAActive = false;

        if (contractPartner.affiliatedPartner) {
            yield fork(
                fetchRequest,
                contractPartnerActionTypes.FETCH_AFFILIATED_CONTRACT_PARTNER_REQUEST,
                partnerManagementService.getACEPartner,
                {
                    acePartnerId: contractPartner.affiliatedPartner.id,
                },
            );

            const affiliatedPartnerResponseAction = yield take([
                contractPartnerActionTypes.FETCH_AFFILIATED_CONTRACT_PARTNER_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.FETCH_AFFILIATED_CONTRACT_PARTNER_REQUEST_FAILED,
            ]);

            if (!affiliatedPartnerResponseAction.error) {
                const {response} = affiliatedPartnerResponseAction.payload;
                const {acePartnerDTO: contractPartnerDTO} = response;
                affiliatedPartnerELLAActive = contractPartnerDTO.ellaActive;
            }
        }

        yield put({
            type: contractPartnerActionTypes.STORE_HAS_ELLA_ACCOUNT,
            payload: {
                hasELLAAccount: data.length > 0,
                contractPartnerId,
                ...(contractPartner.affiliatedPartner && {affiliatedPartnerELLAActive}),
            },
        });

        yield put({
            type: contractPartnerActionTypes.SET_ELLA_ACCOUNT_PERSISTENCE_STATE,
            payload: {persistenceState: persistenceStates.READY},
        });

        return;
    }

    yield put({
        type: contractPartnerActionTypes.SET_ELLA_ACCOUNT_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.FAILED},
    });
};

export default loadContractPartnerELLAAccounts;
