import {efServiceCaseTypes, ServiceCaseVehicle, ServiceCasePerson, ServiceCase} from '@ace-de/eua-entity-types';

const createNewServiceCase = caseType => {
    let newCase = {};

    switch (caseType) {
        case efServiceCaseTypes.VEHICLE: {
            newCase = new ServiceCaseVehicle();
            break;
        }

        case efServiceCaseTypes.PERSON: {
            newCase = new ServiceCasePerson();
            break;
        }

        case efServiceCaseTypes.HOUSEHOLD: {
            newCase = new ServiceCase();
            break;
        }

        default:
            // no-op
    }
    return newCase;
};

export default createNewServiceCase;
