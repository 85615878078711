import React from 'react';
import PropTypes from 'prop-types';
import {useStyles, Icon, arrowLeftIcon, arrowRightIcon} from '@ace-de/ui-components';
import styles from './SidebarExpansionButton.module.scss';

const SidebarExpansionButton = props => {
    const {onClick, isExpanded} = props;
    const {cx} = useStyles(props, styles);

    const handleOnClick = event => {
        if (typeof onClick === 'function') {
            onClick(event);
        }
    };

    return (
        <div
            className={cx('ace-c-sidebar-expansion-button', {
                'ace-c-sidebar-expansion-button--shrinked': !isExpanded,
            })}
            onClick={handleOnClick}
        >
            <Icon icon={isExpanded ? arrowLeftIcon : arrowRightIcon} />
        </div>
    );
};

SidebarExpansionButton.propTypes = {
    onClick: PropTypes.func,
    isExpanded: PropTypes.bool,
};

SidebarExpansionButton.defaultProps = {
    onClick: null,
    isExpanded: true,
};

export default SidebarExpansionButton;
