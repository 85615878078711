import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

/**
 * Search CP operating unit location geolocation
 */
const searchCPOperatingUnitLocationGeolocation = function* searchCPOperatingUnitLocationGeolocation({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    const {searchQueryString, contractPartnerId} = payload;
    yield fork(
        fetchRequest,
        contractPartnerActionTypes.SEARCH_CP_OPERATING_UNIT_LOCATION_GEOLOCATION_REQUEST,
        arcGISRESTService.searchAddressSuggestions,
        {singleLine: searchQueryString},
    );

    const searchOperatingUnitLocationGeolocation = yield take([
        contractPartnerActionTypes.SEARCH_CP_OPERATING_UNIT_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.SEARCH_CP_OPERATING_UNIT_LOCATION_GEOLOCATION_REQUEST_FAILED,
    ]);

    if (!searchOperatingUnitLocationGeolocation.error) {
        const {response} = searchOperatingUnitLocationGeolocation.payload;
        const {arcGISLocationCandidateDTOs} = response;

        yield put({
            type: contractPartnerActionTypes.SET_CP_OPERATING_UNIT_LOCATION_CANDIDATES,
            payload: {
                arcGISLocationCandidateDTOs,
                contractPartnerId,
                searchQueryString,
            },
        });
    }
};

export default searchCPOperatingUnitLocationGeolocation;
