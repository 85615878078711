import {apsPriceTypes} from '@ace-de/eua-entity-types';

/**
 * Price modal config
 */
export default {
    [apsPriceTypes.SURCHARGE_RATE]: {
        name: 'surcharges',
        hasBackdrop: true,
        title: 'edit_surcharges_modal.title.',
        className: 'global!ace-u-modal-content-size--xs',
    },
    [apsPriceTypes.TIME_PRICE]: {
        name: 'rates-by-time',
        hasBackdrop: true,
        title: 'edit_rates_by_time_modal.title.',
        className: 'global!ace-u-modal-content-size--m',
    },
    [apsPriceTypes.PICKUP_PRICE]: {
        name: 'pickup-rates',
        hasBackdrop: true,
        title: 'edit_pickup_rates_modal.title.',
        className: 'global!ace-u-modal-content-size--s',
    },
    [apsPriceTypes.RECOVERY_PRICE]: {
        name: 'simple-service-rates-by-time',
        hasBackdrop: true,
        title: 'edit_simple_service_rates_modal.title.',
        className: 'global!ace-u-modal-content-size--xs',
    },
    [apsPriceTypes.SERVICE_FIXED_PRICE]: {
        name: 'fixed-service-prices',
        hasBackdrop: true,
        title: 'edit_fixed_rates_modal.title.',
        className: 'global!ace-u-modal-content-size--xs',
    },
};
