import {put, fork, take, select} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import contractPartnerScreenTabs from '../contractPartnerScreenTabs';
import modalIds from '../../modalIds';

const loadContractPartnerTemporaryRestrictions = function* loadContractPartnerTemporaryRestrictions({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const {location} = yield select(state => state.router);
    const {activeTab} = yield* selectSearchQueryParams();

    // do not load contract partner temporary restrictions if agent is not on that tab
    if (activeTab !== contractPartnerScreenTabs.TEMPORARY_RESTRICTIONS) return;
    // do not reload contract partner restrictions when TR creation/edit modal is open
    if (location && [
        modalIds.CP_CREATE_TEMPORARY_RESTRICTION,
        modalIds.CP_EDIT_TEMPORARY_RESTRICTION,
    ].includes(location.state?.modalId) && location.state?.isModalOpen) return;

    const {match} = payload;
    const {contractPartnerId} = match.params;

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST,
        partnerManagementService.getACEPartnerRestrictions,
        {
            acePartnerId: contractPartnerId,
        },
    );

    const responseAction = yield take([
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_TEMPORARY_RESTRICTIONS_REQUEST_FAILED,
    ]);

    if (responseAction.error) return;

    const {response} = responseAction.payload;

    yield put({
        type: contractPartnerActionTypes.STORE_CONTRACT_PARTNER_RESTRICTIONS,
        payload: {contractPartnerId, contractPartnerRestrictionDTOs: response.acePartnerRestrictionDTOs},
    });
};

export default loadContractPartnerTemporaryRestrictions;
