import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {Panel, useStyles} from '@ace-de/ui-components';
import {InteractiveIcon, editIcon} from '@ace-de/ui-components/icons';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {apsPriceTypes} from '@ace-de/eua-entity-types';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import getPrice from '../../utils/getPrice';

const FixedRatesPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale, translate} = useTranslate();
    const translatePanel = createTranslateShorthand('fixed_rates_panel');
    const {initiateUpdateFixedRatesFlow} = props;
    const {serviceFixedPrices, isEditingDisabled} = props;

    return (
        <Panel
            title={translatePanel('title.fixed_rates_for_services')}
            actions={!isEditingDisabled && (
                <InteractiveIcon
                    icon={editIcon}
                    onClick={() => initiateUpdateFixedRatesFlow({contentType: apsPriceTypes.SERVICE_FIXED_PRICE})}
                />
            )}
            className={cx('ace-c-panel--full-bleed-content')}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="fixed-rates-service">
                            {translatePanel('table_header.service')}
                        </TableCell>
                        <TableCell qaIdentPart="fixed-rates-price">
                            <div className={cx('global!ace-u-typography--align-right')}>
                                {translatePanel('table_header.price')}
                            </div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {serviceFixedPrices.length > 0 && serviceFixedPrices.map((fixedPrice, idx) => {
                        return (
                            <TableRow
                                key={`${fixedPrice.serviceType}-${idx}`}
                                qaIdentPart={`${fixedPrice.serviceType}-${idx}`}
                            >
                                <TableCell
                                    qaIdentPart="fixed-rate-service"
                                    qaIdentPartPostfix={fixedPrice.id}
                                >
                                    {fixedPrice.serviceType
                                        ? translate(`global.fixed_rate_service_type.${fixedPrice.serviceType.toLowerCase()}`)
                                        : '-'
                                    }
                                </TableCell>
                                <TableCell
                                    qaIdentPart="fixed-rate-price"
                                    qaIdentPartPostfix={fixedPrice.id}
                                >
                                    <div className={cx('global!ace-u-typography--align-right')}>
                                        {fixedPrice.value ? getPrice(fixedPrice.value, activeLocale) : '-'}
                                    </div>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Panel>
    );
};

FixedRatesPanel.propTypes = {
    initiateUpdateFixedRatesFlow: PropTypes.func.isRequired,
    serviceFixedPrices: PropTypes.array.isRequired,
    isEditingDisabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
    return {
        serviceFixedPrices: state.priceManagement.serviceFixedPrices,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateUpdateFixedRatesFlow: payload => dispatch({
        type: priceManagementActionTypes.INITIATE_UPDATE_PRICES_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FixedRatesPanel);
