import React, {Fragment, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {europeanCountries, apmDayOfWeekTypes} from '@ace-de/eua-entity-types';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, DataRow, InteractiveIcon, Divider, Option, Autosuggest} from '@ace-de/ui-components';
import {Form, Input, ToggleSwitch, TimeField, ButtonPrimary, InputField} from '@ace-de/ui-components/form';
import {Icon, editIcon, closeIcon, timeIcon, geocodingIcon, checkmarkIcon} from '@ace-de/ui-components/icons';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import config from '../../config';
import {validateEmail} from '../../utils/validation';

const validatePhoneOrFaxNumber = number => {
    const regex = /^([+])[0-9]{8,15}$/;
    return regex.test(number);
};

const generateCustomTimeFieldOptions = () => {
    const options = [];
    for (let hours = 0; hours < 24; hours += 1) {
        const hoursString = hours < 10 ? `0${hours}` : hours;
        for (let minutes = 0; minutes < 60; minutes += 5) {
            const minutesString = minutes < 10 ? `0${minutes}` : minutes;
            options.push(`${hoursString}:${minutesString}`);
        }
    }
    options.push('24:00');
    return options;
};

const areWorkingHoursExist = workingHoursDTO => !!(workingHoursDTO?.from && workingHoursDTO?.to);

const formatWorkingHours = workingHoursDTO => {
    if (!workingHoursDTO) return null;
    return {
        dayOfWeek: workingHoursDTO.dayOfWeek,
        from: workingHoursDTO?.from?.slice(0, -3) || '',
        to: workingHoursDTO?.to?.slice(0, -3) || '',
    };
};

const formatDisplayAddress = locationDTO => {
    if (!locationDTO) return '';
    return [
        locationDTO.street,
        locationDTO.postCode,
        locationDTO.city,
        locationDTO.country,
    ].filter(value => !!value).join(', ');
};

const initialWorkingHoursErrors = {
    [apmDayOfWeekTypes.MONDAY_TO_FRIDAY]: '',
    [apmDayOfWeekTypes.SATURDAY]: '',
    [apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY]: '',
};

const ContractPartnerAddressDataPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_basic_data_tab');
    const {contractPartner, submitCPContactDetailsForm} = props;
    const {searchContractPartnerLocationGeolocation, contractPartnerLocationRecommendations} = props;
    const {updateContractPartnerLocationFlow, resetContractPartnerLocationRecommendations} = props;
    const [isEditModeActive, setIsEditModeActive] = useState(false);
    const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('');
    const [faxErrorMessage, setFaxErrorMessage] = useState('');
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [workingHoursErrors, setWorkingHoursErrors] = useState({...initialWorkingHoursErrors});
    const [workingHoursData, setWorkingHoursData] = useState({
        [apmDayOfWeekTypes.MONDAY_TO_FRIDAY]: formatWorkingHours(contractPartner?.workingHours?.find(workingHoursDTO => { // eslint-disable-line max-len
            return workingHoursDTO?.dayOfWeek === apmDayOfWeekTypes.MONDAY_TO_FRIDAY;
        })),
        [apmDayOfWeekTypes.SATURDAY]: formatWorkingHours(contractPartner?.workingHours?.find(workingHoursDTO => {
            return workingHoursDTO?.dayOfWeek === apmDayOfWeekTypes.SATURDAY;
        })),
        [apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY]: formatWorkingHours(contractPartner?.workingHours?.find(workingHoursDTO => { // eslint-disable-line max-len
            return workingHoursDTO?.dayOfWeek === apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY;
        })),
        openMondayToFriday: areWorkingHoursExist(contractPartner?.workingHours?.find(workingHoursDTO => {
            return workingHoursDTO?.dayOfWeek === apmDayOfWeekTypes.MONDAY_TO_FRIDAY;
        })),
        openOnSaturday: areWorkingHoursExist(contractPartner?.workingHours?.find(workingHoursDTO => {
            return workingHoursDTO?.dayOfWeek === apmDayOfWeekTypes.SATURDAY;
        })),
        openSundayAndHoliday: areWorkingHoursExist(contractPartner?.workingHours?.find(workingHoursDTO => {
            return workingHoursDTO?.dayOfWeek === apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY;
        })),
    });
    const [contractPartnerSearchQuery, setContractPartnerSearchQuery] = useState(
        contractPartner.chosenAddress?.displayAddress || contractPartner.address?.displayAddress || '',
    );
    const [locationCandidate, setLocationCandidate] = useState(contractPartner?.chosenAddress ? {
        ...contractPartner.chosenAddress,
        latitude: contractPartner?.location?.latitude,
        longitude: contractPartner?.location?.longitude,
    } : null);
    const searchContractPartnerLocationGeolocationDebounced = useMemo(
        () => debounce(searchContractPartnerLocationGeolocation, 250),
        [searchContractPartnerLocationGeolocation],
    );

    const handleContractPartnerSearchQueryChange = searchQueryString => {
        if (!searchQueryString) {
            setContractPartnerSearchQuery('');
            resetContractPartnerLocationRecommendations({
                arcGISLocationCandidateDTOs: [],
            });
            return;
        }
        if (searchQueryString && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchContractPartnerLocationGeolocationDebounced({
                searchQueryString,
            });
        }
        setContractPartnerSearchQuery(searchQueryString);
    };

    const handleOnOptionSelect = locationCandidate => {
        if (!locationCandidate) return;
        setLocationCandidate({
            country: locationCandidate.country,
            city: locationCandidate.city,
            street: locationCandidate.street,
            postCode: locationCandidate.postCode,
            latitude: locationCandidate.latitude,
            longitude: locationCandidate.longitude,
        });
        const displayAddress = formatDisplayAddress(locationCandidate);
        setContractPartnerSearchQuery(displayAddress);
    };


    const handleOnSearchSubmit = () => {
        if (!locationCandidate) return;

        updateContractPartnerLocationFlow({
            contractPartnerId: contractPartner.id,
            contractPartnerData: {
                chosenAddress: locationCandidate,
                location: {
                    latitude: locationCandidate?.latitude,
                    longitude: locationCandidate?.longitude,
                },
            },
        });
    };

    const handleOnWorkingHoursChange = (value, dayOfWeek, fieldName) => {
        setWorkingHoursData(prevState => ({
            ...prevState,
            [dayOfWeek]: {
                ...prevState[dayOfWeek],
                [fieldName]: value,
            },
        }));
    };

    const handleOnToggleChange = (fieldName, dayOfWeek) => {
        setWorkingHoursData(prevState => ({
            ...prevState,
            [fieldName]: !prevState[fieldName],
            [dayOfWeek]: prevState[fieldName]
                ? null
                : {
                    ...prevState[dayOfWeek],
                    dayOfWeek,
                    from: '00:00',
                    to: '24:00',
                },
        }));
    };

    const displayWorkingHours = workingHoursDTO => {
        const fromString = workingHoursDTO?.from?.slice(0, -3) || '';
        const toString = workingHoursDTO?.to?.slice(0, -3) || '';
        return fromString && toString
            ? `${fromString}-${toString}`
            : translateTab('data_row_label.closed');
    };

    const validateWorkingHoursFormat = dayOfWeek => {
        const timeFormatRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
        const isStartTimeValid = workingHoursData[dayOfWeek]?.from
            ? workingHoursData[dayOfWeek].from === '24:00'
                ? true : timeFormatRegex.test(workingHoursData[dayOfWeek].from)
            : true;
        const isEndTimeValid = workingHoursData[dayOfWeek]?.to
            ? workingHoursData[dayOfWeek].to === '24:00'
                ? true : timeFormatRegex.test(workingHoursData[dayOfWeek].to)
            : true;
        return !isStartTimeValid || !isEndTimeValid;
    };

    const validateWorkingHoursRange = dayOfWeek => {
        switch (dayOfWeek) {
            case apmDayOfWeekTypes.MONDAY_TO_FRIDAY: {
                if (!workingHoursData.openMondayToFriday) return false;
                return workingHoursData[dayOfWeek]?.from > workingHoursData[dayOfWeek]?.to;
            }

            case apmDayOfWeekTypes.SATURDAY: {
                if (!workingHoursData.openOnSaturday) return false;
                return workingHoursData[dayOfWeek]?.from > workingHoursData[dayOfWeek]?.to;
            }

            case apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY: {
                if (!workingHoursData.openSundayAndHoliday) return false;
                return workingHoursData[dayOfWeek]?.from > workingHoursData[dayOfWeek]?.to;
            }

            default: {
                return false;
            }
        }
    };

    const toggleEditMode = () => {
        setIsEditModeActive(prevState => !prevState);
        setPhoneNumberErrorMessage('');
        setFaxErrorMessage('');
        setEmailErrorMessage('');
        setWorkingHoursData({
            [apmDayOfWeekTypes.MONDAY_TO_FRIDAY]: formatWorkingHours(contractPartner?.workingHours?.find(workingHoursDTO => { // eslint-disable-line max-len
                return workingHoursDTO?.dayOfWeek === apmDayOfWeekTypes.MONDAY_TO_FRIDAY;
            })),
            [apmDayOfWeekTypes.SATURDAY]: formatWorkingHours(contractPartner?.workingHours?.find(workingHoursDTO => {
                return workingHoursDTO?.dayOfWeek === apmDayOfWeekTypes.SATURDAY;
            })),
            [apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY]: formatWorkingHours(contractPartner?.workingHours?.find(workingHoursDTO => { // eslint-disable-line max-len
                return workingHoursDTO?.dayOfWeek === apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY;
            })),
            openMondayToFriday: areWorkingHoursExist(contractPartner?.workingHours?.find(workingHoursDTO => {
                return workingHoursDTO?.dayOfWeek === apmDayOfWeekTypes.MONDAY_TO_FRIDAY;
            })),
            openOnSaturday: areWorkingHoursExist(contractPartner?.workingHours?.find(workingHoursDTO => {
                return workingHoursDTO?.dayOfWeek === apmDayOfWeekTypes.SATURDAY;
            })),
            openSundayAndHoliday: areWorkingHoursExist(contractPartner?.workingHours?.find(workingHoursDTO => {
                return workingHoursDTO?.dayOfWeek === apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY;
            })),
        });
        setWorkingHoursErrors(prevState => ({
            ...prevState,
            ...initialWorkingHoursErrors,
        }));
        setContractPartnerSearchQuery(
            contractPartner.chosenAddress?.displayAddress || contractPartner.address?.displayAddress || '',
        );
        setLocationCandidate(contractPartner?.chosenAddress ? {
            ...contractPartner.chosenAddress,
            latitude: contractPartner?.location?.latitude,
            longitude: contractPartner?.location?.longitude,
        } : null);
    };

    const handleFormValidations = formValues => {
        let hasError = false;

        if (!validatePhoneOrFaxNumber(formValues.phoneNo)) {
            setPhoneNumberErrorMessage(translateTab('data_row_input.phone_fax_error'));
            hasError = true;
        } else if (phoneNumberErrorMessage) {
            setPhoneNumberErrorMessage('');
        }

        if (formValues.faxNo && !validatePhoneOrFaxNumber(formValues.faxNo)) {
            setFaxErrorMessage(translateTab('data_row_input.phone_fax_error'));
            hasError = true;
        } else if (faxErrorMessage) {
            setFaxErrorMessage('');
        }

        if (formValues.email && !validateEmail(formValues.email)) {
            setEmailErrorMessage(translateTab('data_row_input.email_error'));
            hasError = true;
        } else if (emailErrorMessage) {
            setEmailErrorMessage('');
        }

        const updatedWorkingHoursErrors = {...initialWorkingHoursErrors};
        Object.keys(updatedWorkingHoursErrors).forEach(dayOfWeek => {
            const hasInvalidFormat = validateWorkingHoursFormat(dayOfWeek);
            if (hasInvalidFormat) {
                hasError = true;
                updatedWorkingHoursErrors[dayOfWeek] = translateTab('data_row_input.invalid_time_format_error');
                return;
            }
            const hasRangeError = validateWorkingHoursRange(dayOfWeek);
            if (hasRangeError) {
                hasError = true;
                updatedWorkingHoursErrors[dayOfWeek] = translateTab('data_row_input.general_reachability_error');
            }
        });

        setWorkingHoursErrors(prevState => ({
            ...prevState,
            ...updatedWorkingHoursErrors,
        }));

        return hasError;
    };

    const handleOnSubmit = formValues => {
        const hasError = handleFormValidations(formValues);
        if (hasError) return;

        const workingHours = Object.keys(workingHoursData)
            .filter(key => {
                return [
                    apmDayOfWeekTypes.MONDAY_TO_FRIDAY,
                    apmDayOfWeekTypes.SATURDAY,
                    apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY,
                ].includes(key);
            }).map(dayOfWeek => {
                if (!workingHoursData[dayOfWeek]) {
                    return {
                        dayOfWeek,
                        from: '',
                        to: '',
                    };
                }

                return {
                    dayOfWeek,
                    from: workingHoursData[dayOfWeek]?.from || '',
                    to: workingHoursData[dayOfWeek]?.to === '24:00'
                        ? '00:00' : workingHoursData[dayOfWeek]?.to || '',
                };
            });

        const businessContactDetails = {
            phoneNo: formValues.phoneNo,
            faxNo: formValues.faxNo,
            email: formValues.email,
            websiteUrl: formValues.websiteUrl,
        };
        submitCPContactDetailsForm({
            contractPartnerId: contractPartner.id,
            contractPartnerData: {
                businessContactDetails,
                workingHours,
            },
        });
        toggleEditMode();
    };

    const websiteUrl = useMemo(() => {
        if (!contractPartner?.businessContactDetails?.websiteUrl) return '';
        try {
            return new URL(contractPartner.businessContactDetails.websiteUrl).hostname;
        } catch (err) {
            return contractPartner?.businessContactDetails?.websiteUrl;
        }
    }, [contractPartner?.businessContactDetails?.websiteUrl]);
    const isCPLocationSaved = !!contractPartner?.chosenAddress && !!locationCandidate
        && contractPartner?.chosenAddress?.displayAddress === formatDisplayAddress(locationCandidate);
    const isSubmitCoordinatesButtonDisabled = !locationCandidate || (locationCandidate
        && formatDisplayAddress(locationCandidate) !== contractPartnerSearchQuery);

    return (
        <Panel
            title={translateTab('panel_title.address_data')}
            actions={(
                <InteractiveIcon
                    icon={!isEditModeActive ? editIcon : closeIcon}
                    onClick={toggleEditMode}
                />
            )}
            qaIdent="cp-address"
        >
            <Form name="basicDataAddressForm" onSubmit={handleOnSubmit}>
                <div className={cx('global!ace-u-two-column-layout')}>
                    <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--direction-column'])}>
                        <DataRow
                            label={translateTab('data_row_label.name')}
                            className={cx('global!ace-u-flex--basis-30')}
                            contentClassName={cx('global!ace-u-flex--align-self-flex-start')}
                            qaIdent="cp-name"
                        >
                            {contractPartner.name}
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.street')}
                            qaIdent="cp-address-street"
                        >
                            {contractPartner.address.street}
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.post_code')}
                            qaIdent="cp-address-post-code"
                        >
                            {contractPartner.address.postCode}
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.city')}
                            qaIdent="cp-address-city"
                        >
                            {contractPartner.address.city}
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.state')}
                            qaIdent="cp-address-state"
                        >
                            {contractPartner.address.state}
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.country')}
                            qaIdent="cp-address-country"
                        >
                            {contractPartner.address.country && europeanCountries[contractPartner.address.country]
                                ? translate(`global.country.${snakeCase(europeanCountries[contractPartner.address.country].name)}`)
                                : ''}
                        </DataRow>
                        {!isEditModeActive
                            && (
                                <Fragment>
                                    <DataRow
                                        label={translateTab('data_row_label.longitude')}
                                        qaIdent="cp-address-longitude"
                                    >
                                        {contractPartner?.location?.longitude}
                                    </DataRow>
                                    <DataRow
                                        label={translateTab('data_row_label.latitude')}
                                        qaIdent="cp-address-latitude"
                                    >
                                        {contractPartner?.location?.latitude}
                                    </DataRow>
                                </Fragment>
                            )
                        }
                    </div>
                    {!isEditModeActive ? (
                        <div>
                            <DataRow
                                label={translateTab('data_row_label.phone_no')}
                                qaIdent="cp-contact-details-phone-number"
                            >
                                <span className={cx('global!ace-u-typography--color-highlighted')}>
                                    {contractPartner.businessContactDetails?.phoneNo || '-'}
                                </span>
                            </DataRow>
                            <DataRow
                                label={translateTab('data_row_label.fax_no')}
                                qaIdent="cp-contact-details-fax-number"
                            >
                                <span className={cx('global!ace-u-typography--color-highlighted')}>
                                    {contractPartner.businessContactDetails?.faxNo || '-'}
                                </span>
                            </DataRow>
                            <DataRow
                                label={translateTab('data_row_label.email')}
                                qaIdent="cp-contact-details-email"
                            >
                                {contractPartner.businessContactDetails?.email ? (
                                    <a
                                        href={`mailto:${contractPartner.businessContactDetails?.email}`}
                                        className={cx([
                                            'global!ace-u-typography--color-highlighted',
                                            'global!ace-u-typography--variant-body',
                                        ])}
                                    >
                                        {contractPartner.businessContactDetails?.email}
                                    </a>
                                ) : '-'}
                            </DataRow>
                            <DataRow
                                label={translateTab('data_row_label.website')}
                                qaIdent="cp-contact-details-website"
                            >
                                {contractPartner.businessContactDetails?.websiteUrl ? (
                                    <a
                                        href={contractPartner.businessContactDetails?.websiteUrl}
                                        target="_blank"
                                        className={cx([
                                            'global!ace-u-typography--color-highlighted',
                                            'global!ace-u-typography--variant-body',
                                        ])}
                                        rel="noreferrer"
                                    >
                                        {websiteUrl}
                                    </a>
                                ) : '-'}
                            </DataRow>
                            <DataRow
                                label={translateTab('data_row_label.general_reachability')}
                                className={cx('ace-c-data-row--full-bleed-content')}
                                contentClassName={cx([
                                    'global!ace-u-flex--direction-column',
                                    'global!ace-u-flex--align-items-stretch',
                                ])}
                                qaIdent="cp-reachability"
                            >
                                {(contractPartner?.workingHours || []).map(workingHoursDTO => (
                                    <div key={workingHoursDTO.dayOfWeek}>
                                        <div
                                            className={cx([
                                                'global!ace-u-padding--16',
                                                'global!ace-u-flex',
                                            ])}
                                        >
                                            <span
                                                className={cx([
                                                    'global!ace-u-flex--basis-30',
                                                    'global!ace-u-typography--variant-body-medium',
                                                ])}
                                            >
                                                {translateTab(`data_row_label.${snakeCase(workingHoursDTO.dayOfWeek)}`)}:
                                            </span>
                                            <div>{displayWorkingHours(workingHoursDTO)}</div>
                                        </div>
                                        <Divider />
                                    </div>
                                ))}
                            </DataRow>
                        </div>
                    ) : (
                        <div>
                            <DataRow
                                label={translateTab('data_row_label.phone_no')}
                                qaIdent="cp-contact-details-phone-number"
                                className={cx('ace-c-data-row--condensed')}
                                isFieldRequired={true}
                            >
                                <InputField
                                    name="phoneNo"
                                    value={contractPartner.businessContactDetails?.phoneNo || ''}
                                    className={cx(['ace-c-input--small', 'global!ace-u-width--full'])}
                                    errors={phoneNumberErrorMessage ? [phoneNumberErrorMessage] : []}
                                />
                            </DataRow>
                            <DataRow
                                label={translateTab('data_row_label.fax_no')}
                                qaIdent="cp-contact-details-fax-number"
                            >
                                <InputField
                                    name="faxNo"
                                    value={contractPartner.businessContactDetails?.faxNo || ''}
                                    className={cx(['ace-c-input--small', 'global!ace-u-width--full'])}
                                    errors={faxErrorMessage ? [faxErrorMessage] : []}
                                />
                            </DataRow>
                            <DataRow
                                label={translateTab('data_row_label.email')}
                                qaIdent="cp-contact-details-email"
                            >
                                <InputField
                                    name="email"
                                    value={contractPartner.businessContactDetails?.email || ''}
                                    className={cx(['ace-c-input--small', 'global!ace-u-width--full'])}
                                    errors={emailErrorMessage ? [emailErrorMessage] : []}
                                />
                            </DataRow>
                            <DataRow
                                label={translateTab('data_row_label.website')}
                                qaIdent="cp-contact-details-website"
                            >
                                <Input
                                    name="websiteUrl"
                                    value={websiteUrl}
                                    className={cx(['ace-c-input--small', 'global!ace-u-width--full'])}
                                />
                            </DataRow>
                            <DataRow
                                label={translateTab('data_row_label.general_reachability')}
                                className={cx('ace-c-data-row--full-bleed-content')}
                                qaIdent="cp-reachability"
                            />
                            <DataRow
                                label={translateTab('data_row_label.monday_to_friday')}
                            >
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                    ])}
                                >
                                    <ToggleSwitch
                                        name="openMondayToFriday"
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--justify-space-between',
                                            'global!ace-u-margin--left-16',
                                            'global!ace-u-margin--right-16',
                                        ])}
                                        value={true}
                                        isSelected={!!workingHoursData.openMondayToFriday}
                                        onChange={() => handleOnToggleChange(
                                            'openMondayToFriday',
                                            apmDayOfWeekTypes.MONDAY_TO_FRIDAY,
                                        )}
                                    />
                                    <Form name={`${apmDayOfWeekTypes.MONDAY_TO_FRIDAY}`}>
                                        <TimeField
                                            name="from"
                                            value={workingHoursData[apmDayOfWeekTypes.MONDAY_TO_FRIDAY]?.from || ''}
                                            icon={timeIcon}
                                            className={cx(['ace-c-input-time--small'])}
                                            inputClassName={cx([
                                                'global!ace-u-width--full',
                                                'global!ace-u-typography--align-center',
                                            ])}
                                            customTimeOptions={generateCustomTimeFieldOptions()}
                                            isDisabled={!workingHoursData.openMondayToFriday}
                                            onChange={value => handleOnWorkingHoursChange(value, apmDayOfWeekTypes.MONDAY_TO_FRIDAY, 'from')}
                                            errors={workingHoursErrors[apmDayOfWeekTypes.MONDAY_TO_FRIDAY]
                                                ? [workingHoursErrors[apmDayOfWeekTypes.MONDAY_TO_FRIDAY]] : []}
                                        />
                                        <span
                                            className={cx([
                                                'global!ace-u-flex--basis-30',
                                                'global!ace-u-typography--align-center',
                                            ])}
                                        >–
                                        </span>
                                        <TimeField
                                            name="to"
                                            value={workingHoursData[apmDayOfWeekTypes.MONDAY_TO_FRIDAY]?.to || ''}
                                            icon={timeIcon}
                                            className={cx(['ace-c-input-time--small', 'global!ace-u-flex--grow-1'])}
                                            inputClassName={cx([
                                                'global!ace-u-width--full',
                                                'global!ace-u-typography--align-center',
                                            ])}
                                            customTimeOptions={generateCustomTimeFieldOptions()}
                                            isDisabled={!workingHoursData.openMondayToFriday}
                                            errors={workingHoursErrors[apmDayOfWeekTypes.MONDAY_TO_FRIDAY]
                                                ? [workingHoursErrors[apmDayOfWeekTypes.MONDAY_TO_FRIDAY]] : []}
                                            onChange={value => handleOnWorkingHoursChange(value, apmDayOfWeekTypes.MONDAY_TO_FRIDAY, 'to')}
                                        />
                                    </Form>
                                </div>
                            </DataRow>
                            <DataRow label={translateTab('data_row_label.saturday')}>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                    ])}
                                >
                                    <ToggleSwitch
                                        name="openOnSaturday"
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--justify-space-between',
                                            'global!ace-u-margin--left-16',
                                            'global!ace-u-margin--right-16',
                                        ])}
                                        value={true}
                                        isSelected={!!workingHoursData.openOnSaturday}
                                        onChange={() => handleOnToggleChange(
                                            'openOnSaturday',
                                            apmDayOfWeekTypes.SATURDAY,
                                        )}
                                    />
                                    <Form name={`${apmDayOfWeekTypes.SATURDAY}`}>
                                        <TimeField
                                            name="from"
                                            value={workingHoursData[apmDayOfWeekTypes.SATURDAY]?.from || ''}
                                            icon={timeIcon}
                                            className={cx(['ace-c-input-time--small'])}
                                            inputClassName={cx([
                                                'global!ace-u-width--full',
                                                'global!ace-u-typography--align-center',
                                            ])}
                                            customTimeOptions={generateCustomTimeFieldOptions()}
                                            isDisabled={!workingHoursData.openOnSaturday}
                                            errors={workingHoursErrors[apmDayOfWeekTypes.SATURDAY]
                                                ? [workingHoursErrors[apmDayOfWeekTypes.SATURDAY]] : []}
                                            onChange={value => handleOnWorkingHoursChange(value, apmDayOfWeekTypes.SATURDAY, 'from')}
                                        />
                                        <span
                                            className={cx([
                                                'global!ace-u-flex--basis-30',
                                                'global!ace-u-typography--align-center',
                                            ])}
                                        >–
                                        </span>
                                        <TimeField
                                            name="to"
                                            value={workingHoursData[apmDayOfWeekTypes.SATURDAY]?.to || ''}
                                            icon={timeIcon}
                                            className={cx(['ace-c-input-time--small', 'global!ace-u-flex--grow-1'])}
                                            inputClassName={cx([
                                                'global!ace-u-width--full',
                                                'global!ace-u-typography--align-center',
                                            ])}
                                            customTimeOptions={generateCustomTimeFieldOptions()}
                                            isDisabled={!workingHoursData.openOnSaturday}
                                            errors={workingHoursErrors[apmDayOfWeekTypes.SATURDAY]
                                                ? [workingHoursErrors[apmDayOfWeekTypes.SATURDAY]] : []}
                                            onChange={value => handleOnWorkingHoursChange(value, apmDayOfWeekTypes.SATURDAY, 'to')}
                                        />
                                    </Form>
                                </div>
                            </DataRow>
                            <DataRow label={translateTab('data_row_label.sunday_and_holiday')}>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                    ])}
                                >
                                    <ToggleSwitch
                                        name="openSundayAndHoliday"
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--justify-space-between',
                                            'global!ace-u-margin--left-16',
                                            'global!ace-u-margin--right-16',
                                        ])}
                                        value={true}
                                        isSelected={!!workingHoursData.openSundayAndHoliday}
                                        onChange={() => handleOnToggleChange(
                                            'openSundayAndHoliday',
                                            apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY,
                                        )}
                                    />
                                    <Form name="SUNDAY_AND_HOLIDAY">
                                        <TimeField
                                            name="from"
                                            value={workingHoursData[apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY]?.from || ''}
                                            icon={timeIcon}
                                            className={cx(['ace-c-input-time--small'])}
                                            inputClassName={cx([
                                                'global!ace-u-width--full',
                                                'global!ace-u-typography--align-center',
                                            ])}
                                            customTimeOptions={generateCustomTimeFieldOptions()}
                                            isDisabled={!workingHoursData.openSundayAndHoliday}
                                            errors={workingHoursErrors[apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY]
                                                ? [workingHoursErrors[apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY]] : []} // eslint-disable-line max-len
                                            onChange={value => handleOnWorkingHoursChange(value, apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY, 'from')}
                                        />
                                        <span
                                            className={cx([
                                                'global!ace-u-flex--basis-30',
                                                'global!ace-u-typography--align-center',
                                            ])}
                                        >–
                                        </span>
                                        <TimeField
                                            name="to"
                                            value={workingHoursData[apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY]?.to || ''}
                                            icon={timeIcon}
                                            className={cx(['ace-c-input-time--small', 'global!ace-u-flex--grow-1'])}
                                            inputClassName={cx([
                                                'global!ace-u-width--full',
                                                'global!ace-u-typography--align-center',
                                            ])}
                                            customTimeOptions={generateCustomTimeFieldOptions()}
                                            isDisabled={!workingHoursData.openSundayAndHoliday}
                                            errors={workingHoursErrors[apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY]
                                                ? [workingHoursErrors[apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY]] : []} // eslint-disable-line max-len
                                            onChange={value => handleOnWorkingHoursChange(value, apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY, 'to')}
                                        />
                                    </Form>
                                </div>
                            </DataRow>
                        </div>
                    )}
                </div>
                <div
                    className={cx([
                        'global!ace-u-margin--left-256',
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-flex--align-items-flex-end',
                        'global!ace-u-margin--top-8',
                    ])}
                >
                    {isEditModeActive && (
                        <ButtonPrimary
                            name="submitCPAddressData"
                            type="submit"
                            className={cx('global!ace-u-margin--top-32')}
                        >
                            {translateTab('button_label.save_changes')}
                        </ButtonPrimary>
                    )}
                </div>
                {!!isEditModeActive && (
                    <Fragment>
                        <h3
                            className={cx([
                                'global!ace-u-typography--variant-h3',
                                'global!ace-u-margin--24-0',
                            ])}
                        >
                            {translateTab('content_item_header.localize_location')}
                        </h3>
                        <div className={cx('global!ace-u-flex')}>
                            <Autosuggest
                                name="contractPartnerGeolocation"
                                value={contractPartnerSearchQuery}
                                onChange={handleContractPartnerSearchQueryChange}
                                onOptionSelect={handleOnOptionSelect}
                                optionValueSelector={locationCandidate => {
                                    return formatDisplayAddress(locationCandidate);
                                }}
                                icon={geocodingIcon}
                                className={cx('global!ace-u-flex--grow-1')}
                            >
                                {!!contractPartnerLocationRecommendations.length && (
                                    contractPartnerLocationRecommendations.map((locationCandidate, index) => (
                                        <Option
                                            key={`${locationCandidate.locationId}-${index}`}
                                            value={locationCandidate}
                                            name={`${locationCandidate.locationId}-${index}`}
                                        >
                                            {locationCandidate.formattedAddress}
                                        </Option>
                                    ))
                                )}
                            </Autosuggest>
                            <ButtonPrimary
                                name="submitContractPartnerGeolocation"
                                onClick={handleOnSearchSubmit}
                                isDisabled={isSubmitCoordinatesButtonDisabled}
                                className={cx('global!ace-u-margin--left-32', {
                                    'ace-c-button-primary--is-positive': isCPLocationSaved && !isSubmitCoordinatesButtonDisabled,
                                })}
                            >
                                {isCPLocationSaved && !isSubmitCoordinatesButtonDisabled ? (
                                    <Fragment>
                                        {translateTab('button_label.coordinates_confirmed')}
                                        <Icon
                                            icon={checkmarkIcon}
                                            className={cx(
                                                'ace-c-icon--color-contrast',
                                                'global!ace-u-margin--left-16',
                                            )}
                                        />
                                    </Fragment>
                                ) : translateTab('button_label.confirm_coordinates')}
                            </ButtonPrimary>
                        </div>
                        {isCPLocationSaved && !isSubmitCoordinatesButtonDisabled && (
                            <div className={cx('global!ace-u-two-column-layout', 'global!ace-u-margin--top-24')}>
                                <div>
                                    <DataRow
                                        label={translateTab('data_row_label.longitude')}
                                        qaIdent="cp-contact-details-longitude"
                                    >
                                        {contractPartner?.location?.longitude || ''}
                                    </DataRow>
                                </div>
                                <div>
                                    <DataRow
                                        label={translateTab('data_row_label.latitude')}
                                        qaIdent="cp-contact-details-latitude"
                                    >
                                        {contractPartner?.location?.latitude || ''}
                                    </DataRow>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
            </Form>
        </Panel>
    );
};

ContractPartnerAddressDataPanel.propTypes = {
    contractPartner: PropTypes.object,
    submitCPContactDetailsForm: PropTypes.func.isRequired,
    updateContractPartnerLocationFlow: PropTypes.func.isRequired,
    searchContractPartnerLocationGeolocation: PropTypes.func.isRequired,
    contractPartnerLocationRecommendations: PropTypes.array,
    resetContractPartnerLocationRecommendations: PropTypes.func.isRequired,
};

ContractPartnerAddressDataPanel.defaultProps = {
    contractPartner: null,
    contractPartnerLocationRecommendations: [],
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
        contractPartnerLocationRecommendations: contractPartnerSelectors.getContractPartnerLocationRecommendations(state), // eslint-disable-line max-len
    };
};

const mapDispatchToProps = dispatch => ({
    submitCPContactDetailsForm: payload => dispatch({
        type: contractPartnerActionTypes.SUBMIT_CP_CONTACT_DETAILS_FORM,
        payload,
    }),
    updateContractPartnerLocationFlow: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_UPDATE_CP_LOCATION_DATA,
        payload,
    }),
    searchContractPartnerLocationGeolocation: payload => dispatch({
        type: contractPartnerActionTypes.SEARCH_CP_LOCATION_GEOLOCATION,
        payload,
    }),
    resetContractPartnerLocationRecommendations: payload => dispatch({
        type: contractPartnerActionTypes.SET_CP_LOCATION_RECOMMENDATIONS,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerAddressDataPanel));
