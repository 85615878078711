import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {resolveRoute, withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, ButtonPrimary, Option, Autocomplete, NoResultsBlock} from '@ace-de/ui-components';
import {editIcon, expandIcon, Icon, InteractiveIcon, closeIcon, deleteIcon, findCaseIcon, infoAlertIcon} from '@ace-de/ui-components/icons';
import {Table, TableBody, TableCell, TableHead, TableRow, TableCaption} from '@ace-de/ui-components/data-elements';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import routePaths from '../../routePaths';
import config from '../../config';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

const ContractPartnerRelationsPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_basic_data_tab');
    const {contractPartner, contractPartnerRelations, history, initiateUpdateCPRelationsFlow} = props;
    const [isEditModeActive, setIsEditModeActive] = useState(false);
    const [formData, setFormData] = useState(contractPartner.affiliatePartners);
    const [affiliatePartnersIds, setAffiliatePartnersIds] = useState(
        [...Array(config.RELATIONS_NUMBER_OF_ROWS)].map((value, index) => {
            return contractPartner.affiliatePartners[index]?.id || '';
        }),
    );
    const [error, setError] = useState('');

    const sortedContractPartnerRelations = useMemo(() => {
        return [...contractPartnerRelations].sort((contractPartnerA, contractPartnerB) => {
            return contractPartnerA.id < contractPartnerB.id ? -1 : 1;
        });
    }, [contractPartnerRelations]);

    const openContractPartnerScreen = contractPartnerId => {
        if (!contractPartnerId) return null;
        history.push(resolveRoute(routePaths.CONTRACT_PARTNER, {
            contractPartnerId: contractPartnerId,
        }));
    };

    const toggleEditMode = () => {
        setIsEditModeActive(prevState => !prevState);
        setFormData(contractPartner.affiliatePartners);
        setAffiliatePartnersIds(
            [...Array(config.RELATIONS_NUMBER_OF_ROWS)].map((value, index) => {
                return contractPartner.affiliatePartners[index]?.id || '';
            }),
        );
        setError('');
    };

    const validateAffiliatePartnerIds = () => {
        const filteredPartnerIds = affiliatePartnersIds.filter(partnerId => !!partnerId);
        const uniquePartnerIds = filteredPartnerIds
            .filter((partnerId, index) => affiliatePartnersIds.indexOf(partnerId) === index);
        if (uniquePartnerIds.length !== filteredPartnerIds.length) {
            return translateTab('relations_panel_error_message.already_selected');
        }

        let invalidDataErrorMessage = '';

        affiliatePartnersIds.forEach(partnerId => {
            if (partnerId && !formData.find(partner => partner?.id === partnerId)) {
                const isPartnerIdValid = !!contractPartnerRelations?.find(partner => partner.id === partnerId);
                invalidDataErrorMessage = isPartnerIdValid
                    ? translateTab('relations_panel_error_message.select_partner_id')
                    : translateTab('relations_panel_error_message.invalid_partner_id');
                return;
            }
        });
        formData.forEach(partner => {
            if (partner?.id && !affiliatePartnersIds.find(id => id === partner.id)) {
                invalidDataErrorMessage = translateTab('relations_panel_error_message.select_partner_id');
                return;
            }
        });
        return invalidDataErrorMessage;
    };

    const onChange = (value, index) => {
        if (error) {
            setError('');
        }
        const updatedAffiliatePartnerIds = [...affiliatePartnersIds];
        updatedAffiliatePartnerIds[index] = value;
        setAffiliatePartnersIds(updatedAffiliatePartnerIds);
    };

    const onSelectContractPartnerId = (contractPartnerId, index) => {
        const newRelation = contractPartnerRelations.find(contractPartner => contractPartner.id === contractPartnerId);
        setFormData(prevState => {
            const updatedState = [...prevState];
            updatedState[index] = {...newRelation};
            return updatedState;
        });
    };

    const handleOnDelete = contractPartnerId => {
        if (!contractPartnerId) return;
        const newData = formData.filter(data => data?.id !== contractPartnerId);
        setFormData(newData);

        setAffiliatePartnersIds(
            [...Array(config.RELATIONS_NUMBER_OF_ROWS)].map((value, index) => {
                return newData[index]?.id || '';
            }),
        );
    };

    const onSubmit = () => {
        if (!formData) return;

        const errorMessage = validateAffiliatePartnerIds();
        if (errorMessage !== '') {
            setError(errorMessage);
            return;
        }

        initiateUpdateCPRelationsFlow({
            contractPartnerId: contractPartner.id,
            contractPartnerData: {
                affiliatePartners: formData.filter(data => !!data),
            },
        });
        setIsEditModeActive(false);
    };

    return (
        <Panel
            title={translateTab('panel_title.branches')}
            actions={(
                contractPartner?.affiliatedPartner === null && (
                    <InteractiveIcon
                        icon={!isEditModeActive ? editIcon : closeIcon}
                        onClick={toggleEditMode}
                    />
                )
            )}
        >
            <Table>
                {!contractPartner?.affiliatePartners?.length && !contractPartner?.affiliatedPartner
                    && !isEditModeActive && (
                        <TableCaption>
                            <NoResultsBlock
                                icon={(
                                    <Icon
                                        className={cx('ace-c-icon--xxl')}
                                        icon={findCaseIcon}
                                    />
                                )}
                                message={translateTab('branches_data_no_results.message')}
                                description={translateTab('branches_data_no_results.description')}
                            />
                        </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={3}>{translateTab('branches_data_table_cell.vendor_number')}</TableCell>
                        <TableCell colSpan={4}>{translateTab('branches_data_table_cell.name')}</TableCell>
                        <TableCell colSpan={4}>{translateTab('branches_data_table_cell.address')}</TableCell>
                        <TableCell colSpan={1} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {contractPartner?.affiliatedPartner && (
                        <TableRow className={cx('ace-c-table__row--highlighted')}>
                            <TableCell colSpan={3}>
                                {contractPartner.affiliatedPartner.id || ''}
                            </TableCell>
                            <TableCell colSpan={4}>
                                {contractPartner.affiliatedPartner.name || ''}
                            </TableCell>
                            <TableCell colSpan={4} />
                            <TableCell colSpan={1} />
                        </TableRow>
                    )}
                    {!isEditModeActive ? (
                        !!contractPartner?.affiliatePartners?.length
                        && contractPartner.affiliatePartners.map(affiliatePartner => (
                            <TableRow key={affiliatePartner.id}>
                                <TableCell colSpan={3}>{affiliatePartner.id}</TableCell>
                                <TableCell colSpan={4}>{affiliatePartner.name || ''}</TableCell>
                                <TableCell colSpan={4}>
                                    {[
                                        affiliatePartner.address?.street,
                                        affiliatePartner.address?.postCode,
                                        affiliatePartner.address?.city,
                                    ].filter(value => !!value).join(', ')}
                                </TableCell>
                                <TableCell colSpan={1}>
                                    <Icon
                                        icon={expandIcon}
                                        className={cx([
                                            'ace-c-icon--color-highlight',
                                            'global!ace-u-margin--right-8',
                                        ])}
                                        onClick={() => openContractPartnerScreen(affiliatePartner.id)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        [...Array(config.RELATIONS_NUMBER_OF_ROWS)].map((elementInArray, index) => (
                            <TableRow key={index}>
                                <TableCell colSpan={3}>
                                    <Autocomplete
                                        name={`id-${index}`}
                                        value={affiliatePartnersIds[index] || ''}
                                        className={cx(['ace-c-input--small', 'global!ace-u-width--full'])}
                                        onChange={value => onChange(value, index)}
                                        onOptionSelect={value => onSelectContractPartnerId(value, index)}
                                        isDisabled={!!contractPartner.affiliatePartners
                                            .find(data => formData[index]?.id === data?.id)}
                                    >
                                        {sortedContractPartnerRelations.map(contractPartner => (
                                            <Option
                                                key={`id-${contractPartner.id}`}
                                                name={`id-${contractPartner.id}`}
                                                value={contractPartner.id}
                                                isDisabled={!!formData?.find(data => {
                                                    return contractPartner.id === data?.id;
                                                })}
                                            >
                                                {contractPartner.id}
                                            </Option>
                                        ))}
                                    </Autocomplete>
                                </TableCell>
                                <TableCell colSpan={4}>
                                    {formData[index]?.name || ''}
                                </TableCell>
                                <TableCell colSpan={4}>
                                    {formData[index]
                                        ? [
                                            formData[index].address?.street,
                                            formData[index].address?.postCode,
                                            formData[index].address?.city,
                                        ].filter(value => !!value).join(', ') : ''}
                                </TableCell>
                                <TableCell colSpan={1}>
                                    {formData[index]?.id ? (
                                        <Icon
                                            icon={deleteIcon}
                                            className={cx([
                                                'ace-c-icon--color-highlight',
                                                'global!ace-u-margin--right-8',
                                            ])}
                                            onClick={() => handleOnDelete(formData[index]?.id)}
                                        />
                                    ) : (
                                        <Icon
                                            icon={deleteIcon}
                                            className={cx([
                                                'ace-c-icon--color-disabled',
                                                'global!ace-u-margin--right-8',
                                            ])}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
            {isEditModeActive && (
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-flex--align-items-flex-end',
                        'global!ace-u-margin--top-32',
                    ])}
                >
                    {!!error && (
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-margin--bottom-8',
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-typography--color-warning',
                            ])}
                        >
                            <Icon
                                icon={infoAlertIcon}
                                className={cx([
                                    'global!ace-u-margin--right-4',
                                    'ace-c-icon--s',
                                    'ace-c-icon--color-warning',
                                    'global!ace-u-flex--shrink-0',
                                ])}
                            />
                            {error}
                        </div>
                    )}
                    <ButtonPrimary
                        name="submitVendorNumber"
                        onClick={onSubmit}
                    >
                        {translateTab('button_label.save_changes')}
                    </ButtonPrimary>
                </div>
            )}
        </Panel>
    );
};

ContractPartnerRelationsPanel.propTypes = {
    contractPartner: PropTypes.object,
    history: PropTypes.object.isRequired,
    contractPartnerRelations: PropTypes.array,
    initiateUpdateCPRelationsFlow: PropTypes.func.isRequired,
};

ContractPartnerRelationsPanel.defaultProps = {
    contractPartner: null,
    contractPartnerRelations: [],
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
        contractPartnerRelations: contractPartnerSelectors.getContractPartnerRelations(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateUpdateCPRelationsFlow: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CP_RELATIONS_UPDATE_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerRelationsPanel));
