import {cancel, fork, take} from 'redux-saga/effects';
import {LOCATION_CHANGE} from '@computerrock/formation-router';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import onContractPartnerMapSelectOperationArea from './onContractPartnerMapSelectOperationArea';
import onCPOperationAreaMapFeatureHover from './onCPOperationAreaMapFeatureHover';
import contractPartnerScreenTabs from '../contractPartnerScreenTabs';

const cpOperationAreaMapWatcher = function* cpOperationAreaMapWatcher() {
    mainFlow: while (true) {
        yield take(contractPartnerActionTypes.START_MAP_CP_OPERATION_AREA_WATCHER);
        const onContractPartnerMapSelectOperationAreaTask = yield fork(onContractPartnerMapSelectOperationArea);
        const onCPOperationAreaMapFeatureHoverTask = yield fork(onCPOperationAreaMapFeatureHover);

        while (true) {
            const watcherTerminationAction = yield take([
                contractPartnerActionTypes.STOP_MAP_CP_OPERATION_AREA_WATCHER,
                LOCATION_CHANGE,
            ]);

            if (watcherTerminationAction.type === contractPartnerActionTypes.STOP_MAP_CP_OPERATION_AREA_WATCHER) {
                yield cancel(onContractPartnerMapSelectOperationAreaTask);
                yield cancel(onCPOperationAreaMapFeatureHoverTask);
                continue mainFlow;
            }

            const {activeTab} = yield* selectSearchQueryParams();

            if (activeTab !== contractPartnerScreenTabs.OPERATION_AREA) {
                yield cancel(onContractPartnerMapSelectOperationAreaTask);
                yield cancel(onCPOperationAreaMapFeatureHoverTask);
                continue mainFlow;
            }
        }
    }
};

export default cpOperationAreaMapWatcher;
