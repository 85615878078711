import {select, call} from 'redux-saga/effects';
import {euaFeatureTypes, euaFeatureEntityTypes, euaMarkerTypes} from '@ace-de/eua-arcgis-feature-types';
import config from '../../config';
import contractPartnerInfoPopupTemplate from '../contractPartnerInfoPopupTemplate';
import contractPartnerLabelConfig from '../contractPartnerLabelConfig';

const loadContractPartnerMapLayers = function* loadContractPartnerLayers() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'vpm-contract-partners');

    if (!arcGISMap.isLayerSet('vpm-contract-partner-service-areas')) {
        arcGISMap.createServiceLayer({
            layerId: 'vpm-contract-partner-service-areas',
            serviceURL: config.ARCGIS_CONTRACT_PARTNER_SERVICE_AREAS_LAYER_URL,
            featureEntityType: euaFeatureEntityTypes.CONTRACT_PARTNER_SERVICE_AREA,
            featureType: euaFeatureTypes.SELECTABLE_SERVICE_AREA,
            isVisible: false,
            areFeaturesSelectableOnClick: false,
        });
    }

    if (!arcGISMap.isLayerSet('vpm-contract-partner-locations')) {
        arcGISMap.createServiceLayer({
            layerId: 'vpm-contract-partner-locations',
            serviceURL: config.ARCGIS_CONTRACT_PARTNER_LOCATIONS_LAYER_URL,
            featureEntityType: euaFeatureEntityTypes.CONTRACT_PARTNER,
            featureType: euaFeatureTypes.SELECTABLE_LOCATION_MARKER,
            markerType: euaMarkerTypes.CONTRACT_PARTNER,
            renderByStatus: true,
            isVisible: false,
            areFeaturesSelectableOnClick: true,
            popupTemplate: contractPartnerInfoPopupTemplate,
            outFields: ['*'],
            labels: [contractPartnerLabelConfig],
        });
    }
};

export default loadContractPartnerMapLayers;
