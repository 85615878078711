import {put, select, take, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as qualityManagementFeedbackActionTypes from '../qualityManagementFeedbackActionTypes';

const filterQMFeedbackCaseLogs = function* filterQMFeedbackCaseLogs({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const ecsHistoryManagementService = serviceManager.loadService('ecsHistoryManagementService');
    const {searchQueryParams, serviceCaseId} = payload;

    yield fork(fetchRequest,
        qualityManagementFeedbackActionTypes.FILTER_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOGS_REQUEST,
        ecsHistoryManagementService.filterCaseLogs, {
            serviceCaseId,
            searchQueryParams,
        });

    const filterCaseLogsResponseAction = yield take([
        qualityManagementFeedbackActionTypes.FILTER_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOGS_REQUEST_SUCCEEDED,
        qualityManagementFeedbackActionTypes.FILTER_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOGS_REQUEST_FAILED,
    ]);

    if (!filterCaseLogsResponseAction.error) {
        const {response} = filterCaseLogsResponseAction.payload;
        const {
            caseLogFilterResults,
            totalCount: caseLogFilterResultsCount,
            pageNumber,
        } = response;

        yield put({
            type: qualityManagementFeedbackActionTypes.STORE_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOGS,
            payload: {caseLogFilterResults, caseLogFilterResultsCount, pageNumber},
        });
    }
};

export default filterQMFeedbackCaseLogs;
