import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import * as contractPartnerSelectors from './contractPartnerSelectors';

const ContractPartnerELOTab = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_elo_tab');
    const {contractPartner} = props;

    // if no contract partner, don't render
    if (!contractPartner) return null;

    return (
        <ContentBlock
            className={cx([
                'global!ace-u-height--full',
                'global!ace-u-max-height--full',
            ])}
        >
            <ContentItem
                className={cx([
                    'ace-c-content-item--span-12',
                    'global!ace-u-flex',
                ])}
            >
                {contractPartner?.eloPath ? (
                    <iframe
                        className={cx(['global!ace-u-full-width', 'global!ace-u-height--full'])}
                        src={contractPartner.eloPath}
                        title={`ELLO iframe-${contractPartner.id}`}
                    />
                ) : (
                    <div
                        className={cx([
                            'global!ace-u-full-width',
                            'global!ace-u-flex--align-self-center',
                            'global!ace-u-typography--align-center',
                        ])}
                    >
                        {translateTab('error_message.cannot_load_elo_url')}
                    </div>
                )}
            </ContentItem>
        </ContentBlock>
    );
};

ContractPartnerELOTab.propTypes = {
    contractPartner: PropTypes.object,
};

ContractPartnerELOTab.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(ContractPartnerELOTab));
