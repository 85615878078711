import {put, select, take, fork} from 'redux-saga/effects';
import {euaFeatureEntityTypes} from '@ace-de/eua-arcgis-feature-types';
import * as contractPartnerActionTypes from '../../contract-partners/contractPartnerActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import config from '../../config';

const searchContractPartnerByAttribute = function* searchContractPartnerByAttribute({payload}) {
    const {searchQueryString, fieldToFilterBy} = payload;
    const {serviceManager} = yield select(state => state.application);
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');

    yield put({
        type: contractPartnerActionTypes.SET_CONTRACT_PARTNER_CANDIDATES,
        payload: {contractPartnerCandidateDTOs: []},
    });

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.SEARCH_CONTRACT_PARTNER_BY_ATTRIBUTE_REQUEST,
        arcGISRESTService.queryFeatureServiceByAttribute,
        {
            where: `${fieldToFilterBy} LIKE '%${searchQueryString}%'`,
            featureEntityType: euaFeatureEntityTypes.CONTRACT_PARTNER,
            featureServiceURL: config.ARCGIS_CONTRACT_PARTNER_LOCATIONS_LAYER_URL,
        },
    );

    const searchCPByAttributeResponseAction = yield take([
        contractPartnerActionTypes.SEARCH_CONTRACT_PARTNER_BY_ATTRIBUTE_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.SEARCH_CONTRACT_PARTNER_BY_ATTRIBUTE_REQUEST_FAILED,
    ]);

    if (searchCPByAttributeResponseAction.error) return;

    const {response} = searchCPByAttributeResponseAction.payload;
    const {featureDTOs: contractPartnerCandidateDTOs = []} = response;

    yield put({
        type: contractPartnerActionTypes.SET_CONTRACT_PARTNER_CANDIDATES,
        payload: {contractPartnerCandidateDTOs},
    });
};
export default searchContractPartnerByAttribute;
