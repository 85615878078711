import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {persistenceStates} from '@ace-de/eua-entity-types';
import {useStyles, Modal, ButtonIcon} from '@ace-de/ui-components';
import {Icon, closeIcon, saveIcon} from '@ace-de/ui-components/icons';
import {Form, InputField} from '@ace-de/ui-components/form';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import {validateEmail} from '../../utils/validation';
import useCloseOnEscape from '../../utils/useCloseOnEscape';

const initialErrors = {
    name: '',
    email: '',
    password: '',
};

const CPCreateELLAAccountModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, contractPartner, location} = props;
    const {confirmCPELLAAccountCreation, declineCPELLAAccountCreation, ellaAccountFieldError} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('cp_create_ella_account_modal');
    const translateModalRef = useRef(translateModal);
    const [errors, setErrors] = useState({...initialErrors});
    const modalQueries = location?.query;

    useEffect(() => {
        if (!ellaAccountFieldError) return;

        setErrors(prevState => ({
            ...prevState,
            [ellaAccountFieldError.field]: translateModalRef.current(`error_message.${ellaAccountFieldError.field}_${ellaAccountFieldError.code.toLowerCase()}`),
        }));
    }, [ellaAccountFieldError]);

    const validateFormValues = formValues => {
        let hasError = false;
        const updatedErrors = {...initialErrors};
        // all form fields are mandatory
        ['email', 'name', 'password'].forEach(field => {
            if (!formValues[field]) {
                hasError = true;
                updatedErrors[field] = translateModal('error_message.required_field');
            }
        });
        // validate password length
        if (formValues['password'] && formValues['password'].length < 8) {
            hasError = true;
            updatedErrors['password'] = translateModal('error_message.invalid_password_length');
        }
        // validate email address
        if (formValues['email'] && !validateEmail(formValues['email'])) {
            hasError = true;
            updatedErrors['email'] = translateModal('error_message.invalid_email_format');
        }
        setErrors(updatedErrors);
        return hasError;
    };

    const handleOnSubmit = formValues => {
        const hasError = validateFormValues(formValues);
        if (hasError) return;
        confirmCPELLAAccountCreation({
            ellaAccountData: {
                name: formValues.name,
                email: formValues.email,
                password: formValues.password,
            },
        });
    };

    useCloseOnEscape(() => {
        declineCPELLAAccountCreation();
    });

    return (
        <Modal
            title={translateModal('title.create_ella_account')}
            action={(
                <Icon icon={closeIcon} onClick={declineCPELLAAccountCreation} />
            )}
            hasBackdrop={hasBackdrop}
            hasColoredHeader={true}
        >
            <Form
                name="createCPELLAAccountForm"
                onSubmit={handleOnSubmit}
                isDisabled={contractPartner.persistenceState === persistenceStates.PENDING}
            >
                {formValues => {
                    return (
                        <Fragment>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--align-flex-start',
                                    'global!ace-u-flex--justify-center',
                                    'global!ace-u-flex--column-gap-24',
                                ])}
                            >
                                <InputField
                                    name="name"
                                    value={modalQueries?.name || ''}
                                    label={translateModal('input_label.name')}
                                    isFieldRequired={true}
                                    errors={errors['name'] ? [errors['name']] : []}
                                />
                                <InputField
                                    name="email"
                                    value={modalQueries?.email || ''}
                                    label={translateModal('input_label.email')}
                                    isFieldRequired={true}
                                    errors={errors['email'] ? [errors['email']] : []}
                                />
                                <InputField
                                    name="password"
                                    value=""
                                    type="password"
                                    label={translateModal('input_label.password')}
                                    isFieldRequired={true}
                                    errors={errors['password'] ? [errors['password']] : []}
                                />
                            </div>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--justify-flex-end',
                                ])}
                            >
                                <ButtonIcon
                                    name="submitCPCreateELLAAccountButton"
                                    type="submit"
                                    icon={saveIcon}
                                    className={cx('global!ace-u-margin--top-32')}
                                >
                                    {translateModal('button_label.create')}
                                </ButtonIcon>
                            </div>
                        </Fragment>
                    );
                }}
            </Form>
        </Modal>
    );
};

CPCreateELLAAccountModal.propTypes = {
    declineCPELLAAccountCreation: PropTypes.func.isRequired,
    confirmCPELLAAccountCreation: PropTypes.func.isRequired,
    hasBackdrop: PropTypes.bool,
    contractPartner: PropTypes.object,
    ellaAccountFieldError: PropTypes.object,
    location: PropTypes.object.isRequired,
};

CPCreateELLAAccountModal.defaultProps = {
    hasBackdrop: true,
    contractPartner: null,
    ellaAccountFieldError: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
        ellaAccountFieldError: state.contractPartners.ellaAccountFieldError,
    };
};

const mapDispatchToProps = dispatch => ({
    declineCPELLAAccountCreation: () => dispatch({
        type: contractPartnerActionTypes.DECLINE_CREATE_CP_ELLA_ACCOUNT,
    }),
    confirmCPELLAAccountCreation: payload => dispatch({
        type: contractPartnerActionTypes.CONFIRM_CREATE_CP_ELLA_ACCOUNT,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CPCreateELLAAccountModal);
