import {apmACEPartnerServiceTypes, TowingService, RoadsideAssistanceService, RecoveryService, VehicleStorageService, PickupService, RentalCarService, ClearanceScrappingService, PlainService} from '@ace-de/eua-entity-types';

const createNewService = serviceType => {
    let newService = {};

    switch (serviceType) {
        case apmACEPartnerServiceTypes.TOWING: {
            newService = new TowingService({serviceType});
            break;
        }

        case apmACEPartnerServiceTypes.ROADSIDE_ASSISTANCE: {
            newService = new RoadsideAssistanceService({serviceType});
            break;
        }

        case apmACEPartnerServiceTypes.RECOVERY: {
            newService = new RecoveryService({serviceType});
            break;
        }

        case apmACEPartnerServiceTypes.PICKUP: {
            newService = new PickupService({serviceType});
            break;
        }

        case apmACEPartnerServiceTypes.VEHICLE_STORAGE: {
            newService = new VehicleStorageService({serviceType});
            break;
        }

        case apmACEPartnerServiceTypes.RENTAL_CAR: {
            newService = new RentalCarService({serviceType});
            break;
        }

        case apmACEPartnerServiceTypes.CLEARANCE_SCRAPPING: {
            newService = new ClearanceScrappingService({serviceType});
            break;
        }

        default: {
            newService = new PlainService({serviceType});
        }
    }
    return newService;
};

export default createNewService;
