/**
 * Modal ids
 */
export default {
    CONTRACT_PARTNER_STATUS_UPDATE: 'contract-partner-status-update',
    CP_CREATE_TEMPORARY_RESTRICTION: 'cp-create-temporary-restriction',
    CP_EDIT_TEMPORARY_RESTRICTION: 'cp-edit-temporary-restriction',
    CP_TEMPORARY_RESTRICTION_SUCCESSFUL: 'cp-temporary-restriction-successful',
    CP_ASSIGNMENT_CHANNELS_UPDATE: 'contract-partner-assignment-update',
    CP_CREATE_QUALITY_REPORT: 'cp-create-quality-report',
    DELETE_CP_OPERATING_UNIT_CONFIRMATION: 'delete-cp-operating-unit-confirmation',
    CP_OPERATING_UNIT_DATA: 'cp-operating-unit-data',
    CONTRACT_PARTNER_CONTACT_DATA: 'contract-partner-contact-data',
    DELETE_CP_CONTACT_DATA_CONFIRMATION: 'delete-cp-contact-data-confirmation',
    BC_CONTACT_MODAL: 'bc-contact-modal',
    CREATE_TIME_PERIOD_MODAL: 'create-time-period-modal',
    CP_FIXED_PRICE_MODAL: 'cp-fixed-price-modal',
    UPDATE_HOLIDAY_PERIODS_MODAL: 'update-holiday-periods-modal',
    CP_CREATE_ELLA_ACCOUNT: 'cp-create-ella-account',
    EDIT_PICKUP_RATES: 'edit-pickup-rates',
    FORWARD_QM_FEEDBACK: 'forward-qm-feedback',
    QM_FEEDBACK_ERROR: 'qm-feedback-error',
    CP_EDIT_ELLA_ACCOUNT: 'cp-edit-ella-account',
    EDIT_PRICES: 'edit-prices',
    QM_FEEDBACK_CP_RANKING: 'qm-feedback-cp-ranking',
    ERROR_MESSAGE_MODAL: 'error-message-modal',
};
