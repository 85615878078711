import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {camelCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import {apmVehicleStorageServiceTypes, apmContractPartnerTransportOptions, apmACEPartnerServiceTypes} from '@ace-de/eua-entity-types';
import {ButtonPrimary, ButtonSecondary, Checkbox, CheckboxGroup, Form, Icon, NumberInput, InteractiveIcon, Panel, RadioButton, RadioButtonGroup, useStyles} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {closeIcon, editIcon, checkmarkIcon} from '@ace-de/ui-components/icons';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import getPrice from '../../utils/getPrice';
import createNewService from '../createNewService';
import vehicleTransportsDistances from '../vehicleTransportDistances';

const ContractPartnerTransportVehicleStorage = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_transport_vehicle_storage_panel');
    const {contractPartner, initiateContractPartnerServicesUpdate} = props;
    const [isEditModeActive, setIsEditModeActive] = useState(false);
    const data = {
        distanceThresholdWithPassengers: contractPartner.pickup?.distanceThresholdWithPassengers || 0,
        distanceThresholdWithoutPassengers: contractPartner.pickup?.distanceThresholdWithoutPassengers || 0,
        vehicleStorage: contractPartner.vehicleStorage?.vehicleStorage.map(obj => obj.type) || [],
        openAirSecuredPrice: contractPartner.vehicleStorage?.vehicleStorage.find(obj => obj.type === apmVehicleStorageServiceTypes.OPEN_AIR_SECURED)?.price || '',
        openAirUnsecuredPrice: contractPartner.vehicleStorage?.vehicleStorage.find(obj => obj.type === apmVehicleStorageServiceTypes.OPEN_AIR_UNSECURED)?.price || '',
        carPortSecuredPrice: contractPartner.vehicleStorage?.vehicleStorage.find(obj => obj.type === apmVehicleStorageServiceTypes.CAR_PORT_SECURED)?.price || '',
        carPortUnsecuredPrice: contractPartner.vehicleStorage?.vehicleStorage.find(obj => obj.type === apmVehicleStorageServiceTypes.CAR_PORT_UNSECURED)?.price || '',
        hallPrice: contractPartner.vehicleStorage?.vehicleStorage.find(obj => obj.type === apmVehicleStorageServiceTypes.HALL)?.price || '',
    };

    const toggleEditMode = () => {
        setIsEditModeActive(prevState => !prevState);
    };

    // if no contract partner, don't render
    if (!contractPartner) return null;

    const handleOnSubmit = formValues => {
        if (!formValues) return;

        const doesServiceExist = {
            [apmACEPartnerServiceTypes.PICKUP]: false,
            [apmACEPartnerServiceTypes.VEHICLE_STORAGE]: false,
        };
        // update service if exists, and keep all the other services
        const updatedServices = contractPartner.services?.length
            ? contractPartner.services.map(service => {
                if ([
                    apmACEPartnerServiceTypes.PICKUP,
                    apmACEPartnerServiceTypes.VEHICLE_STORAGE,
                ].includes(service.serviceType)) {
                    doesServiceExist[service.serviceType] = true;
                    return {
                        ...service,
                        ...(service.serviceType === apmACEPartnerServiceTypes.VEHICLE_STORAGE && {
                            vehicleStorage: formValues['vehicleStorage'].map(obj => (
                                {type: obj, price: Number(formValues[`${camelCase(obj)}Price`])}
                            )),
                        }),
                        ...(service.serviceType === apmACEPartnerServiceTypes.PICKUP && {
                            distanceThresholdWithPassengers: formValues['distanceThresholdWithPassengers'],
                            distanceThresholdWithoutPassengers: formValues['distanceThresholdWithoutPassengers'],
                        }),
                    };
                }
                return service;
            }) : [];
        // if service is not already present in services array, add it
        for (const [serviceType, value] of Object.entries(doesServiceExist)) {
            if (!value) {
                updatedServices.push({
                    ...(createNewService(serviceType)),
                    ...(serviceType === apmACEPartnerServiceTypes.VEHICLE_STORAGE && {
                        vehicleStorage: formValues['vehicleStorage'].map(obj => (
                            {type: obj, price: Number(formValues[`${camelCase(obj)}Price`])}
                        )),
                    }),
                    ...(serviceType === apmACEPartnerServiceTypes.PICKUP && {
                        distanceThresholdWithPassengers: formValues['distanceThresholdWithPassengers'],
                        distanceThresholdWithoutPassengers: formValues['distanceThresholdWithoutPassengers'],
                    }),
                });
            }
        }
        let servicesData = contractPartner.services?.length
            ? updatedServices
            : [
                {
                    ...(createNewService(apmACEPartnerServiceTypes.VEHICLE_STORAGE)),
                    vehicleStorage: formValues['vehicleStorage'].map(obj => (
                        {type: obj, price: Number(formValues[`${camelCase(obj)}Price`])}
                    )),
                },
                {
                    ...(createNewService(apmACEPartnerServiceTypes.PICKUP)),
                    distanceThresholdWithPassengers: formValues['distanceThresholdWithPassengers'],
                    distanceThresholdWithoutPassengers: formValues['distanceThresholdWithoutPassengers'],
                },
            ];

        servicesData = servicesData.filter(service => {
            // if the distance is 0 both for with or without passengers, remove PICKUP
            if (service.serviceType === apmACEPartnerServiceTypes.PICKUP
                && !service.distanceThresholdWithPassengers && !service.distanceThresholdWithoutPassengers
            ) {
                return false;
            }
            // if vehicle storage array is empty, remove VEHICLE_STORAGE
            if (service.serviceType === apmACEPartnerServiceTypes.VEHICLE_STORAGE && !service.vehicleStorage?.length) {
                return false;
            }
            return true;
        });

        initiateContractPartnerServicesUpdate({
            contractPartnerId: contractPartner.id,
            contractPartnerData: {
                services: servicesData,
                transportOptions: formValues['transportOptions'],
            },
        });
        setIsEditModeActive(false);
    };

    return (
        <Panel>
            {!isEditModeActive ? (
                <>
                    <div className={cx('global!ace-u-two-column-layout')}>
                        <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--direction-column'])}>
                            <div className={cx('global!ace-u-margin--bottom-32')}>
                                <h2>{translateTab('panel_title.address_data')}</h2>
                            </div>
                            <Table className={cx('global!ace-u-margin--bottom-48')}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{translateTab('transport_table_cell.vehicle_transports')}</TableCell>
                                        <TableCell>{translateTab('transport_table_cell.no_service')}</TableCell>
                                        <TableCell>{translateTab('transport_table_cell.50km')}</TableCell>
                                        <TableCell>{translateTab('transport_table_cell.about_100km')}</TableCell>
                                        <TableCell>{translateTab('transport_table_cell.about_200km')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{translateTab('transport_table_cell.with_passengers')}</TableCell>
                                        {vehicleTransportsDistances
                                            .map((vehicleTransportsDistance, id) => {
                                                const distance = data?.distanceThresholdWithPassengers;
                                                return (
                                                    <TableCell key={vehicleTransportsDistance}>
                                                        <Icon
                                                            icon={
                                                                    vehicleTransportsDistance !== 0
                                                                        ? distance >= vehicleTransportsDistance
                                                                            ? checkmarkIcon : closeIcon
                                                                        : distance === 0
                                                                            ? checkmarkIcon
                                                                            : closeIcon}
                                                            className={cx(
                                                                vehicleTransportsDistance !== 0
                                                                    ? distance >= vehicleTransportsDistance
                                                                        ? 'ace-c-icon--color-success' : 'ace-c-icon--color-disabled'
                                                                    : distance === 0
                                                                        ? 'ace-c-icon--color-success'
                                                                        : 'ace-c-icon--color-disabled',
                                                            )}
                                                        />
                                                    </TableCell>
                                                );
                                            })}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{translateTab('transport_table_cell.without_passengers')}</TableCell>
                                        {vehicleTransportsDistances
                                            .map((vehicleTransportsDistance, id) => {
                                                const distance = data?.distanceThresholdWithoutPassengers;
                                                return (
                                                    <TableCell key={vehicleTransportsDistance}>
                                                        <Icon
                                                            icon={
                                                                    vehicleTransportsDistance !== 0
                                                                        ? distance >= vehicleTransportsDistance
                                                                            ? checkmarkIcon : closeIcon
                                                                        : distance === 0
                                                                            ? checkmarkIcon
                                                                            : closeIcon}
                                                            className={cx(
                                                                vehicleTransportsDistance !== 0
                                                                    ? distance >= vehicleTransportsDistance
                                                                        ? 'ace-c-icon--color-success' : 'ace-c-icon--color-disabled'
                                                                    : distance === 0
                                                                        ? 'ace-c-icon--color-success'
                                                                        : 'ace-c-icon--color-disabled',
                                                            )}
                                                        />
                                                    </TableCell>
                                                );
                                            })}
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={4}>
                                            {translateTab('transport_table_cell.general')}
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.values(apmContractPartnerTransportOptions)
                                        .map(transportOption => {
                                            const hasCPTransportOption = contractPartner.transportOptions
                                                    && contractPartner.transportOptions.includes(transportOption);
                                            return (
                                                <TableRow key={transportOption}>
                                                    <TableCell colSpan={4}>{translateTab(
                                                        `transport_table_cell.${transportOption.toLowerCase()}`,
                                                    )}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Icon
                                                            icon={hasCPTransportOption
                                                                ? checkmarkIcon : closeIcon}
                                                            className={cx(hasCPTransportOption
                                                                ? 'ace-c-icon--color-success'
                                                                : 'ace-c-icon--color-disabled')}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </div>
                        <div
                            className={cx(['global!ace-u-flex', 'global!ace-u-flex--direction-column'])}
                        >
                            <div
                                className={cx(['global!ace-u-flex', 'global!ace-u-flex--justify-space-between', 'global!ace-u-margin--bottom-32'])}
                            >
                                <h2>{translateTab('panel_title.vehicle_storage')}</h2>
                                <InteractiveIcon
                                    icon={!isEditModeActive ? editIcon : closeIcon}
                                    onClick={toggleEditMode}
                                />
                            </div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            {translateTab('transport_table_cell.kind')}
                                        </TableCell>
                                        <TableCell colSpan={2} />
                                        <TableCell colSpan={4}>
                                            {translateTab('transport_table_cell.price')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.values(apmVehicleStorageServiceTypes).map(vehicleStorageType => {
                                        const hasCPServicesVehicleStorageType = data.vehicleStorage
                                                && data.vehicleStorage.some(obj => obj === vehicleStorageType);
                                        return (
                                            <TableRow key={vehicleStorageType}>
                                                <TableCell colSpan={6}>
                                                    {translateTab(`transport_table_cell.${(vehicleStorageType).toLowerCase()}`)}
                                                </TableCell>
                                                <TableCell colSpan={2}>
                                                    <Icon
                                                        icon={hasCPServicesVehicleStorageType
                                                            ? checkmarkIcon
                                                            : closeIcon}
                                                        className={cx(hasCPServicesVehicleStorageType
                                                            ? 'ace-c-icon--color-success'
                                                            : 'ace-c-icon--color-disabled')}
                                                    />
                                                </TableCell>
                                                <TableCell colSpan={4}>
                                                    {getPrice(parseFloat(data[`${camelCase(vehicleStorageType)}Price`]), activeLocale)}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </>
            ) : (
                <Form
                    name="contractPartnerTransportDataForm"
                    onSubmit={handleOnSubmit}
                >
                    {formValues => {
                        return (
                            <>
                                <div className={cx('global!ace-u-two-column-layout')}>
                                    <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--direction-column'])}>
                                        <div
                                            className={cx('global!ace-u-margin--bottom-32')}
                                        >
                                            <h2>{translateTab('panel_title.address_data')}</h2>
                                        </div>
                                        <Table className={cx('global!ace-u-margin--bottom-48')}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{translateTab('transport_table_cell.vehicle_transports')}</TableCell>
                                                    <TableCell>{translateTab('transport_table_cell.no_service')}</TableCell>
                                                    <TableCell>{translateTab('transport_table_cell.50km')}</TableCell>
                                                    <TableCell>{translateTab('transport_table_cell.about_100km')}</TableCell>
                                                    <TableCell>{translateTab('transport_table_cell.about_200km')}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>{translateTab('transport_table_cell.with_passengers')}</TableCell>
                                                    <RadioButtonGroup
                                                        name="distanceThresholdWithPassengers"
                                                        value={data?.distanceThresholdWithPassengers}
                                                    >
                                                        {vehicleTransportsDistances
                                                            .map((vehicleTransportsDistance, id) => {
                                                                return (
                                                                    <TableCell key={vehicleTransportsDistance}>
                                                                        <RadioButton
                                                                            name={`distanceThresholdWithPassengers${vehicleTransportsDistance}`}
                                                                            value={vehicleTransportsDistance}
                                                                        />
                                                                    </TableCell>
                                                                );
                                                            })}
                                                    </RadioButtonGroup>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>{translateTab('transport_table_cell.without_passengers')}</TableCell>
                                                    <RadioButtonGroup
                                                        name="distanceThresholdWithoutPassengers"
                                                        value={data?.distanceThresholdWithoutPassengers}
                                                    >
                                                        {vehicleTransportsDistances
                                                            .map((vehicleTransportsDistance, id) => {
                                                                return (
                                                                    <TableCell key={vehicleTransportsDistance}>
                                                                        <RadioButton
                                                                            name={`distanceThresholdWithoutPassengers${vehicleTransportsDistance}`}
                                                                            value={vehicleTransportsDistance}
                                                                        />
                                                                    </TableCell>
                                                                );
                                                            })}
                                                    </RadioButtonGroup>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell colSpan={4}>
                                                        {translateTab('transport_table_cell.general')}
                                                    </TableCell>
                                                    <TableCell />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <CheckboxGroup
                                                    name="transportOptions"
                                                    value={contractPartner.transportOptions}
                                                >
                                                    {Object.values(apmContractPartnerTransportOptions)
                                                        .map(transportOption => {
                                                            return (
                                                                <TableRow key={transportOption}>
                                                                    <TableCell colSpan={4}>
                                                                        {translateTab(`transport_table_cell.${transportOption.toLowerCase()}`)}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Checkbox
                                                                            key={transportOption}
                                                                            name={transportOption}
                                                                            value={transportOption}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </CheckboxGroup>
                                            </TableBody>
                                        </Table>
                                    </div>
                                    <div
                                        className={cx(['global!ace-u-flex', 'global!ace-u-flex--direction-column'])}
                                    >
                                        <div
                                            className={cx(['global!ace-u-flex', 'global!ace-u-flex--justify-space-between', 'global!ace-u-margin--bottom-32'])}
                                        >
                                            <h2>{translateTab('panel_title.vehicle_storage')}</h2>
                                            <InteractiveIcon
                                                icon={!isEditModeActive ? editIcon : closeIcon}
                                                onClick={toggleEditMode}
                                            />
                                        </div>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell colSpan={6}>
                                                        {translateTab('transport_table_cell.kind')}
                                                    </TableCell>
                                                    <TableCell colSpan={2} />
                                                    <TableCell colSpan={4}>
                                                        {translateTab('transport_table_cell.price')}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <CheckboxGroup
                                                    name="vehicleStorage"
                                                    value={data.vehicleStorage}
                                                >
                                                    {Object.values(apmVehicleStorageServiceTypes)
                                                        .map(vehicleStorageType => {
                                                            return (
                                                                <TableRow key={vehicleStorageType}>
                                                                    <TableCell
                                                                        colSpan={6}
                                                                    >
                                                                        {translateTab(`transport_table_cell.${vehicleStorageType.toLowerCase()}`)}
                                                                    </TableCell>
                                                                    <TableCell colSpan={2}>
                                                                        <Checkbox
                                                                            key={vehicleStorageType}
                                                                            name={vehicleStorageType}
                                                                            value={vehicleStorageType}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell colSpan={4}>
                                                                        {formValues.vehicleStorage?.includes(vehicleStorageType) && ( // eslint-disable-line max-len
                                                                            <NumberInput
                                                                                className={cx(['ace-c-input--small', 'global!ace-u-width--full'])}
                                                                                min={0}
                                                                                name={`${camelCase(vehicleStorageType)}Price`}
                                                                                value={data[`${camelCase(vehicleStorageType)}Price`]}
                                                                            />
                                                                        )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </CheckboxGroup>
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-full-width',
                                        'global!ace-u-flex--justify-flex-end',
                                        'global!ace-u-margin--top-32',
                                    ])}
                                >
                                    <ButtonSecondary
                                        onClick={() => setIsEditModeActive(false)}
                                    >
                                        {translate('contract_partner_services_tab.button_label.discard')}
                                    </ButtonSecondary>
                                    <ButtonPrimary
                                        className={cx('global!ace-u-margin--left-24')}
                                        type="submit"
                                    >
                                        {translate('contract_partner_services_tab.button_label.save')}
                                    </ButtonPrimary>
                                </div>
                            </>
                        );
                    }}
                </Form>
            )}
        </Panel>
    );
};

ContractPartnerTransportVehicleStorage.propTypes = {
    contractPartner: PropTypes.object,
    initiateContractPartnerServicesUpdate: PropTypes.func.isRequired,
};

ContractPartnerTransportVehicleStorage.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateContractPartnerServicesUpdate: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CP_SERVICES_UPDATE,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerTransportVehicleStorage));
