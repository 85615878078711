import routePaths from '../routePaths';
import contractPartnerScreenTabs from '../contract-partners/contractPartnerScreenTabs';

export const bcFeatureRoutes = [
    routePaths.PRICE_MANAGEMENT,
];

export const bcFeatureTabs = [
    contractPartnerScreenTabs.ELO,
];
