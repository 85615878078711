import {take, select, put, fork} from 'redux-saga/effects';
import {persistenceStates} from '@computerrock/formation-core';
import {closeModal, openModal} from '@computerrock/formation-router/sagas';
import {ACEPartner, apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import modalIds from '../../modalIds';
import errorTypes from '../../application/errorTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';

const updateContractPartnerExternalCommissionersFlow = function* updateContractPartnerExternalCommissionersFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    let shouldWaitForAction = true;
    let payloadData = null;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contractPartnerActionTypes.SUBMIT_CONTRACT_PARTNER_EXTERNAL_COMMISSIONERS_FORM); // eslint-disable-line
            payloadData = payload;
        }
        const {contractPartnerId, contractPartnerData} = payloadData;

        yield fork(
            fetchRequest,
            contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_EXTERNAL_COMMISSIONERS_REQUEST,
            partnerManagementService.updateACEPartner,
            {
                acePartnerData: {
                    ...ACEPartner.objectToPatchDTO(contractPartnerData),
                    partnerType: apmACEPartnerTypes.CONTRACT_PARTNER,
                },
                acePartnerId: contractPartnerId,
            },
        );
        const responseAction = yield take([
            contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_EXTERNAL_COMMISSIONERS_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_EXTERNAL_COMMISSIONERS_REQUEST_FAILED,
        ]);

        if (responseAction.error) {
            if (shouldWaitForAction) {
                yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {
                    errorType: errorTypes.CP_EXTERNAL_COMMISSIONERS_UPDATE_FAILED,
                });
            }

            yield put({
                type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                payload: {persistenceState: persistenceStates.READY},
            });

            const nextAction = yield take([
                contractPartnerActionTypes.RETRY_CP_EXTERNAL_COMMISSIONERS_UPDATE,
                contractPartnerActionTypes.CANCEL_RETRY_CP_EXTERNAL_COMMISSIONERS_UPDATE,
            ]);

            shouldWaitForAction = nextAction.type !== contractPartnerActionTypes.RETRY_CP_EXTERNAL_COMMISSIONERS_UPDATE;

            if (nextAction.type === contractPartnerActionTypes.RETRY_CP_EXTERNAL_COMMISSIONERS_UPDATE) {
                yield put({
                    type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.PENDING},
                });
            }

            if (nextAction.type === contractPartnerActionTypes.CANCEL_RETRY_CP_EXTERNAL_COMMISSIONERS_UPDATE) {
                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
            }

            continue;
        }

        if (!responseAction.error) {
            if (!shouldWaitForAction) {
                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});

                yield put({
                    type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.READY},
                });
            }
            shouldWaitForAction = true;
            const {response} = responseAction.payload;
            const {acePartnerDTO} = response;

            yield put({
                type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
                payload: {contractPartnerDTOs: [acePartnerDTO]},
            });
        }
    }
};

export default updateContractPartnerExternalCommissionersFlow;
