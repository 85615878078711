import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {Panel, useStyles} from '@ace-de/ui-components';
import {InteractiveIcon, editIcon} from '@ace-de/ui-components/icons';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {apsPriceTypes} from '@ace-de/eua-entity-types';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import config from '../../config';
import getPrice from '../../utils/getPrice';

const SimpleServiceRatesPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, activeLocale, translate} = useTranslate();
    const translatePanel = createTranslateShorthand('simple_service_rates_panel');
    const {initiateUpdateAdditionalRates, recoveryPrices, isEditingDisabled} = props;

    return (
        <Panel
            title={translatePanel('title.simple_service_rates_by_time')}
            actions={!isEditingDisabled && (
                <InteractiveIcon
                    icon={editIcon}
                    onClick={() => initiateUpdateAdditionalRates({
                        contentType: apsPriceTypes.RECOVERY_PRICE,
                    })}
                />
            )}
            className={cx('ace-c-panel--full-bleed-content')}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="simple-service-rates-service" colSpan={3}>
                            {translatePanel('table_header.service')}
                        </TableCell>
                        <TableCell qaIdentPart="simple-service-rates-unit" colSpan={2}>
                            {translatePanel('table_header.unit')}
                        </TableCell>
                        <TableCell qaIdentPart="simple-service-rates-price" colSpan={2}>
                            <div className={cx('global!ace-u-typography--align-right')}>
                                {translatePanel('table_header.price')}
                            </div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {recoveryPrices.length > 0 && recoveryPrices.map(recoveryPrice => {
                        return (
                            <TableRow
                                key={recoveryPrice.id}
                                qaIdentPart={recoveryPrice.id}
                            >
                                <TableCell
                                    qaIdentPart="recovery-service-type"
                                    qaIdentPartPostfix={recoveryPrice.id}
                                    colSpan={3}
                                >
                                    {translate('global.recovery_service_type.recovery')}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="recovery-unit"
                                    qaIdentPartPostfix={recoveryPrice.id}
                                    colSpan={2}
                                >
                                    {`${config.ADDITIONAL_RATES_BY_TIME_UNIT} ${translate('global.time_unit.min')}`}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="recovery-price"
                                    qaIdentPartPostfix={recoveryPrice.id}
                                    colSpan={2}
                                >
                                    <div className={cx('global!ace-u-typography--align-right')}>
                                        {recoveryPrice.value ? getPrice(recoveryPrice.value, activeLocale) : '-'}
                                    </div>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Panel>
    );
};

SimpleServiceRatesPanel.propTypes = {
    initiateUpdateAdditionalRates: PropTypes.func.isRequired,
    recoveryPrices: PropTypes.array.isRequired,
    isEditingDisabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
    return {
        recoveryPrices: state.priceManagement.recoveryPrices,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateUpdateAdditionalRates: payload => dispatch({
        type: priceManagementActionTypes.INITIATE_UPDATE_PRICES_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SimpleServiceRatesPanel);
