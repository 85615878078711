import {fork, put, select, take} from 'redux-saga/effects';
import {persistenceStates} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import config from '../../config';

const searchBCContacts = function* searchBCContacts() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.SEARCH_BC_CONTACTS);
        const {searchQueryParams} = payload;

        if (!searchQueryParams) continue;

        yield put({
            type: contractPartnerActionTypes.SET_CONTACTS_SEARCH_PERSISTENCE_STATE,
            payload: {persistenceState: persistenceStates.PENDING},
        });

        if (searchQueryParams.get('modal')) searchQueryParams.delete('modal');
        searchQueryParams.append('size', config.DEFAULT_PAGE_SIZE);

        yield fork(
            fetchRequest,
            contractPartnerActionTypes.GET_BC_CONTACTS_SEARCH_RESULTS_REQUEST,
            leaAssignmentFlowService.getContacts,
            {searchQueryParams},
        );

        const responseAction = yield take([
            contractPartnerActionTypes.GET_BC_CONTACTS_SEARCH_RESULTS_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.GET_BC_CONTACTS_SEARCH_RESULTS_REQUEST_FAILED,
        ]);
        if (responseAction.error) {
            yield put({
                type: contractPartnerActionTypes.STORE_BC_CONTACTS_SEARCH_ERROR,
                payload: {isContactSearchError: true},
            });
            yield put({
                type: contractPartnerActionTypes.STORE_BC_CONTACTS_SEARCH_RESULTS,
                payload: {contactDTOs: [], totalCount: 0},
            });
        }

        if (!responseAction.error) {
            const {payload: searchResultsPayload} = responseAction;
            const {contactDTOs, totalCount} = searchResultsPayload.response;

            yield put({
                type: contractPartnerActionTypes.STORE_BC_CONTACTS_SEARCH_RESULTS,
                payload: {contactDTOs, totalCount},
            });

            yield put({
                type: contractPartnerActionTypes.STORE_BC_CONTACTS_SEARCH_ERROR,
                payload: {isContactSearchError: false},
            });
        }

        yield put({
            type: contractPartnerActionTypes.SET_CONTACTS_SEARCH_PERSISTENCE_STATE,
            payload: {persistenceState: persistenceStates.READY},
        });
    }
};

export default searchBCContacts;
