import {fork, put, select, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {persistenceStates} from '@computerrock/formation-core';
import {ACEPartner, apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as applicationActionTypes from '../../application/applicationActionTypes';
import errorTypes from '../../application/errorTypes';

const contractPartnerELLAAccountUpdateFlow = function* contractPartnerELLAAccountUpdateFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const driverELLAUserManagementService = serviceManager.loadService('driverELLAUserManagementService');

    let shouldWaitForAction = true;
    let chosenAction = null;

    while (true) {
        if (shouldWaitForAction) {
            yield take(contractPartnerActionTypes.INITIATE_CP_EDIT_ELLA_ACCOUNT_FLOW);

            yield* openModal(modalIds.CP_EDIT_ELLA_ACCOUNT);

            chosenAction = yield take([
                contractPartnerActionTypes.DECLINE_CP_ELLA_ACCOUNT_EDIT_FLOW,
                contractPartnerActionTypes.CONFIRM_CP_ELLA_ACCOUNT_EDIT_FLOW,
            ]);
        }

        if (chosenAction.type === contractPartnerActionTypes.CONFIRM_CP_ELLA_ACCOUNT_EDIT_FLOW) {
            const {payload} = chosenAction;
            const {ellaAccountActive, contractPartnerId} = payload;

            yield fork(
                fetchRequest,
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ELLA_ACCOUNT_REQUEST,
                driverELLAUserManagementService.toggleUserStatus,
                {
                    enabled: ellaAccountActive,
                    contractPartnerId,
                },
            );

            const responseAction = yield take([
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ELLA_ACCOUNT_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ELLA_ACCOUNT_REQUEST_FAILED,
            ]);

            if (responseAction.error) {
                if (shouldWaitForAction) {
                    yield* closeModal(modalIds.CP_EDIT_ELLA_ACCOUNT);
                    yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {
                        errorType: errorTypes.CP_ELLA_ACCOUNT_UPDATE_FAILED,
                    });
                }

                yield put({
                    type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.READY},
                });

                const nextAction = yield take([
                    contractPartnerActionTypes.RETRY_UPDATE_CP_ELLA_ACCOUNT,
                    contractPartnerActionTypes.CANCEL_RETRY_UPDATE_CP_ELLA_ACCOUNT,
                ]);

                shouldWaitForAction = nextAction.type !== contractPartnerActionTypes.RETRY_UPDATE_CP_ELLA_ACCOUNT;

                if (nextAction.type === contractPartnerActionTypes.RETRY_UPDATE_CP_ELLA_ACCOUNT) {
                    yield put({
                        type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.PENDING},
                    });
                }

                if (nextAction.type === contractPartnerActionTypes.CANCEL_RETRY_UPDATE_CP_ELLA_ACCOUNT) {
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                }

                continue;
            }

            if (!responseAction.error) {
                if (!shouldWaitForAction) {
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});

                    yield put({
                        type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.READY},
                    });
                    shouldWaitForAction = true;
                }
                yield fork(
                    fetchRequest,
                    contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ELLA_ACTIVE_REQUEST,
                    partnerManagementService.updateACEPartner,
                    {
                        acePartnerData: {
                            ...ACEPartner.objectToPatchDTO({ellaActive: ellaAccountActive}),
                            partnerType: apmACEPartnerTypes.CONTRACT_PARTNER,
                        },
                        acePartnerId: contractPartnerId,
                    },
                );
                const acePartnerUpdateResponseAction = yield take([
                    contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ELLA_ACTIVE_REQUEST_SUCCEEDED,
                    contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_ELLA_ACTIVE_REQUEST_FAILED,
                ]);

                if (!acePartnerUpdateResponseAction.error) {
                    const {response} = acePartnerUpdateResponseAction.payload;
                    const {acePartnerDTO} = response;

                    yield put({
                        type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
                        payload: {contractPartnerDTOs: [acePartnerDTO]},
                    });
                }
            }
        }

        yield closeModal(modalIds.CP_EDIT_ELLA_ACCOUNT);
    }
};

export default contractPartnerELLAAccountUpdateFlow;
