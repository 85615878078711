import {all, fork, takeLatest} from 'redux-saga/effects';
import createTimePeriodFlow from './sagas/createTimePeriodFlow';
import updateHolidayPeriodsFlow from './sagas/updateHolidayPeriodsFlow';
import fetchPricesByTimePeriod from './sagas/fetchPricesByTimePeriod';
import cpFixedPriceModalFlow from './sagas/cpFixedPriceModalFlow';
import updatePricesFlow from './sagas/updatePricesFlow';
import updatePricePeriodDates from './sagas/updatePricePeriodDates';
import * as priceManagementActionTypes from './priceManagementActionTypes';

/**
 *  Price management watcher saga
 */
const priceManagementWatcher = function* priceManagementWatcher() {
    yield all([
        fork(createTimePeriodFlow),
        fork(updateHolidayPeriodsFlow),
        fork(updatePricesFlow),
        fork(cpFixedPriceModalFlow),
        takeLatest(priceManagementActionTypes.INITIATE_FETCH_PRICES_BY_TIME_FLOW, fetchPricesByTimePeriod),
        takeLatest(priceManagementActionTypes.INITIATE_PRICE_PERIOD_UPDATE, updatePricePeriodDates),
    ]);
};

export default priceManagementWatcher;
