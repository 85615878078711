import React, {useState, useEffect, Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {HighlightCircle, LoaderSpinner, Modal, useStyles} from '@ace-de/ui-components';
import {useHistory} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {InteractiveIcon, closeIcon} from '@ace-de/ui-components/icons';
import bcModalsConfig from './bcModalsConfig';
import bcModalContentTypes from './bcModalContentTypes';
import * as contractPartnersActionTypes from '../contractPartnerActionTypes';
import BCContactSearchModalView from '../ui-elements/BCContactSearchModalView';
import BCContractPartnerModalView from '../ui-elements/BCContractPartnerModalView';
import bcModalTypes from './bcModalTypes';
import BCActionFailed from '../ui-elements/BCActionFailed';
import useCloseOnEscape from '../../utils/useCloseOnEscape';

const BCContactModal = props => {
    const {cx} = useStyles();
    const {closeModal, returnToContactSearch} = props;
    const history = useHistory();
    const {translate} = useTranslate();
    const [queryParams, setQueryParams] = useState(new URLSearchParams(history.location.search));
    const contentType = queryParams.get('contentType');
    const modalConfig = bcModalsConfig[contentType];
    const modalType = queryParams.get('type');

    useCloseOnEscape(() => {
        contentType === bcModalContentTypes.CONTACT_SEARCH ? closeModal() : returnToContactSearch();
    });

    useEffect(() => {
        setQueryParams(new URLSearchParams(history.location.search));
    }, [history.location.search, setQueryParams]);

    if (!contentType || !modalConfig) return null;

    return (
        <Modal
            action={contentType !== bcModalContentTypes.LOADER && contentType !== bcModalContentTypes.BC_ACTION_FAILED
                ? (
                    <InteractiveIcon
                        icon={closeIcon}
                        onClick={contentType === bcModalContentTypes.CONTACT_SEARCH
                            ? closeModal
                            : returnToContactSearch
                    }
                    />
                )
                : (<Fragment />)}
            title={modalConfig.title
                ? translate(queryParams.get('type') && contentType !== bcModalContentTypes.CONTACT_SEARCH
                    ? queryParams.get('type') === bcModalTypes.CREATE_CONTRACT_PARTNER
                    || queryParams.get('type') === bcModalTypes.CREATE_CREDITOR
                        ? `${modalConfig.title}create_contract_partner`
                        : `${modalConfig.title}${queryParams.get('type').toLowerCase()}`
                    : modalConfig.title)
                : ''
            }
            hasBackdrop={modalConfig.hasBackdrop}
            hasContentPadding={modalConfig.hasContentPadding}
            hasColoredHeader={modalConfig.hasColoredHeader}
            contentClassName={cx(modalConfig.contentClassName)}
        >
            {contentType === bcModalContentTypes.CONTACT_SEARCH && (
                <BCContactSearchModalView />
            )}
            {contentType === bcModalContentTypes.CONTRACT_PARTNER && (
                <BCContractPartnerModalView />
            )}
            {contentType === bcModalContentTypes.LOADER && (
                <Fragment>
                    <HighlightCircle
                        className={cx([
                            'ace-c-highlight-circle--medium',
                            'ace-c-highlight-circle--primary-highlight',
                        ])}
                    >
                        <LoaderSpinner />
                    </HighlightCircle>
                    {modalType && (
                        <p
                            className={cx([
                                'global!ace-u-typography--variant-h2',
                                'global!ace-u-margin--top-32',
                            ])}
                        >
                            {translate(`loader_modal.modal_title.${modalType.toLowerCase()}`)}
                        </p>
                    )}
                </Fragment>
            )}
            {contentType === bcModalContentTypes.BC_ACTION_FAILED && (
                <BCActionFailed />
            )}
        </Modal>
    );
};

BCContactModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    returnToContactSearch: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch({
        type: contractPartnersActionTypes.DECLINE_BC_CONTACT_MODAL_FLOW,
    }),
    returnToContactSearch: () => dispatch({
        type: contractPartnersActionTypes.RETURN_TO_CONTACT_SEARCH,
    }),
});

export default connect(null, mapDispatchToProps)(BCContactModal);
