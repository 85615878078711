import {take, put, select, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {persistenceStates} from '@computerrock/formation-core';
import {OperatingUnit} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import modalIds from '../../modalIds';
import errorTypes from '../../application/errorTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';

const createCPOperatingUnitFlow = function* createCPOperatingUnitFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    let shouldWaitForAction = true;
    let payloadData = null;
    let chosenModalOption = null;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contractPartnerActionTypes.INITIATE_CREATE_CP_OPERATING_UNIT_FLOW);
            payloadData = payload;

            yield* openModal(modalIds.CP_OPERATING_UNIT_DATA);

            chosenModalOption = yield take([
                contractPartnerActionTypes.CONFIRM_CREATE_CP_OPERATING_UNIT,
                contractPartnerActionTypes.DECLINE_CREATE_CP_OPERATING_UNIT,
            ]);
        }

        const {contractPartnerId} = payloadData;

        if (chosenModalOption
            && chosenModalOption.type === contractPartnerActionTypes.CONFIRM_CREATE_CP_OPERATING_UNIT
        ) {
            const {operatingUnitData} = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                contractPartnerActionTypes.CREATE_CP_OPERATING_UNIT_REQUEST,
                partnerManagementService.createCPOperatingUnit,
                {
                    operatingUnitDTO: OperatingUnit.objectToDTO(operatingUnitData),
                },
            );

            const responseAction = yield take([
                contractPartnerActionTypes.CREATE_CP_OPERATING_UNIT_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.CREATE_CP_OPERATING_UNIT_REQUEST_FAILED,
            ]);

            if (responseAction.error) {
                if (shouldWaitForAction) {
                    yield* closeModal(modalIds.CP_OPERATING_UNIT_DATA);
                    yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {
                        errorType: errorTypes.CP_OPERATING_UNIT_CREATION_FAILED,
                    });
                }

                yield put({
                    type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.READY},
                });

                const nextAction = yield take([
                    contractPartnerActionTypes.RETRY_CREATE_CP_OPERATING_UNIT,
                    contractPartnerActionTypes.CANCEL_RETRY_CREATE_CP_OPERATING_UNIT,
                ]);

                shouldWaitForAction = nextAction.type !== contractPartnerActionTypes.RETRY_CREATE_CP_OPERATING_UNIT;

                if (nextAction.type === contractPartnerActionTypes.RETRY_CREATE_CP_OPERATING_UNIT) {
                    yield put({
                        type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.PENDING},
                    });
                }

                if (nextAction.type === contractPartnerActionTypes.CANCEL_RETRY_CREATE_CP_OPERATING_UNIT) {
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                }

                continue;
            }

            if (!responseAction.error) {
                if (!shouldWaitForAction) {
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});

                    yield put({
                        type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.READY},
                    });
                }
                shouldWaitForAction = true;
                const {response} = responseAction.payload;
                const {operatingUnitDTO} = response;

                yield put({
                    type: contractPartnerActionTypes.STORE_CP_OPERATING_UNIT,
                    payload: {operatingUnitDTO, contractPartnerId},
                });
            }
        }

        yield* closeModal(modalIds.CP_OPERATING_UNIT_DATA);
    }
};

export default createCPOperatingUnitFlow;
