import {take, select, put, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {persistenceStates} from '@computerrock/formation-core';
import {ACEPartner, apmACEPartnerTypes} from '@ace-de/eua-entity-types';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import errorTypes from '../../application/errorTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';

/**
 * Contract partner status update flow (uses modal)
 */
const contractPartnerStatusUpdateFlow = function* contractPartnerStatusUpdateFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    let shouldWaitForAction = true;
    let payloadData = null;
    let chosenModalOption = null;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contractPartnerActionTypes.INITIATE_CONTRACT_PARTNER_STATUS_UPDATE_FLOW);
            payloadData = payload;

            yield* openModal(modalIds.CONTRACT_PARTNER_STATUS_UPDATE);

            chosenModalOption = yield take([
                contractPartnerActionTypes.CONFIRM_CONTRACT_PARTNER_STATUS_UPDATE,
                contractPartnerActionTypes.DECLINE_CONTRACT_PARTNER_STATUS_UPDATE,
            ]);
        }
        const {contractPartnerId} = payloadData;

        if (chosenModalOption
            && chosenModalOption.type === contractPartnerActionTypes.CONFIRM_CONTRACT_PARTNER_STATUS_UPDATE) {
            const {contractPartnerData} = chosenModalOption.payload;

            // delete scheduled status change (if exists)
            if (shouldWaitForAction && !contractPartnerData.scheduledAt) {
                yield fork(
                    fetchRequest,
                    contractPartnerActionTypes.DELETE_CP_SCHEDULED_STATUS_CHANGE_REQUEST,
                    partnerManagementService.deleteScheduledContractPartnerStatusChange,
                    {
                        contractPartnerId,
                    },
                );
                yield take([
                    contractPartnerActionTypes.DELETE_CP_SCHEDULED_STATUS_CHANGE_REQUEST_SUCCEEDED,
                    contractPartnerActionTypes.DELETE_CP_SCHEDULED_STATUS_CHANGE_REQUEST_FAILED,
                ]);
            }

            yield fork(
                fetchRequest,
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_STATUS_REQUEST,
                contractPartnerData.scheduledAt
                    ? partnerManagementService.scheduleContractPartnerStatusChange
                    : partnerManagementService.updateACEPartner,
                {
                    ...(contractPartnerData.scheduledAt && {
                        contractPartnerData,
                        contractPartnerId,
                    }),
                    ...(!contractPartnerData.scheduledAt && {
                        acePartnerData: {
                            ...ACEPartner.objectToPatchDTO(contractPartnerData),
                            partnerType: apmACEPartnerTypes.CONTRACT_PARTNER,
                        },
                        acePartnerId: contractPartnerId,
                    }),
                },
            );

            const updateContractPartnerStatusResponseAction = yield take([
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_STATUS_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.UPDATE_CONTRACT_PARTNER_STATUS_REQUEST_FAILED,
            ]);

            if (updateContractPartnerStatusResponseAction.error) {
                if (shouldWaitForAction) {
                    yield* closeModal(modalIds.CONTRACT_PARTNER_STATUS_UPDATE);
                    yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {
                        errorType: errorTypes.CP_STATUS_UPDATE_FAILED,
                    });
                }

                yield put({
                    type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.READY},
                });

                const nextAction = yield take([
                    contractPartnerActionTypes.RETRY_CP_STATUS_UPDATE,
                    contractPartnerActionTypes.CANCEL_RETRY_CP_STATUS_UPDATE,
                ]);

                shouldWaitForAction = nextAction.type !== contractPartnerActionTypes.RETRY_CP_STATUS_UPDATE;

                if (nextAction.type === contractPartnerActionTypes.RETRY_CP_STATUS_UPDATE) {
                    yield put({
                        type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.PENDING},
                    });
                }

                if (nextAction.type === contractPartnerActionTypes.CANCEL_RETRY_CP_STATUS_UPDATE) {
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
                }

                continue;
            }

            if (!updateContractPartnerStatusResponseAction.error) {
                if (!shouldWaitForAction) {
                    yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});

                    yield put({
                        type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                        payload: {persistenceState: persistenceStates.READY},
                    });
                }
                shouldWaitForAction = true;
                const {response} = updateContractPartnerStatusResponseAction.payload;
                const {acePartnerDTO} = response;

                if (acePartnerDTO) {
                    yield put({
                        type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
                        payload: {contractPartnerDTOs: [acePartnerDTO]},
                    });
                }
            }
        }

        yield* closeModal(modalIds.CONTRACT_PARTNER_STATUS_UPDATE);
    }
};

export default contractPartnerStatusUpdateFlow;
