import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {DataRow, Option, Panel, useStyles} from '@ace-de/ui-components';
import {Select, ButtonPrimary, Form} from '@ace-de/ui-components/form';
import {closeIcon, editIcon, InteractiveIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import {snakeCase, camelCase} from 'change-case';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

const ContractPartnerAccountManagerPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_basic_data_tab');
    const {contractPartner, submitAccountManagerForm, accountManagers} = props;
    const [isEditModeActive, setIsEditModeActive] = useState(false);
    const [selectedAccountManager, setSelectedAccountManager] = useState(contractPartner.regionId
        ? accountManagers[contractPartner.regionId] || Object.values(accountManagers)[0]
        : Object.values(accountManagers)[0]);
    const accountManager = useMemo(() => {
        if (!contractPartner?.regionId) return null;
        return accountManagers[contractPartner.regionId] || null;
    }, [contractPartner, accountManagers]);

    const toggleEditMode = () => {
        setIsEditModeActive(prevState => !prevState);
        setSelectedAccountManager(accountManager || Object.values(accountManagers)[0]);
    };

    const selectAccountManager = regionId => {
        const manager = accountManagers[regionId];
        if (manager) {
            setSelectedAccountManager(manager);
        }
    };

    const handleOnSubmit = formValues => {
        const contractPartnerData = {
            regionId: formValues['accountManagerId'],
        };

        submitAccountManagerForm({
            contractPartnerData,
            contractPartnerId: contractPartner.id,
        });

        toggleEditMode();
    };

    // if no contract partner, don't render
    if (!contractPartner) return null;

    return (
        <Panel
            title={translateTab('panel_title.account_manager')}
            actions={(
                <InteractiveIcon
                    icon={!isEditModeActive ? editIcon : closeIcon}
                    onClick={toggleEditMode}
                />
            )}
            qaIdent="cp-account-manager"
        >
            {!isEditModeActive
                ? (
                    <div className={cx('global!ace-u-two-column-layout')}>
                        <div>
                            <DataRow
                                label={translateTab('data_row_label.regional_partner_manager')}
                                qaIdent="cp-account-manager-name"
                            >
                                {accountManager?.name || ''}
                            </DataRow>
                            <DataRow
                                label={translateTab('data_row_label.ace_region_vp_m')}
                                qaIdent="cp-account-manager-region"
                            >
                                {accountManager?.region
                                    ? translate(`global.region.${snakeCase(accountManager.region)}`)
                                    : ''}
                            </DataRow>
                            <DataRow
                                label={translateTab('data_row_label.rbv_code')}
                                qaIdent="cp-account-manager-region-id"
                            >
                                {accountManager?.regionId || ''}
                            </DataRow>
                        </div>
                        <div>
                            <DataRow
                                label={translateTab('data_row_label.phone')}
                                qaIdent="cp-account-manager-phone"
                            >
                                <span className={cx('global!ace-u-typography--color-highlighted')}>
                                    {accountManager?.phone || ''}
                                </span>
                            </DataRow>
                            <DataRow
                                label={translateTab('data_row_label.email')}
                                qaIdent="cp-account-manager-email"
                            >
                                <a
                                    href={`mailto:${accountManager?.email || ''}`}
                                    className={cx([
                                        'global!ace-u-typography--color-highlighted',
                                        'global!ace-u-typography--variant-body',
                                    ])}
                                >
                                    {accountManager?.email || ''}
                                </a>
                            </DataRow>
                        </div>
                    </div>
                ) : (
                    <Form name="accountManagerForm" onSubmit={handleOnSubmit}>
                        <div className={cx('global!ace-u-two-column-layout')}>
                            <div>
                                <DataRow
                                    label={translateTab('data_row_label.regional_partner_manager')}
                                    contentClassName={cx([
                                        'ace-c-data-row--full-bleed-content',
                                        'global!ace-u-padding--8-8-8-16',
                                    ])}
                                    qaIdent="cp-account-manager-id"
                                >
                                    <Select
                                        className={cx([
                                            'global!ace-u-width--full',
                                            'ace-c-select--small',
                                        ])}
                                        name="accountManagerId"
                                        value={selectedAccountManager.regionId}
                                        onChange={regionId => selectAccountManager(regionId)}
                                    >
                                        {Object.values(accountManagers).map(manager => (
                                            <Option
                                                key={manager.regionId}
                                                name={`accountManager${camelCase(manager.name)}`}
                                                value={manager.regionId}
                                            >
                                                {manager.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </DataRow>
                                <DataRow
                                    label={translateTab('data_row_label.ace_region_vp_m')}
                                    qaIdent="cp-selected-account-manager-region"
                                >
                                    {translate(`global.region.${snakeCase(selectedAccountManager.region)}`)}
                                </DataRow>
                                <DataRow
                                    label={translateTab('data_row_label.rbv_code')}
                                    qaIdent="cp-selected-account-manager-region-id"
                                >
                                    {selectedAccountManager.regionId}
                                </DataRow>
                            </div>
                            <div>
                                <DataRow
                                    label={translateTab('data_row_label.phone')}
                                    qaIdent="cp-selected-account-manager-phone"
                                >
                                    {selectedAccountManager.phone}
                                </DataRow>
                                <DataRow
                                    label={translateTab('data_row_label.email')}
                                    qaIdent="cp-selected-account-manager-email"
                                >
                                    {selectedAccountManager.email}
                                </DataRow>
                            </div>
                        </div>
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--direction-column',
                                'global!ace-u-flex--align-items-flex-end',
                                'global!ace-u-margin--top-32',
                            ])}
                        >
                            <ButtonPrimary
                                name="submitAccountManagerData"
                                type="submit"
                            >
                                {translateTab('button_label.save_changes')}
                            </ButtonPrimary>
                        </div>
                    </Form>
                )
            }
        </Panel>
    );
};

ContractPartnerAccountManagerPanel.propTypes = {
    contractPartner: PropTypes.object,
    submitAccountManagerForm: PropTypes.func.isRequired,
    accountManagers: PropTypes.object,
};

ContractPartnerAccountManagerPanel.defaultProps = {
    contractPartner: null,
    accountManagers: {},
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
        accountManagers: contractPartnerSelectors.getAccountManagers(state),
    };
};

const mapDispatchToProps = dispatch => ({
    submitAccountManagerForm: payload => dispatch({
        type: contractPartnerActionTypes.SUBMIT_CONTRACT_PARTNER_ACCOUNT_MANAGER_FORM,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerAccountManagerPanel));
