/**
 * Command action types
 */
export const INITIATE_CREATE_TIME_PERIOD_FLOW = '[price-management] INITIATE_CREATE_TIME_PERIOD_FLOW';
export const CONFIRM_CREATE_TIME_PERIOD = '[price-management] CONFIRM_CREATE_TIME_PERIOD';
export const DECLINE_CREATE_TIME_PERIOD = '[price-management] DECLINE_CREATE_TIME_PERIOD';
export const INITIATE_UPDATE_HOLIDAY_PERIODS_FLOW = '[price-management] INITIATE_UPDATE_HOLIDAY_PERIODS_FLOW';
export const DECLINE_UPDATE_HOLIDAY_PERIODS_FLOW = '[price-management] DECLINE_UPDATE_HOLIDAY_PERIODS_FLOW';
export const CONFIRM_UPDATE_HOLIDAY_PERIODS_FLOW = '[price-management] CONFIRM_UPDATE_HOLIDAY_PERIODS_FLOW';
export const INITIATE_FETCH_PRICES_BY_TIME_FLOW = '[price-management] INITIATE_FETCH_PRICES_BY_TIME_FLOW';
export const INITIATE_UPDATE_PRICES_FLOW = '[price-management] INITIATE_UPDATE_PRICES_FLOW';
export const CONFIRM_UPDATE_PRICES = '[price-management] CONFIRM_UPDATE_PRICES';
export const DECLINE_UPDATE_PRICES = '[price-management] DECLINE_UPDATE_PRICES';
export const INITIATE_CONTRACT_PARTNER_FIXED_PRICE_EDIT_FLOW = '[price-management] INITIATE_CONTRACT_PARTNER_FIXED_PRICE_EDIT_FLOW';
export const DECLINE_CONTRACT_PARTNER_FIXED_PRICE_EDIT_FLOW = '[price-management] DECLINE_CONTRACT_PARTNER_FIXED_PRICE_EDIT_FLOW';
export const CONFIRM_CONTRACT_PARTNER_FIXED_PRICE_EDIT_FLOW = '[price-management] CONFIRM_CONTRACT_PARTNER_FIXED_PRICE_EDIT_FLOW';
export const INITIATE_PRICE_PERIOD_UPDATE = '[price-management] INITIATE_PRICE_PERIOD_UPDATE';

/**
 * Event action types
 */
export const FETCH_PRICE_PERIODS_REQUEST = '[price-management] FETCH_PRICE_PERIODS_REQUEST';
export const FETCH_PRICE_PERIODS_REQUEST_SENT = '[price-management] FETCH_PRICE_PERIODS_REQUEST_SENT';
export const FETCH_PRICE_PERIODS_REQUEST_SUCCEEDED = '[price-management] FETCH_PRICE_PERIODS_REQUEST_SUCCEEDED';
export const FETCH_PRICE_PERIODS_REQUEST_FAILED = '[price-management] FETCH_PRICE_PERIODS_REQUEST_FAILED';

export const FETCH_PRICE_PERIOD_REQUEST = '[price-management] FETCH_PRICE_PERIOD_REQUEST';
export const FETCH_PRICE_PERIOD_REQUEST_SENT = '[price-management] FETCH_PRICE_PERIOD_REQUEST_SENT';
export const FETCH_PRICE_PERIOD_REQUEST_SUCCEEDED = '[price-management] FETCH_PRICE_PERIOD_REQUEST_SUCCEEDED';
export const FETCH_PRICE_PERIOD_REQUEST_FAILED = '[price-management] FETCH_PRICE_PERIOD_REQUEST_FAILED';

export const FETCH_STATE_HOLIDAY_DATES_REQUEST = '[price-management] FETCH_STATE_HOLIDAY_DATES_REQUEST';
export const FETCH_STATE_HOLIDAY_DATES_REQUEST_SENT = '[price-management] FETCH_STATE_HOLIDAY_DATES_REQUEST_SENT';
export const FETCH_STATE_HOLIDAY_DATES_REQUEST_SUCCEEDED = '[price-management] FETCH_STATE_HOLIDAY_DATES_REQUEST_SUCCEEDED';
export const FETCH_STATE_HOLIDAY_DATES_REQUEST_FAILED = '[price-management] FETCH_STATE_HOLIDAY_DATES_REQUEST_FAILED';

export const UPDATE_STATE_HOLIDAY_DATES_REQUEST = '[price-management] UPDATE_STATE_HOLIDAY_DATES_REQUEST';
export const UPDATE_STATE_HOLIDAY_DATES_REQUEST_SENT = '[price-management] UPDATE_STATE_HOLIDAY_DATES_REQUEST_SENT';
export const UPDATE_STATE_HOLIDAY_DATES_REQUEST_SUCCEEDED = '[price-management] UPDATE_STATE_HOLIDAY_DATES_REQUEST_SUCCEEDED';
export const UPDATE_STATE_HOLIDAY_DATES_REQUEST_FAILED = '[price-management] UPDATE_STATE_HOLIDAY_DATES_REQUEST_FAILED';

export const UPDATE_PRICES_BY_TIME_PERIOD_REQUEST = '[price-management] UPDATE_PRICES_BY_TIME_PERIOD_REQUEST';
export const UPDATE_PRICES_BY_TIME_PERIOD_REQUEST_SENT = '[price-management] UPDATE_PRICES_BY_TIME_PERIOD_REQUEST_SENT';
export const UPDATE_PRICES_BY_TIME_PERIOD_REQUEST_SUCCEEDED = '[price-management] UPDATE_PRICES_BY_TIME_PERIOD_REQUEST_SUCCEEDED';
export const UPDATE_PRICES_BY_TIME_PERIOD_REQUEST_FAILED = '[price-management] UPDATE_PRICES_BY_TIME_PERIOD_REQUEST_FAILED';

export const GET_CP_FIXED_PRICES_REQUEST = '[price-management] GET_CP_FIXED_PRICES_REQUEST';
export const GET_CP_FIXED_PRICES_REQUEST_SENT = '[price-management] GET_CP_FIXED_PRICES_REQUEST_SENT';
export const GET_CP_FIXED_PRICES_REQUEST_SUCCEEDED = '[price-management] GET_CP_FIXED_PRICES_REQUEST_SUCCEEDED';
export const GET_CP_FIXED_PRICES_REQUEST_FAILED = '[price-management] GET_CP_FIXED_PRICES_REQUEST_FAILED';

export const CREATE_PRICE_PERIOD_REQUEST = '[price-management] CREATE_PRICE_PERIOD_REQUEST';
export const CREATE_PRICE_PERIOD_REQUEST_SENT = '[price-management] CREATE_PRICE_PERIOD_REQUEST_SENT';
export const CREATE_PRICE_PERIOD_REQUEST_SUCCEEDED = '[price-management] CREATE_PRICE_PERIOD_REQUEST_SUCCEEDED';
export const CREATE_PRICE_PERIOD_REQUEST_FAILED = '[price-management] CREATE_PRICE_PERIOD_REQUEST_FAILED';

export const CREATE_CONTRACT_PARTNER_FIXED_PRICE_PERIOD_REQUEST = '[price-management] CREATE_CONTRACT_PARTNER_FIXED_PRICE_PERIOD_REQUEST';
export const CREATE_CONTRACT_PARTNER_FIXED_PRICE_PERIOD_REQUEST_SENT = '[price-management] CREATE_CONTRACT_PARTNER_FIXED_PRICE_PERIOD_REQUEST_SENT';
export const CREATE_CONTRACT_PARTNER_FIXED_PRICE_PERIOD_REQUEST_SUCCEEDED = '[price-management] CREATE_CONTRACT_PARTNER_FIXED_PRICE_PERIOD_REQUEST_SUCCEEDED';
export const CREATE_CONTRACT_PARTNER_FIXED_PRICE_PERIOD_REQUEST_FAILED = '[price-management] CREATE_CONTRACT_PARTNER_FIXED_PRICE_PERIOD_REQUEST_FAILED';

export const FETCH_ACTIVE_FIXED_PRICE_FOR_CP_REQUEST = '[price-management] FETCH_ACTIVE_FIXED_PRICE_FOR_CP_REQUEST';
export const FETCH_ACTIVE_FIXED_PRICE_FOR_CP_REQUEST_SENT = '[price-management] FETCH_ACTIVE_FIXED_PRICE_FOR_CP_REQUEST_SENT';
export const FETCH_ACTIVE_FIXED_PRICE_FOR_CP_REQUEST_SUCCEEDED = '[price-management] FETCH_ACTIVE_FIXED_PRICE_FOR_CP_REQUEST_SUCCEEDED';
export const FETCH_ACTIVE_FIXED_PRICE_FOR_CP_REQUEST_FAILED = '[price-management] FETCH_ACTIVE_FIXED_PRICE_FOR_CP_REQUEST_FAILED';

export const GET_BULK_PRICE_PERIODS_REQUEST = '[price-management] GET_BULK_PRICE_PERIODS_REQUEST';
export const GET_BULK_PRICE_PERIODS_REQUEST_SENT = '[price-management] GET_BULK_PRICE_PERIODS_REQUEST_SENT';
export const GET_BULK_PRICE_PERIODS_REQUEST_SUCCEEDED = '[price-management] GET_BULK_PRICE_PERIODS_REQUEST_SUCCEEDED';
export const GET_BULK_PRICE_PERIODS_REQUEST_FAILED = '[price-management] GET_BULK_PRICE_PERIODS_REQUEST_FAILED';

export const DELETE_FIXED_PRICE_PERIOD_REQUEST = '[price-management] DELETE_FIXED_PRICE_PERIOD_REQUEST';
export const DELETE_FIXED_PRICE_PERIOD_REQUEST_SENT = '[price-management] DELETE_FIXED_PRICE_PERIOD_REQUEST_SENT';
export const DELETE_FIXED_PRICE_PERIOD_REQUEST_SUCCEEDED = '[price-management] DELETE_FIXED_PRICE_PERIOD_REQUEST_SUCCEEDED';
export const DELETE_FIXED_PRICE_PERIOD_REQUEST_FAILED = '[price-management] DELETE_FIXED_PRICE_PERIOD_REQUEST_FAILED';

export const UPDATE_PRICE_PERIOD_REQUEST = '[price-management] UPDATE_PRICE_PERIOD_REQUEST';
export const UPDATE_PRICE_PERIOD_REQUEST_SENT = '[price-management] UPDATE_PRICE_PERIOD_REQUEST_SENT';
export const UPDATE_PRICE_PERIOD_REQUEST_SUCCEEDED = '[price-management] UPDATE_PRICE_PERIOD_REQUEST_SUCCEEDED';
export const UPDATE_PRICE_PERIOD_REQUEST_FAILED = '[price-management] UPDATE_PRICE_PERIOD_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_TIME_PERIODS = '[price-management] STORE_TIME_PERIODS';
export const SET_HOLIDAYS_LOADING = '[price-management] SET_HOLIDAYS_LOADING';
export const RESET_HOLIDAYS_LOADING = '[price-management] RESET_HOLIDAYS_LOADING';
export const SET_HOLIDAYS_ERROR = '[price-management] SET_HOLIDAYS_ERROR';
export const RESET_HOLIDAYS_ERROR = '[price-management] RESET_HOLIDAYS_ERROR';
export const STORE_PRICES_BY_TIME = '[price-management] STORE_PRICES_BY_TIME';
export const STORE_STATE_HOLIDAY_DATES = '[price-management] STORE_STATE_HOLIDAY_DATES';
export const STORE_FIXED_PRICES = '[price-management] STORE_FIXED_PRICES';
export const SET_ACTIVE_PERIOD_FIXED_PRICE = '[price-management] SET_ACTIVE_PERIOD_FIXED_PRICE';
export const SET_UPDATED_FIXED_PRICES = '[price-management] SET_UPDATED_FIXED_PRICES';
export const REMOVE_DELETED_FIXED_PRICE = '[price-management] REMOVE_DELETED_FIXED_PRICE';
export const STORE_UPDATED_PERIOD = '[price-management] STORE_UPDATED_PERIOD';
export const SET_PRICES_PERSISTENCE_STATE = '[price-management] SET_PRICES_PERSISTENCE_STATE';
export const RESET_PRICES_BY_TIME = '[price-management] RESET_PRICES_BY_TIME';
