import {fork, put, select, take} from 'redux-saga/effects';
import {alfAccountTypes, alfClientTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

const fetchContractPartnerBCDetails = function* fetchContractPartnerBCDetails() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_BC_DETAILS);
        const {searchQueryParams} = payload;

        yield fork(
            fetchRequest,
            contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_BC_DETAILS_REQUEST,
            leaAssignmentFlowService.getContacts,
            {searchQueryParams},
        );

        yield put({
            type: contractPartnerActionTypes.SET_ARE_CONTRACT_PARTNER_BC_DETAILS_LOADING,
            payload: {areContractPartnerBCDetailsLoading: true},
        });

        const responseAction = yield take([
            contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_BC_DETAILS_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_BC_DETAILS_REQUEST_FAILED,
        ]);

        yield put({
            type: contractPartnerActionTypes.SET_ARE_CONTRACT_PARTNER_BC_DETAILS_LOADING,
            payload: {areContractPartnerBCDetailsLoading: false},
        });

        if (!responseAction.error) {
            const {payload: searchResultsPayload} = responseAction;
            const {contactDTOs} = searchResultsPayload.response;

            if (contactDTOs.length === 0) {
                yield put({
                    type: contractPartnerActionTypes.SET_IS_CONTRACT_PARTNER_BC_DETAILS_ERROR,
                    payload: {isContractPartnerBCDetailsError: true},
                });
                continue;
            }

            // store BC contacts
            yield put({
                type: contractPartnerActionTypes.STORE_CONTACTS,
                payload: {
                    contactDTOs,
                },
            });

            let bankAccount = null;

            if (contactDTOs[0].creditorId) {
                yield fork(
                    fetchRequest,
                    contractPartnerActionTypes.FETCH_CP_ACCOUNT_PARTY_DETAILS_REQUEST,
                    leaAssignmentFlowService.getAccountParty,
                    {
                        accountPartyId: contactDTOs[0].creditorId,
                        accountPartyType: alfAccountTypes.CREDITOR,
                        client: alfClientTypes.ACE,
                    },
                );

                const accountPartyResponseAction = yield take([
                    contractPartnerActionTypes.FETCH_CP_ACCOUNT_PARTY_DETAILS_REQUEST_SUCCEEDED,
                    contractPartnerActionTypes.FETCH_CP_ACCOUNT_PARTY_DETAILS_REQUEST_FAILED,
                ]);
                if (!accountPartyResponseAction.error) {
                    const {response: accountPartyResponse} = accountPartyResponseAction.payload;
                    const {accountPartyDTO} = accountPartyResponse;
                    bankAccount = accountPartyDTO.bankAccount;
                    // https://computerrock.atlassian.net/browse/ACELEA-1883
                    // display 'paymentTermsCode' field as part of the bank data
                    if (bankAccount) bankAccount['paymentTermsCode'] = accountPartyDTO['paymentTermsCode'] || null;

                    // store account parties
                    yield put({
                        type: contractPartnerActionTypes.STORE_CONTACTS,
                        payload: {
                            accountPartyDTOs: [accountPartyDTO],
                        },
                    });
                }
            }

            yield put({
                type: contractPartnerActionTypes.STORE_CONTRACT_PARTNER_BC_DETAILS,
                payload: {contractPartnerBCDetails: contactDTOs[0], bankAccount},
            });

            yield put({
                type: contractPartnerActionTypes.SET_IS_CONTRACT_PARTNER_BC_DETAILS_ERROR,
                payload: {isContractPartnerBCDetailsError: false},
            });
            continue;
        }
        yield put({
            type: contractPartnerActionTypes.SET_IS_CONTRACT_PARTNER_BC_DETAILS_ERROR,
            payload: {isContractPartnerBCDetailsError: true},
        });
    }
};

export default fetchContractPartnerBCDetails;
