import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {camelCase, snakeCase} from 'change-case';
import {persistenceStates} from '@computerrock/formation-core';
import {withRouter, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ButtonPrimary, NoResultsBlock, Panel, useStyles, Pill, Paginator, HighlightCircle, LoaderSpinner} from '@ace-de/ui-components';
import {Table, TableCaption, TableHead, TableRow, TableCell, TableBody} from '@ace-de/ui-components/data-elements';
import {Icon, findCaseIcon, InteractiveIcon, resetIcon, searchIcon, calendarIcon, ratingPositiveIcon, ratingNegativeIcon, downloadIcon, noAccessIcon, infoAlertIcon} from '@ace-de/ui-components/icons';
import {Form, SelectField, Option, DateField, AutocompleteField} from '@ace-de/ui-components/form';
import {apmContractPartnerRegionTypes, apmQualityManagementNegativeFeedbackCategoryTypes, apmQualityManagementPositiveFeedbackCategoryTypes, apmGenericQMFeedbackCategoryTypes, apmQualityManagementFeedbackChannelTypes, apmQualityManagementFeedbackClassificationTypes} from '@ace-de/eua-entity-types';
import * as contractPartnerActionTypes from '../contract-partners/contractPartnerActionTypes';
import * as qualityManagementActionTypes from './qualityManagementFeedbackActionTypes';
import * as qualityManagementSelectors from './qualityManagementFeedbackSelectors';
import * as contractPartnerSelectors from '../contract-partners/contractPartnerSelectors';
import routePaths from '../routePaths';
import config from '../config';
import formatECSServiceCaseScreenURL from '../utils/formatECSServiceCaseScreenURL';

const initialQMFeedbacksFilteringParams = {
    createdAfter: moment().subtract(config.DEFAULT_TIME_PERIOD_IN_MONTHS, 'months'),
    createdBefore: moment(),
    categories: ['ALL'],
    isQualified: '',
    createdByUser: ['ALL'],
    regions: ['ALL'],
    classifications: ['ALL'],
    searchTerm: '',
    channel: '',
};
const dateSortingOptions = {
    ASC: 'ASC',
    DESC: 'DESC',
};
const formTypes = {
    FILTER: 'FILTER',
    SORT: 'SORT',
};
const getValuesFromQueryString = (queryString, formType = formTypes.FILTER) => {
    const queryParams = new URLSearchParams(queryString);
    if (formType === formTypes.SORT) {
        return {
            sort: queryParams.get('sort') || `createdAt,${dateSortingOptions.DESC}`,
        };
    }
    return {
        createdAfter: queryParams.get('createdAfter')
            || moment().subtract(config.DEFAULT_TIME_PERIOD_IN_MONTHS, 'months'),
        createdBefore: queryParams.get('createdBefore')
            || moment(),
        categories: queryParams.get('categories') || ['ALL'],
        isQualified: queryParams.get('isQualified') || '',
        createdByUser: queryParams.get('createdByUser') || ['ALL'],
        regions: queryParams.get('regions') || ['ALL'],
        classifications: queryParams.get('classifications') || ['ALL'],
        searchTerm: queryParams.get('searchTerm') || '',
        channel: queryParams.get('channel') || '',
    };
};

const QualityManagementFeedbackSearchScreen = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateScreen = createTranslateShorthand('quality_management_screen');
    const {initiateCreateCPQualityReport, history} = props;
    const {qualityManagementFeedbacksSearchResults, qualityManagementFeedbacksSearchCount} = props;
    const {contractPartners, users, initiateDownloadQMFeedback} = props;
    const {positiveQMFeedbacksCount, negativeQMFeedbacksCount, qmFeedbacksSearchPersistenceState} = props;
    const [filterFormData, setFilterFormData] = useState(history.location.search
        ? getValuesFromQueryString(history.location.search) : initialQMFeedbacksFilteringParams);
    const resetRef = useRef(false);
    const [sortFormData, setSortFormData] = useState(history.location.search
        ? getValuesFromQueryString(history.location.search, formTypes.SORT) : {
            sort: `createdAt,${dateSortingOptions.DESC}`,
        });
    const [endDateError, setEndDateError] = useState(false);
    const [startDateError, setStartDateError] = useState(false);
    const [queryParams, setQueryParams] = useState(new URLSearchParams(history.location.search));
    const topElementRef = useRef(null);
    const paginatorCount = Math.ceil(qualityManagementFeedbacksSearchCount / config.DEFAULT_PAGE_SIZE);

    useEffect(() => {
        if (typeof history.location.search === 'string') {
            const newQueryParams = new URLSearchParams(history.location.search);
            if (newQueryParams.toString() !== queryParams.toString()) {
                setQueryParams(newQueryParams);
            }
        }
    }, [history.location.search, queryParams]);

    const sortedContractPartners = useMemo(() => {
        return contractPartners
            ? Object.values(contractPartners).sort((contractPartnerA, contractPartnerB) => (
                contractPartnerA.name > contractPartnerB.name ? 1 : -1
            )) : [];
    }, [contractPartners]);

    const formatQueryParams = formData => {
        if (!formData) return;
        const apiQueryParams = new URLSearchParams();

        Object.keys(formData).forEach(formField => {
            if (formField === 'qualityManagementFeedbackSortingForm') return;
            if (['categories', 'createdByUser', 'regions', 'classifications'].includes(formField)) {
                formData[formField].forEach(fieldValue => {
                    if (fieldValue !== 'ALL') {
                        apiQueryParams.append(`${formField}`, fieldValue);
                    }
                });
                return;
            }
            if (formField === 'createdAfter' && formData.createdAfter && moment(formData.createdAfter).isValid()) {
                apiQueryParams.append('createdAfter', new Date(formData['createdAfter']).toISOString());
                setStartDateError(false);
                return;
            }
            if (formField === 'createdBefore' && formData.createdBefore && moment(formData.createdBefore).isValid()) {
                const date = new Date(formData['createdBefore']);
                const endDate = moment(date.setHours(23, 59, 59, 59)).format();
                apiQueryParams.append('createdBefore', endDate);
                setEndDateError(false);
                return;
            }
            if (formData[formField] !== undefined && formData[formField] !== '') {
                apiQueryParams.append(`${formField}`, formData[formField]);
            }

            if (formData.createdAfter === '') setStartDateError(true);
            if (formData.createdBefore === '') setEndDateError(true);
        });
        return apiQueryParams;
    };

    const createQualityManagementFeedback = () => {
        initiateCreateCPQualityReport();
    };

    const handleOnChange = formValues => {
        if (resetRef.current) {
            resetRef.current = false;
            return;
        }
        setFilterFormData({
            ...initialQMFeedbacksFilteringParams,
            ...formValues,
        });
        const apiQueryParams = formatQueryParams({
            ...formValues,
            ...sortFormData,
        });
        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';

        history.replace(resolveRoute(routePaths.QUALITY_MANAGEMENT_FEEDBACKS_SEARCH, {}, {search: queryParamsString}));
    };

    const handleResetFilter = () => {
        resetRef.current = true;
        setFilterFormData({...initialQMFeedbacksFilteringParams});

        const queryParams = new URLSearchParams(history.location.search);
        const apiQueryParams = formatQueryParams(sortFormData);
        const queryParamsString = queryParams.get('sort') ? apiQueryParams.toString() : '';

        history.replace(resolveRoute(routePaths.QUALITY_MANAGEMENT_FEEDBACKS_SEARCH, {}, {search: queryParamsString}));
    };

    const handleDownloadTable = () => {
        const apiQueryParams = formatQueryParams({
            ...filterFormData,
            countries: config.CONTRACT_PARTNERS_SEARCH_COUNTRY_CODE,
        });
        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        const fileName = translateScreen('file.name', {
            currentDate: moment().format('YYMMDD'),
        });

        initiateDownloadQMFeedback({
            url: config.ACE_APM_QUALITY_MANAGEMENT_FEEDBACKS_EXPORT_ENDPOINT + `?${queryParamsString}`,
            fileName: `${fileName}.xlsx`,
        });
    };

    const handleOnSort = formValues => {
        setSortFormData({
            ...formValues,
        });

        const apiQueryParams = formValues ? formatQueryParams({
            ...filterFormData,
            ...formValues,
        }) : null;

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';

        history.replace(resolveRoute(
            routePaths.QUALITY_MANAGEMENT_FEEDBACKS_SEARCH,
            {},
            {search: queryParamsString},
        ));
    };

    const handlePaginationPage = page => {
        topElementRef.current?.scrollIntoView({behavior: 'smooth'});
        const apiQueryParams = new URLSearchParams(queryParams);
        apiQueryParams.set('page', `${page}`);

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.QUALITY_MANAGEMENT_FEEDBACKS_SEARCH, {}, {search: queryParamsString}));
    };

    const handleQMFeedbackRedirection = ({qualityManagementFeedbackId}) => {
        history.push(resolveRoute(routePaths.QUALITY_MANAGEMENT_FEEDBACK, {qualityManagementFeedbackId}));
    };

    return (
        <Fragment>
            <Panel ref={topElementRef}>
                <div
                    className={cx([
                        'global!ace-u-margin--bottom-48',
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-space-between',
                        'global!ace-u-flex--align-flex-start',
                    ])}
                >
                    <h3
                        className={cx([
                            'global!ace-u-typography--variant-h3',
                            'global!ace-u-flex--basis-60',
                        ])}
                    >
                        {translateScreen('panel_header.filter')}
                    </h3>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-flex-end',
                            'global!ace-u-flex--align-flex-end',
                            'global!ace-u-flex--basis-40',
                        ])}
                    >
                        <div className={cx('global!ace-u-flex--basis-60')}>
                            <p className={cx('global!ace-u-typography--variant-caption')}>
                                {translateScreen('input_label.period_statistics')}
                            </p>
                            <div
                                className={cx([
                                    'global!ace-u-margin--top-8',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--justify-flex-start',
                                    'global!ace-u-flex--align-center',
                                ])}
                            >
                                <Pill type="pending" className={cx('global!ace-u-padding--24-32')}>
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-flex--justify-space-between',
                                        ])}
                                    >
                                        <p
                                            className={cx([
                                                'global!ace-u-margin--right-16',
                                                'global!ace-u-typography--variant-h3',
                                                'global!ace-u-typography--color-warning',
                                            ])}
                                        >
                                            {negativeQMFeedbacksCount}
                                        </p>
                                        <Icon icon={ratingNegativeIcon} />
                                    </div>
                                </Pill>
                                <Pill
                                    type="pending"
                                    className={cx(['global!ace-u-padding--24-32', 'global!ace-u-margin--left-16'])}
                                >
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-flex--justify-space-between',
                                        ])}
                                    >
                                        <p
                                            className={cx([
                                                'global!ace-u-margin--right-16',
                                                'global!ace-u-typography--variant-h3',
                                                'global!ace-u-typography--color-success',
                                            ])}
                                        >
                                            {positiveQMFeedbacksCount}
                                        </p>
                                        <Icon icon={ratingPositiveIcon} />
                                    </div>
                                </Pill>
                            </div>
                        </div>
                        <ButtonPrimary
                            onClick={createQualityManagementFeedback}
                            className={cx('global!ace-u-flex--basis-30')}
                        >
                            {translateScreen('button_label.new_entry')}
                        </ButtonPrimary>
                    </div>
                </div>
                <Form name="qualityManagementFeedbackSearchForm" onChange={handleOnChange}>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--column-gap-24',
                            'global!ace-u-margin--bottom-32',
                        ])}
                    >
                        <DateField
                            label={translateScreen('input_label.time_period')}
                            name="createdAfter"
                            placeholder={translateScreen('input_placeholder.no_restriction')}
                            className={cx(['global!ace-u-width--256', 'global!ace-u-min-width--256'])}
                            icon={calendarIcon}
                            value={filterFormData.createdAfter}
                            maxDate={moment(filterFormData.createdBefore).format()}
                            errors={startDateError
                                ? [translateScreen('start_date_error_message.please_select_start_date')]
                                : []}
                        />
                        <DateField
                            label={translateScreen('input_label.end_date')}
                            name="createdBefore"
                            placeholder={translateScreen('input_placeholder.end_date')}
                            className={cx(['global!ace-u-width--256', 'global!ace-u-min-width--256'])}
                            icon={calendarIcon}
                            value={filterFormData.createdBefore}
                            minDate={moment(filterFormData.createdAfter).format()}
                            errors={endDateError
                                ? [translateScreen('end_date_error_message.please_select_end_date')]
                                : []}
                        />
                        <SelectField
                            name="categories"
                            label={translateScreen('input_label.category')}
                            className={cx(['global!ace-u-width--256', 'global!ace-u-min-width--256'])}
                            isMultipleChoice={true}
                            placeholder={translateScreen('input_placeholder.all')}
                            value={filterFormData.categories}
                        >
                            {Object.keys(apmQualityManagementPositiveFeedbackCategoryTypes)
                                .concat(Object.keys(apmQualityManagementNegativeFeedbackCategoryTypes))
                                .concat(Object.keys(apmGenericQMFeedbackCategoryTypes))
                                .map(category => (
                                    <Option
                                        key={category}
                                        value={category}
                                        name={`qualityFeedbackCategoryOption${camelCase(category)}`}
                                    >
                                        {translate(`global.quality_management_feedback_category.${snakeCase(category)}`)}
                                    </Option>
                                ))}
                        </SelectField>
                        <SelectField
                            name="channel"
                            label={translateScreen('input_label.channel')}
                            className={cx(['global!ace-u-width--256', 'global!ace-u-min-width--256'])}
                            placeholder={translateScreen('input_placeholder.all')}
                            value={filterFormData.channel || ''}
                        >
                            {Object.keys(apmQualityManagementFeedbackChannelTypes).map(channel => (
                                <Option
                                    key={channel}
                                    value={channel}
                                    name={`qualityManagementFeedbackChannelOption${camelCase(channel)}`}
                                >
                                    {translate(`global.quality_management_feedback_channel.${snakeCase(channel)}`)}
                                </Option>
                            ))}
                        </SelectField>
                        <SelectField
                            name="isQualified"
                            label={translateScreen('input_label.qualification')}
                            className={cx(['global!ace-u-width--256', 'global!ace-u-min-width--256'])}
                            placeholder={translateScreen('input_placeholder.all')}
                            value={filterFormData.isQualified}
                        >
                            <Option
                                value={false}
                                name="qualityManagementFeedbackStatusOptionOpen"
                            >
                                {translate('global.quality_management_feedback_status.open')}
                            </Option>
                            <Option
                                value={true}
                                name="qualityManagementFeedbackStatusOptionQualified"
                            >
                                {translate('global.quality_management_feedback_status.qualified')}
                            </Option>
                        </SelectField>
                        <SelectField
                            name="classifications"
                            label={translateScreen('input_label.classification')}
                            className={cx(['global!ace-u-width--256', 'global!ace-u-min-width--256'])}
                            placeholder={translateScreen('input_placeholder.all')}
                            value={filterFormData.classifications || ''}
                            isMultipleChoice={true}
                        >
                            {Object.keys(apmQualityManagementFeedbackClassificationTypes).map(classificationType => (
                                <Option
                                    key={classificationType}
                                    value={classificationType}
                                    name={`qualityManagementFeedbackChannelOption${camelCase(classificationType)}`}
                                >
                                    {translate(`global.quality_management_feedback_classification_type.${snakeCase(classificationType)}`)}
                                </Option>
                            ))}
                        </SelectField>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--column-gap-24',
                            'global!ace-u-margin--bottom-32',
                        ])}
                    >
                        <SelectField
                            name="createdByUser"
                            label={translateScreen('input_label.recorded_by')}
                            className={cx(['global!ace-u-width--256', 'global!ace-u-min-width--256'])}
                            isMultipleChoice={true}
                            placeholder={translateScreen('input_placeholder.emergency_advisor')}
                            value={filterFormData.createdByUser}
                        >
                            {users && Object.values(users).map(user => (
                                <Option
                                    key={user.id}
                                    value={`${user.firstName} ${user.lastName}`}
                                    name={`reportedByOption${user.id}`}
                                >
                                    {`${user.firstName} ${user.lastName}`}
                                </Option>
                            ))}
                        </SelectField>
                        <SelectField
                            name="regions"
                            label={translateScreen('input_label.region')}
                            className={cx(['global!ace-u-width--256', 'global!ace-u-min-width--256'])}
                            isMultipleChoice={true}
                            placeholder={translateScreen('input_placeholder.all')}
                            value={filterFormData.regions}
                        >
                            {Object.keys(apmContractPartnerRegionTypes).map(region => (
                                <Option key={region} value={region} name={`regionOption${camelCase(region)}`}>
                                    {translate(`global.region.${snakeCase(region)}`)}
                                </Option>
                            ))}
                        </SelectField>
                        <Form name="qualityManagementFeedbackSortingForm" onChange={handleOnSort}>
                            <SelectField
                                name="sort"
                                label={translateScreen('input_label.sort')}
                                className={cx(['global!ace-u-width--256', 'global!ace-u-min-width--256'])}
                                value={sortFormData.sort}
                            >
                                {Object.keys(dateSortingOptions)
                                    .map(sortingOption => (
                                        <Option
                                            key={sortingOption}
                                            value={`createdAt,${sortingOption}`}
                                            name={`dateSortingOption${camelCase(sortingOption)}`}
                                        >
                                            {translateScreen(`input_option.${snakeCase(sortingOption)}`)}
                                        </Option>
                                    ))}
                            </SelectField>
                        </Form>
                        <AutocompleteField
                            name="searchTerm"
                            label={translateScreen('input_label.contract_partner')}
                            className={cx('global!ace-u-flex--grow-1')}
                            icon={searchIcon}
                            value={filterFormData.searchTerm}
                        >
                            {sortedContractPartners.map(contractPartner => (
                                <Option
                                    key={contractPartner.id}
                                    name={`contractPartnerId-${contractPartner.id}`}
                                    value={contractPartner.name}
                                >
                                    {contractPartner.name}
                                </Option>
                            ))}
                        </AutocompleteField>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-space-between',
                        ])}
                    >
                        <div>
                            <span className={cx('global!ace-u-typography--variant-body')}>
                                {translateScreen('label.result_count')}
                            </span>&nbsp;
                            <span className={cx('global!ace-u-typography--variant-body-medium')}>
                                {qualityManagementFeedbacksSearchCount}
                            </span>
                        </div>
                        <div>
                            <InteractiveIcon
                                icon={resetIcon}
                                className={cx([
                                    'ace-c-interactive-icon--reverse',
                                    'ace-c-interactive-icon--highlight',
                                    'global!ace-u-flex--basis-50',
                                ])}
                                onClick={handleResetFilter}
                            >
                                {translateScreen('interactive_icon_label.reset_filter')}
                            </InteractiveIcon>
                        </div>
                    </div>
                </Form>
            </Panel>
            <Panel className={cx('ace-c-panel--full-bleed')}>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--justify-space-between',
                        'global!ace-u-flex--align-center',
                    ])}
                >
                    <h3
                        className={cx([
                            'global!ace-u-margin--left-32',
                            'global!ace-u-typography--variant-h3',
                            'global!ace-u-flex--basis-60',
                        ])}
                    >
                        {translateScreen('panel_header.quality_notifications')}
                    </h3>
                    <InteractiveIcon
                        icon={downloadIcon}
                        className={cx([
                            'ace-c-interactive-icon--reverse',
                            'ace-c-interactive-icon--highlight',
                            'global!ace-u-margin--32',
                        ])}
                        onClick={handleDownloadTable}
                        isDisabled={!qualityManagementFeedbacksSearchResults.length}
                    >
                        {translateScreen('interactive_icon_label.download')}
                    </InteractiveIcon>
                </div>
                <Table>
                    {qmFeedbacksSearchPersistenceState === persistenceStates.PENDING && (
                        <TableCaption>
                            <HighlightCircle
                                className={cx([
                                    'ace-c-highlight-circle--medium',
                                    'ace-c-highlight-circle--primary-highlight',
                                    'global!ace-u-margin--top-64',
                                ])}
                            >
                                <LoaderSpinner />
                            </HighlightCircle>
                            <p
                                className={cx([
                                    'global!ace-u-margin--top-16',
                                    'global!ace-u-margin--bottom-64',
                                    'global!ace-u-typography--variant-h4',
                                ])}
                            >
                                {translateScreen('loader_message.search_in_progress')}
                            </p>
                        </TableCaption>
                    )}
                    {qmFeedbacksSearchPersistenceState !== persistenceStates.PENDING
                        && qualityManagementFeedbacksSearchResults.length === 0 && (
                        <TableCaption>
                            <NoResultsBlock
                                colorVariant={qmFeedbacksSearchPersistenceState === persistenceStates.FAILED
                                    ? 'negative'
                                    : 'primary-highlight'
                                }
                                icon={(
                                    <Icon
                                        className={cx('ace-c-icon--xxl', {
                                            'global!ace-c-icon--color-highlight': qmFeedbacksSearchPersistenceState !== persistenceStates.FAILED,
                                            'global!ace-c-icon--color-warning': qmFeedbacksSearchPersistenceState === persistenceStates.FAILED,
                                        })}
                                        icon={qmFeedbacksSearchPersistenceState === persistenceStates.FAILED
                                            ? infoAlertIcon
                                            : findCaseIcon
                                    }
                                    />
                                )}
                                message={qmFeedbacksSearchPersistenceState === persistenceStates.FAILED
                                    ? translateScreen('results_error.message')
                                    : translateScreen('no_results.message')
                            }
                            />
                        </TableCaption>
                    )}
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {translateScreen('table_header.entry')}
                            </TableCell>
                            <TableCell colSpan={2}>
                                {translateScreen('table_header.contract_partner')}
                            </TableCell>
                            <TableCell>
                                {translateScreen('table_header.channel')}
                            </TableCell>
                            <TableCell colSpan={3}>
                                {translateScreen('table_header.category')}
                            </TableCell>
                            <TableCell>
                                {translateScreen('table_header.case_number')}
                            </TableCell>
                            <TableCell>
                                {translateScreen('table_header.recorded_by')}
                            </TableCell>
                            <TableCell>
                                {translateScreen('table_header.qualification')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {qmFeedbacksSearchPersistenceState === persistenceStates.READY
                            && !!qualityManagementFeedbacksSearchResults?.length
                            && qualityManagementFeedbacksSearchResults.map((feedback, index) => (
                                <TableRow
                                    key={index}
                                    className={cx([
                                        {'global!ace-u-typography--variant-body-bold': !feedback.isQualified},
                                        {'global!ace-u-typography--color-disabled': feedback.classification === apmQualityManagementFeedbackClassificationTypes.NEUTRAL && feedback.isQualified},
                                    ])}
                                    onClick={() => handleQMFeedbackRedirection({
                                        qualityManagementFeedbackId: feedback.id,
                                    })}
                                >
                                    <TableCell>
                                        {moment(feedback.createdAt).format('DD.MM.YYYY HH:mm')}
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        <span
                                            className={cx('global!ace-u-cursor--pointer')}
                                        >
                                            {feedback.partnerName || ''}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        {feedback.qualityManagementFeedbackChannel
                                            ? translate(`global.quality_management_feedback_channel.${snakeCase(feedback.qualityManagementFeedbackChannel)}`)
                                            : translate('global.quality_management_feedback_channel.manual')}
                                    </TableCell>
                                    <TableCell colSpan={3}>
                                        <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--align-center'])}>
                                            {!!feedback.classification && (
                                                <Icon
                                                    className={cx(['global!ace-u-margin--right-16', 'global!ace-u-min-width--24'])}
                                                    icon={feedback.classification === apmQualityManagementFeedbackClassificationTypes.POSITIVE // eslint-disable-line max-len
                                                        ? ratingPositiveIcon
                                                        : feedback.classification === apmQualityManagementFeedbackClassificationTypes.NEGATIVE // eslint-disable-line max-len
                                                            ? ratingNegativeIcon
                                                            : noAccessIcon
                                                }
                                                />
                                            )}
                                            {feedback.categories.map(category => (
                                                translate(`global.quality_management_feedback_category.${snakeCase(category)}`)
                                            )).join(', ')}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        {feedback.caseId ? (
                                            <a
                                                href={formatECSServiceCaseScreenURL(feedback.caseId)}
                                                className={cx([
                                                    'global!ace-u-typography--variant-body',
                                                ])}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {[feedback.prefix, feedback.caseId].filter(value => !!value).join('-')}
                                            </a>
                                        ) : ''}
                                    </TableCell>
                                    <TableCell>
                                        {feedback.createdByUser}
                                    </TableCell>
                                    <TableCell>
                                        {translate(`global.quality_management_feedback_status.${feedback.isQualified ? 'qualified' : 'open'}`)}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </Panel>
            {qualityManagementFeedbacksSearchResults.length > 0 && (
                <Paginator
                    page={queryParams?.get('page') ? parseInt(queryParams.get('page'), 10) : 0}
                    count={paginatorCount}
                    onClick={handlePaginationPage}
                />
            )}
        </Fragment>
    );
};

QualityManagementFeedbackSearchScreen.propTypes = {
    initiateCreateCPQualityReport: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    qualityManagementFeedbacksSearchResults: PropTypes.array,
    qualityManagementFeedbacksSearchCount: PropTypes.number,
    contractPartners: PropTypes.object,
    users: PropTypes.object,
    initiateDownloadQMFeedback: PropTypes.func.isRequired,
    positiveQMFeedbacksCount: PropTypes.number.isRequired,
    negativeQMFeedbacksCount: PropTypes.number.isRequired,
    qmFeedbacksSearchPersistenceState: PropTypes.string.isRequired,
};

QualityManagementFeedbackSearchScreen.defaultProps = {
    qualityManagementFeedbacksSearchResults: [],
    qualityManagementFeedbacksSearchCount: 0,
    contractPartners: {},
    users: {},
};

const mapStateToProps = state => {
    return {
        qualityManagementFeedbacksSearchResults: qualityManagementSelectors.getQMFeedbackSearchResults(state),
        qualityManagementFeedbacksSearchCount: qualityManagementSelectors.getQMFeedbackSearchCount(state),
        contractPartners: contractPartnerSelectors.getContractPartners(state),
        users: state.user.users,
        positiveQMFeedbacksCount: state.qualityManagementFeedbacks.positiveQMFeedbacksCount,
        negativeQMFeedbacksCount: state.qualityManagementFeedbacks.negativeQMFeedbacksCount,
        qmFeedbacksSearchPersistenceState: state.qualityManagementFeedbacks.qmFeedbacksSearchPersistenceState,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateCreateCPQualityReport: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CREATE_CONTRACT_PARTNER_QUALITY_REPORT_FLOW,
        payload,
    }),
    initiateDownloadQMFeedback: payload => dispatch({
        type: qualityManagementActionTypes.INITIATE_QUALITY_MANAGEMENT_FEEDBACKS_DOWNLOAD_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QualityManagementFeedbackSearchScreen));
