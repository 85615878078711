import {take, call, put, select, all} from 'redux-saga/effects';
import * as contractPartnerActionTypes from '../../contract-partners/contractPartnerActionTypes';

/**
 * Select contract partner flow
 */
const selectContractPartnerFlow = function* selectContractPartnerFlow() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.INITIATE_CONTRACT_PARTNER_SELECT_FLOW);
        const {contractPartner} = payload;

        const arcGISMap = yield call(arcGISMapService.getMap, 'vpm-contract-partners');

        if (!arcGISMap) return;

        const vpmContractPartnersLocationsLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-locations');
        const vpmContractPartnerServiceAreasLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-service-areas');

        if (vpmContractPartnersLocationsLayer && vpmContractPartnerServiceAreasLayer) {
            yield all([
                call(vpmContractPartnersLocationsLayer.selectFeatureByAttribute, {
                    where: `contractPa = '${contractPartner['contractPartnerId']}'`,
                }),
                call(vpmContractPartnerServiceAreasLayer.selectFeatureByAttribute, {
                    where: `contractPa = '${contractPartner['contractPartnerId']}'`,
                }),
            ]);
        }

        yield put({
            type: contractPartnerActionTypes.SET_SELECTED_CONTRACT_PARTNER,
            payload: {contractPartner},
        });
    }
};

export default selectContractPartnerFlow;
