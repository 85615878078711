import {fork, put, select, take} from 'redux-saga/effects';
import {persistenceStates} from '@computerrock/formation-core';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as priceManagementActionTypes from '../priceManagementActionTypes';

const fetchPricesByTimePeriod = function* fetchPricesByTimePeriod({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    const {pricePeriodId} = payload;

    yield put({
        type: priceManagementActionTypes.SET_PRICES_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.PENDING},
    });

    yield put({
        type: priceManagementActionTypes.RESET_PRICES_BY_TIME,
    });

    yield fork(
        fetchRequest,
        priceManagementActionTypes.FETCH_PRICE_PERIOD_REQUEST,
        pricingManagementService.getPricePeriod,
        {pricePeriodId},
    );

    const responseAction = yield take([
        priceManagementActionTypes.FETCH_PRICE_PERIOD_REQUEST_SUCCEEDED,
        priceManagementActionTypes.FETCH_PRICE_PERIOD_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {pricePeriodDTO} = response;

        yield put({
            type: priceManagementActionTypes.STORE_PRICES_BY_TIME,
            payload: {pricePeriodDTO},
        });

        yield put({
            type: priceManagementActionTypes.SET_PRICES_PERSISTENCE_STATE,
            payload: {persistenceState: persistenceStates.READY},
        });

        return;
    }

    yield put({
        type: priceManagementActionTypes.SET_PRICES_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.FAILED},
    });
};

export default fetchPricesByTimePeriod;
