import {take, select, fork, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as qualityManagementFeedbackActionTypes from '../qualityManagementFeedbackActionTypes';
import modalIds from '../../modalIds';
import qmFeedbackForwardingStatusTypes from '../qmFeedbackForwardingStatusTypes';

const forwardQMFeedbackFlow = function* forwardQMFeedbackFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    let shouldWaitForTheAction = true;
    while (true) {
        if (shouldWaitForTheAction) {
            yield take(qualityManagementFeedbackActionTypes.INITIATE_FORWARD_QM_FEEDBACK_FLOW);

            yield* openModal(modalIds.FORWARD_QM_FEEDBACK);
        }

        const chosenModalOption = yield take([
            qualityManagementFeedbackActionTypes.CONFIRM_FORWARD_QM_FEEDBACK,
            qualityManagementFeedbackActionTypes.DECLINE_FORWARD_QM_FEEDBACK,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === qualityManagementFeedbackActionTypes.DECLINE_FORWARD_QM_FEEDBACK) {
            shouldWaitForTheAction = true;
            // reset QM feedback forwarding status
            yield put({
                type: qualityManagementFeedbackActionTypes.STORE_QM_FEEDBACK_FORWARDED_STATUS,
                payload: {
                    qmFeedbackForwardingStatus: '',
                },
            });
            yield* closeModal(modalIds.FORWARD_QM_FEEDBACK);
            continue;
        }

        const {emailData, feedbackId} = chosenModalOption?.payload || {};
        yield fork(
            fetchRequest,
            qualityManagementFeedbackActionTypes.FORWARD_QM_FEEDBACK_REQUEST,
            partnerManagementService.forwardACEPartnerQualityManagementNote,
            {
                feedbackId,
                emailData,
            },
        );

        const responseAction = yield take([
            qualityManagementFeedbackActionTypes.FORWARD_QM_FEEDBACK_REQUEST_SUCCEEDED,
            qualityManagementFeedbackActionTypes.FORWARD_QM_FEEDBACK_REQUEST_FAILED,
        ]);

        if (responseAction.error) {
            yield put({
                type: qualityManagementFeedbackActionTypes.STORE_QM_FEEDBACK_FORWARDED_STATUS,
                payload: {
                    qmFeedbackForwardingStatus: qmFeedbackForwardingStatusTypes.FAILED,
                },
            });
            shouldWaitForTheAction = false;
            continue;
        }

        yield put({
            type: qualityManagementFeedbackActionTypes.STORE_QM_FEEDBACK_FORWARDED_STATUS,
            payload: {
                qmFeedbackForwardingStatus: qmFeedbackForwardingStatusTypes.SUCCEEDED,
            },
        });
        shouldWaitForTheAction = false;
    }
};

export default forwardQMFeedbackFlow;
