import {put, take, fork, select} from 'redux-saga/effects';
import {TemporaryRestriction} from '@ace-de/eua-entity-types';
import {persistenceStates} from '@computerrock/formation-core';
import {closeModal, openModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import errorTypes from '../../application/errorTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';

const createTemporaryRestrictionFlow = function* createTemporaryRestrictionFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    let shouldWaitForAction = true;
    let payloadData = null;
    let chosenModalOption = null;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contractPartnerActionTypes.INITIATE_CREATE_TEMPORARY_RESTRICTION_FLOW);
            payloadData = payload;
        }

        const {contractPartner} = payloadData;

        if (shouldWaitForAction) {
            yield* openModal(modalIds.CP_CREATE_TEMPORARY_RESTRICTION);

            chosenModalOption = yield take([
                contractPartnerActionTypes.DECLINE_CREATE_TEMPORARY_RESTRICTION,
                contractPartnerActionTypes.CONFIRM_CREATE_TEMPORARY_RESTRICTION,
            ]);
        }

        if (chosenModalOption?.type === contractPartnerActionTypes.DECLINE_CREATE_TEMPORARY_RESTRICTION) {
            yield* closeModal(modalIds.CP_CREATE_TEMPORARY_RESTRICTION);
            continue;
        }

        const {temporaryRestrictionData} = chosenModalOption?.payload;

        yield fork(
            fetchRequest,
            contractPartnerActionTypes.CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST,
            partnerManagementService.createContractPartnerTemporaryRestriction,
            {
                contractPartnerId: contractPartner.id,
                temporaryRestrictionData: TemporaryRestriction.objectToDTO(temporaryRestrictionData),
            },
        );

        const createTemporaryRestrictionResponseAction = yield take([
            contractPartnerActionTypes.CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.CREATE_CONTRACT_PARTNER_TEMPORARY_RESTRICTION_REQUEST_FAILED,
        ]);

        if (createTemporaryRestrictionResponseAction.error) {
            if (shouldWaitForAction) {
                yield* closeModal(modalIds.CP_CREATE_TEMPORARY_RESTRICTION);
                yield* openModal(modalIds.ERROR_MESSAGE_MODAL, {
                    errorType: errorTypes.CP_TEMPORARY_RESTRICTION_CREATION_FAILED,
                });
            }

            yield put({
                type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                payload: {persistenceState: persistenceStates.READY},
            });

            const nextAction = yield take([
                contractPartnerActionTypes.RETRY_CREATE_CP_TEMPORARY_RESTRICTION,
                contractPartnerActionTypes.CANCEL_RETRY_CREATE_CP_TEMPORARY_RESTRICTION,
            ]);

            shouldWaitForAction = nextAction.type !== contractPartnerActionTypes.RETRY_CREATE_CP_TEMPORARY_RESTRICTION;

            if (nextAction.type === contractPartnerActionTypes.RETRY_CREATE_CP_TEMPORARY_RESTRICTION) {
                yield put({
                    type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.PENDING},
                });
            }

            if (nextAction.type === contractPartnerActionTypes.CANCEL_RETRY_CREATE_CP_TEMPORARY_RESTRICTION) {
                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});
            }

            continue;
        }

        if (!createTemporaryRestrictionResponseAction.error) {
            if (!shouldWaitForAction) {
                yield* closeModal(modalIds.ERROR_MESSAGE_MODAL, {errorType: ''});

                yield put({
                    type: applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE,
                    payload: {persistenceState: persistenceStates.READY},
                });
            }
            shouldWaitForAction = true;
            const {response} = createTemporaryRestrictionResponseAction.payload;

            yield put({
                type: contractPartnerActionTypes.STORE_CONTRACT_PARTNER_RESTRICTIONS,
                payload: {
                    contractPartnerId: contractPartner.id,
                    contractPartnerRestrictionDTOs: [response.contractPartnerRestrictionDTO],
                },
            });

            yield* closeModal(modalIds.CP_CREATE_TEMPORARY_RESTRICTION);

            yield* openModal(modalIds.CP_TEMPORARY_RESTRICTION_SUCCESSFUL);
        }

        yield* closeModal(modalIds.CP_CREATE_TEMPORARY_RESTRICTION);
    }
};

export default createTemporaryRestrictionFlow;
