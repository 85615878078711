import {persistenceStates} from '@computerrock/formation-core';
import * as applicationActionTypes from './applicationActionTypes';

/**
 * Initial application domain state
 *
 * @type {Object}
 */
const initialState = {
    serviceManager: null,
    errorMessagePersistenceState: persistenceStates.READY,
};

/**
 * Application reducer
 *
 * @param state
 * @param {FluxStandardAction} action
 * @returns {Object}
 */
const applicationReducer = (state = initialState, action) => {
    state = {
        ...initialState,
        ...state,
    };

    switch (action.type) {
        case applicationActionTypes.SET_ERROR_MESSAGE_PERSISTENCE_STATE: {
            const {persistenceState} = action.payload;
            state.errorMessagePersistenceState = persistenceState;
            break;
        }

        default:
        // no-op
    }
    return state;
};

export default applicationReducer;
