import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {resolveRoute} from '@computerrock/formation-router';
import {ContentBlock, ContentItem, Divider, NoResultsBlock, Paginator, Panel, useStyles} from '@ace-de/ui-components';
import {Icon, historyIcon} from '@ace-de/ui-components/icons';
import routePaths from '../routePaths';
import LogEntry from './ui-elements/QMFeedbackCaseLogEntry';
import config from '../config';
import QMFeedbackCaseLogFilterPanel from './ui-elements/QMFeedbackCaseLogFilterPanel';

const QualityManagementFeedbackCaseLogTab = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('quality_management_feedback_case_log_tab');
    const {caseLogFilterResults, caseLogFilterResultsCount, caseLogFilterResultsPage} = props;
    const {history} = props;

    const handleOnPaginationChange = page => {
        const searchParams = new URLSearchParams(history.location.search);
        searchParams.set('page', page);
        const queryParamsString = searchParams.toString();
        history.replace(resolveRoute(routePaths.QUALITY_MANAGEMENT_FEEDBACK,
            {},
            {search: queryParamsString}));
    };

    return (
        <ContentBlock>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <Panel title={translateTab('panel_title.case_protocol')}>
                    <Divider />
                    <div className={cx('global!ace-u-padding--16-24-32')}>
                        {caseLogFilterResults?.length === 0 ? (
                            <NoResultsBlock
                                icon={(
                                    <Icon
                                        className={cx('ace-c-icon--xxl')}
                                        icon={historyIcon}
                                    />
                            )}
                                message={translateTab('no_results.message')}
                                description={translateTab('no_results.description')}
                            />
                        ) : caseLogFilterResults?.map((caseLog, idx) => (
                            <LogEntry key={idx} caseLog={caseLog} />
                        ))}
                    </div>
                </Panel>
                <div>
                    {!!caseLogFilterResultsCount && (
                    <Paginator
                        page={caseLogFilterResultsPage}
                        count={Math.ceil(caseLogFilterResultsCount / config.PROTOCOL_DEFAULT_PAGE_SIZE)}
                        onClick={page => handleOnPaginationChange(page)}
                    />
                    )}
                </div>
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <QMFeedbackCaseLogFilterPanel />
            </ContentItem>
        </ContentBlock>
    );
};

QualityManagementFeedbackCaseLogTab.propTypes = {
    history: PropTypes.object,
    caseLogFilterResults: PropTypes.array,
    caseLogFilterResultsCount: PropTypes.number,
    caseLogFilterResultsPage: PropTypes.number,
};

QualityManagementFeedbackCaseLogTab.defaultProps = {
    history: null,
    caseLogFilterResults: [],
    caseLogFilterResultsCount: 0,
    caseLogFilterResultsPage: 0,
};

const mapStateToProps = state => {
    return {
        caseLogFilterResults: state.qualityManagementFeedbacks.qmFeedbackCaseLogFilterResults,
        caseLogFilterResultsCount: state.qualityManagementFeedbacks.qmFeedbackCaseLogFilterResultsCount,
        caseLogFilterResultsPage: state.qualityManagementFeedbacks.qmFeedbackCaseLogFilterResultsPage,
    };
};

export default connect(mapStateToProps)(QualityManagementFeedbackCaseLogTab);
