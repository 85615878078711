import {delay, fork, put, select, take} from 'redux-saga/effects';
import {replace, resolveRoute} from '@computerrock/formation-router';
import {closeModal} from '@computerrock/formation-router/sagas';
import {AccountParty, alfAccountTypes, LeistContact} from '@ace-de/eua-entity-types';
import bcModalContentTypes from '../modals/bcModalContentTypes';
import * as contractPartnersActionTypes from '../contractPartnerActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import bcModalTypes from '../modals/bcModalTypes';

const updateContractPartnerBCCreditorFlow = function* updateContractPartnerBCCreditorFlow() {
    let shouldWaitForAction = true;
    let payloadData = null;
    let shouldRetryCreditorUpdate = false;
    let retryCreditorUpdateData = null;

    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contractPartnersActionTypes.INITIATE_BC_CREDITOR_UPDATE_MODAL_FLOW);
            payloadData = payload;
        }
        const {location} = yield select(state => state.router);

        let chosenAction;

        const {selectedContact, modalType, client} = payloadData;

        if (!shouldRetryCreditorUpdate && client && selectedContact && modalType) {
            // fetch creditor data
            yield fork(fetchRequest,
                contractPartnersActionTypes.GET_BC_CREDITOR_REQUEST,
                leaAssignmentFlowService.getAccountParty,
                {
                    accountPartyId: selectedContact.creditorId,
                    client,
                    accountPartyType: alfAccountTypes.CREDITOR,
                });

            const creditorFetchResponseAction = yield take([
                contractPartnersActionTypes.GET_BC_CREDITOR_REQUEST_SUCCEEDED,
                contractPartnersActionTypes.GET_BC_CREDITOR_REQUEST_FAILED,
            ]);

            let selectedAccountParty;
            if (!creditorFetchResponseAction.error) {
                const {response} = creditorFetchResponseAction.payload;
                const {accountPartyDTO} = response;
                selectedAccountParty = accountPartyDTO;
            }

            yield put({
                type: contractPartnersActionTypes.STORE_CONTACTS,
                payload: {
                    contactDTOs: [selectedContact],
                    ...(!!selectedAccountParty && {accountPartyDTOs: [selectedAccountParty]}),
                },
            });

            const searchParams = new URLSearchParams(location.search);
            searchParams.set('contentType', bcModalContentTypes.CONTRACT_PARTNER);
            searchParams.set('client', client);
            searchParams.set('type', modalType);
            searchParams.set('contactId', selectedContact.id);

            yield put(replace(resolveRoute(location.pathname, {}, {search: searchParams.toString()})));

            chosenAction = yield take([
                contractPartnersActionTypes.RETURN_TO_CONTACT_SEARCH,
                contractPartnersActionTypes.SUBMIT_UPDATE_CREDITOR,
            ]);
        }

        if (shouldRetryCreditorUpdate
            || chosenAction?.type === contractPartnersActionTypes.SUBMIT_UPDATE_CREDITOR) {
            const contactId = !shouldRetryCreditorUpdate && chosenAction.payload.contactId
                ? chosenAction.payload.contactId : retryCreditorUpdateData?.contactId;
            const contractPartnerData = !shouldRetryCreditorUpdate && chosenAction.payload.contractPartnerData
                ? chosenAction.payload.contractPartnerData : retryCreditorUpdateData?.contractPartnerData;

            const creditorData = !shouldRetryCreditorUpdate && chosenAction?.payload?.contractPartnerData
                ? {
                    ...contractPartnerData,
                    id: contactId,
                    accountPartyType: alfAccountTypes.CREDITOR,
                }
                : retryCreditorUpdateData?.creditorData;

            const loaderSearchParams = new URLSearchParams(location.search);
            loaderSearchParams.set('contentType', bcModalContentTypes.LOADER);
            loaderSearchParams.set('type', modalType);

            yield put(replace(resolveRoute(location.pathname, {}, {search: loaderSearchParams.toString()})));

            yield fork(fetchRequest,
                contractPartnersActionTypes.UPDATE_CONTACT_REQUEST,
                leaAssignmentFlowService.updateContact,
                {
                    contactId,
                    contactData: {
                        client,
                        ...LeistContact.objectToDTO(contractPartnerData),
                    },
                });

            const responseAction = yield take([
                contractPartnersActionTypes.UPDATE_CONTACT_REQUEST_SUCCEEDED,
                contractPartnersActionTypes.UPDATE_CONTACT_REQUEST_FAILED,
            ]);

            yield delay(100);
            shouldRetryCreditorUpdate = false;

            if (responseAction.error) {
                const failedQueryParams = new URLSearchParams(location.search);
                failedQueryParams.set('contentType', bcModalContentTypes.BC_ACTION_FAILED);
                failedQueryParams.set('type', bcModalTypes.UPDATE_CONTRACT_PARTNER);
                yield put(replace(resolveRoute(location.pathname, {}, {search: failedQueryParams.toString()})));

                const nextAction = yield take([
                    contractPartnersActionTypes.RETRY_UPDATE_CREDITOR,
                    contractPartnersActionTypes.CANCEL_ACTION,
                    contractPartnersActionTypes.DECLINE_BC_CONTACT_MODAL_FLOW,
                ]);

                if (nextAction.type === contractPartnersActionTypes.RETRY_UPDATE_CREDITOR) {
                    shouldRetryCreditorUpdate = true;
                    shouldWaitForAction = false;
                    retryCreditorUpdateData = {
                        contactId,
                        creditorData,
                        contractPartnerData,
                        isOnlyContact: false,
                    };
                    continue;
                }

                if (nextAction.type === contractPartnersActionTypes.CANCEL_ACTION) {
                    shouldWaitForAction = false;
                    shouldRetryCreditorUpdate = false;
                    continue;
                }
            }

            yield fork(fetchRequest,
                contractPartnersActionTypes.UPDATE_CREDITOR_REQUEST,
                leaAssignmentFlowService.updateAccountParty,
                {
                    accountPartyId: contactId,
                    accountPartyData: {
                        ...AccountParty.objectToDTO(creditorData),
                        client,
                    },
                });

            const creditorUpdateResponseAction = yield take([
                contractPartnersActionTypes.UPDATE_CREDITOR_REQUEST_SUCCEEDED,
                contractPartnersActionTypes.UPDATE_CREDITOR_REQUEST_FAILED,
            ]);

            yield delay(100);
            shouldRetryCreditorUpdate = false;

            if (creditorUpdateResponseAction.error) {
                const failedQueryParams = new URLSearchParams(location.search);
                failedQueryParams.set('contentType', bcModalContentTypes.BC_ACTION_FAILED);
                failedQueryParams.set('type', bcModalTypes.UPDATE_CREDITOR);
                yield put(replace(resolveRoute(location.pathname, {}, {search: failedQueryParams.toString()})));

                const nextAction = yield take([
                    contractPartnersActionTypes.RETRY_UPDATE_CREDITOR,
                    contractPartnersActionTypes.CANCEL_ACTION,
                    contractPartnersActionTypes.DECLINE_BC_CONTACT_MODAL_FLOW,
                ]);

                if (nextAction.type === contractPartnersActionTypes.RETRY_UPDATE_CREDITOR) {
                    shouldRetryCreditorUpdate = true;
                    shouldWaitForAction = false;
                    retryCreditorUpdateData = {
                        contactId,
                        creditorData,
                        contractPartnerData,
                        isOnlyContact: false,
                    };
                    continue;
                }

                if (nextAction.type === contractPartnersActionTypes.CANCEL_ACTION) {
                    shouldWaitForAction = true;
                    shouldRetryCreditorUpdate = false;

                    yield* closeModal(modalIds.BC_CONTACT_MODAL, {contentType: '', type: '', client: '', contactId: ''});
                    yield put({
                        type: contractPartnersActionTypes.INITIATE_BC_CONTACT_MODAL_FLOW,
                        payload: {
                            client,
                            contactId,
                            initiateContactSearch: true,
                        },
                    });
                    continue;
                }
            }

            if (!creditorUpdateResponseAction.error) {
                shouldWaitForAction = true;
                yield* closeModal(modalIds.BC_CONTACT_MODAL, {contentType: '', type: '', client: '', contactId: ''});
                yield put({
                    type: contractPartnersActionTypes.INITIATE_BC_CONTACT_MODAL_FLOW,
                    payload: {
                        client,
                        contactId,
                        initiateContactSearch: true,
                    },
                });
                continue;
            }
        }

        shouldWaitForAction = true;
        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.set('contentType', bcModalContentTypes.CONTACT_SEARCH);
        newSearchParams.set('client', client);

        yield put(replace(resolveRoute(location.pathname, {}, {search: newSearchParams.toString()})));
    }
};

export default updateContractPartnerBCCreditorFlow;
