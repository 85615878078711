import {all, fork, takeLatest} from 'redux-saga/effects';
import * as qualityManagementActionTypes from './qualityManagementFeedbackActionTypes';
import searchQualityManagementFeedbacks from './sagas/searchQualityManagementFeedbacks';
import downloadQualityManagementFeedbacks from './sagas/downloadQualityManagementFeedbacks';
import createQualityManagementFeedbackComment from './sagas/createQualityManagementFeedbackComment';
import forwardQMFeedbackFlow from './sagas/forwardQMFeedbackFlow';
import filterQMFeedbackCaseLogs from './sagas/filterQMFeedbackCaseLogs';
import fetchQMFeedbackCaseLogServices from './sagas/fetchQMFeedbackCaseLogServices';
import qmFeedbackMapContractPartnerWatcher from './sagas/qmFeedbackMapContractPartnerWatcher';
import loadQMFeedbackMapContractPartnerWatcher from './sagas/loadQMFeedbackMapContractPartnerWatcher';
import qualifyQualityManagementFeedback from './sagas/qualifyQualityManagementFeedback';
import qmFeedbackFullScreenMapCPWatcher from './sagas/qmFeedbackFullScreenMapCPWatcher';
import downloadQualityManagementFeedbackCaseLogDocument from './sagas/downloadQualityManagementFeedbackCaseLogDocument';

/**
 * Quality Management Feedbacks watcher saga
 */

const qualityManagementFeedbackWatcher = function* qualityManagementFeedbackWatcher() {
    yield all([
        takeLatest(qualityManagementActionTypes.SEARCH_QUALITY_MANAGEMENT_FEEDBACKS, searchQualityManagementFeedbacks),
        fork(downloadQualityManagementFeedbacks),
        fork(createQualityManagementFeedbackComment),
        fork(forwardQMFeedbackFlow),
        fork(qmFeedbackMapContractPartnerWatcher),
        fork(loadQMFeedbackMapContractPartnerWatcher),
        fork(qualifyQualityManagementFeedback),
        fork(qmFeedbackFullScreenMapCPWatcher),
        fork(downloadQualityManagementFeedbackCaseLogDocument),
        takeLatest(qualityManagementActionTypes.FILTER_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOGS, filterQMFeedbackCaseLogs),
        takeLatest(
            qualityManagementActionTypes.FETCH_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOG_SERVICES,
            fetchQMFeedbackCaseLogServices,
        ),
    ]);
};

export default qualityManagementFeedbackWatcher;
