import {take, call, put, select, all} from 'redux-saga/effects';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

/**
 * Select contract partner flow
 */
const selectCPOperationAreaFlow = function* selectCPOperationAreaFlow() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.INITIATE_CONTRACT_PARTNER_OPERATION_AREA_SELECT_FLOW);
        const {contractPartnerOperationArea: selectedContractPartnerOperationArea} = payload;

        const arcGISMap = yield call(arcGISMapService.getMap, 'vpm-contract-partners-operation-areas');

        if (!arcGISMap) return;

        const vpmContractPartnersLocationsLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-operation-areas-locations');
        const vpmContractPartnerServiceAreasLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-operation-areas-service-areas');
        const vpmContractPartnerAffiliatePartnersLocationsLayer = yield call(arcGISMap.getLayer, 'vpm-contract-partner-operation-areas-affiliate-partners-locations');

        if (vpmContractPartnersLocationsLayer
            && vpmContractPartnerAffiliatePartnersLocationsLayer
            && vpmContractPartnerServiceAreasLayer) {
            yield all([
                call(vpmContractPartnersLocationsLayer.selectFeatureByAttribute, {
                    where: `contractPa = '${selectedContractPartnerOperationArea.id}'`,
                }),
                call(vpmContractPartnerAffiliatePartnersLocationsLayer.selectFeatureByAttribute, {
                    where: `contractPa = '${selectedContractPartnerOperationArea.id}'`,
                }),
                call(vpmContractPartnerServiceAreasLayer.selectFeatureByAttribute, {
                    where: `contractPa = '${selectedContractPartnerOperationArea.id}'`,
                }),
            ]);
        }
        const currentMapCenter = arcGISMap.getMapCenter(true);
        const newMapCenter = selectedContractPartnerOperationArea.location?.longitude
        && selectedContractPartnerOperationArea.location?.latitude ? [
                selectedContractPartnerOperationArea.location?.longitude,
                selectedContractPartnerOperationArea.location?.latitude,
            ] : null;
        if (currentMapCenter && newMapCenter
            && (currentMapCenter[0] !== newMapCenter[0] || currentMapCenter[1] !== newMapCenter[1])) {
            arcGISMap.setMapCenter(newMapCenter);
        }

        yield put({
            type: contractPartnerActionTypes.SET_SELECTED_CONTRACT_PARTNER_OPERATION_AREA,
            payload: {selectedContractPartnerOperationArea},
        });
    }
};

export default selectCPOperationAreaFlow;
