import {put} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import config from '../../config';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import contractPartnerScreenTabs from '../contractPartnerScreenTabs';

const loadContractPartnerServiceCaseSearchResults = function* loadContractPartnerServiceCaseSearchResults({payload}) {
    const {activeTab} = yield* selectSearchQueryParams();

    if (activeTab && activeTab !== contractPartnerScreenTabs.CASE_HISTORY
        && activeTab !== contractPartnerScreenTabs.QUALITY_MANAGEMENT) {
        return;
    }

    const {location, match} = payload;
    const searchQueryParams = new URLSearchParams(location.search);
    searchQueryParams.append('sort', 'emergencyCallDateTime,desc');
    searchQueryParams.append('size', `${config.DEFAULT_PAGE_SIZE}`);
    const {contractPartnerId} = match?.params;
    if (contractPartnerId) {
        searchQueryParams.append('acePartnerId', contractPartnerId);
    }

    yield put({
        type: contractPartnerActionTypes.SEARCH_SERVICE_CASES,
        payload: {searchQueryParams},
    });
};

export default loadContractPartnerServiceCaseSearchResults;
