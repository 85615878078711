import {all, call, select, take, fork} from 'redux-saga/effects';
import {euaFeatureEntityTypes, euaFeatureTypes, euaMarkerTypes} from '@ace-de/eua-arcgis-feature-types';
import contractPartnerInfoPopupTemplate from '../../dashboard/contractPartnerInfoPopupTemplate';
import config from '../../config';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import loadContractPartnerOperationAreas from './loadContractPartnerOperationAreas';
import loadCPOperationAreaMapWatcher from './loadCPOperationAreaMapWatcher';

const loadCPOperationAreaMapLayers = function* loadCPOperationAreaMapLayers() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.INITIATE_CONTRACT_PARTNER_OPERATION_AREAS_MAP);
        const {mapName, contractPartnerId} = payload;
        if (mapName !== 'vpm-contract-partners-operation-areas') return;

        const arcGISMap = yield call(arcGISMapService.getMap, 'vpm-contract-partners-operation-areas');

        if (!arcGISMap.isLayerSet('vpm-contract-partner-operation-areas-service-areas')) {
            arcGISMap.createServiceLayer({
                layerId: 'vpm-contract-partner-operation-areas-service-areas',
                serviceURL: config.ARCGIS_CONTRACT_PARTNER_SERVICE_AREAS_LAYER_URL,
                featureEntityType: euaFeatureEntityTypes.CONTRACT_PARTNER_SERVICE_AREA,
                featureType: euaFeatureTypes.SELECTABLE_SERVICE_AREA,
                isVisible: false,
                areFeaturesSelectableOnClick: false,
            });
        }

        if (!arcGISMap.isLayerSet('vpm-contract-partner-operation-areas-locations')) {
            arcGISMap.createServiceLayer({
                layerId: 'vpm-contract-partner-operation-areas-locations',
                serviceURL: config.ARCGIS_CONTRACT_PARTNER_LOCATIONS_LAYER_URL,
                featureEntityType: euaFeatureEntityTypes.CONTRACT_PARTNER,
                featureType: euaFeatureTypes.SELECTABLE_LOCATION_MARKER,
                markerType: euaMarkerTypes.CONTRACT_PARTNER,
                renderByStatus: true,
                isVisible: false,
                areFeaturesSelectableOnClick: true,
                popupTemplate: contractPartnerInfoPopupTemplate,
                outFields: ['*'],
            });
        }

        if (!arcGISMap.isLayerSet('vpm-contract-partner-operation-areas-affiliate-partners-locations')) {
            arcGISMap.createServiceLayer({
                layerId: 'vpm-contract-partner-operation-areas-affiliate-partners-locations',
                serviceURL: config.ARCGIS_CONTRACT_PARTNER_LOCATIONS_LAYER_URL,
                featureEntityType: euaFeatureEntityTypes.CONTRACT_PARTNER,
                featureType: euaFeatureTypes.SELECTABLE_LOCATION_MARKER,
                markerType: euaMarkerTypes.AFFILIATE_PARTNER,
                isVisible: false,
                areFeaturesSelectableOnClick: true,
                popupTemplate: contractPartnerInfoPopupTemplate,
                outFields: ['*'],
            });
        }

        yield all([
            fork(loadContractPartnerOperationAreas, {contractPartnerId}),
            fork(loadCPOperationAreaMapWatcher),
        ]);
    }
};

export default loadCPOperationAreaMapLayers;
