import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import * as contractPartnerSelectors from './contractPartnerSelectors';
import ContractPartnerBasicDataPanel from './ui-elements/ContractPartnerBasicDataPanel';
import ContractPartnerAccountManagerPanel from './ui-elements/ContractPartnerAccountManagerPanel';
import ContractPartnerRelationsPanel from './ui-elements/ContractPartnerRelationsPanel';
import ContractPartnerAdditionalLocationsPanel from './ui-elements/ContractPartnerAdditionalLocationsPanel';
import ContractPartnerExternalCommissionersPanel from './ui-elements/ContractPartnerExternalCommissionersPanel';
import ContractPartnerAdditionalFeaturesPanel from './ui-elements/ContractPartnerAdditionalFeaturesPanel';
import ContractPartnerEllaActivePanel from './ContractPartnerEllaActivePanel';
import ContractPartnerBCContactData from './ui-elements/ContractPartnerBCContactData';
import config from '../config';
import ContractPartnerAddressDataPanel from './ui-elements/ContractPartnerAddressDataPanel';

const ContractPartnerBasicDataTab = props => {
    const {cx} = useStyles();
    const {contractPartner} = props;

    // if no contract partner, don't render
    if (!contractPartner) return null;

    return (
        <ContentBlock>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                {!config.IS_PRE_BC_VERSION && (
                    <Fragment>
                        <ContractPartnerBCContactData />
                        <ContractPartnerBasicDataPanel />
                    </Fragment>
                )}
                {config.IS_PRE_BC_VERSION && (
                    <ContractPartnerAddressDataPanel />
                )}
                <ContractPartnerAccountManagerPanel />
                <ContractPartnerRelationsPanel />
                <ContractPartnerAdditionalLocationsPanel />
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <ContractPartnerEllaActivePanel />
                <ContractPartnerExternalCommissionersPanel />
                <ContractPartnerAdditionalFeaturesPanel />
            </ContentItem>
        </ContentBlock>
    );
};

ContractPartnerBasicDataTab.propTypes = {
    contractPartner: PropTypes.object,
};

ContractPartnerBasicDataTab.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(ContractPartnerBasicDataTab));
