import React from 'react';
import PropTypes from 'prop-types';
import {renderRoutes} from '@computerrock/formation-router';
import '@ace-de/ui-components/index.module.scss';
import '@arcgis/core/assets/esri/themes/light/main.css';
import {useStyles, AppLayout, AppLayoutFooter} from '@ace-de/ui-components';
import config from '../config';

const ApplicationScreen = ({route}) => {
    const {cx} = useStyles();

    return (
        <AppLayout>
            {renderRoutes(route.routes)}
            <AppLayoutFooter
                contentClassName={cx([
                    'global!ace-u-flex',
                    'global!ace-u-pointer-events--none',
                ])}
            >
                <div
                    className={cx([
                        'global!ace-u-typography--variant-body',
                        'global!ace-u-typography--color-disabled',
                        'global!ace-u-pointer-events--auto',
                    ])}
                >
                    Version: {config.APP_VERSION} Build: {config.APP_BUILD}
                </div>
            </AppLayoutFooter>
        </AppLayout>
    );
};

ApplicationScreen.propTypes = {
    route: PropTypes.object.isRequired,
};

export default ApplicationScreen;
