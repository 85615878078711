import errorTypes from './errorTypes';
import * as contractPartnerActionTypes from '../contract-partners/contractPartnerActionTypes';

const determineActionTypes = requestedAction => {
    if (!requestedAction) return null;

    switch (requestedAction) {
        case errorTypes.CP_CONTACT_DETAILS_UPDATE_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CP_CONTACT_DETAILS_UPDATE,
                decline: contractPartnerActionTypes.CANCEL_RETRY_CP_CONTACT_DETAILS_UPDATE,
            };
        }

        case errorTypes.CP_ACCOUNT_MANAGER_UPDATE_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CP_ACCOUNT_MANAGER_UPDATE,
                decline: contractPartnerActionTypes.CANCEL_RETRY_CP_ACCOUNT_MANAGER_UPDATE,
            };
        }

        case errorTypes.CP_RELATIONS_UPDATE_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CP_RELATIONS_UPDATE,
                decline: contractPartnerActionTypes.CANCEL_RETRY_CP_RELATIONS_UPDATE,
            };
        }

        case errorTypes.CP_EXTERNAL_COMMISSIONERS_UPDATE_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CP_EXTERNAL_COMMISSIONERS_UPDATE,
                decline: contractPartnerActionTypes.CANCEL_RETRY_CP_EXTERNAL_COMMISSIONERS_UPDATE,
            };
        }

        case errorTypes.CP_ADDITIONAL_FEATURES_UPDATE_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CP_ADDITIONAL_FEATURES_UPDATE,
                decline: contractPartnerActionTypes.CANCEL_RETRY_CP_ADDITIONAL_FEATURES_UPDATE,
            };
        }

        case errorTypes.CP_BASIC_SERVICES_UPDATE_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CP_BASIC_SERVICES_UPDATE,
                decline: contractPartnerActionTypes.CANCEL_RETRY_CP_BASIC_SERVICES_UPDATE,
            };
        }

        case errorTypes.CP_SERVICES_UPDATE_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CP_SERVICES_UPDATE,
                decline: contractPartnerActionTypes.CANCEL_RETRY_CP_SERVICES_UPDATE,
            };
        }

        case errorTypes.CP_ADDITIONAL_SERVICES_UPDATE_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CP_ADDITIONAL_SERVICES_UPDATE,
                decline: contractPartnerActionTypes.CANCEL_RETRY_CP_ADDITIONAL_SERVICES_UPDATE,
            };
        }

        case errorTypes.CP_CONTACT_DATA_CREATION_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CREATE_CP_CONTACT_DATA,
                decline: contractPartnerActionTypes.CANCEL_RETRY_CREATE_CP_CONTACT_DATA,
            };
        }

        case errorTypes.CP_CONTACT_DATA_UPDATE_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_UPDATE_CP_CONTACT_DATA,
                decline: contractPartnerActionTypes.CANCEL_RETRY_UPDATE_CP_CONTACT_DATA,
            };
        }

        case errorTypes.CP_ASSIGNMENT_CHANNELS_UPDATE_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CP_ASSIGNMENT_CHANNELS_UPDATE,
                decline: contractPartnerActionTypes.CANCEL_RETRY_CP_ASSIGNMENT_CHANNELS_UPDATE,
            };
        }

        case errorTypes.CP_TEMPORARY_RESTRICTION_CREATION_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CREATE_CP_TEMPORARY_RESTRICTION,
                decline: contractPartnerActionTypes.CANCEL_RETRY_CREATE_CP_TEMPORARY_RESTRICTION,
            };
        }

        case errorTypes.CP_TEMPORARY_RESTRICTION_UPDATE_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_UPDATE_CP_TEMPORARY_RESTRICTION,
                decline: contractPartnerActionTypes.CANCEL_RETRY_UPDATE_CP_TEMPORARY_RESTRICTION,
            };
        }

        case errorTypes.CP_QUALITY_REPORT_CREATION_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CREATE_CP_QUALITY_REPORT,
                decline: contractPartnerActionTypes.CANCEL_RETRY_CREATE_CP_QUALITY_REPORT,
            };
        }

        case errorTypes.CP_STATUS_UPDATE_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CP_STATUS_UPDATE,
                decline: contractPartnerActionTypes.CANCEL_RETRY_CP_STATUS_UPDATE,
            };
        }

        case errorTypes.CP_FIXED_PRICE_UPDATE_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CP_FIXED_PRICE_UPDATE,
                decline: contractPartnerActionTypes.CANCEL_RETRY_CP_FIXED_PRICE_UPDATE,
            };
        }

        case errorTypes.CP_FIXED_PRICE_CREATION_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CREATE_CP_FIXED_PRICE,
                decline: contractPartnerActionTypes.CANCEL_RETRY_CREATE_CP_FIXED_PRICE,
            };
        }

        case errorTypes.CP_FIXED_PRICE_DELETION_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_DELETE_CP_FIXED_PRICE,
                decline: contractPartnerActionTypes.CANCEL_RETRY_DELETE_CP_FIXED_PRICE,
            };
        }

        case errorTypes.CP_ELLA_ACCOUNT_CREATION_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CREATE_CP_ELLA_ACCOUNT,
                decline: contractPartnerActionTypes.CANCEL_RETRY_CREATE_CP_ELLA_ACCOUNT,
            };
        }

        case errorTypes.CP_ELLA_ACCOUNT_UPDATE_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_UPDATE_CP_ELLA_ACCOUNT,
                decline: contractPartnerActionTypes.CANCEL_RETRY_UPDATE_CP_ELLA_ACCOUNT,
            };
        }

        case errorTypes.CP_OPERATING_UNIT_CREATION_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_CREATE_CP_OPERATING_UNIT,
                decline: contractPartnerActionTypes.CANCEL_RETRY_CREATE_CP_OPERATING_UNIT,
            };
        }

        case errorTypes.CP_OPERATING_UNIT_UPDATE_FAILED: {
            return {
                confirm: contractPartnerActionTypes.RETRY_UPDATE_CP_OPERATING_UNIT,
                decline: contractPartnerActionTypes.CANCEL_RETRY_UPDATE_CP_OPERATING_UNIT,
            };
        }

        default: return {
            confirm: null,
            decline: null,
        };
    }
};

export default determineActionTypes;
