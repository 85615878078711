import React from 'react';
import PropTypes from 'prop-types';
import {useStyles, Divider, Icon, linkIcon} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';
import {resolveRoute, withRouter} from '@computerrock/formation-router';
import styles from './ContractPartnerItem.module.scss';
import routePaths from '../../routePaths';

const ContractPartnerItem = props => {
    const {cx} = useStyles(props, styles);
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('dashboard_screen');
    const {contractPartner, isSelected, onSelect, history} = props;

    const handleOnClick = () => {
        if (typeof onSelect === 'function') {
            onSelect(contractPartner.contractPartnerId);
        }
    };

    const handleRedirectToContractPartnerScreen = event => {
        event.preventDefault();
        event.stopPropagation();
        if (history) {
            history.push(resolveRoute(
                routePaths.CONTRACT_PARTNER,
                {contractPartnerId: contractPartner.contractPartnerId},
            ));
        }
    };

    return (
        <div
            className={cx('global!ace-u-cursor--pointer', {
                'ace-c-contract-partner-item--is-selected': isSelected,
            })}
            onClick={handleOnClick}
        >
            <div
                className={cx([
                    'global!ace-u-grid',
                    'global!ace-u-padding--left-32',
                    'global!ace-u-padding--right-32',
                    'global!ace-u-padding--top-16',
                ])}
            >
                <span
                    className={cx([
                        'global!ace-u-typography--variant-body-bold',
                        'global!ace-u-grid-column--span-10',
                        'global!ace-u-margin--bottom-4',
                    ])}
                >
                    {contractPartner.contractPartnerName || ''}
                </span>
                <span
                    className={cx([
                        'global!ace-u-grid-column--span-2',
                        'global!ace-u-margin--left-8',
                    ])}
                    onClick={handleRedirectToContractPartnerScreen}
                >
                    <Icon
                        icon={linkIcon}
                    />
                </span>
            </div>
            <div
                className={cx([
                    'global!ace-u-typography--variant-body',
                    'global!ace-u-margin--bottom-16',
                    'global!ace-u-padding--left-32',
                    'global!ace-u-padding--right-32',
                ])}
            >
                <p>
                    {[contractPartner.location?.postCode, contractPartner.location?.city].filter(value => !!value).join(' ')}
                </p>
                <p>
                    {contractPartner.contactDetails?.phoneNo
                        ? `${translateTab('contract_partner_item_text.tel')} ${contractPartner.contactDetails.phoneNo}` : ''}
                </p>
            </div>
            <Divider />
        </div>
    );
};

ContractPartnerItem.propTypes = {
    contractPartner: PropTypes.object.isRequired,
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func,
    history: PropTypes.object,
};

ContractPartnerItem.defaultProps = {
    isSelected: false,
    onSelect: null,
    history: {},
};

export default withRouter(ContractPartnerItem);
