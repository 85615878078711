/**
 * German states
 * hardcoded on the FE, since BE cannot create an enum
 * values are strictly German translations since that is how the state is stored in the DB
 */
export default {
    BADEN_WURTTEMBERG: 'Baden-Württemberg',
    BAVARIA: 'Bayern',
    BERLIN: 'Berlin',
    BRANDENBURG: 'Brandenburg',
    BREMEN: 'Bremen',
    HAMBURG: 'Hamburg',
    HESSE: 'Hessen',
    LOWER_SAXONY: 'Niedersachsen',
    MECKLENBURG_VORPOMMERN: 'Mecklenburg-Vorpommern',
    NORTH_RHINE_WESTPHALIA: 'Nordrhein-Westfalen',
    RHINELAND_PALATINATE: 'Rheinland-Pfalz',
    SAARLAND: 'Saarland',
    SAXONY: 'Sachsen',
    SAXONY_ANHALT: 'Sachsen-Anhalt',
    SCHLESWIG_HOLSTEIN: 'Schleswig-Holstein',
    THURINGIA: 'Thüringen',
};
