import {call, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {apsPriceTypes} from '@ace-de/eua-entity-types';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import modalIds from '../../modalIds';
import updatePricesByTimePeriod from './updatePricesByTimePeriod';

const updatePricesFlow = function* updatePricesFlow() {
    while (true) {
        const {payload} = yield take(priceManagementActionTypes.INITIATE_UPDATE_PRICES_FLOW);
        yield* openModal(modalIds.EDIT_PRICES, {
            contentType: payload.contentType,
            ...(payload.contentType === apsPriceTypes.TIME_PRICE && {pricePeriodVersion: `${payload.pricePeriodVersion}`}),
        });

        const chosenModalOption = yield take([
            priceManagementActionTypes.CONFIRM_UPDATE_PRICES,
            priceManagementActionTypes.DECLINE_UPDATE_PRICES,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === priceManagementActionTypes.CONFIRM_UPDATE_PRICES
        ) {
            const {pricePeriodId, prices, pricePeriodVersion} = chosenModalOption.payload;
            yield* closeModal(modalIds.EDIT_PRICES, {
                contentType: payload.contentType,
                ...(payload.contentType === apsPriceTypes.TIME_PRICE && {pricePeriodVersion: `${payload.pricePeriodVersion}`}),
            });
            yield call(updatePricesByTimePeriod, {
                payload: {pricePeriodId, prices, pricePeriodVersion},
            });
            continue;
        }

        yield* closeModal(modalIds.EDIT_PRICES, {
            contentType: payload.contentType,
            ...(payload.contentType === apsPriceTypes.TIME_PRICE && {pricePeriodVersion: `${payload.pricePeriodVersion}`}),
        });
    }
};

export default updatePricesFlow;
