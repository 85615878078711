import moment from 'moment';
import {fork, put, select, take} from 'redux-saga/effects';
import {persistenceStates} from '@computerrock/formation-core';
import {resolveRoute, replace, parseSearchQueryParams, matchPath} from '@computerrock/formation-router';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import routePaths from '../../routePaths';
import modalIds from '../../modalIds';
import config from '../../config';

const loadTimePeriods = function* loadTimePeriods({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');
    const {location} = payload;

    if (config.IS_PRE_BC_VERSION || (location && location.state?.isModalOpen
        && [modalIds.CREATE_TIME_PERIOD_MODAL].includes(location.state?.modalId))) return;

    yield fork(
        fetchRequest,
        priceManagementActionTypes.FETCH_PRICE_PERIODS_REQUEST,
        pricingManagementService.getPricePeriods,
        {searchQueryParams: null},
    );

    const responseAction = yield take([
        priceManagementActionTypes.FETCH_PRICE_PERIODS_REQUEST_SUCCEEDED,
        priceManagementActionTypes.FETCH_PRICE_PERIODS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {pricePeriodDTOs} = response;

        yield put({
            type: priceManagementActionTypes.STORE_TIME_PERIODS,
            payload: {pricePeriodDTOs},
        });

        const activePricePeriod = pricePeriodDTOs.find(pricePeriod => (
            moment(pricePeriod?.validFrom).isSameOrBefore(moment(), 'day')
            && moment(pricePeriod?.validUntil).isSameOrAfter(moment(), 'day')
        ));

        const {validFrom, validUntil, pricePeriodId} = parseSearchQueryParams(location.search);

        const priceManagementScreenMatch = matchPath(location.pathname, {
            path: routePaths.PRICE_MANAGEMENT,
            exact: true,
        });

        if (!activePricePeriod && !pricePeriodId) {
            yield put({
                type: priceManagementActionTypes.SET_PRICES_PERSISTENCE_STATE,
                payload: {persistenceState: persistenceStates.READY},
            });
            return;
        }

        if (priceManagementScreenMatch && pricePeriodDTOs && !validFrom && !validUntil && !pricePeriodId) {
            const newSearchParams = new URLSearchParams(location.search);

            newSearchParams.append('validFrom', activePricePeriod.validFrom);
            newSearchParams.append('validUntil', activePricePeriod.validUntil);
            newSearchParams.append('pricePeriodId', activePricePeriod.id);

            yield put(replace(resolveRoute(location.pathname, {}, {search: newSearchParams.toString()})));
        }
        return;
    }

    yield put({
        type: priceManagementActionTypes.RESET_PRICES_BY_TIME,
    });

    yield put({
        type: priceManagementActionTypes.SET_PRICES_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.FAILED},
    });
};

export default loadTimePeriods;
