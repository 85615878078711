import {eventChannel} from 'redux-saga';
import {select, take} from 'redux-saga/effects';
import arcGISAuth from '../../arcGISAuth';

const provideArcGISTokenToServicesFlow = function* provideArcGISTokenToServicesFlow() {
    const {serviceManager} = yield select(state => state.application);

    const authSessionEventChannel = eventChannel(emitter => {
        // returns unsubscribe method to eventChannel
        return arcGISAuth.onAuthSessionStarted(sessionCredentials => {
            emitter({sessionCredentials});
        });
    });

    while (true) {
        const {sessionCredentials} = yield take(authSessionEventChannel);

        try {
            const arcGISMapService = serviceManager.loadService('arcGISMapService');
            arcGISMapService.setAccessToken(sessionCredentials.accessToken);
        } catch (e) {
            // no-op
        }

        try {
            const arcGISService = serviceManager.loadService('arcGISRESTService');
            arcGISService.setAccessToken(sessionCredentials.accessToken);
        } catch (e) {
            // no-op
        }
    }
};

export default provideArcGISTokenToServicesFlow;
