import {take, fork, cancel} from 'redux-saga/effects';
import {LOCATION_CHANGE, matchPath} from '@computerrock/formation-router';
import * as contractPartnerActionTypes from '../../contract-partners/contractPartnerActionTypes';
import onContractPartnerMapDrag from './onContractPartnerMapDrag';
import onContractPartnerMapMouseWheel from './onContractPartnerMapMouseWheel';
import onContractPartnerMapSelectContractPartner from './onContractPartnerMapSelectContractPartner';
import routePaths from '../../routePaths';
import onContractPartnerMapCenterChange from './onContractPartnerMapCenterChange';
import onContractPartnerMapFeatureHover from './onContractPartnerMapFeatureHover';

const contractPartnerMapWatcher = function* contractPartnerMapWatcher() {
    mainFlow: while (true) {
        yield take(contractPartnerActionTypes.START_MAP_CONTRACT_PARTNER_WATCHER);
        const onContractPartnerMapDragTask = yield fork(onContractPartnerMapDrag);
        const onContractPartnerMapMouseWheelTask = yield fork(onContractPartnerMapMouseWheel);
        const onContractPartnerMapSelectContractPartnerTask = yield fork(onContractPartnerMapSelectContractPartner);
        const onContractPartnerMapCenterChangeTask = yield fork(onContractPartnerMapCenterChange);
        const onContractPartnerMapFeatureHoverTask = yield fork(onContractPartnerMapFeatureHover);

        while (true) {
            const watcherTerminationAction = yield take([
                contractPartnerActionTypes.STOP_MAP_CONTRACT_PARTNER_WATCHER,
                LOCATION_CHANGE,
            ]);

            if (watcherTerminationAction.type === contractPartnerActionTypes.STOP_MAP_CONTRACT_PARTNER_WATCHER) {
                yield cancel(onContractPartnerMapDragTask);
                yield cancel(onContractPartnerMapMouseWheelTask);
                yield cancel(onContractPartnerMapSelectContractPartnerTask);
                yield cancel(onContractPartnerMapCenterChangeTask);
                yield cancel(onContractPartnerMapFeatureHoverTask);
                continue mainFlow;
            }

            const {payload} = watcherTerminationAction;
            const {location} = payload;

            const dashboardScreenRouteMatch = matchPath(location.pathname, {
                path: routePaths.DASHBOARD,
                exact: true,
            });

            if (!dashboardScreenRouteMatch) {
                yield cancel(onContractPartnerMapDragTask);
                yield cancel(onContractPartnerMapMouseWheelTask);
                yield cancel(onContractPartnerMapSelectContractPartnerTask);
                yield cancel(onContractPartnerMapCenterChangeTask);
                yield cancel(onContractPartnerMapFeatureHoverTask);
                continue mainFlow;
            }
        }
    }
};

export default contractPartnerMapWatcher;
