import {delay, fork, put, select, take} from 'redux-saga/effects';
import {replace, resolveRoute} from '@computerrock/formation-router';
import {closeModal} from '@computerrock/formation-router/sagas';
import {AccountParty, LeistContact} from '@ace-de/eua-entity-types';
import bcModalContentTypes from '../modals/bcModalContentTypes';
import * as contractPartnersActionTypes from '../contractPartnerActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import bcModalTypes from '../modals/bcModalTypes';

const createContractPartnerBCCreditorFlow = function* createContractPartnerBCCreditorFlow() {
    let shouldWaitForAction = true;
    let payloadData = null;
    let shouldRetryCreditorCreation = false;
    let retryCreditorCreationData = null;
    let shouldPatchContact = false;
    let contactPatchData = null;

    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contractPartnersActionTypes.INITIATE_CREDITOR_CREATION_MODAL_FLOW);
            payloadData = payload;
        }
        const {location} = yield select(state => state.router);

        let chosenAction;

        const {selectedContact, modalType, client} = payloadData;

        if (!shouldRetryCreditorCreation && client && selectedContact && modalType) {
            yield put({
                type: contractPartnersActionTypes.STORE_CONTACTS,
                payload: {contactDTOs: [selectedContact]},
            });

            const searchParams = new URLSearchParams(location.search);
            searchParams.set('contentType', bcModalContentTypes.CONTRACT_PARTNER);
            searchParams.set('client', client);
            searchParams.set('type', modalType);
            searchParams.set('contactId', selectedContact.id);

            yield put(replace(resolveRoute(location.pathname, {}, {search: searchParams.toString()})));

            chosenAction = yield take([
                contractPartnersActionTypes.RETURN_TO_CONTACT_SEARCH,
                contractPartnersActionTypes.SUBMIT_CREDITOR_CREATION,
            ]);
        }

        if (shouldRetryCreditorCreation
            || chosenAction.type === contractPartnersActionTypes.SUBMIT_CREDITOR_CREATION) {
            const accountPartyData = !shouldRetryCreditorCreation && chosenAction?.payload?.creditorData
                ? chosenAction.payload.creditorData : retryCreditorCreationData?.creditorData;
            shouldPatchContact = !shouldRetryCreditorCreation && chosenAction?.payload?.shouldPatchContact
                ? chosenAction.payload.shouldPatchContact : retryCreditorCreationData?.shouldPatchContact;

            const loaderSearchParams = new URLSearchParams(location.search);
            loaderSearchParams.set('contentType', bcModalContentTypes.LOADER);
            loaderSearchParams.set('type', modalType);

            yield put(replace(resolveRoute(location.pathname, {}, {search: loaderSearchParams.toString()})));

            yield fork(fetchRequest,
                contractPartnersActionTypes.CREATE_NEW_CREDITOR_REQUEST,
                leaAssignmentFlowService.createAccountParty,
                {
                    accountPartyData: {
                        ...AccountParty.objectToDTO(accountPartyData),
                        client,
                    },
                });

            const responseAction = yield take([
                contractPartnersActionTypes.CREATE_NEW_CREDITOR_REQUEST_SUCCEEDED,
                contractPartnersActionTypes.CREATE_NEW_CREDITOR_REQUEST_FAILED,
            ]);

            yield delay(100);
            shouldRetryCreditorCreation = false;

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {accountPartyDTO} = response;

                if (!shouldPatchContact) {
                    shouldWaitForAction = true;
                    yield* closeModal(modalIds.BC_CONTACT_MODAL, {contentType: '', type: '', client: '', contactId: ''});
                    yield put({
                        type: contractPartnersActionTypes.INITIATE_BC_CONTACT_MODAL_FLOW,
                        payload: {
                            client,
                            contactId: accountPartyDTO.id,
                            initiateContactSearch: true,
                        },
                    });
                    continue;
                }
                contactPatchData = LeistContact.objectToDTO(accountPartyData);
            }
            if (responseAction.error) {
                const failedQueryParams = new URLSearchParams(location.search);
                failedQueryParams.set('contentType', bcModalContentTypes.BC_ACTION_FAILED);
                failedQueryParams.set('type', bcModalTypes.CREATE_CREDITOR);
                yield put(replace(resolveRoute(location.pathname, {}, {search: failedQueryParams.toString()})));

                const nextAction = yield take([
                    contractPartnersActionTypes.RETRY_CREDITOR_CREATION,
                    contractPartnersActionTypes.CANCEL_ACTION,
                    contractPartnersActionTypes.DECLINE_BC_CONTACT_MODAL_FLOW,
                ]);

                if (nextAction.type === contractPartnersActionTypes.RETRY_CREDITOR_CREATION) {
                    shouldRetryCreditorCreation = true;
                    shouldWaitForAction = false;
                    retryCreditorCreationData = {creditorData: accountPartyData};
                    continue;
                }

                if (nextAction.type === contractPartnersActionTypes.CANCEL_ACTION) {
                    shouldWaitForAction = false;
                    shouldRetryCreditorCreation = false;
                    continue;
                }
            }
        }
        if (shouldPatchContact && !!contactPatchData) {
            yield fork(fetchRequest,
                contractPartnersActionTypes.UPDATE_CONTACT_REQUEST,
                leaAssignmentFlowService.updateContact,
                {
                    contactId: contactPatchData.id,
                    contactData: {
                        client,
                        ...contactPatchData,
                    },
                });

            const responseAction = yield take([
                contractPartnersActionTypes.UPDATE_CONTACT_REQUEST_SUCCEEDED,
                contractPartnersActionTypes.UPDATE_CONTACT_REQUEST_FAILED,
            ]);
            if (responseAction.error) {
                const failedQueryParams = new URLSearchParams(location.search);
                failedQueryParams.set('contentType', bcModalContentTypes.BC_ACTION_FAILED);
                failedQueryParams.set('type', bcModalTypes.UPDATE_BUSINESS_RELATIONS);
                yield put(replace(resolveRoute(location.pathname, {}, {search: failedQueryParams.toString()})));

                const nextAction = yield take([
                    contractPartnersActionTypes.CANCEL_ACTION,
                    contractPartnersActionTypes.DECLINE_BC_CONTACT_MODAL_FLOW,
                ]);

                if (nextAction.type === contractPartnersActionTypes.CANCEL_ACTION) {
                    shouldWaitForAction = true;
                    shouldRetryCreditorCreation = false;
                    yield* closeModal(modalIds.BC_CONTACT_MODAL, {contentType: '', type: '', client: '', contactId: ''});
                    yield put({
                        type: contractPartnersActionTypes.INITIATE_BC_CONTACT_MODAL_FLOW,
                        payload: {
                            client,
                            contactId: contactPatchData.id,
                            initiateContactSearch: true,
                        },
                    });
                    continue;
                }
            }
            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {contactDTO} = response;

                shouldWaitForAction = true;
                yield* closeModal(modalIds.BC_CONTACT_MODAL, {contentType: '', type: '', client: '', contactId: ''});
                yield put({
                    type: contractPartnersActionTypes.INITIATE_BC_CONTACT_MODAL_FLOW,
                    payload: {
                        client,
                        contactId: contactDTO.id,
                        initiateContactSearch: true,
                    },
                });
                continue;
            }
        }

        shouldWaitForAction = true;
        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.set('contentType', bcModalContentTypes.CONTACT_SEARCH);
        newSearchParams.set('client', client);

        yield put(replace(resolveRoute(location.pathname, {}, {search: newSearchParams.toString()})));
    }
};

export default createContractPartnerBCCreditorFlow;
