import {put, select, take, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

const loadContractPartnerAccountManagers = function* loadContractPartnerAccountManagers() {
    const {accountManagers} = yield select(state => state.contractPartners);
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    if (accountManagers && Object.values(accountManagers)?.length > 0) return;

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_ACCOUNT_MANAGERS_REQUEST,
        partnerManagementService.getAccountManagers,
    );

    const responseAction = yield take([
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_ACCOUNT_MANAGERS_REQUEST_FAILED,
        contractPartnerActionTypes.FETCH_CONTRACT_PARTNER_ACCOUNT_MANAGERS_REQUEST_SUCCEEDED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {accountManagerDTOs} = response;

        yield put({
            type: contractPartnerActionTypes.STORE_CONTRACT_PARTNER_ACCOUNT_MANAGERS,
            payload: {accountManagerDTOs},
        });
    }
};

export default loadContractPartnerAccountManagers;
