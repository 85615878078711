import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {ButtonPrimary, Icon, useStyles, warningIcon} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {useTranslate} from '@computerrock/formation-i18n';
import {useSearchQueryParams} from '@computerrock/formation-router';
import {apsPriceTypes} from '@ace-de/eua-entity-types';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import PickupRatesRow from '../ui-elements/PickupRatesRow';
import config from '../../config';

const EditPickupRatesData = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('edit_pickup_rates_modal');
    const {confirmUpdatePickupRates, pickupPrices} = props;
    const [pickupRates, setPickupRates] = useState([...pickupPrices]);
    const [overlappingFields, setOverlappingFields] = useState();
    const [isButtonDisabled, setIsButtonDisabled] = useState();
    const [errorMessage, setErrorMessage] = useState('');

    const {pricePeriodId} = useSearchQueryParams();

    const checkOverlapping = pickupRates => {
        let i = 0;
        const overlap = [];

        while (i < pickupRates.length - 1) {
            for (let j = i + 1; j < pickupRates.length; j += 1) {
                const entry1 = pickupRates[i];
                const entry2 = pickupRates[j];

                switch (true) {
                    case entry1.distanceFrom === entry2.distanceFrom || entry1.distanceTo === entry2.distanceTo:
                        overlap.push(entry1.id, entry2.id);
                        break;
                    case entry1.distanceTo === entry2.distanceFrom:
                        overlap.push(entry1.id, entry2.id);
                        break;
                    case (entry1.distanceFrom > entry2.distanceFrom && entry1.distanceFrom < entry2.distanceTo)
                        || (entry1.distanceTo > entry2.distanceFrom && entry1.distanceTo < entry2.distanceTo):
                        overlap.push(entry1.id, entry2.id);
                        break;
                    case (entry2.distanceFrom > entry1.distanceFrom && entry2.distanceFrom < entry1.distanceTo)
                        || (entry2.distanceTo > entry1.distanceFrom && entry2.distanceTo < entry1.distanceTo):
                        overlap.push(entry1.id, entry2.id);
                        break;
                    default:
                        break;
                }
            }
            i += 1;
        }

        return overlap.length > 0 ? overlap.filter((id, idx) => overlap.indexOf(id) === idx) : null;
    };

    const handleOnClick = () => {
        if (!pickupRates?.length || !pricePeriodId) return;
        const overlappingFields = checkOverlapping(pickupRates);

        if (overlappingFields) {
            setOverlappingFields(overlappingFields);
            setErrorMessage(translateModal('error_message.no_overlapping_entries_allowed'));
            return;
        }

        if (Object.values(pickupRates).some(field => field.value === config.NOT_ALLOWED_PRICE_VALUE)) {
            setErrorMessage(translate('global.error_message.zero_values_not_allowed'));
            return;
        }

        setErrorMessage('');

        confirmUpdatePickupRates({
            pricePeriodId,
            prices: [...pickupRates].map(pickupRates => {
                return {
                    type: apsPriceTypes.PICKUP_PRICE,
                    distanceFrom: pickupRates.distanceFrom,
                    distanceTo: pickupRates.distanceTo,
                    value: pickupRates.value,
                };
            }),
        });
    };

    return (
        <div className={cx('global!ace-u-margin--32-0')}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="edit-pickup-rates-from" colSpan={3}>
                            {translateModal('table_header.from')}
                        </TableCell>
                        <TableCell qaIdentPart="edit-pickup-rates-to" colSpan={3}>
                            {translateModal('table_header.to')}
                        </TableCell>
                        <TableCell qaIdentPart="edit-pickup-rates-price" colSpan={4}>
                            {translateModal('table_header.price')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pickupPrices?.length > 0 && pickupPrices.map((pickupPrice, idx) => {
                        return (
                            <PickupRatesRow
                                key={`${pickupPrice.id}-${idx}`}
                                pickupPrice={pickupPrice}
                                pickupRates={pickupRates}
                                setPickupRates={setPickupRates}
                                overlappingFields={overlappingFields}
                                setIsButtonDisabled={setIsButtonDisabled}
                            />
                        );
                    })}
                </TableBody>
            </Table>
            {!!errorMessage && (
                <p
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-margin--top-16',
                        'global!ace-u-typography--color-warning',
                        'global!ace-u-typography--variant-caption',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--left-16',
                    ])}
                >
                    <Icon
                        icon={warningIcon}
                        className={cx([
                            'global!ace-u-margin--right-8',
                            'ace-c-icon--s',
                            'ace-c-icon--color-warning',
                        ])}
                    />
                    <span>
                        {errorMessage}
                    </span>
                </p>
            )}
            <div
                className={cx([
                    'global!ace-u-width--full',
                    'global!ace-u-flex',
                    'global!ace-u-margin--top-32',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-flex-end',
                ])}
            >
                <ButtonPrimary
                    onClick={handleOnClick}
                    className={cx('global!ace-u-margin--right-32')}
                    isDisabled={isButtonDisabled}
                >
                    {translateModal('button_label.save')}
                </ButtonPrimary>
            </div>
        </div>
    );
};

EditPickupRatesData.propTypes = {
    confirmUpdatePickupRates: PropTypes.func.isRequired,
    pickupPrices: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
    return ({
        pickupPrices: state.priceManagement.pickupPrices,
    });
};

const mapDispatchToProps = dispatch => ({
    confirmUpdatePickupRates: payload => dispatch({
        type: priceManagementActionTypes.CONFIRM_UPDATE_PRICES,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPickupRatesData);
