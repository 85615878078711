/**
 * Contract partner info ArcGIS map popup definition
 */
export default {
    alignment: 'bottom-center',
    dockOptions: {
        buttonEnabled: false,
    },
    viewModel: {
        includeDefaultActions: false,
    },
    highlightEnabled: false,
};
