import bcModalContentTypes from './bcModalContentTypes';

/**
 * BC modal config
 */
export default {
    [bcModalContentTypes.CONTACT_SEARCH]: {
        name: 'contact-search',
        hasBackdrop: true,
        title: 'bc_contact_search_modal.modal_title.contact_search',
        hasColoredHeader: true,
        hasContentPadding: false,
        contentClassName: ['ace-c-modal__content--scrollable', 'global!ace-u-padding-0'],
    },
    [bcModalContentTypes.CONTRACT_PARTNER]: {
        name: 'contract-partner',
        hasBackdrop: true,
        title: 'bc_contract_partner_modal.modal_title.',
        hasColoredHeader: true,
        hasContentPadding: false,
        contentClassName: ['global!ace-u-modal-content-size--s', 'ace-c-modal__content--scrollable', 'global!ace-u-padding-0'],
    },
    [bcModalContentTypes.LOADER]: {
        name: 'loader',
        hasBackdrop: true,
        title: '',
        hasColoredHeader: false,
        hasContentPadding: true,
        contentClassName: [
            'global!ace-u-modal-content-size--s',
            'global!ace-u-padding--128-0',
            'global!ace-u-flex',
            'global!ace-u-flex--direction-column',
            'global!ace-u-flex--align-center',
            'global!ace-u-flex--justify-center',
        ],
    },
    [bcModalContentTypes.BC_ACTION_FAILED]: {
        name: 'bc-action-failed',
        hasBackdrop: true,
        title: '',
        hasColoredHeader: false,
        hasContentPadding: true,
        contentClassName: ['global!ace-u-modal-content-size--s'],
    },
};
