import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {closeIcon, InteractiveIcon, Modal, useStyles} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';
import {useHistory} from '@computerrock/formation-router';
import {apsPriceTypes} from '@ace-de/eua-entity-types';
import priceModalConfig from './priceModalConfig';
import EditRatesByTimeDataV1 from './EditRatesByTimeDataV1';
import EditRatesByTimeDataV2 from './EditRatesByTimeDataV2';
import EditSurchargesData from './EditSurchargesData';
import EditFixedRatesData from './EditFixedRatesData';
import EditPickupRatesData from './EditPickupRatesData';
import EditSimpleServiceRatesData from './EditSimpleServiceRatesData';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import useCloseOnEscape from '../../utils/useCloseOnEscape';

const EditPricesModal = props => {
    const {cx} = useStyles();
    const {translate} = useTranslate();
    const history = useHistory();
    const {declineUpdatePrices} = props;
    const [queryParams, setQueryParams] = useState(new URLSearchParams(history.location.search));
    const contentType = queryParams.get('contentType');
    const pricePeriodVersion = queryParams.get('pricePeriodVersion');
    const modalConfig = priceModalConfig[contentType];

    useCloseOnEscape(() => {
        declineUpdatePrices();
    });

    useEffect(() => {
        setQueryParams(new URLSearchParams(history.location.search));
    }, [history.location.search, setQueryParams]);

    if (!contentType || !modalConfig) return null;

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineUpdatePrices}
                />
            )}
            title={translate(`${modalConfig.title}${queryParams.get('contentType').toLowerCase()}`)}
            hasBackdrop={modalConfig.hasBackdrop}
            className={cx(modalConfig.className)}
            contentClassName={cx('ace-c-modal__content--full-bleed')}
        >
            {contentType === apsPriceTypes.TIME_PRICE && pricePeriodVersion === '1' && (
                <EditRatesByTimeDataV1 pricePeriodVersion={pricePeriodVersion} />
            )}
            {contentType === apsPriceTypes.TIME_PRICE && pricePeriodVersion === '2' && (
                <EditRatesByTimeDataV2 pricePeriodVersion={pricePeriodVersion} />
            )}
            {contentType === apsPriceTypes.SURCHARGE_RATE && (
                <EditSurchargesData />
            )}
            {contentType === apsPriceTypes.SERVICE_FIXED_PRICE && (
                <EditFixedRatesData />
            )}
            {contentType === apsPriceTypes.PICKUP_PRICE && (
                <EditPickupRatesData />
            )}
            {contentType === apsPriceTypes.RECOVERY_PRICE && (
                <EditSimpleServiceRatesData />
            )}
        </Modal>
    );
};

EditPricesModal.propTypes = {
    declineUpdatePrices: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
    declineUpdatePrices: () => dispatch({
        type: priceManagementActionTypes.DECLINE_UPDATE_PRICES,
    }),
});

export default connect(null, mapDispatchToProps)(EditPricesModal);
