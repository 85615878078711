import {fork, put, select, take} from 'redux-saga/effects';
import {replace, resolveRoute} from '@computerrock/formation-router';
import {closeModal, openModal} from '@computerrock/formation-router/sagas';
import {AccountParty, alfAccountTypes, LeistContact} from '@ace-de/eua-entity-types';
import bcModalContentTypes from '../modals/bcModalContentTypes';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import bcModalTypes from '../modals/bcModalTypes';

const updateContractPartnerBCContactDataFlow = function* updateContractPartnerBCContactDataFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    let shouldWaitForAction = true;
    let payloadData = null;
    let chosenModalAction = null;
    let isContactDataUpdateSucceeded = false;

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contractPartnerActionTypes.INITIATE_UPDATE_CONTRACT_PARTNER_BC_CONTACT_DATA);
            const {contactId, contentType, type: modalType, client} = payload;
            payloadData = payload;

            yield* openModal(modalIds.BC_CONTACT_MODAL, {
                client: `${client}`,
                contactId: `${contactId}`,
                contentType,
                type: modalType,
            });

            // wait for the action (confirm, decline)
            chosenModalAction = yield take([
                contractPartnerActionTypes.RETURN_TO_CONTACT_SEARCH,
                contractPartnerActionTypes.SUBMIT_UPDATE_CONTRACT_PARTNER,
                contractPartnerActionTypes.SUBMIT_UPDATE_CREDITOR,
            ]);
        }
        const {location} = yield select(state => state.router);
        const {contactId, client} = payloadData;

        // on decline, close the modal
        if (chosenModalAction?.type === contractPartnerActionTypes.RETURN_TO_CONTACT_SEARCH) {
            shouldWaitForAction = true;
            isContactDataUpdateSucceeded = false;
            yield* closeModal(modalIds.BC_CONTACT_MODAL, {
                client: '',
                contactId: '',
                contentType: '',
                type: '',
            });
            continue;
        }

        if (!isContactDataUpdateSucceeded) {
            // on confirm, send request to update contact
            yield fork(fetchRequest,
                contractPartnerActionTypes.UPDATE_CONTACT_REQUEST,
                leaAssignmentFlowService.updateContact,
                {
                    contactId,
                    contactData: {
                        client,
                        ...LeistContact.objectToDTO(chosenModalAction?.payload?.contractPartnerData || {}),
                    },
                });

            const updateBCContactResponseAction = yield take([
                contractPartnerActionTypes.UPDATE_CONTACT_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.UPDATE_CONTACT_REQUEST_FAILED,
            ]);

            if (updateBCContactResponseAction.error) {
                // on error, change modal content: display 'cancel' and 'retry' buttons
                const queryParams = new URLSearchParams(location.search);
                queryParams.set('contentType', bcModalContentTypes.BC_ACTION_FAILED);
                queryParams.set('type', bcModalTypes.UPDATE_CONTRACT_PARTNER);
                yield put(replace(resolveRoute(location.pathname, {}, {search: queryParams.toString()})));

                const chosenModalAction = yield take([
                    contractPartnerActionTypes.RETRY_UPDATE_CONTACT,
                    contractPartnerActionTypes.CANCEL_ACTION,
                    // contractPartnerActionTypes.DECLINE_BC_CONTACT_MODAL_FLOW,
                ]);

                if (chosenModalAction.type === contractPartnerActionTypes.RETRY_UPDATE_CONTACT) {
                    shouldWaitForAction = false;
                    isContactDataUpdateSucceeded = false;
                    continue;
                }

                if (chosenModalAction.type === contractPartnerActionTypes.CANCEL_ACTION) {
                    shouldWaitForAction = true;
                    isContactDataUpdateSucceeded = false;
                    // close the modal
                    yield* closeModal(modalIds.BC_CONTACT_MODAL, {
                        client: '',
                        contactId: '',
                        contentType: '',
                        type: '',
                    });
                    continue;
                }
            }
        }

        // if contract partner doesn't have a creditor, skip creditor update and close the modal
        if (chosenModalAction.type === contractPartnerActionTypes.SUBMIT_UPDATE_CONTRACT_PARTNER) {
            shouldWaitForAction = true;
            isContactDataUpdateSucceeded = false;
            // close the modal
            yield* closeModal(modalIds.BC_CONTACT_MODAL, {
                client: '',
                contactId: '',
                contentType: '',
                type: '',
            });
            continue;
        }

        isContactDataUpdateSucceeded = true;

        const creditorData = {
            ...(chosenModalAction?.payload?.contractPartnerData || {}),
            id: contactId,
            accountPartyType: alfAccountTypes.CREDITOR,
        };
        // if the contact update succeeded, update a creditor
        yield fork(fetchRequest,
            contractPartnerActionTypes.UPDATE_CREDITOR_REQUEST,
            leaAssignmentFlowService.updateAccountParty,
            {
                accountPartyId: contactId,
                accountPartyData: {
                    ...AccountParty.objectToDTO(creditorData),
                    client,
                },
            });

        const creditorUpdateResponseAction = yield take([
            contractPartnerActionTypes.UPDATE_CREDITOR_REQUEST_SUCCEEDED,
            contractPartnerActionTypes.UPDATE_CREDITOR_REQUEST_FAILED,
        ]);

        if (creditorUpdateResponseAction.error) {
            // on error, change modal content: display 'cancel' and 'retry' buttons
            const queryParams = new URLSearchParams(location.search);
            queryParams.set('contentType', bcModalContentTypes.BC_ACTION_FAILED);
            queryParams.set('type', bcModalTypes.UPDATE_CREDITOR);
            yield put(replace(resolveRoute(location.pathname, {}, {search: queryParams.toString()})));

            const chosenModalAction = yield take([
                contractPartnerActionTypes.RETRY_UPDATE_CREDITOR,
                contractPartnerActionTypes.CANCEL_ACTION,
                // contractPartnerActionTypes.DECLINE_BC_CONTACT_MODAL_FLOW,
            ]);

            if (chosenModalAction.type === contractPartnerActionTypes.RETRY_UPDATE_CREDITOR) {
                shouldWaitForAction = false;
                isContactDataUpdateSucceeded = true;
                continue;
            }

            if (chosenModalAction.type === contractPartnerActionTypes.CANCEL_ACTION) {
                shouldWaitForAction = true;
                isContactDataUpdateSucceeded = false;
                // close the modal
                yield* closeModal(modalIds.BC_CONTACT_MODAL, {
                    client: '',
                    contactId: '',
                    contentType: '',
                    type: '',
                });
                continue;
            }
        }

        // if both contact and creditor updates succeeded, close the modal
        shouldWaitForAction = true;
        isContactDataUpdateSucceeded = false;
        yield* closeModal(modalIds.BC_CONTACT_MODAL, {...(location?.query && {...location.query})});
    }
};

export default updateContractPartnerBCContactDataFlow;
