import React from 'react';
import {useStyles} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';
import styles from './ACELogo.module.scss';

const ACELogo = React.forwardRef((props, ref) => {
    const {cx} = useStyles(props, styles);
    const {translate} = useTranslate();

    return (
        <div className={cx(['global!ace-u-flex', 'global!ace-u-flex--align-center'])}>
            <div ref={ref} className={cx('ace-c-ace-logo')} />
            <div className={cx('global!ace-u-padding--0-32')}>
                {translate('navigation_bar.logo_title.contract_partner_management')}
            </div>
        </div>
    );
});

ACELogo.displayName = 'ACELogo';

export default ACELogo;
