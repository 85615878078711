export const vpmFeatureActions = {
    CREATE: 'CREATE',
    READ: 'READ',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
};

export const vpmFeatures = {
    CONTRACT_PARTNER: 'CONTRACT_PARTNER',
    QUALITY_REPORT: 'QUALITY_REPORT',
    TEMPORARY_RESTRICTION: 'TEMPORARY_RESTRICTION',
};
