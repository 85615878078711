import {fork, put, select, take} from 'redux-saga/effects';
import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as qualityManagementFeedbackActionTypes from '../qualityManagementFeedbackActionTypes';
import qualityManagementFeedbackScreenTabs from '../qualityManagementFeedbackScreenTabs';
import config from '../../config';

const loadQualityManagementFeedback = function* loadQualityManagementFeedback({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const {match, location} = payload;
    const {activeTab} = yield* selectSearchQueryParams();
    const {qualityManagementFeedbackId} = match.params;

    yield fork(
        fetchRequest,
        qualityManagementFeedbackActionTypes.FETCH_QM_FEEDBACKS_REQUEST,
        partnerManagementService.getQualityManagementFeedback,
        {
            qualityManagementFeedbackId,
        },
    );

    const responseAction = yield take([
        qualityManagementFeedbackActionTypes.FETCH_QM_FEEDBACKS_REQUEST_SUCCEEDED,
        qualityManagementFeedbackActionTypes.FETCH_QM_FEEDBACKS_REQUEST_FAILED,
    ]);

    if (responseAction.error) return;

    const {response} = responseAction.payload;
    const {qualityManagementFeedbackDTO} = response;

    yield put({
        type: qualityManagementFeedbackActionTypes.STORE_QUALITY_MANAGEMENT_FEEDBACKS,
        payload: {qualityManagementFeedbackDTOs: [qualityManagementFeedbackDTO]},
    });

    if (activeTab === qualityManagementFeedbackScreenTabs.QM_OVERVIEW) {
        // set the default 'activeContractPartnerId' value
        yield put({
            type: qualityManagementFeedbackActionTypes.SET_ACTIVE_CONTRACT_PARTNER_ID,
            payload: {
                activeContractPartnerId: qualityManagementFeedbackDTO.contractPartnerId,
            },
        });
    }

    if (location.query.activeTab !== qualityManagementFeedbackScreenTabs.CASE_LOG
    || !qualityManagementFeedbackDTO.caseId) return;

    const searchQueryParams = new URLSearchParams(location.search);
    searchQueryParams.append('size', `${config.PROTOCOL_DEFAULT_PAGE_SIZE}`);
    if (!searchQueryParams.get('page')) {
        searchQueryParams.append('page', 0);
    }

    yield put({
        type: qualityManagementFeedbackActionTypes.FILTER_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOGS,
        payload: {searchQueryParams, serviceCaseId: qualityManagementFeedbackDTO.caseId},
    });

    yield put({
        type: qualityManagementFeedbackActionTypes.FETCH_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOG_SERVICES,
        payload: {serviceCaseId: qualityManagementFeedbackDTO.caseId},
    });
};

export default loadQualityManagementFeedback;
