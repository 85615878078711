import {delay, fork, put, select, take} from 'redux-saga/effects';
import {replace, resolveRoute} from '@computerrock/formation-router';
import {closeModal} from '@computerrock/formation-router/sagas';
import {AccountParty, alfAccountTypes, LeistContact} from '@ace-de/eua-entity-types';
import bcModalContentTypes from '../modals/bcModalContentTypes';
import * as contractPartnersActionTypes from '../contractPartnerActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import bcModalTypes from '../modals/bcModalTypes';

const createContractPartnerBCContactFlow = function* createContractPartnerBCContactFlow() {
    let shouldWaitForAction = true;
    let payloadData = null;
    let shouldRetryContactCreation = false;
    let retryContactCreationData = null;
    let shouldRetryCreditorCreation = false;
    let retryCreditorCreationData = null;
    let shouldCreateCreditor = false;
    let contactId = null;

    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contractPartnersActionTypes.INITIATE_CONTRACT_PARTNER_CREATION_MODAL_FLOW);
            payloadData = payload;
        }
        const {location} = yield select(state => state.router);

        let chosenAction;

        const {modalType, client} = payloadData;

        if (!shouldRetryContactCreation && !shouldRetryCreditorCreation && client && modalType) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set('contentType', bcModalContentTypes.CONTRACT_PARTNER);
            searchParams.set('client', client);
            searchParams.set('type', modalType);

            yield put(replace(resolveRoute(location.pathname, {}, {search: searchParams.toString()})));

            chosenAction = yield take([
                contractPartnersActionTypes.RETURN_TO_CONTACT_SEARCH,
                contractPartnersActionTypes.SUBMIT_CONTRACT_PARTNER_CREATION,
            ]);
        }

        if (shouldRetryContactCreation
            || chosenAction?.type === contractPartnersActionTypes.SUBMIT_CONTRACT_PARTNER_CREATION) {
            const contractPartnerData = !shouldRetryContactCreation && chosenAction.payload.contractPartnerData
                ? chosenAction.payload.contractPartnerData : retryContactCreationData?.contractPartnerData;
            shouldCreateCreditor = !shouldRetryContactCreation && chosenAction.payload.isOnlyContact
                ? !chosenAction.payload.isOnlyContact : !retryContactCreationData?.isOnlyContact;

            const loaderSearchParams = new URLSearchParams(location.search);
            loaderSearchParams.set('contentType', bcModalContentTypes.LOADER);
            loaderSearchParams.set('type', modalType);

            yield put(replace(resolveRoute(location.pathname, {}, {search: loaderSearchParams.toString()})));

            yield fork(fetchRequest,
                contractPartnersActionTypes.CREATE_NEW_CONTACT_REQUEST,
                leaAssignmentFlowService.createContact,
                {
                    contactData: {
                        client,
                        ...LeistContact.objectToDTO(contractPartnerData),
                    },
                });

            const responseAction = yield take([
                contractPartnersActionTypes.CREATE_NEW_CONTACT_REQUEST_SUCCEEDED,
                contractPartnersActionTypes.CREATE_NEW_CONTACT_REQUEST_FAILED,
            ]);

            yield delay(100);
            shouldRetryContactCreation = false;

            if (responseAction.error) {
                const failedQueryParams = new URLSearchParams(location.search);
                failedQueryParams.set('contentType', bcModalContentTypes.BC_ACTION_FAILED);
                failedQueryParams.set('type', bcModalTypes.CREATE_CONTRACT_PARTNER);
                yield put(replace(resolveRoute(location.pathname, {}, {search: failedQueryParams.toString()})));

                const nextAction = yield take([
                    contractPartnersActionTypes.RETRY_CONTACT_CREATION,
                    contractPartnersActionTypes.CANCEL_ACTION,
                    contractPartnersActionTypes.DECLINE_BC_CONTACT_MODAL_FLOW,
                ]);

                if (nextAction.type === contractPartnersActionTypes.RETRY_CONTACT_CREATION) {
                    shouldRetryContactCreation = true;
                    shouldWaitForAction = false;
                    retryContactCreationData = {
                        contractPartnerData,
                        isOnlyContact: !shouldCreateCreditor,
                    };
                    continue;
                }

                if (nextAction.type === contractPartnersActionTypes.CANCEL_ACTION) {
                    shouldWaitForAction = false;
                    shouldRetryContactCreation = false;
                    continue;
                }
            }

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {contactDTO} = response;

                if (!shouldCreateCreditor) {
                    shouldWaitForAction = true;
                    yield* closeModal(modalIds.BC_CONTACT_MODAL, {contentType: '', type: '', client: '', contactId: ''});
                    yield put({
                        type: contractPartnersActionTypes.INITIATE_BC_CONTACT_MODAL_FLOW,
                        payload: {
                            client,
                            contactId: contactDTO.id,
                            initiateContactSearch: true,
                        },
                    });
                    continue;
                }
                shouldCreateCreditor = true;
                contactId = contactDTO.id;
            }
        }

        if (shouldRetryCreditorCreation || (shouldCreateCreditor && contactId)) {
            const creditorData = !shouldRetryCreditorCreation && chosenAction?.payload?.contractPartnerData
                ? {
                    ...chosenAction.payload.contractPartnerData,
                    id: contactId,
                    accountPartyType: alfAccountTypes.CREDITOR,
                }
                : retryCreditorCreationData?.creditorData;

            const loaderSearchParams = new URLSearchParams(location.search);

            if (loaderSearchParams.get('contentType') !== bcModalContentTypes.LOADER) {
                loaderSearchParams.set('contentType', bcModalContentTypes.LOADER);
                loaderSearchParams.set('type', modalType);

                yield put(replace(resolveRoute(location.pathname, {}, {search: loaderSearchParams.toString()})));
            }

            yield fork(fetchRequest,
                contractPartnersActionTypes.CREATE_NEW_CREDITOR_REQUEST,
                leaAssignmentFlowService.createAccountParty,
                {
                    accountPartyData: {
                        ...AccountParty.objectToDTO(creditorData),
                        client,
                    },
                });

            const creditorCreationResponseAction = yield take([
                contractPartnersActionTypes.CREATE_NEW_CREDITOR_REQUEST_SUCCEEDED,
                contractPartnersActionTypes.CREATE_NEW_CREDITOR_REQUEST_FAILED,
            ]);

            yield delay(100);
            shouldRetryCreditorCreation = false;

            if (!creditorCreationResponseAction.error) {
                const {response} = creditorCreationResponseAction.payload;
                const {accountPartyDTO} = response;

                shouldWaitForAction = true;
                yield* closeModal(modalIds.BC_CONTACT_MODAL, {contentType: '', type: '', client: '', contactId: ''});
                yield put({
                    type: contractPartnersActionTypes.INITIATE_BC_CONTACT_MODAL_FLOW,
                    payload: {
                        client,
                        contactId: accountPartyDTO.id,
                        initiateContactSearch: true,
                    },
                });
                continue;
            }

            if (creditorCreationResponseAction.error) {
                const failedQueryParams = new URLSearchParams(location.search);
                failedQueryParams.set('contentType', bcModalContentTypes.BC_ACTION_FAILED);
                failedQueryParams.set('type', bcModalTypes.CREATE_CREDITOR);
                yield put(replace(resolveRoute(location.pathname, {}, {search: failedQueryParams.toString()})));

                const nextAction = yield take([
                    contractPartnersActionTypes.RETRY_CREDITOR_CREATION,
                    contractPartnersActionTypes.CANCEL_ACTION,
                    contractPartnersActionTypes.DECLINE_BC_CONTACT_MODAL_FLOW,
                ]);

                if (nextAction.type === contractPartnersActionTypes.RETRY_CREDITOR_CREATION) {
                    shouldRetryCreditorCreation = true;
                    shouldWaitForAction = false;
                    retryCreditorCreationData = {
                        creditorData,
                    };
                    continue;
                }

                if (nextAction.type === contractPartnersActionTypes.CANCEL_ACTION) {
                    shouldWaitForAction = true;
                    shouldRetryCreditorCreation = false;
                    shouldCreateCreditor = false;

                    yield* closeModal(modalIds.BC_CONTACT_MODAL, {contentType: '', type: '', client: '', contactId: ''});
                    yield put({
                        type: contractPartnersActionTypes.INITIATE_BC_CONTACT_MODAL_FLOW,
                        payload: {
                            client,
                            contactId,
                            initiateContactSearch: true,
                        },
                    });
                    continue;
                }
            }
        }

        shouldWaitForAction = true;
        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.set('contentType', bcModalContentTypes.CONTACT_SEARCH);
        newSearchParams.set('client', client);

        yield put(replace(resolveRoute(location.pathname, {}, {search: newSearchParams.toString()})));
    }
};

export default createContractPartnerBCContactFlow;
