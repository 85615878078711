import {createSelector} from 'reselect';
/**
 * General state selectors
 */
export const getQMFeedbacks = state => state.qualityManagementFeedbacks.qmFeedbacks;
export const getQMFeedbackSearchResults = state => state.qualityManagementFeedbacks.qmFeedbacksSearchResults;
export const getQMFeedbackSearchCount = state => state.qualityManagementFeedbacks.qmFeedbacksSearchCount;
export const getQMSelectedFeedback = state => state.qualityManagementFeedbacks.qmSelectedFeedback;

/**
 * Returns selector for qmFeedback matched from route
 */
export const createQMFeedbackSelector = () => createSelector(
    [
        getQMFeedbacks,
        (state, props) => {
            const {match} = props;
            return match?.params['qualityManagementFeedbackId'];
        },
    ], (qmFeedbacks, qualityManagementFeedbackId) => {
        if (!qualityManagementFeedbackId) return null;
        return qmFeedbacks[qualityManagementFeedbackId] || null;
    },
);
