import {selectSearchQueryParams} from '@computerrock/formation-router/sagas';
import contractPartnerScreenTabs from '../contractPartnerScreenTabs';
import filterMapCPOperationAreasById from './filterMapCPOperationAreasById';

const loadContractPartnerOperationAreas = function* loadContractPartnerOperationAreas({contractPartnerId}) {
    const {activeTab} = yield* selectSearchQueryParams();

    if (activeTab !== contractPartnerScreenTabs.OPERATION_AREA) return;

    yield* filterMapCPOperationAreasById(contractPartnerId);
};

export default loadContractPartnerOperationAreas;
