import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useStyles, Panel, NoResultsBlock, Button} from '@ace-de/ui-components';
import {deleteIcon, editIcon, Icon, findCaseIcon, plusIcon, InteractiveIcon} from '@ace-de/ui-components/icons';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {useTranslate} from '@computerrock/formation-i18n';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

const ContractPartnerAdditionalLocationsPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_basic_data_tab');
    const {contractPartner, initiateOperatingUnitCreationFlow} = props;
    const {initiateDeleteOperatingUnitFlow, initiateUpdateOperatingUnitFlow} = props;
    const [isEditModeActive] = useState(false);

    const handleOnCreate = () => {
        initiateOperatingUnitCreationFlow({
            contractPartnerId: contractPartner.id,
        });
    };

    const handleOnEdit = unitId => {
        initiateUpdateOperatingUnitFlow({
            unitId,
            contractPartnerId: contractPartner.id,
        });
    };

    const handleOnDelete = unitId => {
        initiateDeleteOperatingUnitFlow({
            unitId,
            contractPartnerId: contractPartner.id,
        });
    };

    // if no contract partner, don't render
    if (!contractPartner) return null;

    return (
        <Panel
            title={translateTab('panel_title.additional_locations')}
            actions={(
                <Button
                    onClick={handleOnCreate}
                >
                    <Icon
                        icon={plusIcon}
                        className={cx([
                            'ace-c-icon--color-highlight',
                            'global!ace-u-margin--right-8',
                        ])}
                    />
                    {translateTab('button_label.create_new_operating_unit')}
                </Button>
            )}
        >
            {!isEditModeActive && (
                <Table qaIdent="contract-partner-additional-locations">
                    {contractPartner.operatingUnits?.length === 0 && (
                        <TableCaption>
                            <NoResultsBlock
                                icon={(
                                    <Icon
                                        className={cx('ace-c-icon--xxl')}
                                        icon={findCaseIcon}
                                    />
                                )}
                                message={translateTab('no_results.message')}
                                description={translateTab('no_results.description')}
                            />
                        </TableCaption>
                    )}
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={4} qaIdentPart="cp-additional-location-name">
                                {translateTab('table_row_label.name_type')}
                            </TableCell>
                            <TableCell colSpan={6} qaIdentPart="cp-additional-location-address">
                                {translateTab('table_row_label.address')}
                            </TableCell>
                            <TableCell colSpan={1} />
                            <TableCell colSpan={1} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contractPartner.operatingUnits
                            && contractPartner.operatingUnits
                                .filter(unit => !unit.deleted)
                                .sort((unitA, unitB) => unitA.id - unitB.id)
                                .map(unit => (
                                    <TableRow
                                        key={unit.id}
                                        qaIdentPart={unit.id}
                                    >
                                        <TableCell
                                            qaIdentPart="cp-additional-location-name"
                                            qaIdentPartPostfix={unit.id}
                                            colSpan={4}
                                        >
                                            {unit.name}
                                        </TableCell>
                                        <TableCell
                                            qaIdentPart="cp-additional-location-address"
                                            qaIdentPartPostfix={unit.id}
                                            colSpan={6}
                                        >
                                            {unit.location?.formattedAddress || ''}
                                        </TableCell>
                                        <TableCell colSpan={1}>
                                            <InteractiveIcon
                                                icon={editIcon}
                                                onClick={() => handleOnEdit(unit.id)}
                                                className={cx('ace-c-interactive-icon--primary')}
                                            />
                                        </TableCell>
                                        <TableCell colSpan={1}>
                                            <InteractiveIcon
                                                icon={deleteIcon}
                                                onClick={() => handleOnDelete(unit.id)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                    </TableBody>
                </Table>
            )}
        </Panel>
    );
};

ContractPartnerAdditionalLocationsPanel.propTypes = {
    contractPartner: PropTypes.object,
    initiateDeleteOperatingUnitFlow: PropTypes.func.isRequired,
    initiateUpdateOperatingUnitFlow: PropTypes.func.isRequired,
    initiateOperatingUnitCreationFlow: PropTypes.func.isRequired,
};

ContractPartnerAdditionalLocationsPanel.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateDeleteOperatingUnitFlow: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_DELETE_CP_OPERATING_UNIT_FLOW,
        payload,
    }),
    initiateUpdateOperatingUnitFlow: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_UPDATE_CP_OPERATING_UNIT_FLOW,
        payload,
    }),
    initiateOperatingUnitCreationFlow: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CREATE_CP_OPERATING_UNIT_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerAdditionalLocationsPanel));
