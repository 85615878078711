const errorTypes = {
    PRICE_MATRIX_UPLOAD_FAILED: 'PRICE_MATRIX_UPLOAD_FAILED',
    UNKNOWN_ERROR: 'UNKNOWN_ERROR',
    CP_CONTACT_DETAILS_UPDATE_FAILED: 'CP_CONTACT_DETAILS_UPDATE_FAILED',
    CP_ACCOUNT_MANAGER_UPDATE_FAILED: 'CP_ACCOUNT_MANAGER_UPDATE_FAILED',
    CP_RELATIONS_UPDATE_FAILED: 'CP_RELATIONS_UPDATE_FAILED',
    CP_EXTERNAL_COMMISSIONERS_UPDATE_FAILED: 'CP_EXTERNAL_COMMISSIONERS_UPDATE_FAILED',
    CP_ADDITIONAL_FEATURES_UPDATE_FAILED: 'CP_ADDITIONAL_FEATURES_UPDATE_FAILED',
    CP_BASIC_SERVICES_UPDATE_FAILED: 'CP_BASIC_SERVICES_UPDATE_FAILED',
    CP_SERVICES_UPDATE_FAILED: 'CP_SERVICES_UPDATE_FAILED',
    CP_ADDITIONAL_SERVICES_UPDATE_FAILED: 'CP_ADDITIONAL_SERVICES_UPDATE_FAILED',
    CP_CONTACT_DATA_CREATION_FAILED: 'CP_CONTACT_DATA_CREATION_FAILED',
    CP_CONTACT_DATA_UPDATE_FAILED: 'CP_CONTACT_DATA_UPDATE_FAILED',
    CP_ASSIGNMENT_CHANNELS_UPDATE_FAILED: 'CP_ASSIGNMENT_CHANNELS_UPDATE_FAILED',
    CP_TEMPORARY_RESTRICTION_CREATION_FAILED: 'CP_TEMPORARY_RESTRICTION_CREATION_FAILED',
    CP_TEMPORARY_RESTRICTION_UPDATE_FAILED: 'CP_TEMPORARY_RESTRICTION_UPDATE_FAILED',
    CP_QUALITY_REPORT_CREATION_FAILED: 'CP_QUALITY_REPORT_CREATION_FAILED',
    CP_STATUS_UPDATE_FAILED: 'CP_STATUS_UPDATE_FAILED',
    CP_FIXED_PRICE_UPDATE_FAILED: 'CP_FIXED_PRICE_UPDATE_FAILED',
    CP_FIXED_PRICE_CREATION_FAILED: 'CP_FIXED_PRICE_CREATION_FAILED',
    CP_FIXED_PRICE_DELETION_FAILED: 'CP_FIXED_PRICE_DELETION_FAILED',
    CP_ELLA_ACCOUNT_CREATION_FAILED: 'CP_ELLA_ACCOUNT_CREATION_FAILED',
    CP_ELLA_ACCOUNT_UPDATE_FAILED: 'CP_ELLA_ACCOUNT_UPDATE_FAILED',
    CP_OPERATING_UNIT_CREATION_FAILED: 'CP_OPERATING_UNIT_CREATION_FAILED',
    CP_OPERATING_UNIT_UPDATE_FAILED: 'CP_OPERATING_UNIT_UPDATE_FAILED',
    PRICE_MANAGEMENT_TIME_PERIOD_UPDATE_FAILED: 'PRICE_MANAGEMENT_TIME_PERIOD_UPDATE_FAILED',
    PRICE_MANAGEMENT_PRICES_BY_TIME_UPDATE_FAILED: 'PRICE_MANAGEMENT_PRICES_BY_TIME_UPDATE_FAILED',
};

export default errorTypes;
