import {AccessControlPlugin} from '@ace-de/eua-user-access-control';
import {eupUserRoleTypes, clientTypes} from '@ace-de/eua-entity-types';
import vpmAuthorizationRoles from './application/vpmAuthorizationRoles';
import routePaths from './routePaths';

/**
 * Instantiate AccessControlPlugin for VPM auth layer
 *
 * @type {AccessControlPlugin}
 */
const vpmAccessControl = new AccessControlPlugin({
    name: 'vpmSystem',
    client: clientTypes.VPM_CLIENT,
    systemRoles: vpmAuthorizationRoles,
    systemAccessDeniedURI: routePaths.SYSTEM_ACCESS_DENIED,
    routeAccessDeniedURI: routePaths.ROUTE_ACCESS_DENIED,
    defaultLayoutRole: eupUserRoleTypes.CP_MANAGER,
});

export default vpmAccessControl;
