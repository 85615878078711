/**
 * @typedef {string} tabId
 */

/**
 * qualityManagementFeedback screen tabs
 *
 * @enum {tabId}
 */
export default {
    QM_OVERVIEW: 'qm-overview',
    CASE_LOG: 'case-log',
};
