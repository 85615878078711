import {put, select, take, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import modalIds from '../../modalIds';

const deleteCPOperatingUnitFlow = function* deleteCPOperatingUnitFlow() {
    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');

    while (true) {
        const {payload} = yield take(contractPartnerActionTypes.INITIATE_DELETE_CP_OPERATING_UNIT_FLOW);
        const {unitId, contractPartnerId} = payload;

        yield* openModal(modalIds.DELETE_CP_OPERATING_UNIT_CONFIRMATION);

        const responseAction = yield take([
            contractPartnerActionTypes.CONFIRM_DELETE_CP_OPERATING_UNIT,
            contractPartnerActionTypes.DECLINE_DELETE_CP_OPERATING_UNIT,
        ]);

        if (responseAction.type === contractPartnerActionTypes.CONFIRM_DELETE_CP_OPERATING_UNIT) {
            yield fork(
                fetchRequest,
                contractPartnerActionTypes.DELETE_CP_OPERATING_UNIT_REQUEST,
                partnerManagementService.deleteCPOperatingUnit,
                {
                    unitId,
                },
            );

            const deleteCPOperatingUnitResponseAction = yield take([
                contractPartnerActionTypes.DELETE_CP_OPERATING_UNIT_REQUEST_SUCCEEDED,
                contractPartnerActionTypes.DELETE_CP_OPERATING_UNIT_REQUEST_FAILED,
            ]);

            if (!deleteCPOperatingUnitResponseAction.error) {
                yield put({
                    type: contractPartnerActionTypes.REMOVE_CP_OPERATING_UNIT,
                    payload: {
                        contractPartnerId,
                        unitId,
                    },
                });
            }
        }

        yield* closeModal(modalIds.DELETE_CP_OPERATING_UNIT_CONFIRMATION);
    }
};

export default deleteCPOperatingUnitFlow;
