import {put, select, take, fork} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as qualityManagementFeedbackActionTypes from '../qualityManagementFeedbackActionTypes';

const fetchQMFeedbackCaseLogServices = function* fetchQMFeedbackCaseLogServices({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const ecsHistoryManagementService = serviceManager.loadService('ecsHistoryManagementService');
    const {serviceCaseId} = payload;

    yield fork(
        fetchRequest,
        qualityManagementFeedbackActionTypes.FETCH_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOG_SERVICES_REQUEST,
        ecsHistoryManagementService.getServiceTypes,
        {serviceCaseId},
    );

    const responseAction = yield take([
        qualityManagementFeedbackActionTypes.FETCH_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOG_SERVICES_REQUEST_SUCCEEDED,
        qualityManagementFeedbackActionTypes.FETCH_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOG_SERVICES_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {serviceTypes} = response;

        yield put({
            type: qualityManagementFeedbackActionTypes.STORE_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOG_SERVICES,
            payload: {serviceTypes: serviceTypes},
        });
    }
};

export default fetchQMFeedbackCaseLogServices;
