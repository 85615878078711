import {useEffect, useCallback} from 'react';

const useCloseOnEscape = cb => {
    const handleOnKeyDown = useCallback(event => {
        if (event?.key !== 'Escape') return;
        cb();
    }, [cb]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);
};

export default useCloseOnEscape;
