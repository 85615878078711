import applicationReducer from './application/applicationReducer';
import userReducer from './user-profiles/userProfileReducer';
import keycloakAuth from './keycloakAuth';
import arcGISAuth from './arcGISAuth';
import contractPartnerReducer from './contract-partners/contractPartnerReducer';
import qualityManagementFeedbackReducer from './quality-management-feedbacks/qualityManagementFeedbackReducer';
import priceManagementReducer from './price-management/priceManagementReducer';
import vpmAccessControl from './vpmAccessControl';

// application reducers
const reducers = {
    application: applicationReducer,
    user: userReducer,
    contractPartners: contractPartnerReducer,
    qualityManagementFeedbacks: qualityManagementFeedbackReducer,
    priceManagement: priceManagementReducer,
    ...keycloakAuth.reducer,
    ...arcGISAuth.reducer,
    ...vpmAccessControl.reducer,
};

export default reducers;
