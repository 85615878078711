const {apsTimePriceTariffTypes} = require('@ace-de/eua-entity-types');

const TIME_SLOTS = {
    earlySlot: ['07:00', '18:00'],
    lateSlot: ['18:00', '07:00'],
    allDaySlot: ['00:00', '24:00'],
};

const DAY_OPTIONS = {
    MONDAY: 'MONDAY',
    TUESDAY: 'TUESDAY',
    FRIDAY: 'FRIDAY',
    SATURDAY: 'SATURDAY',
    SUNDAY: 'SUNDAY',
    WORKDAYS: 'WORKDAYS',
    SUNDAY_HOLIDAY: 'SUNDAY_HOLIDAY',
};

const generateTariffDetails = tariff => {
    let extendedTariff;

    switch (tariff) {
        case apsTimePriceTariffTypes.WORKDAY_EARLY:
            extendedTariff = {
                days: [DAY_OPTIONS['WORKDAYS']],
                timeSlot: TIME_SLOTS['earlySlot'],
            };
            break;

        case apsTimePriceTariffTypes.WORKDAY_LATE:
            extendedTariff = {
                days: [DAY_OPTIONS['WORKDAYS']],
                timeSlot: TIME_SLOTS['lateSlot'],
            };
            break;

        case apsTimePriceTariffTypes.SATURDAY:
            extendedTariff = {
                days: [DAY_OPTIONS['SATURDAY']],
                timeSlot: TIME_SLOTS['earlySlot'],
            };
            break;

        case apsTimePriceTariffTypes.SUNDAY_HOLIDAY:
            extendedTariff = {
                days: [DAY_OPTIONS['SUNDAY_HOLIDAY'], DAY_OPTIONS['FRIDAY']],
                timeSlot: TIME_SLOTS['allDaySlot'],
            };
            break;
        default:
    }

    return extendedTariff;
};

export default generateTariffDetails;
