/**
 * @typedef {string} qmFeedbackForwardingStatus
 */

/**
 * QM feedback forwarding status types
 *
 * @enum {msClientType}
 */
export default {
    FAILED: 'FAILED',
    SUCCEEDED: 'SUCCEEDED',
};
