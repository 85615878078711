import {eventChannel} from 'redux-saga';
import {select, take, put, call} from 'redux-saga/effects';
import * as qualityManagementFeedbackActionTypes from '../qualityManagementFeedbackActionTypes';

const onQMFeedbackFullScreenMapClickSelectCP = function* onQMFeedbackFullScreenMapClickSelectCP() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');

    const arcGISMap = yield call(arcGISMapService.getMap, 'qm-feedback-cp-ranking-full-screen-map');

    const qmFeedbackMapClickEventChannel = eventChannel(emitter => {
        if (arcGISMap) {
            const unsubscribeClickListener = arcGISMap.on('graphic-click', payload => {
                emitter(payload);
            });

            // returns unsubscribe method to eventChannel
            return () => unsubscribeClickListener();
        }
        return () => {};
    });

    while (true) {
        const {graphic} = yield take(qmFeedbackMapClickEventChannel);
        if (graphic?.attributes?.graphicId) {
            const activeContractPartnerId = graphic.attributes.graphicId.split('-')[1];
            yield put({
                type: qualityManagementFeedbackActionTypes.SET_ACTIVE_CONTRACT_PARTNER_ID,
                payload: {
                    activeContractPartnerId,
                },
            });
        }
    }
};

export default onQMFeedbackFullScreenMapClickSelectCP;
