import {all, takeLatest, fork} from 'redux-saga/effects';
import * as contractPartnerActionTypes from './contractPartnerActionTypes';
import searchContractPartners from './sagas/searchContractPartners';
import contractPartnerStatusUpdateFlow from './sagas/contractPartnerStatusUpdateFlow';
import contractPartnerContactDetailsUpdateFlow from './sagas/contractPartnerContactDetailsUpdateFlow';
import editTemporaryRestrictionFlow from './sagas/editTemporaryRestrictionFlow';
import updateContractPartnerAccountManager from './sagas/updateContractPartnerAccountManager';
import contractPartnerAssignmentChannelsUpdateFlow from './sagas/contractPartnerAssignmentChannelsUpdateFlow';
import updateContractPartnerRelationsFlow from './sagas/updateContractPartnerRelationsFlow';
import updateContractPartnerBasicServicesFlow from './sagas/updateContractPartnerBasicServicesFlow';
import updateContractPartnerAdditionalServicesFlow from './sagas/updateContractPartnerAdditionalServicesFlow';
import searchServiceCases from './sagas/searchServiceCases';
import updateContractPartnerServices from './sagas/updateContractPartnerServices';
import updateContractPartnerExternalCommissionersFlow from './sagas/updateContractPartnerExternalCommissionersFlow';
import updateContractPartnerAdditionalFeaturesFlow from './sagas/updateContractPartnerAdditionalFeaturesFlow';
import initiateCPCreateELLAAccountFlow from './sagas/initiateCPCreateELLAAccountFlow';
import searchCPQualityManagementFeedbacks from './sagas/searchCPQualityManagementFeedbacks';
import deleteCPOperatingUnitFlow from './sagas/deleteCPOperatingUnitFlow';
import createCPOperatingUnitFlow from './sagas/createCPOperatingUnitFlow';
import searchCPOperatingUnitLocationGeolocation from './sagas/searchCPOperatingUnitLocationGeolocation';
import updateCPOperatingUnitFlow from './sagas/updateCPOperatingUnitFlow';
import createTemporaryRestrictionFlow from './sagas/createTemporaryRestrictionFlow';
import createQualityReportFlow from './sagas/createQualityReportFlow';
import selectCPOperationAreaFlow from './sagas/selectCPOperationAreaFlow';
import cpOperationAreaMapWatcher from './sagas/cpOperationAreaMapWatcher';
import createCPContactDataFlow from './sagas/createCPContactDataFlow';
import deleteCPContactDataFlow from './sagas/deleteCPContactDataFlow';
import updateCPContactDataFlow from './sagas/updateCPContactDataFlow';
import downloadCPOverview from './sagas/downloadCPOverview';
import searchCPLocationGeolocation from './sagas/searchCPLocationGeolocation';
import updateContractPartnerLocationFlow from './sagas/updateContractPartnerLocationFlow';
import fetchContractPartnerBCDetails from './sagas/fetchContractPartnerBCDetails';
import searchBCContactsFlow from './sagas/searchBCContactsFlow';
import searchBCContacts from './sagas/searchBCContacts';
import createContractPartnerBCCreditorFlow from './sagas/createContractPartnerBCCreditorFlow';
import createContractPartnerBCContactFlow from './sagas/createContractPartnerBCContactFlow';
import updateContractPartnerBCCreditorFlow from './sagas/updateContractPartnerBCCreditorFlow';
import updateContractPartnerBCContactFlow from './sagas/updateContractPartnerBCContactFlow';
import contractPartnerELLAAccountUpdateFlow from './sagas/contractPartnerELLAAccountUpdateFlow';
import updateContractPartnerBCContactDataFlow from './sagas/updateContractPartnerBCContactDataFlow';
import loadCPOperationAreaMapLayers from './sagas/loadCPOperationAreaMapLayers';

/**
 *  Contract partner watcher saga
 */
const contractPartnerWatcher = function* contractPartnerWatcher() {
    yield all([
        takeLatest(contractPartnerActionTypes.SEARCH_CONTRACT_PARTNERS, searchContractPartners),
        takeLatest(contractPartnerActionTypes.SEARCH_SERVICE_CASES, searchServiceCases),
        takeLatest(
            contractPartnerActionTypes.SEARCH_CP_QUALITY_MANAGEMENT_FEEDBACKS,
            searchCPQualityManagementFeedbacks,
        ),
        takeLatest(
            contractPartnerActionTypes.SEARCH_CP_OPERATING_UNIT_LOCATION_GEOLOCATION,
            searchCPOperatingUnitLocationGeolocation,
        ),
        fork(contractPartnerStatusUpdateFlow),
        fork(contractPartnerContactDetailsUpdateFlow),
        fork(createTemporaryRestrictionFlow),
        fork(createQualityReportFlow),
        fork(editTemporaryRestrictionFlow),
        fork(updateContractPartnerAccountManager),
        fork(contractPartnerAssignmentChannelsUpdateFlow),
        fork(updateContractPartnerRelationsFlow),
        fork(updateContractPartnerBasicServicesFlow),
        fork(updateContractPartnerAdditionalServicesFlow),
        fork(updateContractPartnerServices),
        fork(updateContractPartnerExternalCommissionersFlow),
        fork(updateContractPartnerAdditionalFeaturesFlow),
        fork(initiateCPCreateELLAAccountFlow),
        fork(deleteCPOperatingUnitFlow),
        fork(createCPOperatingUnitFlow),
        fork(updateCPOperatingUnitFlow),
        fork(selectCPOperationAreaFlow),
        fork(cpOperationAreaMapWatcher),
        fork(createCPContactDataFlow),
        fork(deleteCPContactDataFlow),
        fork(updateCPContactDataFlow),
        fork(downloadCPOverview),
        fork(updateContractPartnerLocationFlow),
        fork(fetchContractPartnerBCDetails),
        fork(searchBCContacts),
        fork(createContractPartnerBCCreditorFlow),
        fork(createContractPartnerBCContactFlow),
        fork(updateContractPartnerBCCreditorFlow),
        fork(updateContractPartnerBCContactFlow),
        fork(contractPartnerELLAAccountUpdateFlow),
        takeLatest(contractPartnerActionTypes.SEARCH_CP_LOCATION_GEOLOCATION, searchCPLocationGeolocation),
        takeLatest(contractPartnerActionTypes.INITIATE_BC_CONTACT_MODAL_FLOW, searchBCContactsFlow),
        fork(updateContractPartnerBCContactDataFlow),
        fork(loadCPOperationAreaMapLayers),
    ]);
};

export default contractPartnerWatcher;
