import React from 'react';
import {useTranslate} from '@computerrock/formation-i18n';
import {AppLayoutMain, Icon, towingIcon, useStyles} from '@ace-de/ui-components';

const MaintenanceScreen = () => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('maintenance_screen');

    return (
        <AppLayoutMain>
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-center',
                    'global!ace-u-height--full',
                ])}
            >
                <Icon
                    icon={towingIcon}
                    className={cx([
                        'global!ace-c-icon--xxl',
                        'global!ace-c-icon--color-warning',
                    ])}
                />
                <p
                    className={cx([
                        'global!ace-u-typography--variant-h3',
                        'global!ace-u-margin--24-0',
                    ])}
                >
                    {translateScreen('heading.maintenance').toUpperCase()}
                </p>
                <p className={cx(['global!ace-u-typography--variant-body-bold', 'global!ace-u-typography--align-center'])}>
                    {translateScreen('subheading.maintenance')}
                </p>
            </div>
        </AppLayoutMain>
    );
};

export default MaintenanceScreen;
