import {eventChannel} from 'redux-saga';
import {call, select, take} from 'redux-saga/effects';

const onContractPartnerMapFeatureHover = function* onContractPartnerMapFeatureHover() {
    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'vpm-contract-partners');

    const featureHoverEventChannel = eventChannel(emitter => {
        const unsubscribeHoverListener = arcGISMap.on('feature-hover', () => {
            emitter(true);
        });
        const unsubscribeHoverEndListener = arcGISMap.on('feature-hover-end', () => {
            emitter(false);
        });

        return () => {
            unsubscribeHoverListener();
            unsubscribeHoverEndListener();
        };
    });

    while (true) {
        const eventOccurred = yield take(featureHoverEventChannel);
        if (eventOccurred) {
            arcGISMap.setMapCursor('pointer');
            continue;
        }
        arcGISMap.setMapCursor('default');
    }
};

export default onContractPartnerMapFeatureHover;
