import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {ButtonPrimary, List, Panel, TextAreaField, useStyles} from '@ace-de/ui-components';
import * as qualityManagementSelectors from '../qualityManagementFeedbackSelectors';
import * as qualityManagementFeedbackActionTypes from '../qualityManagementFeedbackActionTypes';
import config from '../../config';

const QMFeedbackInternalCommentsPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translatePanel = createTranslateShorthand('qm_feedback_internal_comments');
    const {qualityManagementFeedback, submitQMFeedbackComment} = props;
    const [comment, setComment] = useState('');

    const handleOnSubmit = () => {
        if (!comment) return;
        submitQMFeedbackComment({comment, qmFeedbackId: qualityManagementFeedback.id});
        setComment('');
    };

    if (!qualityManagementFeedback) return;

    const {comments} = qualityManagementFeedback;

    return (
        <Panel
            title={translatePanel('panel_title.comments')}
            className={cx(['global!ace-u-full-height', 'global!ace-u-margin--0'])}
        >
            {comments?.length > 0 && (
                <List
                    className={cx('global!ace-u-full-width')}
                >
                    {comments.map(comment => {
                        return (
                            <div className={cx('global!ace-u-margin--bottom-16')} key={comment.createdAt}>
                                <p
                                    className={cx([
                                        'global!ace-u-typography--variant-caption',
                                        'global!ace-u-margin--bottom-8',
                                    ])}
                                >
                                    {comment.createdByUser}, {moment(comment.createdAt).format('DD.MM.YYYY HH:mm')}
                                </p>
                                <span className={cx('global!ace-u-typography--variant-body')}>
                                    {comment.comment}
                                </span>
                            </div>
                        );
                    })}
                </List>
            )}
            <TextAreaField
                className={cx('global!ace-u-full-width', 'global!ace-u-margin--bottom-16')}
                name="comment"
                value={comment}
                onChange={value => setComment(value)}
                label={translatePanel('input_label.comment')}
                maxLength={config.MAXIMUM_TEXT_AREA_CONTENT_LENGTH}
                isResizable={false}
                isFieldRequired={true}
            />
            <div
                className={cx([
                    'global!ace-u-width--full',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-flex-end',
                ])}
            >
                <ButtonPrimary
                    name="submitComment"
                    onClick={handleOnSubmit}
                    isDisabled={!comment}
                >
                    {translatePanel('button_label.save')}
                </ButtonPrimary>
            </div>
        </Panel>
    );
};

QMFeedbackInternalCommentsPanel.propTypes = {
    qualityManagementFeedback: PropTypes.object,
    submitQMFeedbackComment: PropTypes.func.isRequired,
};

QMFeedbackInternalCommentsPanel.defaultProps = {
    qualityManagementFeedback: null,
};

const mapStateToProps = (state, props) => {
    const qmFeedbackSelector = qualityManagementSelectors.createQMFeedbackSelector();
    return {
        qualityManagementFeedback: qmFeedbackSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitQMFeedbackComment: payload => dispatch({
        type: qualityManagementFeedbackActionTypes.SUBMIT_QUALITY_MANAGEMENT_FEEDBACK_COMMENT,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QMFeedbackInternalCommentsPanel));
