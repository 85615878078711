import {renderRoutes} from '@computerrock/formation-router';
import routePaths from './routePaths';
import ApplicationScreen from './application/ApplicationScreen';
import NotFoundScreen from './application/NotFoundScreen';
import AuthRedirectionScreen from './application/AuthRedirectionScreen';
import * as applicationLoaderSagas from './application/applicationLoaderSagas';
import * as userProfileLoaderSagas from './user-profiles/userProfileLoaderSagas';
import * as priceManagementLoaderSagas from './price-management/priceManagementLoaderSagas';
import keycloakAuth from './keycloakAuth';
import arcGISAuth from './arcGISAuth';
import * as dashboardLoaderSagas from './dashboard/dashboardLoaderSagas';
import * as contractPartnerLoaderSagas from './contract-partners/contractPartnerLoaderSagas';
import * as qualityManagementFeedbackLoaderSagas from './quality-management-feedbacks/qualityManagementFeedbackLoaderSagas';
import DashboardSectionScreen from './dashboard/DashboardSectionScreen';
import DashboardScreen from './dashboard/DashboardScreen';
import ContractPartnerSearchScreen from './contract-partners/ContractPartnerSearchScreen';
import ContractPartnerScreen from './contract-partners/ContractPartnerScreen';
import QualityManagementFeedbackSearchScreen from './quality-management-feedbacks/QualityManagementFeedbackSearchScreen';
import PriceManagementScreen from './price-management/PriceManagementScreen';
import vpmAccessControl from './vpmAccessControl';
import AccessDeniedScreen from './application/AccessDeniedScreen';
import MaintenanceScreen from './application/MaintenanceScreen';
import config from './config';
import QualityManagementFeedbackScreen from './quality-management-feedbacks/QualityManagementFeedbackScreen';

/**
 * Location object definition (react-router, history)
 *
 * @typedef {Object} Location
 * @property {string} key - key is available for all by HashHistory
 * @property {string} pathname - path part of route
 * @property {string} search - search query part of route
 * @property {string} hash - hash (#) part of route
 * @property {Object} state - user defined state for the route
 */

/**
 * ConnectedRoute object definition (react-router-config, connected-react-router, redux-saga)
 *
 * @typedef {Object} ConnectedRoute
 * @property {string} path - any valid URL path that path-to-regexp understands.
 * @property {?Object} component - React component for application screen
 * @property {?function} render - render prop function
 * @property {?Location} location - for matching against different location than one in history
 * @property {?boolean} exact - when true, will only match if the path matches the location.pathname exactly
 * @property {?boolean} string - when true, a path that has a trailing slash will only match a location.pathname
 *                      with a trailing slash
 * @property {?boolean} sensitive - when true, will match if the path is case sensitive.
 * @property {?Array<ConnectedRoute>} routes - sub-routes
 * @property {?Array<Array<[saga, Object]>>} locationChangeSideEffects - Redux sagas (side-effects) to be run
 *                                            with payload object
 */

/**
 * Static route configuration
 *
 * @returns {Array<ConnectedRoute>}
 */
const getRoutes = () => [{
    component: ApplicationScreen,
    locationChangeSideEffects: [],
    routes: [
        window.DYNAMIC_ENV.IS_MAINTENANCE_MODE_ON
        && window.location.hostname !== config.BACKDOOR_URL
            ? {
                path: '*',
                component: MaintenanceScreen,
            } : {
                path: routePaths.KEYCLOAK_AUTHORIZE,
                exact: true,
                component: AuthRedirectionScreen,
                locationChangeSideEffects: [
                    [keycloakAuth.authorize],
                ],
            },
        {
            path: routePaths.KEYCLOAK_AUTHENTICATE,
            exact: true,
            component: AuthRedirectionScreen,
            locationChangeSideEffects: [
                [keycloakAuth.authenticate],
            ],
        },
        {path: routePaths.ARCGIS_AUTHORIZE,
            exact: true,
            component: AuthRedirectionScreen,
            locationChangeSideEffects: [
                [arcGISAuth.authorize],
            ]},
        {
            path: routePaths.ARCGIS_AUTHENTICATE,
            exact: true,
            component: AuthRedirectionScreen,
            locationChangeSideEffects: [
                [arcGISAuth.authenticate],
            ],
        },
        {
            path: routePaths.SYSTEM_ACCESS_DENIED,
            exact: true,
            component: AccessDeniedScreen,
            locationChangeSideEffects: [
                [keycloakAuth.loadAuthSession],
            ],
        },
        {
            component: ({route}) => renderRoutes(route.routes),
            locationChangeSideEffects: [
                [applicationLoaderSagas.ensureRoute],
                [keycloakAuth.loadAuthSession],
                [userProfileLoaderSagas.loadUserProfile],
                [vpmAccessControl.authenticateSystemAccess],
                [arcGISAuth.loadAuthSession],
                [userProfileLoaderSagas.loadUserProfiles],
                [vpmAccessControl.authenticateRouteAccess],
            ],
            routes: [
                {
                    path: routePaths.ROOT,
                    exact: true,
                    component: () => null,
                    locationChangeSideEffects: [],
                },
                {
                    path: routePaths.ROUTE_ACCESS_DENIED,
                    exact: true,
                    component: AccessDeniedScreen,
                    locationChangeSideEffects: [],
                },
                {
                    path: routePaths.DASHBOARD_SECTION,
                    component: DashboardSectionScreen,
                    locationChangeSideEffects: [
                        [priceManagementLoaderSagas.loadTimePeriods],
                    ],
                    routes: [
                        {
                            path: routePaths.DASHBOARD,
                            exact: true,
                            component: DashboardScreen,
                            locationChangeSideEffects: [
                                [dashboardLoaderSagas.loadContractPartnerMapLayers],
                                [dashboardLoaderSagas.loadContractPartnerRecommendations],
                                [dashboardLoaderSagas.loadContractPartnerMapWatcher],
                            ],
                        },
                        {
                            path: routePaths.CONTRACT_PARTNER_SEARCH,
                            exact: true,
                            component: ContractPartnerSearchScreen,
                            locationChangeSideEffects: [
                                [contractPartnerLoaderSagas.loadContractPartnerSearchResults],
                                [contractPartnerLoaderSagas.loadContractPartnerAccountManagers],
                            ],
                        },
                        {
                            path: routePaths.QUALITY_MANAGEMENT_FEEDBACKS_SEARCH,
                            exact: true,
                            component: QualityManagementFeedbackSearchScreen,
                            locationChangeSideEffects: [
                                [qualityManagementFeedbackLoaderSagas.loadQualityManagementFeedbackSearchResults],
                                // TODO: optimize within the https://computerrock.atlassian.net/browse/ACEMS-1073
                                [dashboardLoaderSagas.loadContractPartners],
                            ],
                        },
                        {
                            path: routePaths.PRICE_MANAGEMENT,
                            exact: true,
                            component: PriceManagementScreen,
                            locationChangeSideEffects: [
                                [priceManagementLoaderSagas.loadStateHolidayDates],
                            ],
                        },
                    ],
                },
                {
                    path: routePaths.CONTRACT_PARTNER,
                    exact: true,
                    component: ContractPartnerScreen,
                    locationChangeSideEffects: [
                        [contractPartnerLoaderSagas.loadContractPartner],
                        [contractPartnerLoaderSagas.loadContractPartnerTemporaryRestrictions],
                        [contractPartnerLoaderSagas.loadContractPartnerRelations],
                        [contractPartnerLoaderSagas.loadContractPartnerServiceCaseSearchResults],
                        [contractPartnerLoaderSagas.loadACECommissioners],
                        [contractPartnerLoaderSagas.loadCPQMFeedbackSearchResults],
                        [priceManagementLoaderSagas.loadActiveFixedPricePeriod],
                        [priceManagementLoaderSagas.loadContractPartnerFixedPricePeriods],
                        [contractPartnerLoaderSagas.loadContractPartnerELLAAccounts],
                        [contractPartnerLoaderSagas.loadContractPartnerELOPath],
                        [contractPartnerLoaderSagas.loadContractPartnerAccountManagers],
                    ],
                },
                {
                    path: routePaths.QUALITY_MANAGEMENT_FEEDBACK,
                    exact: true,
                    component: QualityManagementFeedbackScreen,
                    locationChangeSideEffects: [
                        [qualityManagementFeedbackLoaderSagas.loadQualityManagementFeedback],
                        [qualityManagementFeedbackLoaderSagas.loadQMFeedbackFullScreenMapCPWatcher],
                    ],
                },
                {
                    path: '*',
                    component: NotFoundScreen,
                },
            ],
        },
    ],
}];

export default getRoutes;
