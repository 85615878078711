import React, {useState, useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {apsPriceTypes} from '@ace-de/eua-entity-types';
import {Modal, useStyles, Form, InputCurrencyField, LabelWithIcon, DateField, ButtonPrimary, ToggleSwitch} from '@ace-de/ui-components';
import {Tab, TabBar} from '@ace-de/ui-components/navigation';
import {InteractiveIcon, Icon, closeIcon, calendarIcon, plusIcon, infoAlertIcon} from '@ace-de/ui-components/icons';
import * as priceManagementActionTypes from '../../price-management/priceManagementActionTypes';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import * as priceManagementSelectors from '../../price-management/priceManagementSelectors';
import config from '../../config';
import useCloseOnEscape from '../../utils/useCloseOnEscape';

const validationDisabledRuleset = {
    CURRENT: {
        price: true,
        fixedPrice: true,
        validFrom: true,
        validUntil: false,
        activePeriod: true,
        submit: false,
    },
    PAST: {
        price: true,
        fixedPrice: true,
        validFrom: true,
        validUntil: true,
        activePeriod: true,
        submit: true,
    },
    UPCOMING: {
        price: false,
        fixedPrice: false,
        validFrom: false,
        validUntil: false,
        activePeriod: false,
        submit: false,
    },
};

const FixedPriceModal = props => {
    const {hasBackdrop, saveChanges, closeModal, contractPartner, fixedPrices, activeFixedPriceDataset} = props;
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('fixed_price_modal');
    const [fixedPriceEditData, setFixedPriceEditData] = useState();
    const [fixedPriceFormData, setFixedPriceFormData] = useState();
    const [isFixedPrice, setIsFixedPrice] = useState(false);
    const [activePeriod, setActivePeriod] = useState('');
    const [maxDateError, setMaxDateError] = useState('');
    const [minDateError, setMinDateError] = useState('');
    const [overlappingDateError, setOverlappingDateError] = useState('');
    const [activeRuleset, setActiveRuleset] = useState();
    const validationRules = validationDisabledRuleset[activeRuleset] || null;
    const sortedFixedPrices = useMemo(() => {
        if (!fixedPrices) return [];
        return [...Object.values(fixedPrices).sort((fixedPriceA, fixedPriceB) => {
            return moment(fixedPriceA.validUntil).isAfter(fixedPriceB.validUntil) ? 1 : -1;
        })];
    }, [fixedPrices]);

    useEffect(() => {
        if ((activeFixedPriceDataset?.id && activePeriod === '')) {
            setFixedPriceEditData(
                activeFixedPriceDataset
                    ? {
                        ...activeFixedPriceDataset,
                        price: activeFixedPriceDataset.prices[0]?.value || '',
                    }
                    : undefined,
            );
            setIsFixedPrice(activeFixedPriceDataset
                ? !!activeFixedPriceDataset.prices[0]?.value
                : false);
            setActivePeriod(activeFixedPriceDataset ? activeFixedPriceDataset.id : 'NEW');
            setActiveRuleset(activeFixedPriceDataset ? 'CURRENT' : 'UPCOMING');
            return;
        }
        if (!activeFixedPriceDataset?.id && activePeriod === '') {
            setActivePeriod('NEW');
            setActiveRuleset('UPCOMING');
            return;
        }
        if (activePeriod && activePeriod === 'LATEST') {
            const pricesDataSet = Object.values(fixedPrices);
            setActivePeriod(activeFixedPriceDataset
                ? activeFixedPriceDataset.id
                : pricesDataSet.length > 0
                    ? pricesDataSet[pricesDataSet.length - 1]?.id
                    : 'NEW');
        }

        if (activePeriod && activePeriod === 'NEW') {
            setActiveRuleset('UPCOMING');
            const editPriceData = {};

            if (sortedFixedPrices.length > 0) {
                const lastFixedPricePeriod = sortedFixedPrices[sortedFixedPrices.length - 1];
                const validFrom = moment(lastFixedPricePeriod.validUntil).add(1, 'days').format('YYYY-MM-DD');
                const validUntil = moment(validFrom).add(1, 'years').subtract(1, 'days').format('YYYY-MM-DD');
                editPriceData.validFrom = validFrom;
                editPriceData.validUntil = validUntil;
            }

            setFixedPriceEditData(editPriceData);
            setFixedPriceFormData(editPriceData);
        }

        if (!activeFixedPriceDataset?.id && !Object.values(fixedPrices).length) {
            setFixedPriceEditData(null);
            setActivePeriod('NEW');
            setActiveRuleset('UPCOMING');
        }
    }, [
        activeFixedPriceDataset,
        setFixedPriceEditData,
        setIsFixedPrice,
        setActivePeriod,
        activePeriod,
        fixedPrices,
        sortedFixedPrices,
    ]);

    useEffect(() => {
        if (Object.values(fixedPrices).length === 0 || !fixedPrices[activePeriod]
            || fixedPrices[activePeriod].prices[0]?.value === fixedPriceEditData?.price) return;
        setFixedPriceEditData({
            ...fixedPrices[activePeriod],
            price: fixedPrices[activePeriod].prices[0]?.value || '',
        });
        setIsFixedPrice(!!fixedPrices[activePeriod]?.prices[0]?.value || false);
    }, [activePeriod, fixedPrices, fixedPriceEditData?.price]);

    const isSubmitDisabled = !fixedPriceFormData
        || (isFixedPrice && !fixedPriceFormData?.price)
        || (!fixedPriceFormData?.validFrom || !fixedPriceFormData?.validUntil);

    const validateFormValues = formValues => {
        if (!formValues) return false;
        const {validFrom, validUntil} = formValues;
        if (validFrom && validUntil) {
            // it can not be in the past or before the start date
            const hasValidUntilError = !validationRules.validUntil
                && (moment(validUntil).isSameOrBefore(validFrom) || moment(validUntil).isSameOrBefore());

            // it must be in the future
            const hasValidFromError = !validationRules.validFrom && moment(validFrom).isSameOrBefore();

            const hasOverlappingError = Object.values(fixedPrices).filter(fixedPrice => (
                fixedPrice.id !== activePeriod
            )).some(fixedPrice => (
                moment(validUntil).isBetween(fixedPrice.validFrom, fixedPrice.validUntil)
                || moment(validFrom).isBetween(fixedPrice.validFrom, fixedPrice.validUntil)
            ));

            if (hasValidUntilError && !maxDateError) {
                setMaxDateError(translateModal('date_error_label.invalid_until_date'));
            }

            if (!hasValidUntilError && maxDateError) {
                setMaxDateError('');
            }

            if (hasOverlappingError && !overlappingDateError) {
                setOverlappingDateError(translateModal('date_error_label.overlapping_date'));
            }

            if (!hasOverlappingError && overlappingDateError) {
                setOverlappingDateError('');
            }

            setMinDateError(hasValidFromError ? translateModal('date_error_label.invalid_from_date') : '');

            return !hasValidUntilError && !hasValidFromError && !hasOverlappingError;
        }
    };

    const handleFormDataChange = formValues => {
        if (!formValues) return;
        validateFormValues(formValues);
        setFixedPriceFormData(formValues);
    };

    const formatFixedPricesPayload = formValues => {
        const fixedPricesPayload = {};
        switch (activeRuleset) {
            case 'UPCOMING': {
                fixedPricesPayload.validFrom = moment(formValues.validFrom).format('YYYY-MM-DD');
                fixedPricesPayload.validUntil = moment(formValues.validUntil).format('YYYY-MM-DD');

                if (activePeriod === 'NEW' && formValues.price && isFixedPrice) {
                    fixedPricesPayload.price = formValues.price;
                }

                if (activePeriod !== 'NEW' && formValues.price) {
                    fixedPricesPayload.prices = [
                        {
                            value: formValues.price,
                            type: apsPriceTypes.CONTRACT_PARTNER_FIXED_PRICE,
                        },
                    ];
                }
                break;
            }
            case 'CURRENT': {
                fixedPricesPayload.validUntil = moment(formValues.validUntil).format('YYYY-MM-DD');
                fixedPricesPayload.prices = [];
                break;
            }
            default: {
                break;
            }
        }
        return fixedPricesPayload;
    };

    const handleOnSubmit = formValues => {
        if (!formValues.validFrom || !formValues.validUntil) {
            setMinDateError(!formValues.validFrom ? translateModal('date_error_label.invalid_from_date') : '');
            setMaxDateError(!formValues.validUntil ? translateModal('date_error_label.invalid_until_date') : '');
            return;
        }

        const hasError = !validateFormValues(formValues);
        if (hasError) return;

        if (minDateError) setMinDateError('');
        if (maxDateError) setMaxDateError('');

        saveChanges({
            fixedPriceData: formatFixedPricesPayload(formValues),
            contractPartnerId: contractPartner.id,
            activePeriod,
            isFixedPrice,
        });
        setActivePeriod('LATEST');
    };

    useCloseOnEscape(() => {
        closeModal();
    });

    const handlePeriodChange = selectedPeriod => {
        const newDataSet = selectedPeriod !== 'NEW'
            ? fixedPrices[selectedPeriod]
            : null;
        setFixedPriceEditData(newDataSet
            ? {
                ...newDataSet,
                price: newDataSet.prices[0]?.value || '',
            }
            : {});
        setIsFixedPrice(!!newDataSet?.prices[0]?.value || false);
        setActivePeriod(selectedPeriod);

        if (selectedPeriod === 'NEW') {
            setActiveRuleset('UPCOMING');
            const editPriceData = {};

            if (sortedFixedPrices.length > 0) {
                const lastFixedPricePeriod = sortedFixedPrices[sortedFixedPrices.length - 1];
                const validFrom = moment(lastFixedPricePeriod.validUntil).add(1, 'days').format('YYYY-MM-DD');
                const validUntil = moment(lastFixedPricePeriod.validUntil).add(1, 'years').format('YYYY-MM-DD');
                editPriceData.validFrom = validFrom;
                editPriceData.validUntil = validUntil;
            }

            setFixedPriceEditData(editPriceData);
            return;
        }

        if (newDataSet && moment(newDataSet?.validUntil).isAfter(activeFixedPriceDataset?.validUntil)) {
            setActiveRuleset('UPCOMING');
            return;
        }

        if (selectedPeriod === activeFixedPriceDataset?.id) {
            setActiveRuleset('CURRENT');
            return;
        }

        setActiveRuleset('PAST');
    };
    if (!contractPartner) return null;

    return (
        <Modal
            hasBackdrop={hasBackdrop}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={closeModal}
                />
            )}
            contentClassName={cx('ace-c-modal__content--full-bleed')}
            className={cx('global!ace-u-modal-content-size--m')}
            title={translateModal('modal_title.fixed_price_edit')}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-full-width',
                    'global!ace-u-flex--direction-column',
                ])}
            >
                <div
                    className={cx(['global!ace-u-full-width', 'global!ace-u-margin--32-0'])}
                >
                    <TabBar
                        name="fixed-price-period-tabs"
                        onChange={handlePeriodChange}
                        value={activePeriod}
                        className={cx('ace-c-tab-bar--secondary-background')}
                    >
                        {sortedFixedPrices.length > 0 && sortedFixedPrices.map(fixedPrice => {
                            return (
                                <Tab
                                    className={cx('ace-c-tab--secondary-background')}
                                    name={`pricePeriod${fixedPrice.id}`}
                                    key={fixedPrice.id}
                                    value={fixedPrice.id}
                                >
                                    {`${moment(fixedPrice.validFrom).format('DD.MM.YY')}-${moment(fixedPrice.validUntil).format('DD.MM.YY')}`}
                                </Tab>
                            );
                        })}
                        {sortedFixedPrices.length >= 0
                        && sortedFixedPrices.length < config.FIXED_PRICE_LIMIT && (
                            <Tab
                                name="pricePeriodNew"
                                value="NEW"
                                className={cx('ace-c-tab--secondary-background')}
                            >
                                <LabelWithIcon
                                    className={cx('ace-c-label-with-icon--icon-reverse')}
                                    label={translateModal('tab_label.create_new')}
                                >
                                    <Icon icon={plusIcon} />
                                </LabelWithIcon>
                            </Tab>
                        )}
                    </TabBar>
                </div>
                <div className={cx('global!ace-u-margin--0-32')}>
                    <ToggleSwitch
                        name="fixedPrice"
                        isSelected={isFixedPrice}
                        onChange={value => setIsFixedPrice(value)}
                        isDisabled={validationRules?.fixedPrice || false}
                    >
                        <span
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-margin--left-48',
                            ])}
                        >
                            {translateModal('toggle_label.fixed_price')}
                        </span>
                    </ToggleSwitch>
                </div>
                <Form
                    name="fixedPriceEditForm"
                    onChange={handleFormDataChange}
                    onSubmit={handleOnSubmit}
                >
                    <div className={cx(['global!ace-u-margin--32'])}>
                        <DateField
                            className={cx('global!ace-u-margin--right-24')}
                            name="validFrom"
                            label={translateModal('date_field_label.valid_from')}
                            icon={calendarIcon}
                            value={fixedPriceEditData?.validFrom || ''}
                            isDisabled={validationRules?.validFrom || false}
                            errors={minDateError ? [minDateError] : []}
                            isFieldRequired={true}
                        />
                        <DateField
                            className={cx('global!ace-u-margin--right-24')}
                            name="validUntil"
                            label={translateModal('date_field_label.valid_until')}
                            icon={calendarIcon}
                            errors={maxDateError ? [maxDateError] : []}
                            value={fixedPriceEditData?.validUntil || ''}
                            isDisabled={validationRules?.validUntil || false}
                            isFieldRequired={true}
                        />
                        <InputCurrencyField
                            name="price"
                            label={translateModal('input_label.fixed_price')}
                            placeholder="-"
                            isDisabled={validationRules?.price || !isFixedPrice}
                            value={fixedPriceEditData?.price || ''}
                            isFieldRequired={isFixedPrice}
                        />
                    </div>
                    {!!overlappingDateError && (
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-margin--4-32',
                                'global!ace-u-typography--variant-caption',
                                'global!ace-u-typography--color-warning',
                            ])}
                        >
                            <Icon
                                icon={infoAlertIcon}
                                className={cx([
                                    'global!ace-u-margin--right-4',
                                    'ace-c-icon--s',
                                    'ace-c-icon--color-warning',
                                ])}
                            />
                            {overlappingDateError}
                        </div>
                    )}
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-flex-end',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-margin--0-32-32-0',
                        ])}
                    >
                        <ButtonPrimary
                            type="submit"
                            name="submitButton"
                            isDisabled={validationRules?.submit
                                || isSubmitDisabled
                                || !!maxDateError
                                || !!overlappingDateError
                                || !!minDateError}
                        >
                            {translateModal('button_label.submit')}
                        </ButtonPrimary>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

FixedPriceModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    saveChanges: PropTypes.func.isRequired,
    contractPartner: PropTypes.object,
    fixedPrices: PropTypes.object,
    activeFixedPriceDataset: PropTypes.object,
};

FixedPriceModal.defaultProps = {
    hasBackdrop: true,
    contractPartner: null,
    fixedPrices: null,
    activeFixedPriceDataset: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();
    const fixedPricesSelector = priceManagementSelectors.createFixedPricesSelector();
    const activeFixedPricesDatasetSelector = priceManagementSelectors.createActiveFixedPriceDatasetSelector();
    return {
        contractPartner: contractPartnerSelector(state, props),
        fixedPrices: fixedPricesSelector(state, props),
        activeFixedPriceDataset: activeFixedPricesDatasetSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    closeModal: payload => dispatch({
        type: priceManagementActionTypes.DECLINE_CONTRACT_PARTNER_FIXED_PRICE_EDIT_FLOW,
        payload,
    }),
    saveChanges: payload => dispatch({
        type: priceManagementActionTypes.CONFIRM_CONTRACT_PARTNER_FIXED_PRICE_EDIT_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FixedPriceModal);
