import {select, fork, take, put} from 'redux-saga/effects';
import {persistenceStates} from '@computerrock/formation-core';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';

/**
 * Search service cases
 */
const searchServiceCases = function* searchServiceCases({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const ecsFlowService = serviceManager.loadService('ecsFlowService');
    const {searchQueryParams} = payload;

    yield put({
        type: contractPartnerActionTypes.SET_SERVICE_CASE_SEARCH_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.PENDING},
    });

    yield fork(
        fetchRequest,
        contractPartnerActionTypes.SEARCH_SERVICE_CASES_REQUEST,
        ecsFlowService.searchServiceCases,
        {
            searchQueryParams,
        },
    );

    const searchServiceCasesResponseAction = yield take([
        contractPartnerActionTypes.SEARCH_SERVICE_CASES_REQUEST_SUCCEEDED,
        contractPartnerActionTypes.SEARCH_SERVICE_CASES_REQUEST_FAILED,
    ]);

    if (!searchServiceCasesResponseAction.error) {
        const {response} = searchServiceCasesResponseAction.payload;
        const {results: serviceCaseSearchResults, totalCount: serviceCaseSearchResultsCount} = response;

        yield put({
            type: contractPartnerActionTypes.STORE_SERVICE_CASE_SEARCH_RESULTS,
            payload: {serviceCaseSearchResults, serviceCaseSearchResultsCount},
        });

        yield put({
            type: contractPartnerActionTypes.SET_SERVICE_CASE_SEARCH_PERSISTENCE_STATE,
            payload: {persistenceState: persistenceStates.READY},
        });

        return;
    }

    yield put({
        type: contractPartnerActionTypes.STORE_SERVICE_CASE_SEARCH_RESULTS,
        payload: {serviceCaseSearchResults: [], serviceCaseSearchResultsCount: 0},
    });

    yield put({
        type: contractPartnerActionTypes.SET_SERVICE_CASE_SEARCH_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.FAILED},
    });
};

export default searchServiceCases;
