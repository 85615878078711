import config from '../config';

const getPrice = (price, activeLocale, options = {}) => {
    let isValidNumber = true;
    if (!['string', 'number'].includes(typeof price)) isValidNumber = false;
    const parsedPrice = parseFloat(price);
    if (isNaN(parsedPrice)) isValidNumber = false;

    const {showCurrency = true, minimumFractionDigits = 2, maximumFractionDigits = 2} = options;
    return (isValidNumber ? parsedPrice : 0).toLocaleString(activeLocale, {
        ...(showCurrency ? {style: 'currency'} : {}),
        ...(showCurrency ? {currency: config.CURRENCY} : {}),
        minimumFractionDigits,
        maximumFractionDigits,
    });
};

export default getPrice;
