import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from '@computerrock/formation-router';
import {useStyles, ContentBlock, ContentItem} from '@ace-de/ui-components';
import * as contractPartnerSelectors from './contractPartnerSelectors';
import ContractPartnerAdditionalServicesPanel from './ui-elements/ContractPartnerAdditionalServicesPanel';
import ContractPartnerTransportVehicleStorage from './ui-elements/ContractPartnerTransportVehicleStorage';
import ContractPartnerBasicServicesPanel from './ui-elements/ContractPartnerBasicServicesPanel';
import ContractPartnerOperatingFeaturesPanel from './ui-elements/ContractPartnerOperatingFeaturesPanel';

const ContractPartnerServicesTab = props => {
    const {cx} = useStyles();
    const {contractPartner} = props;

    // if no contract partner, don't render
    if (!contractPartner) return null;

    return (
        <ContentBlock>
            <ContentItem className={cx('ace-c-content-item--span-9')}>
                <ContractPartnerBasicServicesPanel />
                <ContractPartnerTransportVehicleStorage />
                <ContractPartnerOperatingFeaturesPanel />
            </ContentItem>
            <ContentItem className={cx('ace-c-content-item--span-3')}>
                <ContractPartnerAdditionalServicesPanel />
            </ContentItem>
        </ContentBlock>
    );
};


ContractPartnerServicesTab.propTypes = {
    contractPartner: PropTypes.object,
};

ContractPartnerServicesTab.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(ContractPartnerServicesTab));
