import React, {Fragment, useMemo} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {apmDayOfWeekTypes} from '@ace-de/eua-entity-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles} from '@ace-de/ui-components';
import {Icon, areaIcon, checkmarkIcon, closedIcon} from '@ace-de/ui-components/icons';
import styles from './CPRankingItem.module.scss';

const CPRankingItem = props => {
    const {cx} = useStyles(props, styles);
    const {translate, createTranslateShorthand} = useTranslate();
    const translatePanel = createTranslateShorthand('qm_feedback_cp_ranking_panel');
    const {contractPartner, isSelected, onClick} = props;

    const workingHoursData = useMemo(() => {
        const current = moment();
        const dayOfWeek = current.isoWeekday();
        const hour = current.hour();
        const minute = current.minute();
        const dayOfWeekType = dayOfWeek <= 5
            ? apmDayOfWeekTypes.MONDAY_TO_FRIDAY
            : dayOfWeek === 6 ? apmDayOfWeekTypes.SATURDAY : apmDayOfWeekTypes.SUNDAY_AND_HOLIDAY;
        const workingHoursDTO = contractPartner?.workingHours?.find(workingHoursDTO => {
            return workingHoursDTO.dayOfWeek === dayOfWeekType;
        });

        if (!workingHoursDTO) return {
            workingHoursString: '',
            isOpen: undefined,
        };

        const fromHour = workingHoursDTO?.from ? parseInt(workingHoursDTO.from.slice(0, 2), 10) : null;
        const fromMinute = workingHoursDTO?.from ? parseInt(workingHoursDTO.from.slice(3, 5), 10) : null;
        const toHour = workingHoursDTO?.to
            ? (parseInt(workingHoursDTO?.to.slice(0, 2), 10) !== 0
                ? parseInt(workingHoursDTO.to.slice(0, 2), 10) : 24
            ) : null;
        const toMinute = workingHoursDTO.to ? parseInt(workingHoursDTO.to.slice(3, 5), 10) : null;
        if (typeof fromHour === 'number' && typeof fromMinute === 'number'
            && typeof toHour === 'number' && typeof toMinute === 'number'
        ) {
            const isOpen = (fromHour < hour || (fromHour === hour && fromMinute <= minute))
                && (toHour > hour || (toHour === hour && toMinute > minute));

            return {
                workingHoursString: workingHoursDTO?.from && workingHoursDTO?.to
                    ? [workingHoursDTO?.from?.slice(0, 5), workingHoursDTO?.to?.slice(0, 5)]
                        .filter(value => !!value).join(' - ')
                    : '',
                isOpen,
            };
        }

        return {
            workingHoursString: workingHoursDTO?.from && workingHoursDTO?.to
                ? [workingHoursDTO?.from?.slice(0, 5), workingHoursDTO?.to?.slice(0, 5)]
                    .filter(value => !!value).join(' - ')
                : '',
            isOpen: undefined,
        };
    }, [contractPartner?.workingHours]);

    // if no contract partner, don't render
    if (!contractPartner) return null;

    return (
        <div
            className={cx([
                'global!ace-u-padding--bottom-16',
                'global!ace-u-padding--top-24',
                'global!ace-u-padding--left-24',
                'global!ace-u-padding--right-24',
                'global!ace-u-cursor--pointer',
            ], {
                'ace-c-cp-ranking-item--highlighted': !!isSelected,
            })}
            onClick={onClick}
        >
            <p
                className={cx(['global!ace-u-typography--variant-body', 'global!ace-u-margin--right-8'], {
                    'global!ace-u-typography--variant-body-bold': !!isSelected,
                })}
            >
                {`${contractPartner.rank}: ${contractPartner.name}`}
            </p>
            <p className={cx('global!ace-u-typography--variant-caption')}>
                {contractPartner.formattedAddress || ''}
            </p>
            <div
                className={cx([
                    'global!ace-u-typography--variant-caption',
                    'global!ace-u-typography--color-medium-emphasis',
                    'global!ace-u-margin--4-0-8',
                ])}
            >
                {contractPartner.routeToDamageLocation && (
                    <Fragment>
                        <span>
                            {translatePanel('cp_ranking_item_label.distance_to_damage_location')}
                            &nbsp;
                            {Math.round(contractPartner.routeToDamageLocation.totalKilometers * 10) / 10}
                            &nbsp;
                            {translate('global.unit.km')}
                        </span>
                        &nbsp;
                        <span className={cx('global!ace-u-typography--color-success')}>
                            ({Math.round(contractPartner.routeToDamageLocation.totalTravelTime)}{translate('global.time_unit.minute')})
                        </span>
                    </Fragment>
                )}
            </div>
            {!!workingHoursData?.workingHoursString && (
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--top-4',
                        'global!ace-u-typography--variant-caption',
                        'global!ace-u-typography--color-medium-emphasis',
                    ])}
                >
                    <Icon
                        icon={
                            workingHoursData.isOpen
                                ? checkmarkIcon
                                : closedIcon
                        }
                        className={cx([
                            'ace-c-icon--s',
                            workingHoursData.isOpen
                                ? 'ace-c-icon--color-success'
                                : 'ace-c-icon--color-warning',
                            'global!ace-u-margin--right-8',
                        ])}
                    />
                    {workingHoursData.workingHoursString}
                </div>
            )}
            {!!contractPartner?.fixPricePolygon && (
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--top-8',
                    ])}
                >
                    <Icon
                        icon={areaIcon}
                        className={cx('global!ace-u-margin--right-8')}
                    />
                    <p
                        className={cx('global!ace-u-typography--variant-caption', 'global!ace-u-flex--grow-1')}
                    >
                        {translatePanel('cp_ranking_item_label.fixed_price_area')}
                    </p>
                </div>
            )}
        </div>
    );
};

CPRankingItem.propTypes = {
    contractPartner: PropTypes.object,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
};

CPRankingItem.defaultProps = {
    contractPartner: {},
    isSelected: false,
    onClick: () => undefined,
};

export default CPRankingItem;
