import {produce} from 'immer';
import {persistenceStates} from '@computerrock/formation-core';
import {ECSHistory, efServiceAssignmentTypes, QualityManagementFeedback} from '@ace-de/eua-entity-types';
import * as qualityManagementActionTypes from './qualityManagementFeedbackActionTypes';

const initialState = {
    qmFeedbacksSearchResults: [],
    qmFeedbacksSearchCount: 0,
    qmSelectedFeedback: null,
    qmFeedbackForwardingStatus: '',
    positiveQMFeedbacksCount: 0,
    negativeQMFeedbacksCount: 0,
    qmFeedbacks: {},
    qmFeedbackCaseLogFilterResults: [],
    qmFeedbackCaseLogFilterResultsCount: 0,
    qmFeedbackCaseLogFilterResultsPage: 0,
    qmFeedbackCaseLogServices: [],
    activeContractPartnerId: null,
    qmFeedbacksSearchPersistenceState: persistenceStates.PENDING,
};

const qualityManagementFeedbackReducer = produce((draft, action) => {
    switch (action.type) {
        case qualityManagementActionTypes.STORE_QUALITY_MANAGEMENT_FEEDBACKS_SEARCH_RESULTS: {
            const {qualityManagementFeedbacksSearchResults, qualityManagementFeedbacksSearchCount} = action.payload;
            const {positiveQMFeedbacksCount, negativeQMFeedbacksCount} = action.payload;
            draft.qmFeedbacksSearchResults = qualityManagementFeedbacksSearchResults.map(feedback => (
                new QualityManagementFeedback().fromDTO(feedback)
            ));
            draft.qmFeedbacksSearchCount = typeof qualityManagementFeedbacksSearchCount === 'number'
                ? qualityManagementFeedbacksSearchCount : 0;
            draft.positiveQMFeedbacksCount = typeof positiveQMFeedbacksCount === 'number'
                ? positiveQMFeedbacksCount : 0;
            draft.negativeQMFeedbacksCount = typeof negativeQMFeedbacksCount === 'number'
                ? negativeQMFeedbacksCount : 0;
            break;
        }

        case qualityManagementActionTypes.SET_SELECTED_FEEDBACK: {
            const {feedback} = action.payload;
            draft.qmSelectedFeedback = feedback;
            break;
        }

        case qualityManagementActionTypes.STORE_QM_FEEDBACK_FORWARDED_STATUS: {
            const {qmFeedbackForwardingStatus} = action.payload;
            draft.qmFeedbackForwardingStatus = qmFeedbackForwardingStatus;
            break;
        }

        case qualityManagementActionTypes.STORE_QUALITY_MANAGEMENT_FEEDBACKS: {
            const {qualityManagementFeedbackDTOs} = action.payload;
            qualityManagementFeedbackDTOs.forEach(qualityManagementFeedbackDTO => {
                const qmFeedback = draft.qmFeedbacks[qualityManagementFeedbackDTO.id];
                if (!qmFeedback) {
                    const newQMFeedback = new QualityManagementFeedback().fromDTO(qualityManagementFeedbackDTO);
                    draft.qmFeedbacks[newQMFeedback.id] = newQMFeedback;
                    return;
                }
                draft.qmFeedbacks[qualityManagementFeedbackDTO.id] = qmFeedback.fromDTO(qualityManagementFeedbackDTO);
            });
            break;
        }

        case qualityManagementActionTypes.STORE_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOGS: {
            const {caseLogFilterResults, caseLogFilterResultsCount, pageNumber} = action.payload;
            draft.qmFeedbackCaseLogFilterResults = caseLogFilterResults.map(caseLogDTO => {
                return new ECSHistory().fromDTO(caseLogDTO);
            });
            draft.qmFeedbackCaseLogFilterResultsCount = typeof (caseLogFilterResultsCount) === 'number'
                ? caseLogFilterResultsCount
                : 0;
            draft.qmFeedbackCaseLogFilterResultsPage = pageNumber || 0;
            break;
        }

        case qualityManagementActionTypes.STORE_QUALITY_MANAGEMENT_FEEDBACK_CASE_LOG_SERVICES: {
            const {serviceTypes} = action.payload;

            // If serviceTypes contains UNKNOWN service type that means that serviceType is not selected yet on ECS
            // and in that case we want to show VEHICLE filter. As soon as some serviceType is selected on ECS,
            // we won't have UNKNOWN value anymore, hence we don't need to show VEHICLE filter.
            const hasUnknownServiceType = serviceTypes.includes('UNKNOWN');
            draft.caseLogServices = serviceTypes.filter(serviceType => {
                if (hasUnknownServiceType && serviceType === efServiceAssignmentTypes.VEHICLE) return serviceType;
                return serviceType !== 'UNKNOWN' && serviceType !== efServiceAssignmentTypes.VEHICLE;
            });
            break;
        }

        case qualityManagementActionTypes.SET_ACTIVE_CONTRACT_PARTNER_ID: {
            const {activeContractPartnerId} = action.payload;
            draft.activeContractPartnerId = activeContractPartnerId;
            break;
        }

        case qualityManagementActionTypes.SET_QM_FEEDBACKS_SEARCH_PERSISTENCE_STATE: {
            const {persistenceState} = action.payload;
            draft.qmFeedbacksSearchPersistenceState = persistenceState;
            break;
        }

        default:
    }
}, initialState);

export default qualityManagementFeedbackReducer;
