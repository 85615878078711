import {put, take, fork, select, delay} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';

const updateHolidayPeriodsFlow = function* updateHolicayPeriodsFlow() {
    let shouldWaitForAction = true;
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');

    while (true) {
        if (shouldWaitForAction) {
            yield take(priceManagementActionTypes.INITIATE_UPDATE_HOLIDAY_PERIODS_FLOW);

            yield* openModal(modalIds.UPDATE_HOLIDAY_PERIODS_MODAL);
        }

        const modalSelectedOption = yield take([
            priceManagementActionTypes.CONFIRM_UPDATE_HOLIDAY_PERIODS_FLOW,
            priceManagementActionTypes.DECLINE_UPDATE_HOLIDAY_PERIODS_FLOW,
        ]);

        if (modalSelectedOption.type === priceManagementActionTypes.CONFIRM_UPDATE_HOLIDAY_PERIODS_FLOW) {
            shouldWaitForAction = false;
            yield put({
                type: priceManagementActionTypes.SET_HOLIDAYS_LOADING,
            });

            yield fork(
                fetchRequest,
                priceManagementActionTypes.UPDATE_STATE_HOLIDAY_DATES_REQUEST,
                pricingManagementService.updateStateHolidayDates,
            );

            const responseAction = yield take([
                priceManagementActionTypes.UPDATE_STATE_HOLIDAY_DATES_REQUEST_FAILED,
                priceManagementActionTypes.UPDATE_STATE_HOLIDAY_DATES_REQUEST_SUCCEEDED,
            ]);

            if (responseAction.error) {
                yield put({
                    type: priceManagementActionTypes.RESET_HOLIDAYS_LOADING,
                });
                yield put({
                    type: priceManagementActionTypes.SET_HOLIDAYS_ERROR,
                });
            }

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {stateHolidayDatesDTOs} = response;

                yield put({
                    type: priceManagementActionTypes.STORE_STATE_HOLIDAY_DATES,
                    payload: {stateHolidayDatesDTOs},
                });
                yield delay(300);
                yield put({
                    type: priceManagementActionTypes.RESET_HOLIDAYS_LOADING,
                });
            }

            continue;
        }

        if (modalSelectedOption.type === priceManagementActionTypes.DECLINE_UPDATE_HOLIDAY_PERIODS_FLOW) {
            yield* closeModal(modalIds.UPDATE_HOLIDAY_PERIODS_MODAL);
            yield put({
                type: priceManagementActionTypes.RESET_HOLIDAYS_ERROR,
            });
            yield put({
                type: priceManagementActionTypes.RESET_HOLIDAYS_LOADING,
            });
            shouldWaitForAction = true;
        }
    }
};

export default updateHolidayPeriodsFlow;
