import React, {Fragment, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, List, Divider, InteractiveIcon, Form, Checkbox, Input, ButtonPrimary, CheckboxGroup} from '@ace-de/ui-components';
import {apmContractPartnerExternalCommissionerTypes} from '@ace-de/eua-entity-types';
import {Icon, checkmarkIcon, closeIcon, editIcon} from '@ace-de/ui-components/icons';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import config from '../../config';

const ContractPartnerExternalCommissionersPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_basic_data_tab');
    const {contractPartner, submitExternalCommissionersForm} = props;
    const [isEditModeActive, setIsEditModeActive] = useState(false);

    const toggleEditMode = () => {
        setIsEditModeActive(prevState => !prevState);
    };

    const handleOnSubmit = formValues => {
        submitExternalCommissionersForm({
            contractPartnerData: {
                externalCommissioners: formValues.externalCommissioners,
                otherExternalCommissioner: formValues.otherExternalCommissioner,
            },
            contractPartnerId: contractPartner.id,
        });

        setIsEditModeActive(false);
    };

    // if no contract partner, don't render
    if (!contractPartner) return null;

    const {externalCommissioners, otherExternalCommissioner} = contractPartner;

    return (
        <Panel
            title={translateTab('panel_title.external_commissioners')}
            actions={(
                <InteractiveIcon
                    icon={!isEditModeActive
                        ? editIcon
                        : closeIcon
                    }
                    onClick={toggleEditMode}
                />
            )}
        >
            {!isEditModeActive
                ? (
                    <List className={cx('global!ace-u-full-width')}>
                        {Object.values(apmContractPartnerExternalCommissionerTypes).map(externalCommissioner => {
                            const hasCPExternalCommissioner = externalCommissioners
                                .includes(externalCommissioner);
                            return (
                                <Fragment key={externalCommissioner}>
                                    <div
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--justify-space-between',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-padding--16',
                                        ])}
                                    >
                                        <span className={cx('global!ace-u-typography--variant-body')}>
                                            {translate(`global.contract_partner_external_commissioners.${(externalCommissioner).toLowerCase()}`)}
                                        </span>
                                        <Icon
                                            icon={hasCPExternalCommissioner ? checkmarkIcon : closeIcon}
                                            className={cx(hasCPExternalCommissioner
                                                ? 'ace-c-icon--color-success'
                                                : 'ace-c-icon--color-warning')}
                                        />
                                    </div>
                                    <Divider />
                                </Fragment>
                            );
                        })}
                        <div
                            className={cx([
                                'global!ace-u-full-width',
                                'global!ace-u-flex',
                                'global!ace-u-flex--justify-space-between',
                                'global!ace-u-flex--align-center',
                                'global!ace-u-padding--16',
                            ])}
                        >
                            <div className={cx('global!ace-u-typography--variant-body')}>
                                <span>
                                    {translateTab(`list_item_label.other`)}
                                </span>
                                <span>{otherExternalCommissioner || ''}</span>
                            </div>
                        </div>
                    </List>
                ) : (
                    <Form name="additionalCommissionersForm" onSubmit={handleOnSubmit}>
                        <CheckboxGroup name="externalCommissioners" value={contractPartner.externalCommissioners}>
                            {Object.values(apmContractPartnerExternalCommissionerTypes).map(externalCommissioner => {
                                return (
                                    <Fragment key={externalCommissioner}>
                                        <div
                                            className={cx([
                                                'global!ace-u-full-width',
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--justify-space-between',
                                                'global!ace-u-flex--align-center',
                                                'global!ace-u-padding--16',
                                            ])}
                                        >
                                            <span className={cx('global!ace-u-typography--variant-body')}>
                                                {translate(`global.contract_partner_external_commissioners.${(externalCommissioner).toLowerCase()}`)}
                                            </span>
                                            <Checkbox
                                                key={externalCommissioner}
                                                name={externalCommissioner}
                                                value={externalCommissioner}
                                            />
                                        </div>
                                        <Divider />
                                    </Fragment>
                                );
                            })}
                            <div
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--justify-space-between',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-padding--16',
                                ])}
                            >
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-typography--variant-body',
                                        'global!ace-u-full-width',
                                    ])}
                                >
                                    <span className={cx('global!ace-u-margin--right-16')}>
                                        {translateTab(`list_item_label.other`)}
                                    </span>
                                    <Input
                                        name="otherExternalCommissioner"
                                        value={contractPartner.otherExternalCommissioner || ''}
                                        className={cx(['ace-c-input--small', 'global!ace-u-width--full'])}
                                        maxLength={config.MAXIMUM_INPUT_CONTENT_LENGTH}
                                    />
                                </div>
                            </div>
                        </CheckboxGroup>
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--direction-row-reverse',
                                'global!ace-u-margin--top-32',
                            ])}
                        >
                            <ButtonPrimary
                                name="submitExternalCommissionersData"
                                type="submit"
                            >
                                {translateTab('button_label.save')}
                            </ButtonPrimary>
                        </div>
                    </Form>
                )}
        </Panel>
    );
};

ContractPartnerExternalCommissionersPanel.propTypes = {
    contractPartner: PropTypes.object,
    submitExternalCommissionersForm: PropTypes.func.isRequired,
};

ContractPartnerExternalCommissionersPanel.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitExternalCommissionersForm: payload => dispatch({
        type: contractPartnerActionTypes.SUBMIT_CONTRACT_PARTNER_EXTERNAL_COMMISSIONERS_FORM,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerExternalCommissionersPanel));
