/**
 * Contract partner info ArcGIS map popup template
 */
export default {
    'actions': [
        {
            id: 'find-contract-partner',
            className: 'esri-icon-launch-link-external',
            title: 'Informationen zum Vertragspartner',
        },
    ],
    'content': [
        {
            'type': 'fields',
            'fieldInfos': [
                {
                    'fieldName': 'name',
                    'label': 'Name',
                },
                {
                    'fieldName': 'fax',
                    'label': 'Fax',
                },
                {
                    'fieldName': 'phoneNo',
                    'label': 'Telefon',
                },
                {
                    'fieldName': 'address',
                    'label': 'Adresse',
                },
                {
                    'fieldName': 'WebsiteURL',
                    'label': 'Website',
                },
            ],
        },
    ],
};
